import React, { Component } from 'react';
import Table from "react-bootstrap/Table";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { PointSpreadLoading } from "react-loadingg";
import Abrechnungenhistory from "../ListComponents/Abrechnungenhistory";
import SelectField from "../Forms/SelectField";
import Beitragsliste from "../ListComponents/Beitragsliste";
import InputField from "../Forms/InputField";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import UserStore from "../../stores/UserStore";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Buchung from '../../models/Buchung';

interface AbrechnungProps extends RouteComponentProps { }

interface AbrechungState {

    loaded: boolean,
    loadingAbrechnung: boolean,
    sendingAbrechnung: boolean,
    showConfirm: boolean

    beitragsliste: Buchung[],
    notiz: string,

    //Hier bitte datentypen überprüfen
    abrBrutto?: number
    abrNetto?: number
    abrUst?: number
    bstAnteilNetto?: number
    bstAnteilUst?: number
    bstAnteilBrutto?: number
    vgdBrutto?: number
    vgdUst?: number
    vgdNetto?: number
    hvAnteilBrutto?: number
    hvAnteilUst?: number
    hvAnteilNetto?: number
    vgdGutschrift?: number
    mahngebuehren?: number

    abrechnungJanuar: string,
    abrechnungFebruar: string
    abrechnungMaerz: string
    abrechnungApril: string
    abrechnungMai: string
    abrechnungJuni: string
    abrechnungJuli: string
    abrechnungAugust: string
    abrechnungSeptember: string
    abrechnungOktober: string
    abrechnungNovember: string
    abrechnungDezember: string
    abrechnungDezemberVorJahr: string

    bstBelastung?: number

    abrechnungActive: boolean,

    currentAbrDate: string | Date
}

class Abrechnung extends Component<AbrechnungProps, AbrechungState> {

    constructor(props: AbrechnungProps) {
        super(props);

        if (!UserStore.hasBerechtigung('buha_abrechnung') && typeof this.props.history !== "undefined") {
            this.props.history.push("/notallowed/");
        }

        this.state = {
            loaded: false,
            loadingAbrechnung: true,
            sendingAbrechnung: false,
            showConfirm: false,

            beitragsliste: [],
            notiz: '',

            abrechnungJanuar: "",
            abrechnungFebruar: "",
            abrechnungMaerz: "",
            abrechnungApril: "",
            abrechnungMai: "",
            abrechnungJuni: "",
            abrechnungJuli: "",
            abrechnungAugust: "",
            abrechnungSeptember: "",
            abrechnungOktober: "",
            abrechnungNovember: "",
            abrechnungDezember: "",
            abrechnungDezemberVorJahr: "",

            abrechnungActive: false,

            currentAbrDate: this.buildCurrentAbrDate(new Date())
        }

    }

    buildCurrentAbrDate(value: Date) {
        //let month = String(newDate.getMonth() + 1).padStart(2, '0');
        //ALH20-53 Till 10. select pre-month // overruled => ALH20-177{
        let month = '0';
        let year = 0;

        if (value.getMonth() === 0) {
            month = '12';
            year = value.getFullYear() - 1;
        } else {
            month = String(value.getMonth()).padStart(2, '0');
            year = value.getFullYear();
        }

        let newDateDay = new Date(year, parseInt(month), 0);
        let day = String(newDateDay.getDate()).padStart(2, '0');

        return year + "-" + month + "-" + day + "T00:00:00"
    }

    updateAbrechnung(val: Date | string) {
        this.setState({
            loadingAbrechnung: true,
            currentAbrDate: val,
        });
        this.loadAbrechnung(val);
    }

    convertDates(buchung: Buchung) {
        buchung.buchungsdatum = new Date(buchung.buchungsdatum || "")
        return buchung;
    }

    async loadAbrechnung(dateBis: Date | string) {

        try {
            let res = await fetch(UserStore.url + '/abrechnung', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        abrBis: dateBis,
                    }
                })
            });

            let result = await res.json();

            if (result && result.success) {
                //check if values present

                let abrechnungActive = (
                    result.data.abrBrutto === 0 &&
                    result.data.abrNetto === 0 &&
                    result.data.abrUst === 0 &&
                    result.data.bstAnteilNetto === 0 &&
                    result.data.bstAnteilUst === 0 &&
                    result.data.bstAnteilBrutto === 0 &&
                    result.data.vgdBrutto === 0 &&
                    result.data.vgdUst === 0 &&
                    result.data.vgdNetto === 0 &&
                    result.data.hvAnteilBrutto === 0 &&
                    result.data.hvAnteilUst === 0 &&
                    result.data.hvAnteilNetto === 0 &&
                    result.data.vgdGutschrift === 0 &&
                    result.data.mahngebuehren === 0
                );

                this.setState({
                    loadingAbrechnung: false,
                    beitragsliste: result.data.beitragsliste.map(this.convertDates),

                    abrBrutto: result.data.abrBrutto,
                    abrNetto: result.data.abrNetto,
                    abrUst: result.data.abrUst,
                    bstAnteilNetto: result.data.bstAnteilNetto,
                    bstAnteilUst: result.data.bstAnteilUst,
                    bstAnteilBrutto: result.data.bstAnteilBrutto,
                    vgdBrutto: result.data.vgdBrutto,
                    vgdUst: result.data.vgdUst,
                    vgdNetto: result.data.vgdNetto,
                    hvAnteilBrutto: result.data.hvAnteilBrutto,
                    hvAnteilUst: result.data.hvAnteilUst,
                    hvAnteilNetto: result.data.hvAnteilNetto,
                    vgdGutschrift: result.data.vgdGutschrift,
                    mahngebuehren: result.data.mahngebuehren,

                    abrechnungJanuar: result.data.abrechnungJanuar,
                    abrechnungFebruar: result.data.abrechnungFebruar,
                    abrechnungMaerz: result.data.abrechnungMaerz,
                    abrechnungApril: result.data.abrechnungApril,
                    abrechnungMai: result.data.abrechnungMai,
                    abrechnungJuni: result.data.abrechnungJuni,
                    abrechnungJuli: result.data.abrechnungJuli,
                    abrechnungAugust: result.data.abrechnungAugust,
                    abrechnungSeptember: result.data.abrechnungSeptember,
                    abrechnungOktober: result.data.abrechnungOktober,
                    abrechnungNovember: result.data.abrechnungNovember,
                    abrechnungDezember: result.data.abrechnungDezember,
                    abrechnungDezemberVorJahr: result.data.abrechnungDezemberVorJahr,

                    bstBelastung: result.data.bstBelastung,

                    notiz: result.data.notiz,

                    abrechnungActive: abrechnungActive,

                    loaded: true,
                });
            }

        } catch (e) {
            console.log("Error in loading homemessage: ", e);
        }

    }

    componentDidMount() {
        let newDate = new Date();
        this.loadAbrechnung(this.buildCurrentAbrDate(newDate));

        document.title = 'Abrechnung | ' + UserStore.title

        this.setState({
            currentAbrDate: this.buildCurrentAbrDate(newDate)
        })

    }

    setNotizValue(val: string) {
        this.setState({
            notiz: val
        })
    }

    showConfirm(abrDate: Date) {
        const abrYear = abrDate.getFullYear();
        const abrMonth = abrDate.getMonth();

        //wenn im folgejahr buchungen vom vorjahr drin sind dann werden diese ignoriert
        return !!this.state.beitragsliste.filter(b => b.buchungsdatum!.getFullYear() === abrYear).filter(b => b.buchungsdatum!.getMonth() < abrMonth).length;
    }

    async finishAbrechnung(skipConfirmation: boolean = false) {

        this.setState({
            sendingAbrechnung: true
        })

        let show = false;
        let abrDate = new Date(this.state.currentAbrDate)

        if (!skipConfirmation)
            show = this.showConfirm(abrDate);

        if (!show) {
            //Send data
            let res = await fetch(UserStore.url + '/abrechnungSet', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        BSTNr: Number(UserStore.username),
                        dataset: {
                            bstNr: UserStore.username,
                            abrJahr: abrDate.getFullYear(),
                            abrMonat: (abrDate.getMonth() + 1),
                            abrBis: this.state.currentAbrDate,
                            abrOK: true,

                            notiz: this.state.notiz,
                        }
                    }
                })
            });

            let response = await res.json();

            if (response.success) {
                toast.success("Abrechnung abgesendet.", {
                    autoClose: false
                });
                this.updateAbrechnung(this.state.currentAbrDate);
            } else {
                toast.error("Abrechnung abgesenden fehlgeschlagen.", {
                    autoClose: false
                });
            }
        } else {
            this.setState({
                showConfirm: true
            })
        }

        this.setState({
            sendingAbrechnung: false
        })
    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    /**
     * Show Text from API or Button "Fehlmeldung" if currently we are in that month or 10 days later
     * @param month Month of this item (0=dez last year, ...)
     * @param textApi
     * @returns {string}
     */
    checkFehlmeldungButton(month: number, textApi: string | null) {

        let currMonth = (new Date()).getMonth() + 1;
        let currDay = (new Date()).getDate();

        if (textApi !== "" && textApi !== null) {
            if (textApi === "Fehlmeldung") {
                textApi = "Fehlmeldung abgegeben";
            }
            return textApi;
        } else if (currMonth === month || (currMonth === month + 1 && currDay <= 10)) {
            let callYear = (new Date()).getFullYear();
            return (
                <div><Button variant="outline-secondary" size="sm" className='fullSize' onClick={() => this.callFehlmeldung(month, callYear)}>Fehlmeldung eintragen</Button></div>
            )
        }
        return "";
    }

    getCurrentMonatSelectString() {
        let thisDate = new Date(this.state.currentAbrDate);

        let Monate = [
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember"
        ]

        return Monate[thisDate.getMonth()];
    }

    async callFehlmeldung(month: number, year: number) {

        if (month === 0) {
            month = 12;
            year -= 1;
        }

        let res = await fetch(UserStore.url + '/abrechnungfehlmeldung', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    AbrJahr: year,
                    AbrMonat: month,
                }
            })
        });

        let result = await res.json();

        if (result && result.success) {
            this.updateAbrechnung(this.state.currentAbrDate);
        } else if (!result.success && result.data.message) {
            toast.error(result.data.message, {
                autoClose: 1000
            });
        }
    }

    closeConfirm() {
        this.setState({
            showConfirm: false
        })
    }

    changeAbrechnungMonth() {
        //lowest month
        let lowestMonthBuchung: Buchung | undefined = undefined;

        for (const buchung of this.state.beitragsliste.filter(b => b.buchungsdatum!.getFullYear() === new Date().getFullYear())) {
            if (!lowestMonthBuchung || lowestMonthBuchung!.buchungsdatum!.getMonth() > buchung.buchungsdatum!.getMonth()) {
                lowestMonthBuchung = { ...buchung }
            }
        }

        if (lowestMonthBuchung) {
            const months = this.getAbrechnungsMonths(lowestMonthBuchung!.buchungsdatum!);
            if (months.length) {
                const abrDate = new Date(months[months.length - 1].id);
                this.updateAbrechnung(this.buildCurrentAbrDate(new Date(new Date().getFullYear(), abrDate.getMonth() + 1, abrDate.getDate())));
            }
            this.closeConfirm();
        } else {
            toast.error("Fehler beim Setzen des Abrechnungsmonats, bitte selbstständig den Monat abändern.", {
                autoClose: 5000
            });
        }
    }

    getAbrechnungsMonths(newDate: Date) {
        let year = newDate.getFullYear();

        //build dropdown values
        let valuesAbrechnungMonths = [];
        if (newDate.getMonth() === 0) {
            valuesAbrechnungMonths.push({ 'id': (year - 1) + '-12-31T00:00:00', 'value': 'Dezember ' + (year - 1) });
        }
        if (newDate.getMonth() >= 0) { valuesAbrechnungMonths.push({ 'id': year + '-01-31T00:00:00', 'value': 'Januar ' + year }) }
        if (newDate.getMonth() >= 1) { valuesAbrechnungMonths.push({ 'id': year + '-02-29T00:00:00', 'value': 'Februar ' + year }) }
        if (newDate.getMonth() >= 2) { valuesAbrechnungMonths.push({ 'id': year + '-03-31T00:00:00', 'value': 'März ' + year }) }
        if (newDate.getMonth() >= 3) { valuesAbrechnungMonths.push({ 'id': year + '-04-30T00:00:00', 'value': 'April ' + year }) }
        if (newDate.getMonth() >= 4) { valuesAbrechnungMonths.push({ 'id': year + '-05-31T00:00:00', 'value': 'Mai ' + year }) }
        if (newDate.getMonth() >= 5) { valuesAbrechnungMonths.push({ 'id': year + '-06-30T00:00:00', 'value': 'Juni ' + year }) }
        if (newDate.getMonth() >= 6) { valuesAbrechnungMonths.push({ 'id': year + '-07-31T00:00:00', 'value': 'Juli ' + year }) }
        if (newDate.getMonth() >= 7) { valuesAbrechnungMonths.push({ 'id': year + '-08-31T00:00:00', 'value': 'August ' + year }) }
        if (newDate.getMonth() >= 8) { valuesAbrechnungMonths.push({ 'id': year + '-09-30T00:00:00', 'value': 'September ' + year }) }
        if (newDate.getMonth() >= 9) { valuesAbrechnungMonths.push({ 'id': year + '-10-31T00:00:00', 'value': 'Oktober ' + year }) }
        if (newDate.getMonth() >= 10) { valuesAbrechnungMonths.push({ 'id': year + '-11-30T00:00:00', 'value': 'November ' + year }) }
        if (newDate.getMonth() >= 11) { valuesAbrechnungMonths.push({ 'id': year + '-12-31T00:00:00', 'value': 'Dezember ' + year }) }

        return valuesAbrechnungMonths;
    }

    onClickFinishAbrechung = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (this.state.sendingAbrechnung) {
            return;
        }
        this.setState({
            sendingAbrechnung: true
        })
        await this.finishAbrechnung(event.currentTarget.name === "finishSkip")
    }

    render() {

        let newDate = new Date();
        const valuesAbrechnungMonths = this.getAbrechnungsMonths(newDate);

        if (this.state.loaded) {
            return (
                <div>
                    <h3 className="alh_pageheader">Abrechnung</h3>
                    <div className="alh_subheader">Sehen Sie hier alle Abrechnungen.</div>

                    <Container>
                        <Row className="alh_rowTopList">
                            <Col lg={6}>
                                <div className="alh_frontBoxes">
                                    <h3>Erledigte Abrechnungen</h3>
                                    <Abrechnungenhistory />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="alh_frontBoxes">

                                    <h3>Abrechnungen</h3>
                                    <Table striped bordered hover size="sm" className="alhTable">
                                        <tbody>
                                            <tr>
                                                <th>Dez. Vorjahr</th>
                                                <th>{this.checkFehlmeldungButton(0, this.state.abrechnungDezemberVorJahr)}</th>
                                                <th>&nbsp;</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                            <tr>
                                                <th>Januar</th>
                                                <th>{this.checkFehlmeldungButton(1, this.state.abrechnungJanuar)}</th>
                                                <th>Juli</th>
                                                <th>{this.checkFehlmeldungButton(7, this.state.abrechnungJuli)}</th>
                                            </tr>
                                            <tr>
                                                <th>Februar</th>
                                                <th>{this.checkFehlmeldungButton(2, this.state.abrechnungFebruar)}</th>
                                                <th>August</th>
                                                <th>{this.checkFehlmeldungButton(8, this.state.abrechnungAugust)}</th>
                                            </tr>
                                            <tr>
                                                <th>März</th>
                                                <th>{this.checkFehlmeldungButton(3, this.state.abrechnungMaerz)}</th>
                                                <th>September</th>
                                                <th>{this.checkFehlmeldungButton(9, this.state.abrechnungSeptember)}</th>
                                            </tr>
                                            <tr>
                                                <th>April</th>
                                                <th>{this.checkFehlmeldungButton(4, this.state.abrechnungApril)}</th>
                                                <th>Oktober</th>
                                                <th>{this.checkFehlmeldungButton(10, this.state.abrechnungOktober)}</th>
                                            </tr>
                                            <tr>
                                                <th>Mai</th>
                                                <th>{this.checkFehlmeldungButton(5, this.state.abrechnungMai)}</th>
                                                <th>November</th>
                                                <th>{this.checkFehlmeldungButton(11, this.state.abrechnungNovember)}</th>
                                            </tr>
                                            <tr>
                                                <th>Juni</th>
                                                <th>{this.checkFehlmeldungButton(6, this.state.abrechnungJuni)}</th>
                                                <th>Dezember</th>
                                                <th>{this.checkFehlmeldungButton(12, this.state.abrechnungDezember)}</th>
                                            </tr>
                                        </tbody>
                                    </Table>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <br />

                    <Container>
                        <Row className="alh_rowTopList">
                            <Col lg={12}>
                                <div className="">
                                    <h3>Abrechnung für Zahlungen im Monat <span className="highlightValue">{this.getCurrentMonatSelectString()}</span></h3>
                                    <span>Hier können Sie den Monat anpassen:</span>
                                    <SelectField
                                        id='abrechnugenMonat'
                                        name='abrechnugenMonat'
                                        withPleaseSelect={false}
                                        formGroupClassName='small-form-group'
                                        size='sm'
                                        options={valuesAbrechnungMonths}
                                        value={this.state.currentAbrDate.toString()}
                                        onChange={(e: string) => this.updateAbrechnung(e)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row className="alh_rowTopList">
                            <Col lg={4}>
                                <div className="alh_frontBoxes clearMinheight">
                                    <Table striped bordered hover size="sm" className="alhTable">
                                        <tbody>
                                            <tr>
                                                <th>Abrechnung netto:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.abrNetto ?? 0)}</th>
                                            </tr>
                                            <tr>
                                                <th>zzgl. UST:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.abrUst ?? 0)}</th>
                                            </tr>
                                            <tr>
                                                <th>Abrechnung brutto:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.abrBrutto ?? 0)}</th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div><span className="alh_bold">Belastung</span>: {this.numberFormat(this.state.bstBelastung ?? 0)}</div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="alh_frontBoxes clearMinheight">
                                    <Table striped bordered hover size="sm" className="alhTable">
                                        <tbody>
                                            <tr>
                                                <th>BST Anteil netto:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.bstAnteilNetto ?? 0)}</th>
                                            </tr>
                                            <tr>
                                                <th>zzgl. UST:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.bstAnteilUst ?? 0)}</th>
                                            </tr>
                                            <tr>
                                                <th>BST Anteil brutto:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.bstAnteilBrutto ?? 0)}</th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table striped bordered hover size="sm" className="alhTable alhTable_clearMarginBottom">
                                        <tbody>
                                            <tr>
                                                <th>VGD netto:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.vgdNetto ?? 0)}</th>
                                            </tr>
                                            <tr>
                                                <th>zzgl. UST:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.vgdUst ?? 0)}</th>
                                            </tr>
                                            <tr>
                                                <th>VGD brutto:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.vgdBrutto ?? 0)}</th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="alh_frontBoxes clearMinheight">
                                    <Table striped bordered hover size="sm" className="alhTable">
                                        <tbody>
                                            <tr>
                                                <th>HV Anteil netto:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.hvAnteilNetto ?? 0)}</th>
                                            </tr>
                                            <tr>
                                                <th>zzgl. UST:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.hvAnteilUst ?? 0)}</th>
                                            </tr>
                                            <tr>
                                                <th>HV Anteil brutto:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.hvAnteilBrutto ?? 0)}</th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table striped bordered hover size="sm" className="alhTable">
                                        <tbody>
                                            <tr>
                                                <th>VGD Gutschrift:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.vgdGutschrift ?? 0)}</th>
                                            </tr>
                                            <tr>
                                                <th>Mahngebühren:</th>
                                                <th className={(this.state.loadingAbrechnung ? 'pulsate' : '') + ' text-right halfSize'}>{this.numberFormat(this.state.mahngebuehren ?? 0)}</th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <br />

                    <Container>
                        <Row className="alh_rowTopList">
                            <Col lg={12}>
                                <div className="alh_frontBoxes clearMinheight">
                                    <h3>Notiz zur Abrechnung</h3>
                                    <InputField
                                        size='sm'
                                        as='textarea'
                                        type='clean'
                                        inputGroupClass=''
                                        placeholder='Notiz zur Abrechnung (Nur ausfüllen, wenn Klärungsbedarf besteht.)'
                                        required={false}
                                        value={this.state.notiz}
                                        onChange={(val) => this.setNotizValue(val)}
                                    />
                                    <br />

                                    {!this.state.sendingAbrechnung ?
                                        <Button variant="success" className='fullSize alhButton' disabled={this.state.abrechnungActive || this.state.sendingAbrechnung} onClick={this.onClickFinishAbrechung}>Abrechnung absenden</Button> :
                                        <div className="alh_stat_container">
                                            <PointSpreadLoading color="#ffc800" />
                                        </div>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <br />

                    <Container>
                        <Row className="alh_rowTopList">
                            <Col lg={12}>
                                <div className="alh_frontBoxes clearMinheight">
                                    <h3>Beitragsliste</h3>
                                    <Beitragsliste liste={this.state.beitragsliste} loading={!this.state.loaded} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <br />

                    <Modal show={this.state.showConfirm} onHide={() => this.closeConfirm()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <span className="alh_bold">Achtung</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Die Abrechnung enthält Buchungen für mehrere Monate.<br />
                            Wollen Sie die Abrechnung übermitteln oder den Abrechnungsmonat korrigieren?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" name="finishSkip" disabled={this.state.sendingAbrechnung} onClick={this.onClickFinishAbrechung}>
                                Übermitteln
                            </Button>
                            <Button variant="primary" onClick={() => this.changeAbrechnungMonth()}>
                                Abrechnungsmonat korrigieren
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            );
        } else {
            return (
                <div className="alh_stat_container">
                    <PointSpreadLoading color="#ffc800" />
                </div>
            );
        }
    }
}

export default withRouter(Abrechnung);
