export enum Decision {
    yes = 'Ja',
    no = 'Nein'
}

export enum ZahlungsweiseSollRechnungen {
    Überweisung = "Überweisung",
    Bankeinzug = "Bankeinzug"
}

export enum Ueberweisungsoptionen {
    Sofort = "Sofort",
    ZehnTage = "10 Tage",
    VierzehnTage = "14 Tage"
}

export enum ZahlungsweiseHaben {
    Überweisung = "Überweisung",
    Bankeinzug = "Bankeinzug",
    Bar = "Bar",
    ECCash = "EC Cash"
}

export enum Beratungsstellen {
    Beratungsstelle1 = "Beratungsstelle 1",
    Beratungsstelle2 = "Beratungsstelle 2"
}