import React, { Component } from 'react';
import ucImage from '../../assets/images/notallowed.png';
import { Alert } from "react-bootstrap";

class UnderConstruction extends Component {
    render() {
        return (
            <div className="notAllowed">
                <img src={ucImage}
                    width="70px"
                    alt="Not allowed" />

                <br /><br />

                <Alert key="newAlertOutdated" variant="danger">
                    <div><b>Sie haben keine Berechtigung diese Seite anzusehen.</b></div>
                </Alert>
            </div>
        );
    }
}

export default UnderConstruction;