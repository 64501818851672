import React from 'react';
import { observer } from "mobx-react";
import UserStore from "./stores/UserStore";
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

//Style
import './styles/App.css';

//Screens
import Home from './Components/Screens/Home';
import Hilfe from "./Components/Screens/Hilfe";
import MyBst from "./Components/Screens/MyBst";
import Statistik from "./Components/Screens/Statistik";
import Mitglied from "./Components/Screens/Mitglied";
import Mitgliederliste from "./Components/ListComponents/Mitgliederliste";
import NotAllowed from "./Components/Screens/NotAllowed";
import Belegeliste from "./Components/ListComponents/Belegeliste";
import Sammelbuchung from "./Components/Screens/Sammelbuchung";

//Components
import Footer from "./Components/Footer";
import LoginForm from "./Components/LoginForm";
import NavigationBar from "./Components/NavigationBar";
import { Layout } from "./Components/Layout";
import Imprint from "./Components/Screens/Imprint";
import Datasecurity from "./Components/Screens/Datasecurity";
import Postbox from "./Components/Screens/Postbox";
import { PointSpreadLoading } from "react-loadingg";
import Uploads from "./Components/Screens/Uploads";
import Geburtstagsliste from "./Components/ListComponents/Geburtstagsliste";
import Mitarbeiter from "./Components/ListComponents/Mitarbeiter";
import { ToastContainer, Slide } from "react-toastify";
import Offenebeitraege from "./Components/ListComponents/Offenebeitraege";
import OffeneBEIdent from "./Components/ListComponents/OffeneBEIdent";
import Abrechnung from "./Components/Screens/Abrechnung";
import Datenexport from "./Components/Screens/Datenexport";
import Bstwechsel from "./Components/Screens/Bstwechsel";
import { ALHProvider } from "./Components/ALHContext";
import Widgets from "./Components/Blocks/Widgets";
import Buchungsjournal from "./Components/ListComponents/Buchungsjournal";

import ReactGA from "react-ga";
import MatomoTracker from '@datapunt/matomo-tracker-js'
import { createBrowserHistory } from "history";
import { runInAction } from 'mobx';
import Voreinstellungen from './Components/Screens/Voreinstellungen';
import MitarbeiterCreate from './Components/Screens/MitarbeiterCreate';
import { HeartbeatService } from './services/HeartbeatService';
import { BstContext } from './stores/BstContextProvider';
import MyBstService from './services/MyBstService';

// Define url for API
const ALHConfigs = function () {
    return {
        defLogin: "",
        defPswd: ""
    };
}

ReactGA.initialize("UA-217830-7");
const MaTracker = new MatomoTracker({
    urlBase: 'https://matomo.alh-webservice.de',
    siteId: parseInt(process.env.REACT_APP_MATOMO_SITEID!),
})

const history = createBrowserHistory();
history.listen(update => {
    ReactGA.set({ page: update.location.pathname });
    ReactGA.pageview(update.location.pathname);

    MaTracker.trackPageView();
});

class App extends React.Component<{}, { mandantId: number, bstLoaded: boolean }> {

    // resembles the useContext hook in functional components
    static contextType = BstContext
    context!: React.ContextType<typeof BstContext>

    constructor(props: {}) {
        super(props);

        UserStore.isLoggedIn = false;
        UserStore.loading = true;

        this.state = {
            mandantId: UserStore.mandantId,
            bstLoaded: false
        }
    }

    async componentDidMount() {

        //Header height
        window.addEventListener('scroll', () => {
            if (window.scrollY === 0) {
                document.getElementById('root')?.classList.remove('alhScrolled');
            } else {
                document.getElementById('root')?.classList.add('alhScrolled');
            }
        });

        //Track Page view
        if (window.location.href.indexOf("localhost") === -1) {
            ReactGA.pageview(window.location.pathname);
            MaTracker.trackPageView();
        }

        await this.loadBstSettings();

        this.isLoggedInRequester();
    }

    async componentDidUpdate(_: Readonly<{}>, prevState: Readonly<{ mandantId: number; bstLoaded: boolean; }>, snapshot?: any): Promise<void> {
        const { settings } = this.context

        if (!prevState.bstLoaded && !settings) {
            await this.loadBstSettings()
        }
    }

    async loadBstSettings() {
        const { setSettings } = this.context

        // bst initial load
        const resultBST = await MyBstService.get()

        if (resultBST) {
            setSettings(resultBST)

            this.setState({
                bstLoaded: true
            })
        }
    }

    async isLoggedInRequester() {
        let me = this;
        try {

            let result = await HeartbeatService.heartbeat()

            if (result && result.success && result.bstNr !== "") {

                //https://mobx.js.org/actions.html#asynchronous-actions
                runInAction(() => {
                    UserStore.username = result.username;
                    UserStore.name = result.name;
                    UserStore.anrede = result.anrede;
                    UserStore.mandantId = result.mandantId;
                    UserStore.rights = result.rights;
                    UserStore.bstNr = result.bstNr + "";
                    UserStore.isLoggedIn = true;
                    UserStore.loading = false;
                })

                this.setState({
                    mandantId: result.mandantId,
                });

                //periodic check
                setTimeout(function () {
                    me.isLoggedInRequester();
                }, 60000);

            } else {
                runInAction(() => {
                    UserStore.isLoggedIn = false;
                    UserStore.loading = false;
                })

                let currentURL = new URL(window.location.href);
                if (currentURL.pathname !== '/impressum' && currentURL.pathname !== '/datenschutz' && currentURL.pathname !== "" && !currentURL.pathname.startsWith('/login/') && currentURL.pathname !== "/") {
                    console.log('not logged in -> redirect')
                    window.location.href = "/login" + currentURL.pathname;
                }

                setTimeout(function () {
                    me.isLoggedInRequester();
                }, 30000);
            }

        } catch (e) {
            runInAction(() => {
                UserStore.loading = false;
                UserStore.isLoggedIn = false;
            })
        }
    }

    render() {

        if (UserStore.loading) {

            return (
                <React.Fragment>
                    {/*<NavigationBar mode="login" />*/}
                    <PointSpreadLoading color="#ffc800" />
                </React.Fragment>
            )

        } else {

            if (UserStore.isLoggedIn && this.state.bstLoaded) {

                return (
                    <ALHProvider config={ALHConfigs()}>
                        <Router>
                            <Route>
                                <NavigationBar mode="normal" username={UserStore.username} />
                            </Route>
                            <Widgets />
                            <ToastContainer />
                            <Layout>
                                <Switch>
                                    <Route exact path='/'>
                                        <Home />
                                    </Route>
                                    <Route exact path='/hilfe'>
                                        <Hilfe />
                                    </Route>

                                    {/* Meine Beratungsstelle Routs */}
                                    <Route exact path='/meinebst'>
                                        <MyBst />
                                    </Route>
                                    <Route exact path='/voreinstellungen'>
                                        <Voreinstellungen />
                                    </Route>
                                    <Route exact path='/mitarbeiter'>
                                        <Mitarbeiter />
                                    </Route>
                                    <Route exact path='/mitarbeiter/neu'>
                                        <MitarbeiterCreate />
                                    </Route>
                                    <Route exact path='/statistik'>
                                        <Statistik />
                                    </Route>
                                    <Route exact path='/mitgliederliste'>
                                        <Mitgliederliste />
                                    </Route>
                                    <Route exact path='/mitgliederliste/:search'>
                                        <Mitgliederliste />
                                    </Route>
                                    <Route exact path='/mitglied/:id'>
                                        <Mitglied />
                                    </Route>
                                    <Route exact path='/mitglied'>
                                        <Mitglied />
                                    </Route>
                                    <Route exact path='/mitgliedBlanko'>
                                        <Mitgliederliste mode="blanko" />
                                    </Route>
                                    <Route exact path='/mitgliedBlankoNew'>
                                        <Mitgliederliste mode="blanko" addNew={true} />
                                    </Route>

                                    {/* Extra Routs */}
                                    <Route exact path='/geburtstagsliste'>
                                        <Geburtstagsliste />
                                    </Route>
                                    <Route exact path='/blankounterlagen'>
                                        <Mitgliederliste mode="blanko" addNew={false} />
                                    </Route>
                                    <Route exact path='/belegeliste'>
                                        <Belegeliste />
                                    </Route>
                                    <Route exact path='/abrechnung'>
                                        <Abrechnung />
                                    </Route>
                                    <Route exact path='/sammelbuchung'>
                                        <Sammelbuchung />
                                    </Route>
                                    <Route exact path='/offeneBeitraege'>
                                        <Offenebeitraege mode="OB" />
                                    </Route>
                                    <Route exact path='/mahnliste'>
                                        <Offenebeitraege mode="ML" />
                                    </Route>
                                    <Route exact path='/mahnlistera'>
                                        <Offenebeitraege mode="MLRA" />
                                    </Route>
                                    <Route exact path='/zahlungserinnerung'>
                                        <Offenebeitraege mode="ZEL" />
                                    </Route>
                                    <Route exact path='/offeneBEIdentBE'>
                                        <OffeneBEIdent mode="BE" />
                                    </Route>
                                    <Route exact path='/offeneBEIdentID'>
                                        <OffeneBEIdent mode="ID" />
                                    </Route>
                                    <Route exact path='/postbox'>
                                        <Postbox />
                                    </Route>
                                    <Route exact path='/uploads'>
                                        <Uploads />
                                    </Route>
                                    <Route exact path='/datenexport'>
                                        <Datenexport />
                                    </Route>
                                    <Route exact path='/bstwechsel'>
                                        <Bstwechsel />
                                    </Route>
                                    <Route exact path='/buchungsjournal'>
                                        <Buchungsjournal />
                                    </Route>

                                    {/* Allgemeiner Routs */}
                                    <Route exact path='/impressum'>
                                        <Imprint />
                                    </Route>
                                    <Route exact path='/notallowed'>
                                        <NotAllowed />
                                    </Route>
                                    <Route exact path='/datenschutz'>
                                        <Datasecurity />
                                    </Route>
                                </Switch>
                            </Layout>
                            <Route>
                                <Footer withSearch="false" />
                            </Route>
                        </Router>
                    </ALHProvider>
                )

            } else {

                return (
                    <React.Fragment>
                        {/*<NavigationBar mode="login" />*/}
                        <ToastContainer
                            transition={Slide}
                        />
                        <ALHProvider config={ALHConfigs()}>
                            <Layout>
                                <Router>
                                    <Switch>
                                        <Route exact path='/'>
                                            <LoginForm setBstSettings={() => this.loadBstSettings()} />
                                        </Route>
                                        <Route exact path='/login/:hist1'
                                            children={({ match }) => (
                                                <LoginForm setBstSettings={() => this.loadBstSettings()} match={
                                                    { params: { ...match?.params } }
                                                } />
                                            )}
                                        />
                                        <Route exact path='/login/:hist1/:hist2'
                                            children={({ match }) => (
                                                <LoginForm setBstSettings={() => this.loadBstSettings()} match={
                                                    { params: { ...match?.params } }
                                                } />
                                            )}
                                        />
                                        <Route exact path='/impressum' component={Imprint} />
                                        <Route exact path='/datenschutz' component={Datasecurity} />
                                    </Switch>

                                </Router>
                            </Layout>
                            <Footer withSearch="false" />
                        </ALHProvider>
                    </React.Fragment>
                )

            }
        }

    }
}

export default observer(App);
