import React, { Component } from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader'
import { Col, Container, FormControl, InputGroup, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortNumericDown } from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "../Forms/SubmitButton";
import SelectField from "../Forms/SelectField";
import UserStore from "../../stores/UserStore";
import { toast } from "react-toastify";
import { trackEvent, trackException } from "../ALHUtility";
import InfoIcon from "../Blocks/InfoIcon";
import { HilfeModal } from "./Hilfe";
import { WaveTopBottomLoading } from "react-loadingg";
import Moment from "react-moment";
import InputSearchField from "../Forms/InputSearchField";
import MitgliedItem from '../../models/Mitglied';
import InputField from '../Forms/InputField';

interface UploadProps {
    mode?: string
    mGNr?: string
    withHistory?: boolean
    active?: boolean
}

interface UploadState {
    file: string[]
    mGNr?: string
    mGNrTemp?: string
    uploadMode: string
    extension: string
    uploadedFiles: IFileWithMeta[]
    uploading: boolean
    mgSearchList: MitgliedItem[]
    withHistory?: boolean
    uploadHistory: {
        datum: Date
        dokumentArt: string
    }[]
    active: boolean
    loading: boolean
    uploadNotiz?: string
}

class Uploads extends Component<UploadProps, UploadState> {

    constructor(props: UploadProps) {
        super(props)
        this.state = {
            file: [],
            mGNr: this.props.mGNr,
            mGNrTemp: this.props.mGNr,
            uploadMode: "",
            extension: "",
            uploadedFiles: [],
            uploading: false,

            mgSearchList: [],

            withHistory: this.props.withHistory,
            uploadHistory: [],

            active: false,
            loading: true,
        }

        this.handleChangeStatus = this.handleChangeStatus.bind(this)

        if (this.props.mode !== 'small') {
            document.title = 'Uploads | ' + UserStore.title;
        }
    }

    async componentDidMount() {

        await this.getAllMitgliederSearch();

    }

    async getAllMitgliederSearch() {

        if (this.state.mgSearchList.length === 0) {

            try {

                let res = await fetch(UserStore.url + '/mitglieder', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                let result = await res.json();

                //save in state for cache
                if (result && result.success) {
                    this.setState({
                        mgSearchList: result.data.mitglieder,
                    });
                }

            } catch (e) {
                console.log("Error in loading Mitgliederliste: " + e);
            }

        }

    }

    async componentDidUpdate(prevProps: UploadProps) {

        //Init Component if active clicked
        if (this.props.active !== prevProps.active && this.props.active) {
            await this.loadUploadHistory();

            this.setState({
                active: true,
                loading: false,
            });
        }

    }

    async loadUploadHistory() {

        //Load questions
        let resMa = await fetch(UserStore.url + '/bstdocupload', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    'mgNr': this.state.mGNr,
                }
            })
        });

        let result = await resMa.json();

        if (result && result.success) {
            this.setState({
                uploadHistory: result.data.resources,
            });
        }

    }

    handleChangeStatus({ meta, file, xhr, ...rest }: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) {

        if (status === "removed") {
            const reader = new FileReader();
            reader.onload = (event) => {
                //cut base64 data for Server (ALH20-11)
                if (event.target) {
                    let fileb64 = event.target.result;
                    if (fileb64 && typeof fileb64 === "string") {
                        this.setState({
                            file: [...this.state.file.filter(f => f !== (fileb64! as string).substring((fileb64! as string).indexOf("base64,") + 7))],
                        });
                    }
                }
            };
            reader.readAsDataURL(file);

            this.setState({
                uploadedFiles: allFiles
            })
        }

        if (status === "done") {
            const reader = new FileReader();
            reader.onload = (event) => {
                //cut base64 data for Server (ALH20-11)
                if (event.target) {
                    let fileb64 = event.target.result;
                    if (fileb64 && typeof fileb64 === "string") {
                        this.setState({
                            file: [...this.state.file, fileb64.substring(fileb64.indexOf("base64,") + 7)],
                        });
                    }
                }
            };
            reader.readAsDataURL(file);

            //get extension
            const re = /(?:\.([^.]+))?$/;
            const temp = re.exec(file.name)
            let ext = ""
            if (temp) {
                ext = temp[1]
            }

            this.setState({
                extension: ext,
                uploadedFiles: allFiles
            });
        }

    }

    async saveData() {
        this.setState({
            uploading: true,
        });

        //Check MgNr upload in SO mode
        let thisMgNr = this.state.mGNr;
        if (!thisMgNr) {
            thisMgNr = this.state.mGNrTemp;
        }

        for (let index = 0; index < this.state.file.length; index++) {
            let dokumentArt = this.state.uploadMode;

            if (this.state.uploadMode === "EB") {
                dokumentArt = "BE_KÜ"
            }

            if (this.state.file.length === 2) {
                dokumentArt += "_" + (index + 1)
            }

            //Send data
            let res = await fetch(UserStore.url + '/docuploadSet', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        BSTNr: Number(UserStore.bstNr),
                        dataset: {
                            BSTNr: UserStore.username,
                            mgnr: thisMgNr,
                            dokumentArt: dokumentArt,
                            dokumentDateiendung: this.state.extension,
                            dokument: this.state.file[index],
                            dokumentNotiz: this.state.uploadNotiz
                        }
                    }
                })
            });

            try {
                let response = await res.json();

                if (response.success) {
                    toast.success("Datei wurde erfolgreich hochgeladen.", {
                        autoClose: 5000
                    });

                    this.loadUploadHistory()

                    this.state.uploadedFiles[0].remove();

                    if (this.props.mode === 'small') {
                        trackEvent('Files', 'Upload', this.state.uploadMode + " | small");
                    } else {
                        trackEvent('Files', 'Upload', this.state.uploadMode);
                    }

                } else if (!response.success && response.data.message) {
                    toast.warning(response.data.message);

                    this.setState({
                        uploading: false,
                    });

                    trackException('FilesUpload', false);
                }

            } catch (e) {

                toast.warning("Leider ist etwas schief gelaufen beim Upload. Evtl. war die Datei größer als erlaubt. Bitte kontrollieren Sie dies und versuchen Sie es erneut.", {
                    autoClose: 20000
                });

                this.setState({
                    uploading: false,
                });

                trackException('FilesUploadCatch', true);

            }
        }

        this.setState({
            uploading: false,
            file: [],
            mGNr: thisMgNr,
            uploadedFiles: [],
            uploadNotiz: ''
        })
    }

    setInputValue(field: string, val: string) {
        switch (field) {
            case 'uploadNotiz':
                this.setState({
                    uploadNotiz: val
                })
                break;
            case 'mGNr':
                this.setState({
                    mGNr: this.state.uploadMode === "SO" ? "" : val,
                    mGNrTemp: val
                })
                break;
            case 'mgNameSearch':
                this.setState({
                    mGNr: val
                })
                break;
            case 'mgNameSearchTemp':
                this.setState({
                    mGNrTemp: val,
                    uploadNotiz: ''
                })
                break;
            case 'uploadMode':
                this.resetFilesUpload()
                this.setState({
                    uploadMode: val
                })
                break;
        }
    }

    resetFilesUpload() {
        let uploadedFiles = this.state.uploadedFiles;
        if (uploadedFiles) {
            // he only remove one element because the loop just gets disrupted for no reason
            // method sequence => remove() => handleRemove()(dropzone.tsx) => handleChangeStatus => internal file array in dropzone.tsx gets spliced
            for (let index = 0; index < uploadedFiles.length; index++) {
                const element = uploadedFiles[index];
                element.remove()   
            }
        }
    }

    render() {
        const { withHistory = false, mode = "normal" } = this.props
        const { mGNr = '' } = this.state

        return (
            <div>
                <div>
                    <h3 className="alh_pageheader">Upload Dokumente <HilfeModal
                        hilfeFile="uploaddokumente.json"
                    /></h3>
                    <div className={(mode === "small") ? "d-none" : "alh_subheader"}>Hier können Sie im Dropdown Ihr gewünschtes Dokument auswählen, hochladen und direkt an die Hauptverwaltung senden.</div>

                    <Container>
                        <Row className="alh_rowTopList">
                            <Col md={(mode === "small") ? 12 : 6}>

                                <InputGroup className={"mb-2 mr-sm-2 " + ((mGNr === "") ? '' : 'd-none')}>
                                    <InputSearchField
                                        placeholder="Namen oder MG-Nummer eingeben"
                                        values={this.state.mgSearchList}
                                        value={this.state.mGNrTemp}
                                        onChange={(text) => this.setInputValue('mgNameSearchTemp', text)}
                                        onSelItem={(id) => this.setInputValue('mgNameSearch', id)}
                                    />
                                    <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Hier können Sie den Namen oder die Mitgliedsnummer des Mitgliedes suchen.</InfoIcon>
                                </InputGroup>

                                <InputGroup className={"mb-2 mr-sm-2 " + ((mGNr === "") ? 'd-none' : '')}>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text><FontAwesomeIcon icon={faSortNumericDown} /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        size="sm"
                                        id="inlineFormInputGroupUsername2"
                                        placeholder="Mitgliedsnummer (z.B. 1234-5678)"
                                        value={(mGNr) ? mGNr : ""}
                                        disabled={(!!this.props.mGNr)}
                                        onChange={(e) => this.setInputValue('mGNr', e.target.value)}
                                    />
                                    <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Hier können nur Mitgliedsnummern eingegeben werden, die aktuell Ihrer Beratungsstelle zugeordnet sind.
                                        Wählen Sie beim Upload “Sonstige” aus, kann statt der Mitgliedsnummer auch ein Freitext eingegeben werden.</InfoIcon>
                                </InputGroup>

                                <SelectField
                                    id='uploadMode'
                                    name='uploadMode'
                                    withPleaseSelect={true}
                                    options={
                                        [
                                            { id: 'BE', value: 'Beitrittserklärung (BE)' },
                                            { id: 'EB', value: 'Einmalberatung (BE + KÜ)' },
                                            { id: 'KÜ', value: 'Kündigung (KÜ)' },
                                            { id: 'SO', value: 'Sonstige' }
                                        ]
                                    }
                                    value={this.state.uploadMode}
                                    onChange={(val) => this.setInputValue('uploadMode', val)}
                                />

                                {mGNr && <InputField
                                    as='textarea'
                                    type='textclean'
                                    id='uploadNotiz'
                                    name='uploadNotiz'
                                    size='sm'
                                    placeholder='Infos für hochgeladene Datei(en)'
                                    initialSize={1}
                                    value={this.state.uploadNotiz ?? ''}
                                    onChange={(val) => this.setInputValue('uploadNotiz', val)}
                                />}
                            </Col>
                            <Col md={(this.props.mode === "small") ? 12 : 6}>
                                <Dropzone
                                    onChangeStatus={this.handleChangeStatus}
                                    accept={"image/jpeg,image/png,.xlsx,.docx,.est" + ((new Date()).getFullYear() - 1) + ",.est" + ((new Date()).getFullYear() - 2) + ",.steuer" + ((new Date()).getFullYear() - 1) + ",.steuer" + ((new Date()).getFullYear() - 2) + ",.pdf,"}
                                    maxFiles={2}
                                    inputContent={<div>
                                        Datei(en) zum Upload hierher ziehen oder in das Feld klicken um die Datei auszuwählen. <br />
                                        Mögliche Dateiformate: PDF, PNG, JPG oder JPEG <br />
                                        Maximale Dateigröße: 5 MB
                                    </div>}
                                    canRemove
                                />
                            </Col>
                        </Row>
                    </Container>
                    <SubmitButton
                        text={!this.state.uploading ? "Jetzt senden" : "Daten werden übermittelt ..."}
                        onClick={() => this.saveData()}
                        disabled={this.checkUploadDisable(mGNr)}
                        mode="success" />
                </div>

                {withHistory &&
                    <div className="alh_spacerTop">
                        <Container>
                            <Row className="alh_rowTopList">
                                <Col md={(this.props.mode === "small") ? 12 : 6}>
                                    <h3 className="alh_pageheader">Historische Uploads</h3>
                                    <div className="alh_subheader">Sehen Sie hier alle bereits für dieses Mitglied, in der Vergangenheit hochgeladenen Dokumente.</div>

                                    {!this.state.loading &&
                                        <>
                                            <Table striped bordered hover size="sm" className="alhTable">
                                                <thead>
                                                    <tr>
                                                        <th>Dokumenten-Art</th>
                                                        <th className="text-right">Upload Zeitpunkt</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.uploadHistory.sort((a, b) => a.datum < b.datum ? 1 : -1).map((elem, index) => (
                                                        <tr key={index}>
                                                            <td>{elem.dokumentArt}</td>
                                                            <td className="text-right"><Moment format="DD.MM.YYYY H:mm">{elem.datum}</Moment></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>


                                            {this.state.uploadHistory.length === 0 &&
                                                <div className="info">Keine Einträge vorhanden!</div>
                                            }
                                        </>
                                    }

                                    {this.state.loading &&
                                        <div className="alhLoadingRel">
                                            <WaveTopBottomLoading color="#ffc800" />
                                        </div>}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }

            </div>
        );
    }

    checkUploadDisable(mGNr: string) {

        if (this.state.uploadMode === "SO") {
            //Sonstige => text der eingetragen wurde wird genommen ohne ein MG zu wählen
            if (this.state.mGNrTemp === "" || this.state.file.length === 0 || this.state.uploading) {
                return true;
            }

        } else if (this.state.uploadMode !== "") {
            //Andere Uploads
            if (mGNr === "" || this.state.file.length === 0 || this.state.uploading) {
                return true;
            }

        } else if (this.state.uploadMode === "") {
            return true;
        }

        return false;
    }
}

export default Uploads;
