import React, { Component } from "react";
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSortNumericDown } from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "../Forms/SubmitButton";
import UserStore from "../../stores/UserStore";
import InputCheckbox from "../Forms/InputCheckbox";
import { toast } from "react-toastify";
import { trackEvent, trackException } from "../ALHUtility";
import ConfirmModal from "../Blocks/ConfirmModal";
import { HilfeModal } from "./Hilfe";
import MitgliedItem from "../../models/Mitglied";

interface BstwechselProps {
    mGNr?: string
    mode?: string
}

interface BstwechselState {
    mGNr: string
    mGNachname: string
    aktenanforderung: boolean
    showModal: boolean

    mitgliederList: string[]
}

class Bstwechsel extends Component<BstwechselProps, BstwechselState> {

    constructor(props: BstwechselProps) {
        super(props)
        this.state = {
            mGNr: this.props.mGNr ?? '',
            mGNachname: '',
            aktenanforderung: false,
            showModal: false,

            mitgliederList: [],
        }

        document.title = 'BST-Wechsel | ' + UserStore.title;
        this.moveMGl = this.moveMGl.bind(this);
    }

    async componentDidMount() {

        let thisMitglieder: string[] = [];

        let res = await fetch(UserStore.url + '/mitglieder', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        let result = await res.json();

        const mitglieder: MitgliedItem[] = result.data.mitglieder
        mitglieder.forEach((item) => {
            thisMitglieder.push(item.mgNr ?? '');
        });

        this.setState({
            mitgliederList: thisMitglieder,
        });
    }

    setAktenAVCheck(val: boolean) {
        this.setState({
            aktenanforderung: val
        })
    }

    setInputValue(field: string, val: string) {
        switch (field) {
            case 'mGNr':
                this.setState({
                    mGNr: val
                })
                break;
            case 'mGNachname':
                this.setState({
                    mGNachname: val
                })
                break;
        }
    }

    async moveMGl() {
        //Send data
        let res = await fetch(UserStore.url + '/bstwechselSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.username),
                    dataset: {
                        BSTNr: UserStore.username,
                        mgnr: this.state.mGNr,
                        mgnachname: this.state.mGNachname,
                        aktenanforderung: this.state.aktenanforderung,
                    }
                }
            })
        });

        let response = await res.json();

        if (response.success) {
            toast.success("Mitglied wurde erfolgreich gewechselt. Schauen Sie nun in Ihrer Mitgliederliste nach.", {
                autoClose: 5000
            });

            this.setState({
                mGNr: '',
                mGNachname: '',
                aktenanforderung: false,
                showModal: false,
            });

            trackEvent('BSTWechsel', 'Wechsel');

        } else if (!response.success && response.data && response.data.message) {
            toast.warning("Zu den von Ihnen eigegebenen Daten konnte kein Mitglied gefunden werden. Bitte überprüfen Sie die Einträge. | " + response.data.message);

            trackException('BSTWechsel', false);
        } else if (!response.success && response.error) {
            toast.warning("Zu den von Ihnen eigegebenen Daten konnte kein Mitglied gefunden werden. Bitte überprüfen Sie die Einträge. | " + response.error);

            trackException('BSTWechsel', false);
        }
    }

    showModal(val = true) {

        //check MG not yet own MG
        if (this.state.mitgliederList.some(item => this.state.mGNr === item)) {
            toast.warning("Das Mitglied ist bereits Ihrer Beratungsstelle zugeordnet.");

            return false;
        }

        this.setState({
            showModal: val,
        })

    }

    render() {
        const { mode = "normal" } = this.props

        return (
            <div>
                <h3 className="alh_pageheader">BST-Wechsel</h3>
                <div className="alh_subheader">Sie können hier ein Mitglied auf Ihre BST umbuchen.
                    <HilfeModal
                        hilfeFile="bstwechsel.json"
                    /></div>

                <Container>
                    <Row className="alh_rowTopList">
                        <Col md={(mode === "small") ? 12 : 6}>
                            <InputGroup className="mb-2 mr-sm-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faSortNumericDown} /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    size="sm"
                                    id="mGNr"
                                    placeholder="Mitgliedsnummer (z.B. 1234-5678)"
                                    value={this.state.mGNr}
                                    onChange={(e) => this.setInputValue('mGNr', e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup className="mb-2 mr-sm-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faEdit} /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    size="sm"
                                    id="mGNachname"
                                    placeholder="Nachname des Mitglieds"
                                    value={this.state.mGNachname}
                                    onChange={(e) => this.setInputValue('mGNachname', e.target.value)}
                                />
                            </InputGroup>
                            <InputCheckbox
                                name=""
                                checked={this.state.aktenanforderung}
                                onChange={(val) => this.setAktenAVCheck(val)}
                                text="Letzter VZ als Digiakte erwünscht?"
                                id="avcheck"
                            />
                        </Col>
                        <Col md={(mode === "small") ? 12 : 6}>
                            Anleitung zum BST-Wechsel eines Mitglieds:
                            <br /><br />
                            <ul>
                                <li>Erfragen Sie die MITGLIEDSNUMMER und den NACHNAMEN</li>
                                <li>Stellen Sie sicher, dass einem Umzug zu Ihrer BST nichts im Wege steht</li>
                                <li>Tragen Sie alle Daten ein (Mitgliedsnummer im Format 1234-5678) und klicken Sie dann auf "Mitglied auf meine BST umbuchen"</li>
                                <li>Das Mitglied erscheint dann direkt in Ihrer "Mitgliederliste"</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <ConfirmModal
                    heading="Mitglied auf meine BST umbuchen"
                    body="Fair geht vor! Bitte vergewissern Sie sich beim Mitglied ob der/die bisherige Beratungsstellenleiter:in noch keine Vorarbeit geleistet hat? Falls doch, klären Sie bitte vor der Terminvereinbarung bzw. Erstellung der Steuererklärung den Beitragsanspruch mit der Hauptverwaltung."
                    show={this.state.showModal}
                    okButtonStr="Mitglied umbuchen"
                    confirmFct={() => this.moveMGl()}
                    closeFct={() => this.showModal(false)}
                    showConfirm={true}
                />
                <SubmitButton
                    text="Mitglied auf meine BST umbuchen"
                    onClick={() => this.showModal()}
                    disabled={this.state.mGNr === "" || this.state.mGNachname === ""}
                    mode="success" />
            </div>
        );
    }
}

export default Bstwechsel;
