import UserStore from "../stores/UserStore";

export const HomedataService = {
    getHomedata: async () => {
        let res = await fetch(UserStore.url + '/homedata', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        return await res.json();
    }
}