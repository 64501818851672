import React, { Component } from 'react';
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { HilfeModal } from "../Screens/Hilfe";
import Form from "react-bootstrap/Form";
import { trackEvent } from "../ALHUtility";
import KeyValue from '../../models/KeyValue';

interface TableOperatorOptionsProps {
    name: string
    showSearch?: boolean
    placeholder: string
    formGroupClassName?: string
    value?: string
    valueYear?: number
    valuesYear?: string
    valueMonth?: number
    valuesMonth?: KeyValue[]
    print: boolean
    helpfile?: string

    onlyActive?: (value: boolean) => void
    onChangeMonth?: (value: number) => void
    onChangeYear?: (value: number) => void
    onChange?: (value: string) => void
}

class TableOperatorOptions extends Component<TableOperatorOptionsProps> {

    constructor(props: TableOperatorOptionsProps) {
        super(props);

        this.change = this.change.bind(this);
        this.changeYear = this.changeYear.bind(this);
        this.changeMonth = this.changeMonth.bind(this);
        this.changeOnlyActive = this.changeOnlyActive.bind(this);
    }

    componentDidUpdate(prevProps: TableOperatorOptionsProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    calculateYearsArray() {
        let result = [];
        if (this.props.valuesYear !== undefined && this.props.valuesYear.indexOf('-') > 0) {
            let valueSplit = this.props.valuesYear.split("-");
            if (valueSplit.length === 2) {
                for (let i = parseInt(valueSplit[0]); i <= parseInt(valueSplit[1]); i++) {
                    result.push({ 'id': i, 'value': i });
                }
            }
        }

        return result;
    }

    change(event: React.ChangeEvent<FormControlElement>) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    changeOnlyActive(event: React.ChangeEvent<FormControlElement>) {
        if (this.props.onlyActive) {
            this.props.onlyActive(event.target.value === "0" ? false : true);
        }
    }

    changeYear(event: React.ChangeEvent<FormControlElement>) {
        const year = parseInt(event.target.value)

        if (this.props.onChangeYear) {
            this.props.onChangeYear(year);
        }
    }

    changeMonth(event: React.ChangeEvent<FormControlElement>) {
        const month = parseInt(event.target.value)

        if (this.props.onChangeMonth) {
            this.props.onChangeMonth(month);
        }
    }

    thisPrint() {
        trackEvent('Liste', 'Print', this.props.name);
        window.print();
    }

    render() {

        let allYears = this.calculateYearsArray();

        const { showSearch = true, print = true } = this.props

        //Width of printIcon
        let iconsWidth = 6;
        if (print) {
            if (allYears.length > 0) {
                iconsWidth -= 3;
            }
            if (!!this.props.valuesMonth && this.props.valuesMonth.length > 0) {
                iconsWidth -= 3;
            }
            if (this.props.onlyActive) {
                iconsWidth -= 2;
            }
            if (iconsWidth <= 0) {
                iconsWidth = 12;
            }
        }

        return (
            <Container className="alh_noprint">
                <Row className="alh_rowTopList">
                    <Col xs={12} lg={6}>
                        <InputGroup className={(showSearch ? '' : 'd-none') + " mb-2 mr-sm-2"}>
                            <InputGroup.Prepend>
                                <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                size="sm"
                                type="text"
                                id="alhTableSearch"
                                placeholder={this.props.placeholder}
                                onChange={this.change}
                                value={this.props.value}
                            />
                        </InputGroup>
                    </Col>

                    {this.props.onlyActive &&
                        <Col xs lg={2}>
                            <Form.Group className={this.props.formGroupClassName}>
                                <Form.Control
                                    as="select"
                                    name="OnlyActive"
                                    id="alhTableOnlyActive"
                                    size="sm"
                                    onChange={this.changeOnlyActive}
                                >
                                    <option key='1' value='1'>Nur Aktive</option>
                                    <option key='0' value='0'>Alle</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    }

                    {(allYears.length > 0) &&
                        <Col xs lg={3}>
                            <Form.Group className={this.props.formGroupClassName}>
                                <Form.Control
                                    as="select"
                                    name="Year"
                                    id="alhTableYear"
                                    size="sm"
                                    onChange={this.changeYear}
                                    value={this.props.valueYear}
                                >
                                    <option key='-1' value='-1'>-- Alle Jahre --</option>
                                    {allYears.map(item => (
                                        <option key={item.id} value={item.id}>{item.value}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    }

                    {!!this.props.valuesMonth && this.props.valuesMonth.length > 0 &&
                        <Col xs lg={3}>
                            <Form.Group className={this.props.formGroupClassName}>
                                <Form.Control
                                    as="select"
                                    name="Month"
                                    id="alhTableMonth"
                                    size="sm"
                                    onChange={this.changeMonth}
                                    value={this.props.valueMonth}
                                >
                                    <option key='-1' value='-1'>-- Alle Monate --</option>
                                    {this.props.valuesMonth.map(item => (
                                        <option key={item.id} value={item.id}>{item.value}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    }

                    {(print || this.props.helpfile) &&
                        <Col xs lg={iconsWidth} className="alh_iconcont alh_mobileHide">
                            {(this.props.helpfile && this.props.helpfile !== '') &&
                                <HilfeModal
                                    hilfeFile={this.props.helpfile}
                                />
                            }
                            {(print) &&
                                <FontAwesomeIcon onClick={() => this.thisPrint()} icon={faPrint} title="Drucken" className="faButton faButtonSpaceright" />
                            }
                        </Col>
                    }

                </Row>
            </Container>
        );
    }

}


export default TableOperatorOptions;