import { toast } from "react-toastify";
import { downloadFile } from "../Components/ALHUtility";
import UserStore from "../stores/UserStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const FileService = {
    printFile: async (endpoint: string, body: string, filename: string) => {
        //show working message and inactivate Button
        const toastId = toast.dark(<div><FontAwesomeIcon icon={ faPrint } /> Dokument wird erzeugt, <br />einen Moment bitte...</div >, {
            autoClose: false
        });

        let res = await fetch(UserStore.url + `/${endpoint}`, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'responseType': 'arraybuffer'
            },
            body: body
        });

        let response = await res.json();
        if (response.success) {
            downloadFile(response.data.dokument, filename)
        } else {
            toast.error("Fehler bei Erstellung des Dokuments: " + response.data.message, {
                autoClose: false
            });
        }

        toast.dismiss(toastId);
    }
}