import React, { useCallback, useEffect, useState } from "react"
import { Container, Row, Col, Table, Form } from "react-bootstrap"
import { MitarbeiterDatencheckField, mitarbeiterDatencheckFields } from "../../stores/DatencheckFields"
import SubmitButton from "../Forms/SubmitButton";
import MitarbeiterItem from "../../models/Mitarbeiter";
import InputField from "../Forms/InputField";
import SelectField from "../Forms/SelectField";
import { toast } from "react-toastify";
import InputCheckboxForm from "../Forms/InputCheckboxForm";
import { withRouter } from "react-router-dom";
import MitarbeiterService from "../../services/MitarbeiterService";
import MyBstService from "../../services/MyBstService";

const defaultMitarbeiter: MitarbeiterItem = {
    bstmaId: -1,
    nachname: "",
    vorname: "",
    adresse: {
        strasse: "",
        land: "",
        ort: "",
        ortsteil: "",
        plz: ""
    },
    maAktiv: false,
    bstmaPortalAktiv: false,
    anrede: "",
    beruf: "",
    strasse: "",
    plz: "",
    ort: "",
    gebDatum: new Date(),
    eMail: "",
    eMailBeantragen: false,
    eMailPrivat: "",
    anmeldungErsteBst: false,
    handy: "",
    ofdAngemeldet: undefined,
    ofdAb: new Date(1899, 11, 30),
    ofdAn: new Date(1899, 11, 30)
}

const optionalFields = ['eMailPrivat', 'beruf', 'webportalZugangBeantragen', 'ofdBeantragen', 'handy']

const MitarbeiterCreate = () => {

    const [isDirty, setIsDirty] = useState(false)
    const [validated, setValidated] = useState(false)
    const [mitarbeiter, setMitarbeiter] = useState<MitarbeiterItem>(defaultMitarbeiter)
    const [mitarbeiterCreated, setMitarbeiterCreated] = useState(false)
    const [hasSecondBeratungsstelle, setHasSecondBeratungsstelle] = useState(false)

    const getPartOfNumber = (value: string, index: number) => {
        const splitNumber = value.split(" ")
        if (splitNumber.length === 2) {
            let result = splitNumber[index]
            if (index === 0) {
                result = result.substring(1, result.length - 1)
            }
            return result
        }
    }

    const loadSecondBstStelle = useCallback(async () => {
        const hasSecondBeratungsstelle = await MyBstService.hasSecondBeratungsstelle()
        setHasSecondBeratungsstelle(hasSecondBeratungsstelle)
    }, [])

    useEffect(() => {
        loadSecondBstStelle()
    }, [loadSecondBstStelle])

    useEffect(() => {
        if (hasSecondBeratungsstelle) {
            setMitarbeiter(mitarbeiter => ({ ...mitarbeiter, anmeldungZweiteBst: false }))
        }
    }, [hasSecondBeratungsstelle])

    const handleChange = (prop: string, newValue: string | number | boolean, index?: number) => {

        //wenn index dann ist es auf jedenfall eine telefonnummer, wo der wert erst zusammengesetzt werden muss
        if (typeof index !== "undefined") {
            let splitValue: string[] = []
            splitValue = (mitarbeiter[prop]?.toString() ?? "").split(" ")

            if (splitValue.length === 2) {
                newValue = index === 0 ? `(${newValue}) ${splitValue[1]}` : `${splitValue[0]} ${newValue}`
            } else {
                newValue = index === 0 ? `(${newValue}) 0000` : `(0000) ${newValue}`
            }
        }

        setMitarbeiter({ ...mitarbeiter, [prop]: newValue })
        setIsDirty(true)
    }

    const buildTableRow = (field: MitarbeiterDatencheckField, children: JSX.Element) => {
        return <tr key={field.prop}>
            <td>{field.columnText}</td>
            {children}
        </tr>
    }

    const getField = (field: MitarbeiterDatencheckField) => {
        let control: JSX.Element = <td></td>;
        switch (field.type) {
            case "Checkbox":
                if (field.children) {
                    control = <td>
                        {field.children.map(c => (hasSecondBeratungsstelle ? true : c.prop !== "anmeldungZweiteBst") && <div key={c.prop} className="myBst_Line">
                            <InputCheckboxForm
                                name={c.name}
                                id={c.prop}
                                checked={!!mitarbeiter[c.differentDatencheckProp ?? c.prop]}
                                title={c.title}
                                text={c.title}
                                onChange={(val) => handleChange(c.differentDatencheckProp ?? c.prop, val)}
                            />
                        </div>)}
                    </td>
                } else {
                    control = <td>
                        <div className="myBst_Line">
                            <div className="myBst_EditControls">
                                {(field.condition && field.condition(mitarbeiter[field.prop])) ? <InputCheckboxForm
                                    readOnly
                                    name={field.name}
                                    id={field.differentDatencheckProp}
                                    checked={!!mitarbeiter[field.differentDatencheckProp!]}
                                    title={field.differentTitle}
                                    text={field.differentTitle}
                                    onChange={() => { }}
                                /> : <InputCheckboxForm
                                    name={field.name}
                                    id={field.prop}
                                    checked={!!mitarbeiter[field.differentDatencheckProp ?? field.prop]}
                                    title={field.title}
                                    text={field.title}
                                    onChange={(val) => handleChange(field.differentDatencheckProp ?? field.prop, val)}
                                />}
                            </div>
                        </div>
                    </td>
                }
                break;
            case "Datum":
                control = <td>
                    <InputField
                        key={field.prop}
                        type='date'
                        id={field.prop}
                        name={field.name}
                        required={!optionalFields.find(o => field.prop.includes(o))}
                        isInvalid={field.validate ? field.validate(mitarbeiter[field.prop]) : false}
                        placeholder={field.placeholder ?? ""}
                        value={mitarbeiter[field.prop]?.toString() ?? ""}
                        onChange={(val) => handleChange(field.prop, val)}
                    />
                </td>
                break;
            case "Textfeld":
                control = <td>
                    {!field.readonly && <div className="myBst_Line">
                        <div className="myBst_EditControls">
                            {!field.children ? <InputField
                                key={field.prop}
                                type='textclean'
                                id={field.prop}
                                name={field.name}
                                required={!optionalFields.find(o => field.prop.includes(o))}
                                isInvalid={field.validate ? field.validate(mitarbeiter[field.prop]) : false}
                                placeholder={field.placeholder ?? ""}
                                value={mitarbeiter[field.prop]?.toString() ?? ""}
                                onChange={(val) => handleChange(field.prop, val)}
                            /> :
                                field.children.map((c, index) => <InputField
                                    key={c.prop}
                                    type="textclean"
                                    id={c.prop}
                                    name={c.name}
                                    placeholder={c.placeholder ?? ""}
                                    value={getPartOfNumber(mitarbeiter[field.prop]?.toString() ?? "", index) ?? ""}
                                    onChange={(val) => handleChange(field.prop, val, index)}
                                />)
                            }
                        </div>
                    </div>}
                </td>
                break;
            case "Dropdown":
                control = <td>
                    <div className="myBst_Line">
                        <div className="myBst_EditControls">
                            <SelectField
                                id={field.prop}
                                name={field.name}
                                required={!optionalFields.find(o => field.prop.includes(o))}
                                withPleaseSelect={true}
                                value={mitarbeiter[field.prop]?.toString() ?? ""}
                                options={field.values!}
                                onChange={(val) => handleChange(field.prop, val)}
                            />
                        </div>
                    </div>
                </td>
                break;
            case "Radio":
                control = <td>
                    <div className="myBst_Line">
                        <div className="myBst_EditControls">
                            <label>
                                <input
                                    type="radio"
                                    value="Ja"
                                    name={field.name}
                                    checked={typeof mitarbeiter[field.differentDatencheckProp ?? field.prop] !== "undefined" ? !!mitarbeiter[field.differentDatencheckProp ?? field.prop] : false}
                                    onChange={(e) => handleChange(field.prop, true)}
                                /> Ja (steuerlich tätig)
                            </label>
                        </div>
                    </div>
                    <div className="myBst_Line">
                        <div className="myBst_EditControls">
                            <label>
                                <input
                                    type="radio"
                                    value="Nein"
                                    name={field.name}
                                    checked={typeof mitarbeiter[field.differentDatencheckProp ?? field.prop] !== "undefined" ? !mitarbeiter[field.differentDatencheckProp ?? field.prop] : false}
                                    onChange={(e) => handleChange(field.prop, false)}
                                /> Nein (nur organisatorische Aufgaben)
                            </label>
                        </div>
                    </div>
                </td>
                break;
        }

        return buildTableRow(field, control)
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        //disable button
        setIsDirty(false)

        if (!mitarbeiter.anmeldungErsteBst && !mitarbeiter.anmeldungZweiteBst) {
            toast.warning("Bitte eine BST auswählen, in der der Mitarbeiter angemeldet werden soll.", {
                autoClose: 10000
            });
            return false;
        }

        if (typeof mitarbeiter['ofdBeantragen'] === "undefined") {
            toast.warning("Soll der Mitarbeiter bei der OFD angemeldet werden?", {
                autoClose: 10000
            });
            return false;
        }

        const form = event.currentTarget;

        if (!form.reportValidity()) {
            toast.warning("Leider sind nicht alle Pflichtdaten korrekt eingetragen. Bitte kontrollieren Sie diese noch einmal.", {
                autoClose: 10000
            });

            setValidated(true)
            return false;
        }

        try {
            await MitarbeiterService.createMitarbeiter(mitarbeiter)

            toast.success("Mitarbeiter erfolgreich angelegt", {
                autoClose: 10000
            })
            setMitarbeiterCreated(true)
        } catch (e) {
            console.log("Error creating Mitarbeiter: " + e);
            toast.error("Fehler beim Anlegen des Mitarbeiters: " + e, {
                autoClose: 10000
            })
        } finally {
            setValidated(true)
        }
    }

    const navigateToMitarbeiter = () => {
        window.location.href = "/mitarbeiter"
    }

    return <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Container>
            <Row className="alh_rowSpacer">
                <Col className="alh_colData">
                    <Table striped bordered hover>
                        <tbody>
                            {mitarbeiterDatencheckFields.filter(d => d.order < 10).map(f => getField(f))}
                        </tbody>
                    </Table>
                </Col>
                <Col className="alh_colData">
                    <Table striped bordered hover>
                        <tbody>
                            {mitarbeiterDatencheckFields.filter(d => d.prop !== 'mitarbeiterAbmelden' && d.prop !== 'ofdAn').filter(d => d.order >= 10).map(f => getField(f))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>

        {!mitarbeiterCreated && <SubmitButton
            type='submit'
            disabled={!isDirty}
            text="Daten überprüfen und speichern"
            mode="success"
            className="alhButton_mgsave"
        />}

        {mitarbeiterCreated && <SubmitButton text="Zurück zu Mitarbeiter" onClick={() => navigateToMitarbeiter()} mode="success" />}
    </Form>
}

export default withRouter(MitarbeiterCreate)