import React, { Component } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import MitgliedItem from '../../models/Mitglied';

interface InputSearchFieldProps {
    values: MitgliedItem[]
    className?: string
    placeholder: string
    value?:string
    onChange?: (value: string) => void
    onSelItem: (value: string) => void
}

interface InputSearchFieldState {
    searchItems: MitgliedItem[]
}

class InputSearchField extends Component<InputSearchFieldProps, InputSearchFieldState> {

    constructor(props: InputSearchFieldProps) {
        super(props);

        this.state = {
            searchItems: this.props.values
        }

        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
    }

    componentDidUpdate(prevProps: InputSearchFieldProps) {

        if (this.props.values !== prevProps.values) {
            this.setState({
                searchItems: this.props.values,
            })
        }

    }

    handleOnSearch(string: string, results: MitgliedItem[]) {
        if (this.props.onChange) {
            this.props.onChange(string);
        }
    }

    handleOnHover(result: MitgliedItem) {
        // the item hovered
        //console.log(result)
    }

    handleOnSelect(item: MitgliedItem) {
        if (typeof this.props.onSelItem) {
            this.props.onSelItem(item.mgNr ?? '');
        }
    }

    handleOnFocus() {
        //console.log('Focused')
    }

    formatResult(item: MitgliedItem) {
        return (
            <>
                <span className="alh_searchItem">{item.mgNr} | {item.name}</span>
            </>
        )
    }

    render() {
        const { searchItems } = this.state

        const preppedItems = searchItems.map((m, index) => {
            return { ...m, id: index }
        })

        return (
            <div className={this.props.className + " isSearchAutomcomplete isform-control " + ((this.state.searchItems.length === 0) ? "isform-control-disabled" : "")}>
                <ReactSearchAutocomplete
                    items={preppedItems}
                    onSearch={this.handleOnSearch}
                    onHover={this.handleOnHover}
                    onSelect={this.handleOnSelect}
                    onFocus={this.handleOnFocus}
                    formatResult={this.formatResult}

                    inputSearchString={this.props.value}

                    showIcon={false}
                    autoFocus={(this.state.searchItems.length > 0)}

                    fuseOptions={{
                        keys: ["name", "mgNr"],
                        threshold: 0.4,
                    }}
                    resultStringKeyName="name"

                    styling={{
                        height: "30px",
                        border: "1px solid rgb(206, 212, 218)",
                        borderRadius: "0",
                        backgroundColor: "white",
                        boxShadow: "none",
                        hoverBackgroundColor: "#eee",
                        color: "#212121",
                        fontSize: "14px",
                        fontFamily: "\"Open Sans\", sans-serif",

                        iconColor: "grey",
                        lineColor: "rgb(232, 234, 237)",
                        placeholderColor: "grey",
                        clearIconMargin: '3px 5px 0 0',
                        searchIconMargin: '0 0 0 5px'
                    }}

                    placeholder={(this.state.searchItems.length > 0) ? this.props.placeholder : "Bitte warten..."}
                />
            </div>
        );
    }
}

export default InputSearchField;
