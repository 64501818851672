import React, { Component } from 'react';
import ucImage from '../assets/images/underconstruction.png';

class UnderConstruction extends Component {
    render() {
        return (
            <div className="underConstruction">
                <img src={ucImage}
                    style={{ /*align: "center",*/ alignContent: "center" }}
                    width="200px"
                    alt="Under construction" />
            </div>
        );
    }
}

export default UnderConstruction;