import Berechtigung from "../models/Berechtigung";
import MitarbeiterItem from "../models/Mitarbeiter";
import UserStore from "../stores/UserStore";
import ServiceHelper from "./ServiceHelper";

const MitarbeiterService = {
    getMitarbeiter: async (): Promise<MitarbeiterItem[]> => {
        let res = await fetch(UserStore.url + '/mitarbeiter', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        let result = await res.json();
        return result.data.mitarbeiter ?? []
    },
    createMitarbeiter: async (mitarbeiter: MitarbeiterItem) => {
        let res = await fetch(UserStore.url + '/mitarbeiterNeu', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: ServiceHelper.buildRequestBody({
                mitarbeiter
            })
        });

        const response = await res.json();

        if(!response.success){
            throw new Error(response.data.message)
        }
    },
    getMitarbeiterBerechtigungen: async (bstmaId: number): Promise<Berechtigung[]> => {
        let res = await fetch(UserStore.url + '/mitarbeiterberechtigung', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTMAId: bstmaId
                }
            })
        });

        let result = await res.json();
        return result.data.berechtigungen
    },
    saveMitarbeiterBerechtigungen: async (bstmaId: number, berechtigungen: Berechtigung[]): Promise<{ success: boolean }> => {
        let res = await fetch(UserStore.url + '/mitarbeiterberechtigungSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.username),
                    BSTMAId: bstmaId,
                    dataset: {
                        BSTNr: UserStore.username,
                        BSTMAId: bstmaId,
                        berechtigungen: berechtigungen
                    }
                }
            })
        });

        let response = await res.json();

        return response
    }
}

export default MitarbeiterService