import React, { Component } from 'react';
import { PointSpreadLoading } from "react-loadingg";
import SubmitButton from "../Forms/SubmitButton";
import { toast } from "react-toastify";
import UserStore from "../../stores/UserStore";
import BeratungsstelleStandort from '../../models/BeratungsstelleStandort';
import Finanzdaten from '../../models/Finanzdaten';
import Bankverbindung from '../../models/Bankverbindung';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MyBstService from '../../services/MyBstService';
import Grunddaten from '../../models/MyBstDatencheck/Grunddaten';
import MyBstAccordion from '../Blocks/MyBstAccordion';
import grunddatenDatencheckFields from '../../stores/DatencheckFields';
import DatencheckService from '../../services/DatencheckService';
import InputField from '../Forms/InputField';

export interface Datencheck {
    id: number
    oldValue: string | number | boolean
    newValue: string | number | boolean
    prop: string
    toDoEmpfaenger: "ALH\\andrea.huettner" | "ALH\\lena.wohlmannstetter" | ""
    genehmigungspflichtig: boolean
    order?: number
}

export interface MyBstData {
    [key: string]: any
    grunddaten: Grunddaten
    bstStandort1: BeratungsstelleStandort
    bstStandort2: BeratungsstelleStandort
    finanzdaten: Finanzdaten
    bankverbindung?: Bankverbindung
    weitereInformationen?: string
}

interface MyBstProps extends RouteComponentProps { }

interface MyBstState extends MyBstData {
    datencheckProps: Datencheck[]
    saveButtonClicked: boolean
    disableSave: boolean
    hasSecondBeratungsstelle: boolean
}

const datencheckWeiterInformationen = "weitereinformationen"

class MyBst extends Component<MyBstProps, MyBstState> {

    constructor(props: MyBstProps) {
        super(props);

        if (!UserStore.hasBerechtigung('bst_grunddaten') && typeof this.props.history !== "undefined") {
            this.props.history.push("/notallowed/");
        }
    }

    setDatencheckItem(items: Datencheck[]) {
        let datenchecks: Datencheck[] = this.state.datencheckProps || []

        for (const item of items) {
            if (datenchecks.find(d => item.id === -1 ? d.prop === item.prop : d.id === item.id)) {
                datenchecks = [...datenchecks.filter(d => item.id === -1 ? d.prop !== item.prop : d.id !== item.id), { ...item }]
            } else {
                datenchecks = [...datenchecks, { ...item }]
            }
        }

        //console.log("datenchecks", datenchecks)

        this.setState({
            disableSave: false,
            datencheckProps: [...datenchecks]
        })

    }

    deleteDatencheckItem(prop: string) {
        this.setState({
            datencheckProps: this.state.datencheckProps.filter(d => d.prop !== prop)
        })
    }

    async componentDidMount() {
        try {
            const [myBstData, datenchecks, hasSecondBeratungsstelle] = await Promise.all([
                MyBstService.getFull(),
                DatencheckService.get(),
                MyBstService.hasSecondBeratungsstelle()
            ])

            if (myBstData && myBstData.success) {
                this.setState({
                    ...myBstData.data,
                    hasSecondBeratungsstelle: hasSecondBeratungsstelle,
                    datencheckProps: datenchecks
                });
            } else {
                toast.error(myBstData.error, {
                    autoClose: 5000
                })
            }

        } catch (e) {
            console.log("Error in loading: " + e);
        }

        document.title = 'BST Grunddaten | ' + UserStore.title;

        this.setState({
            disableSave: true
        })
    }

    async saveData() {

        if (this.state) {
            this.setState({
                saveButtonClicked: true
            })

            //Send data
            const response = await DatencheckService.set(this.state.datencheckProps
                .filter(d => d.oldValue !== d.newValue)
                .map(d => {
                    let copy = { ...d }
                    if (d.id !== -1) {
                        for (const iterator of grunddatenDatencheckFields) {
                            if (iterator.fields.find(f => d.prop.includes(f.prop))) {
                                copy = { ...copy, genehmigungspflichtig: iterator.fields.find(f => d.prop.includes(f.prop))?.genehmigungspflichtig ?? true }
                            }
                        }
                    }
                    return {
                        ...copy,
                        oldValue: d.oldValue?.toString() ?? "",
                        newValue: d.newValue.toString()
                    }
                })
            )

            //console.log("response", response)

            if (response) {
                if (response.success) {
                    toast.success("Daten erfolgreich gespeichert!", {
                        autoClose: 5000
                    });
                    this.setState({
                        datencheckProps: response.data.datenchecks.map((d: Datencheck) => ({ ...d }))
                    })
                } else {
                    toast.error("Fehler beim Speichern. " + response.message, {
                        autoClose: 5000
                    })
                }
            } else {
                toast.error("Fehler beim Speichern", {
                    autoClose: 5000
                })
            }

            this.setState({
                saveButtonClicked: false
            })
            this.componentDidMount()
        } else {
            toast.error("Daten sind leer. Es kann nichts gespeichert werden", {
                autoClose: 5000
            })
        }
    }

    disableSaveButton(disable: boolean) {
        this.setState({
            disableSave: disable
        })
    }

    render() {
        if (this.state) {
            return (
                <>
                    <div>
                        <h3 className="alh_pageheader">Meine Beratungsstelle</h3>
                        <div className="alh_subheader">Hier sehen Sie alle Daten Ihrer Beratungsstelle. Je nach Datenfeld können Sie die Daten direkt ändern oder eine Änderung beantragen. Um Ihre Änderungen endgültig zu übernehmen, klicken Sie ganz unten auf Speichern.</div>

                        {/* Filtern wenn es keinen bststandort2 gibt */}
                        {grunddatenDatencheckFields.filter(d => this.state.hasSecondBeratungsstelle ? true : d.accordionProperty !== "bstStandort2").map((accordion, index) =>
                            <MyBstAccordion
                                key={index}
                                accordion={accordion}
                                data={this.state}
                                datenchecks={this.state.datencheckProps}
                                updateDatenchecks={(datencheck) => this.setDatencheckItem(datencheck)}
                                deleteDatencheck={(prop) => this.deleteDatencheckItem(prop)}
                                disableSave={(disable) => this.disableSaveButton(disable)}
                            />)}

                        <InputField
                            as="textarea"
                            key="notizfeld"
                            type='textclean'
                            id="notizfeld"
                            name="notizfeld"
                            placeholder="Weitere Informationen"
                            value={
                                this.state.weitereInformationen ??
                                this.state.datencheckProps.find(d => d.prop === datencheckWeiterInformationen)?.newValue.toString() ?? ""
                            }
                            onChange={(val) => {
                                if (val) {
                                    const foundDatencheck = this.state.datencheckProps.find(d => d.prop === datencheckWeiterInformationen)
                                    let datencheckToSave: Datencheck | undefined = undefined
                                    if (foundDatencheck) {
                                        datencheckToSave = {
                                            ...foundDatencheck,
                                            oldValue: foundDatencheck.newValue,
                                            newValue: val
                                        }
                                    } else {
                                        datencheckToSave = {
                                            id: -1,
                                            newValue: val,
                                            oldValue: "",
                                            prop: datencheckWeiterInformationen,
                                            toDoEmpfaenger: 'ALH\\andrea.huettner',
                                            genehmigungspflichtig: false
                                        }
                                    }

                                    this.setDatencheckItem([{ ...datencheckToSave }])
                                }
                            }}
                        />

                        <SubmitButton text="Speichern" disabled={this.state.saveButtonClicked || this.state.disableSave} onClick={() => this.saveData()} mode="success" />

                    </div>
                </>
            );

        } else {

            return (
                <div>
                    <PointSpreadLoading color="#ffc800" />
                </div>
            )

        }

    }
}

export default withRouter(MyBst);
