import React, { Component } from "react";

class Imprint extends Component {
    render() {
        return (
            <div>
                <h2>Impressum</h2>
                <div className="wpb_wrapper">
                    <p>Wir sind ein bundesweit tätiger Lohnsteuerhilfeverein, der innovative Wege beschreitet. Wir
                        bieten Arbeitnehmern eine preiswerte Alternative für ihre steuerliche Beratung im Rahmen einer
                        Mitgliedschaft begrenzt nach § 4 Nr. 11 StBerG und einen kompetenten, kundenfreundlichen
                        Service.</p>
                    <p>Aktuell Lohnsteuerhilfeverein e.V.<br />
                        Fischbräustr. 6<br />
                        84323 Massing</p>
                    <p>Telefon (08724) 965 300 100<br />
                        Fax (08724) 965 300 75</p>
                    <p>E-Mail: <a href="mailto:info@aktuell-verein.de">info@aktuell-verein.de</a></p>
                    <p><strong>Rechtsform:</strong> eingetragener Verein deutschen Rechts<br />
                        <strong>Registergericht:</strong> Landshut<br />
                        <strong>Registernummer:</strong> VR 10607<br />
                        <strong>USt-IdNr.:</strong> DE 197 627 670</p>
                    <p><a href="/datenschutz">Datenschutzerklärung</a>
                    </p>
                    <p><a target="_blank" href="https://www.aktuell-verein.de/bildnachweis/" rel="noopener noreferrer">Bildnachweis</a></p>
                    <p><strong>Vertretungsverhältnisse:<br />
                    </strong>Vorstand: Christian Staller, Ulrich Danner, Sandra Staller<br />
                        Fischbräustr. 6<br />
                        84323 Massing</p>
                    <p><strong>Aufsichtsbehörde:<br />
                    </strong>Bayerisches Landesamt für Steuern, Krelingstr. 50, 90332 Nürnberg, www.lfst.bayern.de</p>
                    <p>Alle im Aktuell Lohnsteuerhilfeverein e.V. tätigen Beratungsstellenleiter haben die nach § 23
                        StBerG notwendige Qualifikation und wurden von der jeweils zuständigen Aufsichtsbehörde in das
                        Verzeichnis der Lohnsteuerhilfevereine und ihrer Beratungsstellen eingetragen.</p>
                    <p><strong>Haftpflichtversicherung:<br />
                    </strong>Gothaer Allgemeine Versicherungs AG<br />
                        Gothaer Allee 1<br />
                        50969 Köln<br />
                        Tel.: (0221) 308 00<br />
                        Fax: (0221) 308 103<br />
                        E-Mail: info@gothaer.de</p>
                    <p>Der räumliche Geltungsbereich des Versicherungsschutzes umfasst Tätigkeiten in den
                        Mitgliedsländern der Europäischen Union und genügt so mindestens § 25 Abs. 2,
                        Steuerberatungsgesetz (StBerG), §§ 9 ff. Verordnung zur Durchführung der Vorschriften über
                        Lohnsteuerhilfevereine (DV in der Fassung des Verordnungsentwurfs des BMF vom 2.8.2010)</p>
                    <p><strong>Berufsrecht:<br />
                    </strong>Es gelten die folgenden berufsrechtlichen Regelungen für Lohnsteuervereine und
                        Steuerberater:</p>
                    <ul className="alh_li_standard">
                        <li>Steuerberatungsgesetz (StBerG), insbes. §§ 4 Nr. 11, 13 – 31</li>
                        <li>Verordnung zur Durchführung der Vorschriften über Lohnsteuerhilfevereine (DVLStHV)</li>
                    </ul>
                    <p>Die genannten berufsrechtlichen Vorschriften für Lohnsteuerhilfevereine sind unter
                        www.gesetze-im-internet.de (Homepage des Bundesministeriums der Justiz) einsehbar.</p>
                    <p><strong>Informationen nach dem Verbraucherstreitbeilegungsgesetz:</strong><br />
                        Es besteht keine Verpflichtung und keine Teilnahme an einem Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle.</p>
                    <p><strong>Inhaltlich verantwortlich:<br />
                    </strong>Ulrich Danner, Anschrift wie oben</p>
                    <p><strong>Rechtliche Hinweise:<br />
                    </strong>Der Aktuell Lohnsteuerhilfeverein e.V. prüft und aktualisiert die Informationen auf seinen
                        Webseiten regelmäßig. Trotz aller Sorgfalt können Fehler oder zwischenzeitige Veränderungen
                        nicht ausgeschlossen werden. Eine Haftung für die Aktualität, Richtigkeit und Vollständigkeit
                        der zur Verfügung gestellten Informationen kann daher nicht übernommen werden.</p>
                    <p>Der Aktuell Lohnsteuerhilfeverein e.V. ist für den Inhalt der Webseiten, die aufgrund
                        von&nbsp;Verlinkungen erreicht werden, nicht verantwortlich.</p>
                    <p>Inhalt und Struktur der Webseiten des Aktuell Lohnsteuerhilfeverein e.V. sind urheberrechtlich
                        geschützt. Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von
                        Texten, Textteilen oder Bildmaterial, bedarf der vorherigen Zustimmung durch den Vorstand des
                        Aktuell Lohnsteuerhilfeverein e.V.</p>

                </div>
            </div>
        );
    }
}

export default Imprint;
