import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Component } from "react";
import ChecklistItem from "../../models/ChecklistItem";
import InputCheckbox from "../Forms/InputCheckbox";

interface ChecklistContainerProps {
    checklistItem: ChecklistItem
    showNotizModal?: (frageId: number) => void
    handleCheckboxChange: (item: ChecklistItem, val: boolean) => void
}

class ChecklistContainer extends Component<ChecklistContainerProps> {

    render() {
        return (
            <div className='alh_ChecklistContainerBorder' >
                <div className={`${this.props.checklistItem.taskFulfilled ? 'alh_opacity' : ''}`}>
                    {this.props.checklistItem.frage && <>
                        {this.props.checklistItem.frage.frageName}
                        <br />
                    </>}
                    {this.props.checklistItem.taskDoc ? this.props.checklistItem.taskDoc.bezeichnung : this.props.checklistItem.frage!.belegFehltNotiz}
                </div>
                <div className="alh_checklistRight">
                    {this.props.showNotizModal && <div className={"alh_clickicon alhBP_LegCheck"} title={this.props.checklistItem.frage!.notiz}>
                        <FontAwesomeIcon onClick={() => this.props.showNotizModal!(this.props.checklistItem.frage!.id)}
                            icon={faComment} />
                    </div>}
                    <InputCheckbox
                        text={this.props.checklistItem.taskText}
                        checked={this.props.checklistItem.taskFulfilled}
                        onChange={(value) => this.props.handleCheckboxChange(this.props.checklistItem, value)}
                    />
                </div>
            </div>)
    }
}

export default ChecklistContainer;