import * as React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import UserStore from "../stores/UserStore";

interface FooterProps {
    withSearch: string;
}

interface FooterState {
    searchMGNummer: string;
}

class Footer extends React.Component<FooterProps, FooterState> {

    constructor(props: FooterProps) {
        super(props);

        this.state = {
            searchMGNummer: "",
        }

        this.onMGNummerChange = this.onMGNummerChange.bind(this);
        this.onLoadMGDetails = this.onLoadMGDetails.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    onMGNummerChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchMGNummer: event.target.value
        })
    }

    onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.charCode === 13) {
            this.onLoadMGDetails();
        }
    }

    async onLoadMGDetails() {
        //check if there is one Mitglied with this Search
        const res = await fetch(UserStore.url + '/mitglieder', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        const result = await res.json();
        const search = this.state.searchMGNummer;
        const currentList = result.data.mitglieder.filter(function (mitglied: any) {
            return (
                mitglied.name.toLowerCase().includes(search.toLowerCase()) ||
                mitglied.namePartner.toLowerCase().includes(search.toLowerCase()) ||
                mitglied.mgNr.toLowerCase().includes(search.toLowerCase()) ||
                mitglied.telefon.toLowerCase().includes(search.toLowerCase()) ||
                mitglied.plzOrt.toLowerCase().includes(search.toLowerCase())
            );
        });

        console.log(currentList.length);
    }

    render() {
        return (
            <Container fluid className="alhFooter">
                <Row>
                    <Col md={this.props.withSearch === 'true' ? 4 : 6} sm={12} className="alh_sm_center">{(UserStore.mandantId === 1) ? "Aktuell Lohnsteuerhilfeverein e.V." : ((UserStore.mandantId === 2) ? "Altbayerischer Lohnsteuerhilfeverein e.V." : "")}</Col>
                    <Col md={4} sm={0} className={this.props.withSearch === 'true' ? 'alh_mgsearch' : 'd-none'}>
                        <div>
                            MG Schnellsuche: <input type="text" onChange={this.onMGNummerChange} onKeyPress={this.onKeyUp} value={this.state.searchMGNummer} />
                            <FontAwesomeIcon className="alh_icon" icon={faSearch} onClick={this.onLoadMGDetails} />
                        </div>
                    </Col>
                    <Col md={this.props.withSearch === 'true' ? 4 : 6} sm={12} className="alh_flinks"><a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a></Col>
                </Row>
            </Container>
        )
    }
}

export default Footer;
