import React, { Component } from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPrint, faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import UserStore from "../../stores/UserStore";
import { WaveTopBottomLoading } from "react-loadingg";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import InputField from "../Forms/InputField";
import SelectField from "../Forms/SelectField";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { Alert, Col, Container, Row } from "react-bootstrap";
import InfoIcon from "../Blocks/InfoIcon";
import { trackModal } from "../ALHUtility";
import InputCheckbox from "../Forms/InputCheckbox";
import SubmitButton from "../Forms/SubmitButton";
import Buchung, { JournalBuchung } from '../../models/Buchung';
import Leistung from '../../models/Leistung';
import Zahlungweise from '../../models/Zahlungsweise';
import KeyValue from '../../models/KeyValue';
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader'
import MitgliedService from '../../services/MitgliedService';
import BuchungService from '../../services/BuchungService';
import AufgabenService from '../../services/AufgabenService';
import { CommonService } from '../../services/CommonService';
import NotizenService from '../../services/NotizenService';

interface MitgliederBJournalBase {
    mGNr: string
    showBuchung: string
    showBuchungData: any
    reloadBJournal: number
    active: boolean

    oPs: Buchung[]
    oLs: Leistung[]
}

interface MitgliederBJournalProps extends MitgliederBJournalBase {
    zahlungsweisehaben: string
    zahlungsweise: string
    nachname?: string
    onReload: () => void
}

interface MitgliederBJournalState extends MitgliederBJournalBase {
    loading: boolean
    showEdit: boolean
    showUpload: boolean
    selectedBeitragsjahr?: number
    showEditMode: string
    showEditType: string
    currentEditBuchung: number
    currentBuchung: JournalBuchung

    zahlungsweiseNewDefault: string
    leistungsdatumNewDefault: Date | string

    buchungen: Buchung[]
    gesamtoffen: number
    summeSoll: number
    summeHaben: number

    printSammelrechnungChecked: boolean

    boStaffeln: { beitrag: number }[]

    zahlungsweisen: Zahlungweise[]
    vorgangValues: KeyValue[]

    loadAllBuchungen: boolean

    vgdUebermittlung: {
        confirmed: boolean
        buchungsId: number
        beitragsjahr?: number
        showPrompt: boolean
    }
}

class MitgliederBJournal extends Component<MitgliederBJournalProps, MitgliederBJournalState> {

    constructor(props: MitgliederBJournalProps) {
        super(props);

        this.state = {
            loading: false,
            active: false,
            showEdit: false,
            showUpload: false,
            selectedBeitragsjahr: 0,
            showEditMode: 'edit',
            showEditType: 'buchung',
            currentEditBuchung: -1,
            currentBuchung: {
                index: -1,
                buchungHandle: -1,
                ...this.props.showBuchungData
            },

            zahlungsweiseNewDefault: this.props.zahlungsweisehaben,
            leistungsdatumNewDefault: moment(new Date().getTime()).format('YYYY-MM-DD'),

            buchungen: [],
            mGNr: this.props.mGNr,
            gesamtoffen: 0,
            summeSoll: 0,
            summeHaben: 0,
            showBuchung: this.props.showBuchung,
            showBuchungData: this.props.showBuchungData,
            reloadBJournal: this.props.reloadBJournal,
            printSammelrechnungChecked: false,

            boStaffeln: [],

            zahlungsweisen: [
                { id: "Bankeinzug", value: "Bankeinzug", filter1: true, filter2: true },
                { id: "Bar", value: "Bar", filter1: true, filter2: false },
                { id: "EC Cash", value: "EC Cash", filter1: true, filter2: false },
                { id: "Überweisung", value: "Überweisung", filter1: true, filter2: true },
            ],

            oPs: this.props.oPs,
            oLs: this.props.oLs,
            vorgangValues: [],
            loadAllBuchungen: false,
            vgdUebermittlung: {
                beitragsjahr: 0,
                buchungsId: 0,
                confirmed: false,
                showPrompt: false
            }
        }

        this.buchungClose = this.buchungClose.bind(this);
        this.buchungSave = this.buchungSave.bind(this);
        this.buchungShow = this.buchungShow.bind(this);
    }

    async loadBuchungen() {
        this.setState({
            loading: true,
        })

        try {
            let result = await MitgliedService.loadBuJournal(this.state.mGNr, this.state.loadAllBuchungen)

            let resultBO = await CommonService.getBeitragsordnung(this.state.mGNr)

            if (result && result.success) {
                this.setState({
                    buchungen: result.data.buchungen,
                    gesamtoffen: result.data.gesamtbeitragOffen,
                    summeSoll: result.data.sollSumme,
                    summeHaben: result.data.habenSumme,

                    boStaffeln: resultBO.data.items,
                    loading: false
                });
            }

        } catch (e) {
            console.log("Error in loading homemessage: " + e);
        }
    }

    componentDidMount() {

    }

    async componentDidUpdate(prevProps: MitgliederBJournalProps, prevState: MitgliederBJournalState) {

        if (prevProps.oPs !== this.props.oPs) {

            this.setState({
                oPs: this.props.oPs,
            });

        }
        if (prevProps.oLs !== this.props.oLs) {

            this.setState({
                oLs: this.props.oLs,
            });

        }
        if (prevProps.reloadBJournal !== this.props.reloadBJournal || this.state.loadAllBuchungen !== prevState.loadAllBuchungen) {

            await this.loadBuchungen();

        }
        if (prevProps.showBuchung !== this.props.showBuchung && this.props.showBuchung !== "") {

            await this.buchungShow(-1, this.props.showBuchung, this.props.showBuchungData);
            this.setState({
                showBuchung: this.props.showBuchung,
                showBuchungData: this.props.showBuchungData,
            });

        }

        //Init Component if active clicked
        if (this.props.active !== prevProps.active && this.props.active) {
            await this.loadBuchungen();

            this.setState({
                active: true,
            });
        }

    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    async buchungDelete(art: any, handle: number) {
        if (window.confirm('Dies Buchung wirklich löschen?')) {

            let response = await BuchungService.deleteBuchung(handle)

            if (response.success) {
                toast.success("Buchung erfolgreich gelöscht!", {
                    autoClose: 5000
                });

                this.loadBuchungen();

                if (this.props.onReload) {
                    this.props.onReload();
                }

            } else {
                toast.error("Buchung konnte nicht gelöscht werden.", {
                    autoClose: false
                });
            }
        }

    }

    async printSammelrechnungBJ(nachname: string) {
        //get all checked items
        let allHandles = "";
        this.state.buchungen.forEach((item) => {
            if (typeof item['checked'] !== "undefined" && item['checked']) {
                if (allHandles !== "") allHandles += ",";
                allHandles += item['buchungHandle'];
            }
        });

        await MitgliedService.mitgliedPrintFile(this.state.mGNr, "rptMGRechnung", "D", "RechZahlungsweise:=" + this.props.zahlungsweise + ";RechBuchungHandles:=" + allHandles + ";", this.state.mGNr + "_" + nachname + "_Rechnung.pdf")
    }

    async buchungPrint(buchungID: number, nachname: string) {
        await MitgliedService.mitgliedPrintFile(
            this.state.mGNr,
            "rptMGRechnung",
            "D",
            "RechZahlungsweise:=" + (this.state.buchungen[buchungID].zahlungsweise === "" ? this.props.zahlungsweise : this.state.buchungen[buchungID].zahlungsweise) + ";RechBuchungHandles:=" + this.state.buchungen[buchungID].buchungHandle + ";",
            this.state.mGNr + "_" + nachname + "_Rechnung.pdf")
    }

    buchungClose() {
        let editBuchung: JournalBuchung = {
            index: -1,
            buchungHandle: -1,
            modalOpenedForEdit: false,
            vgdUebermittlung: false,
            bmg: 0,
            vv: false,
            vgdPrintPossible: false
        };

        this.setState({
            showEdit: false,
            currentEditBuchung: -1,
            currentBuchung: editBuchung,
            showBuchung: '',
        })

        //reset showbuchung in mitglied
        if (typeof this.props.onReload === "function") {
            this.props.onReload();
        }
    }

    async buchungSave(teilzahlung: boolean, bmg?: number, vv?: boolean) {

        this.setState({ loading: true })

        const toastId = toast.info("Buchung wird durchgeführt...")

        let currentBuchung = this.state.currentBuchung;
        currentBuchung.istTeilzahlung = teilzahlung;
        currentBuchung.beitragsjahr = Number(currentBuchung.beitragsjahr);

        currentBuchung.bmg = bmg ?? 0
        currentBuchung.vv = vv ?? false

        //Send data
        let response = await BuchungService.saveBuchung(currentBuchung)

        if (response.success) {
            this.buchungClose();
            this.loadBuchungen();

            toast.success("Buchung erfolgreich gespeichert.", {
                autoClose: 5000
            });

            if (typeof this.props.onReload === "function") {
                this.props.onReload();
            }
        } else {
            if (response.data && response.data.message) {
                toast.error("Buchung konnte nicht ausgeführt werden: " + response.data.message, { autoClose: false });
            } else {
                toast.error("Es gab einen Problem bei der Buchung. " + response.data.message, {
                    autoClose: false
                });
            }
        }

        toast.dismiss(toastId)
        this.setState({ loading: false })
        return response.success as boolean
    }

    async notizSave(mode = 'new') {

        this.setState({ loading: true })

        let currentBuchung = this.state.currentBuchung;

        if (mode === 'clear') {
            currentBuchung.notiz = '';
        }

        let response = await NotizenService.setBuchungNotiz(this.state.mGNr, currentBuchung.notiz)

        if (response.success) {

            toast.success("Notiz erfolgreich abgesendet.", {
                autoClose: 5000
            });
            this.buchungClose();

        } else {
            if (response.data && response.data.message) {
                toast.error("Notiz konnte nicht gesendet werden: " + response.data.message, {
                    autoClose: 5000
                });
            } else {
                toast.error("Es gab ein Problem bei der Notiz.", {
                    autoClose: 5000
                });
            }
        }

        this.setState({ loading: false })
    }

    async todoHVSave() {
        let currentBuchung = this.state.currentBuchung;

        //Send data
        let response = await AufgabenService.saveAufgabeToHv(this.state.mGNr, currentBuchung.notiz);

        if (response.success) {

            toast.success("Aufgabe wurde gesendet, Sehen Sie auf der Homeseite nach, wie der Status ist.", {
                autoClose: 5000
            });
            this.buchungClose();

        } else {
            if (response.data && response.data.message) {
                toast.error("Aufgabe konnte nicht gesendet werden: " + response.data.message, {
                    autoClose: 5000
                });
            } else {
                toast.error("Es gab ein Problem bei der Notiz.", {
                    autoClose: 5000
                });
            }
        }

    }

    async loadNotiz() {

        try {
            let result = await NotizenService.loadMgAbrechNotiz(this.state.mGNr)

            if (result && result.success) {
                return result.data.notiz;
            }

        } catch (e) {

        }
    }

    async setCurrentBuchung(buchungID: number, defaultVorgang = "", data: any = []) {
        let editBuchung: JournalBuchung;
        let mode = 1;
        let thisShowEditType = 'buchung';
        let modalOpenedForEdit = false;

        //check if only notiz
        if (data['onlyNotiz']) {
            thisShowEditType = 'notiz';
        }
        if (data['onlyTodoHV']) {
            thisShowEditType = 'todoHV';
        }

        if (data['grunddatenTodoHV']) {
            thisShowEditType = 'grunddatenTodoHV';
        }

        if (data['modalOpenedForEdit']) {
            modalOpenedForEdit = data['modalOpenedForEdit']
        }

        //check for data and get index
        if (data['handle'] && data['mode'] === 2) {
            mode = 2;
            //search oLs entry
            for (let i = 0; i < this.state.oLs.length; i++) {
                if (this.state.oLs[i].buchungHandle === data['handle']) {
                    buchungID = i;
                }
            }
        }

        if (buchungID === -1) {
            let currVorgang = "Beitrag";
            let currBeitragsjahr: string | number = new Date().getFullYear();
            let currentVorganWahl = currVorgang + " " + currBeitragsjahr;  //Eigentlich gibt es keine "neutrale" Buchung mehr

            defaultVorgang = defaultVorgang.trim();

            if (defaultVorgang !== "" && defaultVorgang.indexOf(" ") > 0) {

                let currVorgangSplit = defaultVorgang.split(" ");

                currVorgang = currVorgangSplit[0];
                currBeitragsjahr = currVorgangSplit[1];
                if (!Number.isInteger(currBeitragsjahr)) {
                    currBeitragsjahr = currBeitragsjahr.replace(currVorgang, "");
                }
                currentVorganWahl = currVorgang + " " + currBeitragsjahr;

            } else if (defaultVorgang !== "") {

                //kein split zb für Aufnahmegebühr
                currentVorganWahl = defaultVorgang;
                currVorgang = defaultVorgang;
                currBeitragsjahr = 0;

            }

            //check if oPs has this entry
            let tempWahl = this.getBetragFromOp(currentVorganWahl, true)
            if (tempWahl) {
                currentVorganWahl = tempWahl as string;
            }

            let newNotiz = "";
            if (thisShowEditType === 'notiz') {
                newNotiz = await this.loadNotiz();
            }


            editBuchung = {
                index: -1,
                buchungTanEdit: 0,

                bstNr: UserStore.username,
                mgNr: this.state.mGNr,

                buchungsart: mode, //1:zahlung oder 2:leistung
                vorgang: currVorgang,
                leistungsdatum: this.state.leistungsdatumNewDefault,

                betrag: this.getBetragFromOp(currentVorganWahl) as number | undefined,
                betragMax: this.getBetragFromOp(currentVorganWahl) as number | undefined,

                zahlungsweise: this.state.zahlungsweiseNewDefault,
                istTeilzahlung: false,
                beitragsjahr: currBeitragsjahr as number,
                notiz: newNotiz,
                modalOpenedForEdit: modalOpenedForEdit,
                vgdUebermittlung: false,
                bmg: 0,
                vv: false,
                vgdPrintPossible: false
            }

            //update valid Vorgang for buchungsart
            this.updateValidVorgaenge(mode, currentVorganWahl);

        } else {

            let thisList: Buchung[] | Leistung[] = [];
            let betrag: number | undefined = 0;

            if (mode === 1) {
                thisList = this.state.buchungen;
                betrag = thisList[buchungID].haben;
            } else if (mode === 2) {
                thisList = this.state.oLs;
                betrag = thisList[buchungID].betrag;
            }


            let buchungstext = thisList[buchungID].buchungstext?.split(" ");

            let beitragsjahr = 0
            
            if(thisList[buchungID].leistungsdatum) {
                beitragsjahr = new Date(thisList[buchungID].leistungsdatum!).getFullYear()
            }

            if(!isNaN(Number(!!buchungstext && buchungstext[1]))){
                beitragsjahr = Number(!!buchungstext && buchungstext[1])
            }

            editBuchung = {
                index: thisList[buchungID].buchungHandle,
                buchungTanEdit: thisList[buchungID].buchungHandle,

                bstNr: UserStore.username,
                mgNr: this.state.mGNr,

                buchungsart: data["VGDUebermittlung"] ? 2 : thisList[buchungID].buchungsart,
                vorgang: thisList[buchungID].vorgangsart,
                leistungsdatum: thisList[buchungID].leistungsdatum?.toString().substr(0, 10),
                betrag: betrag,
                betragMax: betrag,
                zahlungsweise: thisList[buchungID].zahlungsweise,
                istTeilzahlung: thisList[buchungID].istTeilzahlung,
                beitragsjahr: beitragsjahr,
                notiz: (thisList[buchungID].notiz === null ? "" : thisList[buchungID].notiz as string | undefined),
                modalOpenedForEdit: modalOpenedForEdit,
                vgdUebermittlung: !!data["VGDUebermittlung"],
                bmg: 0,
                vv: false,
                vgdPrintPossible: (thisList[buchungID] as Buchung).vgdPrintPossible
            }

            //update valid Vorgang for buchungsart
            this.updateValidVorgaenge(mode, (thisList[buchungID].vorgangsart + (Number(!!buchungstext && buchungstext[1]) > 0 ? " " + buchungstext![1] : "")));
        }

        this.setState({
            currentBuchung: editBuchung,
            currentEditBuchung: buchungID,
            showEditType: thisShowEditType,
        })
    }

    async buchungShow(buchungID: number, defaultVorgang = "", data: any = []) {

        trackModal('/mitglied/buchungShow');

        this.setCurrentBuchung(buchungID, defaultVorgang, data)

        this.setState({
            showEdit: true,
            showEditMode: (buchungID === -1) ? "new" : "edit",
        })
    }

    getBetragFromOp(val: string, check: boolean = false) {
        //fill betrag according Ops
        let currentBetrag = -1;
        let vorgangId = '';

        let thisEntries = [];
        thisEntries = this.state.oPs;
        thisEntries.forEach((entry) => {
            if (entry.vorgangsart) {
                let reducedKonto = entry.konto?.replace(entry.vorgangsart, "");
                vorgangId = entry.vorgangsart + ((entry.vorgangsart !== reducedKonto && (reducedKonto?.length ?? -1) > 0) ? " " + reducedKonto : "");

                if (vorgangId === val) {
                    currentBetrag = entry.betrag ?? -1;
                }
            }
        });

        if (check) {
            if (currentBetrag !== -1 || thisEntries.length === 0) {
                return undefined;
            } else {
                return this.state.oPs[0].vorgang;
            }
        } else {
            return currentBetrag;
        }
    }

    updateValidVorgaenge(buchungsart: number, forceValue = "") {

        //calc VorgangValues
        let currVorgangValues = [];
        let forceValuePresent = false;
        let vorgangId = '';

        let thisEntries = [];
        thisEntries = this.state.oPs;
        thisEntries.forEach((entry) => {
            let valid = true;
            if (buchungsart === 2 && this.state.currentBuchung.index === -1 && this.buchungstextExists(entry.vorgang ?? '')) {
                valid = false;
            }
            if (valid) {
                if (entry.vorgangsart) {
                    //calc correct vorgang
                    let reducedKonto = entry.konto?.replace(entry.vorgangsart, "");
                    vorgangId = entry.vorgangsart + ((entry.vorgangsart !== reducedKonto && (reducedKonto?.length ?? -1) > 0) ? " " + reducedKonto : "");

                    currVorgangValues.push({
                        id: vorgangId.trim(),
                        value: entry.vorgang?.trim(),
                    });

                    if (vorgangId === forceValue) {
                        forceValuePresent = true;
                    }
                }
            }
        });

        //check force and present
        if (forceValue && !forceValuePresent) {

            //console.log("Muss dazu: " + forceValue);
            currVorgangValues.push({
                id: forceValue.trim(),
                value: forceValue.trim()
            })
        }

        this.setState({
            vorgangValues: currVorgangValues,
        });

    }

    buchungstextExists(val: string) {
        return this.state.oLs.some(function (el) {
            return el.buchungstext === val;
        });
    }

    setInputValue(property: string, val: any, date = false) {
        var buchung = this.state.currentBuchung;
        if (date) {
            buchung[property] = val + "T00:00:00";
        } else {
            buchung[property] = val;
        }

        //split in case of Buchung
        if (property === "vorgang") {
            if (val !== "" && val.indexOf(" ") > 0) {
                let valueExplode = val.split(" ");
                buchung["vorgang"] = valueExplode[0];
                buchung["beitragsjahr"] = Number(valueExplode[1]);
                buchung["betrag"] = this.getBetragFromOp(val) as number | undefined;
                buchung["betragMax"] = this.getBetragFromOp(val) as number | undefined;
            } else {
                buchung["vorgang"] = val;
                buchung["beitragsjahr"] = 0;
                buchung["betrag"] = this.getBetragFromOp(val) as number | undefined;
                buchung["betragMax"] = this.getBetragFromOp(val) as number | undefined;
            }
        }

        //check LDatum not in Future
        if (property === "leistungsdatum") {
            if (new Date(val) > new Date()) {
                toast.error("Das Leistungsdatum darf nicht in der Zukunft liegen!", {
                    autoClose: 5000
                });
            } else {
                this.setState({
                    leistungsdatumNewDefault: val
                })
            }
        }

        //check Betrag not > max betrag
        if (property === "betrag") {
            if (val > this.state.currentBuchung.betragMax! && !this.valueInStaffel(val)) {
                toast.error("Der Beitrag entspricht nicht der Beitragsordnung. Kein Buchen möglich. Bitte Betrag anpassen", {
                    autoClose: 5000
                });
            }
        }

        //Check valid Vorgang for buchungsart
        if (property === "buchungsart") {
            this.updateValidVorgaenge(val);
        }

        //store zahlungsweise for default
        if (property === "zahlungsweise") {
            this.setState({
                zahlungsweiseNewDefault: val,
            })
        }

        this.setState({
            currentBuchung: buchung
        })
    }

    setCheckLine(index: number, value: boolean) {

        let thisItems = this.state.buchungen;
        thisItems[index].checked = value;

        //calc if at least one is checked
        let sumChecked = 0;
        thisItems.forEach((item) => {
            if (item.checked) {
                sumChecked += 1;
            }
        });

        this.setState({
            oPs: thisItems,
            printSammelrechnungChecked: (sumChecked > 0),
        })
    }

    // ALH20-105
    isValidPrint(kennzeichen: string, vorgangsart: string, status: number, benutzer: string, bst: string) {
        if (kennzeichen === "H") {
            if ((vorgangsart === "Beitrag" ||
                vorgangsart === "Aufnahmegeb" ||
                vorgangsart === "MahngebVerein" ||
                vorgangsart === "MahngebRA" ||
                vorgangsart === "EMA") && benutzer === "BST" && bst === UserStore.bstNr) {

                if (status === 2) {
                    return true;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    valueInStaffel(val: number) {
        let result = false;
        this.state.boStaffeln.forEach((item) => {
            if (item.beitrag === val) {
                result = true;
            }
        });

        return result;
    }

    async openUploadPopUp(confirmed: boolean) {
        if (confirmed) {
            await this.setCurrentBuchung(this.state.vgdUebermittlung.buchungsId, "", { "VGDUebermittlung": true })
        }

        this.setState({
            showUpload: true,
            selectedBeitragsjahr: this.state.vgdUebermittlung.beitragsjahr,
            vgdUebermittlung: { ...this.state.vgdUebermittlung, confirmed: confirmed, showPrompt: false }
        })
    }

    openConfirmationPrompt(buchungId: number, beitragsjahr?: number) {
        this.setState({
            vgdUebermittlung: {
                beitragsjahr: beitragsjahr,
                buchungsId: buchungId,
                confirmed: false,
                showPrompt: true
            }
        })
    }

    async bookAufnahmegeb(buchungId: number) {
        this.setCurrentBuchung(buchungId, "", { "VGDUebermittlung": true }).then(() => {
            this.buchungSave(false)
        })
    }

    loadAllBuchungen() {
        this.setState({
            loadAllBuchungen: true
        })
    }

    render() {

        const { nachname = '' } = this.props

        let list = this.state.buchungen;

        // first sort leistungsdatum then sort beitragsjahr 
        list.sort((a, b) => (new Date(b.leistungsdatum ?? "").valueOf()) - (new Date(a.leistungsdatum ?? "").valueOf())).sort((a, b) => (b.beitragsjahr ?? -1) - (a.beitragsjahr ?? -1))

        return (
            <div>
                <h3>Buchungsjournal<InfoIcon space="left" placement="right"><small>Hier sehen Sie das Buchungsjournal des Mitglieds. Um eine Rechnung für das Mitglied zu drucken, setzen Sie bei den gewünschten Soll-Stellungen den Haken, die auf der Rechnung erscheinen sollen.Bei eingebuchten Beiträgen als Barzahlung können Sie auch die Haben-Buchungen markieren und eine Rechnung (Quittung) mit Unterschriftenzeile ausdrucken. Die Rechnung können Sie anschließend aufrufen, indem Sie auf den Button “Sammelrechnung drucken” klicken.</small></InfoIcon></h3>
                <div className="alh_subheader">Summe Soll: {this.numberFormat(this.state.summeSoll)} | Summe Haben: {this.numberFormat(this.state.summeHaben)}</div>

                {!this.state.loading &&
                    <div>
                        <SubmitButton
                            type='button'
                            disabled={!this.state.printSammelrechnungChecked}
                            onClick={() => this.printSammelrechnungBJ(nachname)}
                            text="Sammelrechnung drucken"
                            size="sm"
                        /><br />
                        <SubmitButton
                            type='button'
                            onClick={() => this.loadAllBuchungen()}
                            text="Alle Buchungen laden"
                            size="sm"
                        />
                    </div>
                }

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th className="alh_tablecheckbox">&nbsp;</th>
                            <th>L-Datum</th>
                            <th>Buchungstext</th>
                            <th className="text-right">Soll</th>
                            <th className="text-right">Haben</th>
                            <th>Zahlungsw.</th>
                            <th className="alh_tableactions">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((mg, index) => (
                            <tr key={index} className={(mg.benutzer !== "BST" && mg.bstNr !== UserStore.bstNr) ? "highlight" : ""}>
                                <td className="alh_tablecheckbox">
                                    <InputCheckbox
                                        name={"b_" + index}
                                        title="Buchung"
                                        id={"bcb_" + index}
                                        checked={this.state.buchungen[index].checked}
                                        disabled={!this.isValidPrint(mg.kennzeichen, mg.vorgangsart ?? '', mg.portalStatus, mg.benutzer, mg.bstNr ?? '')}
                                        onChange={(val) => this.setCheckLine(index, val)}
                                    />
                                </td>
                                <td><Moment format="DD.MM.YYYY">{mg.leistungsdatum}</Moment></td>
                                <td>{mg.buchungstext}</td>
                                <td className="text-right">{(mg.soll !== null) ? this.numberFormat(mg.soll) : ""}</td>
                                <td className="text-right">{(mg.haben !== null) ? this.numberFormat(mg.haben) : ""}</td>
                                <td>{mg.zahlungsweise}</td>
                                <td className="tableActions">
                                    <FontAwesomeIcon icon={faPrint} onClick={() => this.buchungPrint(index, nachname)} title="Rechnung drucken" className={(this.isValidPrint(mg.kennzeichen, mg.vorgangsart ?? '', mg.portalStatus, mg.benutzer, mg.bstNr ?? '') ? "faButton faButtonSpaceright" : "d-none")} />
                                    <FontAwesomeIcon icon={faEdit} onClick={() => this.buchungShow(index)} title="Eintrag Bearbeiten" className={(mg.portalStatus === 1 ? "faButton" : "d-none")} />
                                    <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.buchungDelete(mg.buchungsart, mg.buchungHandle ?? -1)} title="Eintrag löschen" className={(mg.portalStatus === 1 ? "faButton" : "d-none")} />
                                    <FontAwesomeIcon icon={faPlus} onClick={() => {
                                        if(mg.vorgangsart?.toLowerCase() !== "aufnahmegeb") {
                                            this.openConfirmationPrompt(index, mg.beitragsjahr)
                                        } else {
                                            this.bookAufnahmegeb(index)
                                        }
                                    }} title="Dateiupload" className={(mg.vgdPrintPossible ? "faButton" : "d-none")} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}

                <Modal
                    size="lg"
                    show={this.state.showEdit}
                    onHide={this.buchungClose}
                    backdrop="static"
                    keyboard={true}
                >
                    <Modal.Header closeButton>
                        {this.state.showEditMode === 'edit' && this.state.showEditType === 'buchung' &&
                            <Modal.Title>Buchung bearbeiten</Modal.Title>
                        }
                        {this.state.showEditMode === 'new' && this.state.showEditType === 'buchung' &&
                            <Modal.Title>Buchung erstellen</Modal.Title>
                        }
                        {this.state.showEditMode === 'new' && this.state.showEditType === 'notiz' &&
                            <Modal.Title>Notiz zur Abrechnung erstellen</Modal.Title>
                        }
                        {this.state.showEditMode === 'new' && (this.state.showEditType === 'todoHV' || this.state.showEditType === "grunddatenTodoHV") &&
                            <Modal.Title>Aufgabe für Hauptverwaltung erstellen</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showEditMode === 'edit' && this.state.showEditType === 'buchung' &&
                            <Alert key="newAlert" variant="warning">Hier können Sie die Buchung bearbeiten:</Alert>
                        }
                        {this.state.showEditMode === 'new' && this.state.showEditType === 'buchung' &&
                            <Alert key="newAlert" variant="warning">Hier können Sie eine neue Buchung für dieses Mitglied erstellen:</Alert>
                        }
                        {this.state.showEditMode === 'new' && this.state.showEditType === 'notiz' &&
                            <Alert key="newAlert" variant="warning">Wird mit nächster Abrechnung an HV übermittelt:</Alert>
                        }
                        {this.state.showEditMode === 'new' && this.state.showEditType === 'todoHV' &&
                            <Alert key="newAlert" variant="warning">Hier können Sie eine Aufgabe an die Hauptverwaltung senden, wenn z.B. eine Sollbuchung angepasst werden soll:</Alert>
                        }
                        {this.state.showEditMode === 'new' && this.state.showEditType === "grunddatenTodoHV" &&
                            <Alert key="newAlert" variant="warning">Hier können Sie eine Aufgabe an die Hauptverwaltung senden, wenn z.B. der Name wegen Heirat angepasst werden soll:</Alert>
                        }

                        {this.state.showEditType === 'buchung' &&
                            <div>
                                <SelectField
                                    id='buchungsart'
                                    name='buchungsart'
                                    withPleaseSelect={false}
                                    options={
                                        [
                                            { id: 1, value: 'Zahlungen inkl. Leistung' },
                                            { id: 2, value: 'Nur Leistung, Direktzahlung an HV (VGD)' }
                                        ]
                                    }
                                    disabled={this.state.showEditMode === 'edit'}
                                    value={this.state.currentBuchung.buchungsart}
                                    onChange={(val) => this.setInputValue('buchungsart', Number(val), false)}
                                />

                                <Container className="alh_containerNoPadding">
                                    <Row className="">
                                        <Col lg={6}>
                                            <div>Vorgang: <InfoIcon space="left" placement="right"><small>Wählen Sie hier
                                                aus Ihren offenen Vorgängen aus.</small></InfoIcon></div>
                                            <SelectField
                                                id='vorgang'
                                                name='vorgang'
                                                withPleaseSelect={false}
                                                disabled={this.state.showEditMode === 'edit'}
                                                options={this.state.vorgangValues}
                                                value={this.state.currentBuchung.vorgang + (((this.state.currentBuchung.beitragsjahr ?? -1) > 0) ? " " + this.state.currentBuchung.beitragsjahr : "")}
                                                onChange={(val) => this.setInputValue('vorgang', val, false)}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <div>L-Datum: <InfoIcon space="left" placement="right"><small>Tragen Sie hier
                                                das Zahlungsdatum ein.</small></InfoIcon></div>
                                            <InputField
                                                type='date'
                                                id='leistungsdatum'
                                                name='leistungsdatum'
                                                placeholder='Leistungsdatum'
                                                maxDate={moment().format("YYYY-MM-DD")}
                                                inputClassNames="form-control-sm"
                                                setFocus={true}
                                                value={moment(this.state.currentBuchung.leistungsdatum).format("YYYY-MM-DD")}
                                                onChange={(val) => this.setInputValue('leistungsdatum', val, true)}
                                            />

                                        </Col>
                                    </Row>
                                    <Row className="alh_containerNoPadding">
                                        <Col lg={6}>
                                            <div>Betrag:</div>
                                            <InputField
                                                type='number'
                                                id='betrag'
                                                name='betrag'
                                                placeholder='Betrag'
                                                inputClassNames="form-control-sm"
                                                value={parseFloat(this.state.currentBuchung.betrag?.toString() ?? '').toFixed(2)}
                                                onChange={(val) => this.setInputValue('betrag', Number(val), false)}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <div>Zahlungsweise (Haben): <InfoIcon space="left" placement="right"><small>Die
                                                bevorzugte Zahlungsweise kann über Meine BST | Grunddaten voreingestellt
                                                werden.</small></InfoIcon></div>
                                            <SelectField
                                                id='zahlungsweise'
                                                name='zahlungsweise'
                                                withPleaseSelect={false}
                                                options={this.state.zahlungsweisen.filter(zahlung => zahlung["filter" + this.state.currentBuchung.buchungsart] === true)}
                                                value={this.state.currentBuchung.zahlungsweise}
                                                onChange={(val) => this.setInputValue('zahlungsweise', val, false)}
                                            />
                                        </Col>
                                    </Row>
                                </Container>

                            </div>
                        }

                        {this.state.showEditType === 'notiz' &&
                            <div>
                                <InputField
                                    as='textarea'
                                    type='textclean'
                                    id='notiz'
                                    name='notiz'
                                    forceAutofill={true}
                                    setFocus={true}
                                    placeholder='Notiz zur Abrechnung'
                                    value={this.state.currentBuchung.notiz ?? ''}
                                    onChange={(val) => this.setInputValue('notiz', val)}
                                />
                            </div>
                        }

                        {(this.state.showEditType === 'todoHV' || this.state.showEditType === "grunddatenTodoHV") &&
                            <div>
                                <InputField
                                    as='textarea'
                                    type='textclean'
                                    id='notiz'
                                    name='notiz'
                                    forceAutofill={true}
                                    setFocus={true}
                                    placeholder='Aufgabe für Hauptverwaltung'
                                    value=""
                                    onChange={(val) => this.setInputValue('notiz', val)}
                                />
                            </div>
                        }

                    </Modal.Body>


                    {this.state.showEditType === 'buchung' &&
                        <Modal.Footer>
                            {!this.state.loading ?
                                <>
                                    {UserStore.hasBerechtigung('bst_mg', 'write') && new Date(this.state.currentBuchung.leistungsdatum ?? '') <= new Date() && (!this.state.currentBuchung.modalOpenedForEdit || ((this.state.currentBuchung.betrag ?? 0) < (this.state.currentBuchung.betragMax ?? -1) && this.state.currentBuchung.buchungsart === 1)) ?
                                        <Button
                                            variant="primary"
                                            onClick={() => this.buchungSave(true)}>
                                            Teilzahlung
                                        </Button> : null}
                                    {UserStore.hasBerechtigung('bst_mg', 'write') && new Date(this.state.currentBuchung.leistungsdatum ?? '') <= new Date() && (this.valueInStaffel(this.state.currentBuchung.betrag ?? 0) && this.state.currentBuchung.buchungsart === 1 && this.state.currentBuchung.betrag !== this.state.currentBuchung.betragMax) ?
                                        <Button
                                            variant="primary"
                                            onClick={() => this.buchungSave(false)}>
                                            Buchen / Sollkorrektur
                                        </Button> : null}
                                    {UserStore.hasBerechtigung('bst_mg', 'write') && new Date(this.state.currentBuchung.leistungsdatum ?? '') <= new Date() && ((this.state.currentBuchung.modalOpenedForEdit && this.state.currentBuchung.betrag === this.state.currentBuchung.betragMax) || this.state.currentBuchung.buchungsart === 2) ?
                                        <Button
                                            variant="primary"
                                            onClick={() => this.buchungSave(false)}>
                                            Buchen
                                        </Button> : null}
                                </>
                                : <div className="alhLoadingRel">
                                    <WaveTopBottomLoading color="#ffc800" />
                                </div>}
                            <Button variant="secondary" onClick={this.buchungClose}>
                                Schliessen
                            </Button>
                        </Modal.Footer>
                    }

                    {this.state.showEditType === 'notiz' &&
                        <Modal.Footer>
                            {this.state.loading ?
                                <>
                                    <Button
                                        variant="outline-danger"
                                        id="alh_btn_buchen"
                                        onClick={() => this.notizSave('clear')}
                                        disabled={this.state.currentBuchung.notiz === ""}>
                                        Notiz löschen
                                    </Button>
                                    <Button
                                        variant="primary"
                                        id="alh_btn_buchen"
                                        onClick={() => this.notizSave()}
                                        disabled={this.state.currentBuchung.notiz === ""}>
                                        Notiz speichern
                                    </Button>
                                </>
                                : <div className="alhLoadingRel">
                                    <WaveTopBottomLoading color="#ffc800" />
                                </div>}
                            <Button variant="secondary" onClick={this.buchungClose}>
                                Schliessen
                            </Button>
                        </Modal.Footer>
                    }

                    {(this.state.showEditType === 'todoHV' || this.state.showEditType === "grunddatenTodoHV") &&
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                id="alh_btn_todohvsend"
                                onClick={() => this.todoHVSave()}
                                disabled={this.state.currentBuchung.notiz === ""}>
                                Aufgabe absenden
                            </Button>
                            <Button variant="secondary" onClick={this.buchungClose}>
                                Schliessen
                            </Button>
                        </Modal.Footer>
                    }
                </Modal>

                <ModalVgdFileUploads
                    bJahr={this.state.selectedBeitragsjahr}
                    mgnr={this.state.mGNr}
                    showUpload={this.state.showUpload}
                    selectedBuchung={this.state.currentBuchung}
                    promptAccepted={!!this.state.vgdUebermittlung.confirmed}
                    uploadClose={() => this.setState({ showUpload: false })}
                    reloadBuchungen={() => this.loadBuchungen()}
                />

                <ConfirmationModal showConfirmation={this.state.vgdUebermittlung.showPrompt} setConfirmation={(confirmed) => this.openUploadPopUp(confirmed)} />

            </div>
        );
    }
}

const ConfirmationModal = ({ showConfirmation, setConfirmation }: { showConfirmation: boolean, setConfirmation: (confirmed: boolean) => void }) => {
    return <Modal
        size="lg"
        show={showConfirmation}
        backdrop="static"
        keyboard={true}>
        <Modal.Body>
            Sind Sie für diese Einkommensteuererklärung vollumfänglich steuerlich tätig geworden inklusive Elsterversand und Bescheidprüfung?
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="primary"
                id="dps_haben_button"
                onClick={() => setConfirmation(true)}>
                Ja
            </Button>
            <Button variant="secondary" onClick={() => setConfirmation(false)}>
                Nein
            </Button>
        </Modal.Footer>
    </Modal>
}

const ModalVgdFileUploads = ({ bJahr, mgnr, showUpload, selectedBuchung, promptAccepted, uploadClose, reloadBuchungen }:
    {
        bJahr?: number,
        mgnr: string,
        showUpload: boolean,
        promptAccepted: boolean,
        selectedBuchung: JournalBuchung,
        uploadClose: () => void,
        reloadBuchungen: () => void
    }) => {
    const [bmg, setBmg] = React.useState("")
    const [begruendung, setBegruendung] = React.useState<string>()
    const [vv, setVv] = React.useState(false)
    const [uploadedFilesB64, setUploadedFilesB64] = React.useState<string[]>([])
    const [uploadedFiles, setUploadedFiles] = React.useState<IFileWithMeta[]>([])
    const [uploading, setUploading] = React.useState(false)

    const handleChangeStatus = (fileWithMeta: IFileWithMeta, status: StatusValue) => {
        const reader = new FileReader();
        const { file } = fileWithMeta

        if (status === "removed") {
            reader.onload = (event) => {
                //cut base64 data for Server (ALH20-11)
                if (event.target) {
                    let fileb64 = event.target.result;
                    if (fileb64 && typeof fileb64 === "string") {
                        setUploadedFilesB64([...uploadedFilesB64.filter(f => f !== (fileb64! as string).substring((fileb64! as string).indexOf("base64,") + 7))],)
                        setUploadedFiles(oldFiles => oldFiles.filter(file => file.meta.id !== fileWithMeta.meta.id))
                        fileWithMeta.remove()
                    }
                }
            };
        }

        if (status === "done") {
            reader.onload = (event) => {
                //cut base64 data for Server (ALH20-11)
                if (event.target) {
                    let fileb64 = event.target.result;
                    if (fileb64 && typeof fileb64 === "string") {
                        setUploadedFilesB64([...uploadedFilesB64, fileb64.substring(fileb64.indexOf("base64,") + 7)])
                        setUploadedFiles(oldFiles => [...oldFiles, { ...fileWithMeta }])
                    }
                }
            };

        }
        reader.readAsDataURL(file);
    }

    const sendFiles = async () => {
        const toastId = toast.dark(<div><FontAwesomeIcon icon={faPrint} /> Dokument wird erzeugt,<br />einen Moment bitte...</div>, {
            autoClose: false
        });

        try {
            selectedBuchung.istTeilzahlung = false;

            if(!selectedBuchung.beitragsjahr) {
                selectedBuchung.beitragsjahr = Number(bJahr)
            } else {
                selectedBuchung.beitragsjahr = Number(selectedBuchung.beitragsjahr);
            }

            selectedBuchung.bmg = Number(bmg.replace(",", ".")) ?? 0
            selectedBuchung.vv = vv ?? false

            const response = await MitgliedService.mitgliedVgdPrint(mgnr, selectedBuchung, uploadedFilesB64, bJahr, begruendung);

            if (response.success) {
                toast.success("Datei wurde erfolgreich hochgeladen und Buchung erfolgreich durchgeführt.", {
                    autoClose: 5000
                });
            } else if (!response.success && response.data.message) {
                toast.error(response.data.message, {
                    autoClose: false
                });
                return false;
            }
        } catch (e) {
            toast.warning("Leider ist etwas schief gelaufen beim Upload. Evtl. war die Datei größer als erlaubt. Bitte kontrollieren Sie dies und versuchen Sie es erneut.",
                {
                    autoClose: false
                });
            return false;
        } finally {
            toast.dismiss(toastId);
        }

        return true
    }

    const removeFiles = () => {
        uploadedFiles.map(f => f.remove())
        setUploadedFiles([])
        setUploadedFilesB64([])
    }

    const uploadAndShowBuchung = async () => {
        setUploading(true)

        var success = await sendFiles()
        if (success) {
            if (promptAccepted) {
                reloadBuchungen()
                if (success) {
                    removeFiles()
                }
            }

            internalUploadClose()
        }

        setUploading(false)
    }

    const reset = () => {
        setBegruendung(undefined)
        setBmg("")
        setVv(false)
        removeFiles()
    }

    const internalUploadClose = () => {
        reset()
        uploadClose()
    }

    return <Modal
        size="lg"
        show={showUpload}
        backdrop="static"
        keyboard={true}>
        <Modal.Header>
            <h3><b>Datei(en) hochladen</b></h3>
        </Modal.Header>
        <Modal.Body>
            <p><b>VGD</b></p>
            Beitragsjahr
            <InputField
                size='sm'
                type='clean'
                containerClassName='formInline formInlineSpacing'
                inputClassNames='formInline'
                inputGroupClass='formInline'
                placeholder='BJahr'
                disabled
                value={bJahr?.toString() ?? ""}
                onChange={() => { }}
            /> BMG
            <InputField
                size='sm'
                type='clean'
                containerClassName='formInline formInlineSpacing'
                inputClassNames='formInline'
                inputGroupClass='formInline'
                placeholder='BMG'
                value={bmg ?? ""}
                onChange={(val) => setBmg(val)}
            /> V+V
            <InputCheckbox
                name='print.VGDVV'
                containerClassName='formInline formInlineSpacing'
                checked={vv}
                onChange={(val) => setVv(val)}
                subtext=''
            />
            <p />

            {!promptAccepted && <>
                <p><b>In welchem Umfang sind Sie steuerlich für diese Einkommensteuererklärung tätig geworden und wie hoch war der Zeitaufwand?</b></p>
                <InputField
                    as="textarea"
                    key="notizfeld"
                    type='textclean'
                    id="notizfeld"
                    name="notizfeld"
                    placeholder="Weitere Informationen"
                    value={begruendung ?? ""}
                    onChange={(val) => {
                        setBegruendung(val)
                    }}
                />
            </>}

            {promptAccepted && <>
                <p><b>Elsterprotokoll oder</b></p>
                <VgdModalDropzone name="elster" handleChangeStatus={handleChangeStatus} />
                <p />
                <p><b>Sendebericht oder</b></p>
                <VgdModalDropzone name="sendebericht" handleChangeStatus={handleChangeStatus} />
                <p />
                <p><b>unterschriebene VE oder</b></p>
                <VgdModalDropzone name="ve" handleChangeStatus={handleChangeStatus} />
                <p />
                <p><b>Bescheid an BST addressiert</b></p>
                <VgdModalDropzone name="bescheid" handleChangeStatus={handleChangeStatus} />
                <p />
            </>}

        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="primary"
                id="dps_haben_button"
                onClick={() => uploadAndShowBuchung()}
                disabled={(promptAccepted ? !uploadedFilesB64.length : !begruendung) || !bmg || uploading}>
                {promptAccepted ? "Datei(en) absenden und buchen" : "Datei absenden"}
            </Button>
            <Button variant="secondary" onClick={internalUploadClose}>
                Schliessen
            </Button>
        </Modal.Footer>
    </Modal>
}

const VgdModalDropzone = ({ name, handleChangeStatus }: { name: string, handleChangeStatus: ({ meta, file, xhr, ...rest }: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => void }) => {
    return <Dropzone
        key={name}
        onChangeStatus={handleChangeStatus}
        accept={".pdf"}
        maxFiles={1}
        inputContent={<div>
            Datei(en) zum Upload hierher ziehen oder in das Feld klicken um die Datei auszuwählen. <br />
            Mögliche Dateiformate: PDF <br />
            Maximale Dateigröße: 5 MB
        </div>}
    />
}

export default MitgliederBJournal;
