import React, { Component } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { faLink, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface HilfeStateBase {
    title: string
    description: string
}

interface HilfeItemProps {
    file: string
}

interface HilfeItemState extends HilfeStateBase {
    video: string
    link: string
}

interface HilfeModalProps {
    hilfeFile: string
}

interface HilfeModalState extends HilfeStateBase {
    showModal: boolean
    hilfeFile: string
    video: string
}

export default class Hilfe extends Component {
    render() {
        return (
            <div>
                <h3 className="alh_pageheader">ALH Webportal Profihilfe</h3>
                <div className="alh_subheader">Auf dieser Seite zeigen wir Ihnen Tipps und Tricks für das neue Portal. Wir werden die Hilfethemen kontinuierlich ausbauen.</div>

                <Container>
                    <Row className="alh_rowTopList">
                        <Col lg={4}>
                            <HilfeItem file="aufgabe2hv.json" />
                        </Col>
                        <Col lg={4}>
                            <HilfeItem file="uploaddokumente.json" />
                        </Col>
                        <Col lg={4}>
                            <HilfeItem file="kuendigungdrucken.json" />
                        </Col>
                        <Col lg={4}>
                            <HilfeItem file="emailabruf.json" />
                        </Col>
                        <Col lg={4}>
                            <HilfeItem file="bstwechsel.json" />
                        </Col>
                        <Col lg={4}>
                            <HilfeItem file="gebtagsliste.json" />
                        </Col>
                        <Col lg={4}>
                            <HilfeItem file="mitarbeiterliste.json" />
                        </Col>
                        <Col lg={4}>
                            <HilfeItem file="schnellinsportal.json" />
                        </Col>
                        <Col lg={4}>
                            <HilfeItem file="sammelbuchung.json" />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export class HilfeModal extends Component<HilfeModalProps, HilfeModalState> {

    constructor(props: HilfeModalProps) {
        super(props);

        this.state = {
            showModal: false,
            hilfeFile: this.props.hilfeFile,

            title: '',
            description: '',
            video: '',
        }

        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    componentDidMount() {
        let me = this;

        fetch('/hilfeJson/' + this.state.hilfeFile
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {

                me.setState({
                    title: myJson.title,
                    description: myJson.description,
                    video: myJson.video,
                })
            });
    }

    showModal() {
        this.setState({
            showModal: true,
        })
    }

    closeModal() {
        this.setState({
            showModal: false,
        })
    }

    render() {

        return (
            <span>
                <FontAwesomeIcon onClick={() => this.showModal()} icon={faQuestionCircle} title="Info und Hilfe" className="faButton faButtonSpaceright" />

                <Modal
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={true}
                    size="lg"
                    ref={React.createRef()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>ALH Profihilfe:<br />{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: this.state.description }} />

                        {this.state.video !== "" &&
                            <div>
                                <br />
                                <Video loop muted
                                    key={this.state.video}
                                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                    onCanPlayThrough={() => {
                                        // Do stuff
                                    }}>
                                    <source src={this.state.video} type="video/webm" />
                                </Video>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Schliessen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </span>
        )
    }
}

export class HilfeItem extends Component<HilfeItemProps, HilfeItemState> {

    constructor(props: HilfeItemProps) {
        super(props);

        this.state = {
            title: '',
            description: '',
            video: '',
            link: ''
        }
    }

    componentDidMount() {
        let me = this;

        fetch('/hilfeJson/' + this.props.file
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {

                me.setState({
                    title: myJson.title,
                    description: myJson.description,
                    video: myJson.video,
                    link: myJson.link,
                })
            });
    }

    render() {
        return (
            <div>
                <Alert variant="secondary">
                    <div>
                        <Alert.Heading>{this.state.title}</Alert.Heading>
                        {this.state.link !== "" &&
                            <a href={"/" + this.state.link} className="alh_hilfelink"><FontAwesomeIcon icon={faLink} title="Direktlink" className="faButton faButtonSpaceright" /></a>
                        }
                    </div>

                    <div dangerouslySetInnerHTML={{ __html: this.state.description }} />

                    {this.state.video !== "" &&
                        <Video loop muted
                            key={this.state.video}
                            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                            onCanPlayThrough={() => {
                                // Do stuff
                            }}>
                            <source src={this.state.video} type="video/webm" />
                        </Video>
                    }
                </Alert>
            </div>
        )
    }
}
