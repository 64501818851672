import Buchung from "../models/Buchung";
import UserStore from "../stores/UserStore";

const BuchungService = {
    saveBuchung: async (buchung: Buchung) => {
        let res = await fetch(UserStore.url + '/buchungSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.bstNr),
                    dataset: buchung
                }
            })
        });

        return await res.json();
    },
    deleteBuchung: async (handle: number) => {
        //Send data
        let res = await fetch(UserStore.url + '/buchungDel', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.username),
                    dataset: {
                        BSTNr: UserStore.username,
                        Buchungsart: 1,
                        BuchungTan: handle,
                    }
                }
            })
        });

        return await res.json();
    }
}

export default BuchungService