import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Container, Table, Form, Alert } from "react-bootstrap";
import { PointSpreadLoading } from "react-loadingg";
import MitgliederOP from "../ListComponents/MitgliederOP";
import MitgliederBJournal from "../ListComponents/MitgliederBJournal";
import Aufgaben from "../ListComponents/Aufgaben";
import SelectField from "../Forms/SelectField";
import InputField from "../Forms/InputField";
import InputCheckbox from "../Forms/InputCheckbox";
import SubmitButton from "../Forms/SubmitButton";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { sortList, trackEvent, trackModal } from "../ALHUtility";
import UserStore from "../../stores/UserStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import MitgliederRueckBuchung from "../ListComponents/MitgliederRueckBuchung";
import Uploads from "./Uploads";
import InfoIcon from "../Blocks/InfoIcon";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Buchung from '../../models/Buchung';
import KeyValue from '../../models/KeyValue';
import MitarbeiterItem from '../../models/Mitarbeiter';
import Bankverbindung from '../../models/Bankverbindung';
import Finanzdaten from '../../models/Finanzdaten';
import MitgliedItem from '../../models/Mitglied';
import Beratungsprotokoll from './Beratungsprotokoll';
import IBAN from 'iban';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Leistung from '../../models/Leistung';
import MitarbeiterService from '../../services/MitarbeiterService';
import MitgliedDokumente from './MitgliedReiter/MitgliedDokumente';
import MitgliedService from '../../services/MitgliedService';
import Leitzahlen from '../../models/Leitzahlen';
import LeitzahlenService from '../../services/LeitzahlenService';
import { ZahlungsweiseSollRechnungen } from '../../stores/Constants';
import { BstContext } from '../../stores/BstContextProvider';
import { CommonService } from '../../services/CommonService';


interface MitgliedDataset {
    [key: string]: any
    mitgliedsnummer?: string
    matchcode: string
    bstNr: string,
    bstStandort: number,
    bstMitarbeiter: string,
    adresseHandle: number,
    mitglied: MitgliedItem,
    partner: MitgliedItem,
    telefax: string,
    einwillEMailKommErteilt: number,
    identifikation: {
        hatIdentifikation: boolean,
        identifikationAm: Date | string | null
    },
    identifikationPartner: {
        hatIdentifikation: boolean,
        identifikationAm: Date | string | null
    },
    hatDatenschutzerklaerung: boolean,
    hatDatenschutzerklaerungPartner: boolean,
    bankverbindung: Bankverbindung,
    steuer: Finanzdaten
    steuerPartner: Finanzdaten,
    hatPostbox: boolean,
    beitrittAm: string,
    beitrittRueckwirkendJahr: number,
    beitrittRueckwirkendDatum: Date | string | null,
    hatHVBE: boolean,
    hatUnterschrMitglied: boolean,
    hatUnterschrPartner: boolean,
    kontoAktJahr: {
        aufnahmeGebuehr: number
        beitragsjahr: number
        bemessungsgrundlage?: number
        bemessungsgrundlageSave: boolean
        beitrag?: number
        hatVermietVerpacht: boolean
        beitragOffen: number
    },
    herkunft: string,
    istBlankoMG: boolean,
    kuendWirksamDatum: Date | string | null,
    portalStSoftReferenzNr: string,
    adresseHandleWiedereintritt: number,
    neuanlageAufnahmegebuer: number,
    bpAngelegt: number,
    bpFinal: number
    zusammenveranlagung: boolean
    zusatzInfos: string
}

interface MitgliedState {
    [key: string]: any
    loaded: boolean,
    mtglAvailable: boolean,
    validated: boolean,
    dataChanged: boolean,
    // if new mg true otherwise false
    isNew: boolean,
    isWiedereintritt: boolean,

    oPs: Buchung[],    //Child mit offenen Posten
    oLs: Leistung[],    //Child mit offenen Leistungen
    gesamtoffen: number,
    showBuchung: string,
    showBuchungData: any,
    reloadBJournal: number,
    zahlungsweiseSollRechnungen1: string,
    zahlungsweiseSollRechnungen2: string,

    beitragsordnung: {
        bmgBis: number
        staffelNr: number
    }[],

    gruppenTitle: { wert: string }[],
    gruppenHerkunft: { wert: string }[],
    gruppenNationalitaet: { gruppe: string, bezeichnung: string }[],
    gruppenAnrede: KeyValue[],
    gruppenAnredePartner: KeyValue[],
    mitarbeiter: MitarbeiterItem[],

    mGNr?: string,
    saveDisabled: boolean,

    defaultTabIndex: number,

    beitrittRueckwirkendJahrFix: number,

    dataset?: MitgliedDataset,
    iban_invalid: boolean,
    wrongPLZ: boolean,

    mgRueckReload: boolean,

    numNotizen: number,
    numAufgaben: number,

    //Tab handling for performance
    activeTabs: {
        [key: string]: boolean
        bp: boolean,
        upload: boolean,
        rueckwirkend: boolean,
        booking: boolean,
    }

    mitglieder: MitgliedItem[]

    leitzahlen: Leitzahlen[]
    filteredLeitzahlen: KeyValue[]
    freieEingabeBic: boolean
}

interface MitgliedParams {
    id?: string
}

class Mitglied extends Component<RouteComponentProps<MitgliedParams>, MitgliedState> {

    // resembles the useContext hook in functional components
    static contextType = BstContext
    context!: React.ContextType<typeof BstContext>

    constructor(props: RouteComponentProps<MitgliedParams>) {
        super(props);

        this.state = {
            loaded: false,
            mtglAvailable: false,
            validated: false,
            dataChanged: false,
            isNew: false,
            isWiedereintritt: false,

            oPs: [],    //Child mit offenen Posten
            oLs: [],    //Child mit offenen Leistungen
            gesamtoffen: 0,
            showBuchung: '',
            showBuchungData: [],
            reloadBJournal: 0,
            zahlungsweiseSollRechnungen1: '',
            zahlungsweiseSollRechnungen2: '',

            beitragsordnung: [],

            gruppenTitle: [],
            gruppenHerkunft: [],
            gruppenNationalitaet: [],
            gruppenAnrede: [
                { id: 'Herr', value: 'Herr' },
                { id: 'Frau', value: 'Frau' }
            ],
            gruppenAnredePartner: [
                { id: "kein Partner vorhanden", value: 'Kein Partner vorhanden' },
                { id: 'Herr', value: 'Herr' },
                { id: 'Frau', value: 'Frau' }
            ],
            mitarbeiter: [],


            mGNr: this.props.match.params.id,
            saveDisabled: false,

            defaultTabIndex: 11,

            beitrittRueckwirkendJahrFix: 0,

            dataset: {
                mitgliedsnummer: this.props.match.params.id,
                matchcode: "",
                bstNr: UserStore.username,
                bstStandort: 0,
                bstMitarbeiter: '0',
                adresseHandle: 0,

                mitglied: {
                    nationalitaet: "DE",
                    anrede: "",
                    titel: "",
                    nachname: "",
                    vorname: "",
                    adresse: {
                        strasse: "",
                        land: "DE",
                        plz: "",
                        ort: "",
                        ortsteil: ""
                    }
                },
                partner: {
                    nationalitaet: "DE",
                    anrede: "kein Partner vorhanden",
                    titel: "",
                    nachname: "",
                    vorname: "",
                    adresse: {
                        strasse: "",
                        land: "DE",
                        plz: "",
                        ort: "",
                        ortsteil: ""
                    }
                },
                telefax: "",
                einwillEMailKommErteilt: 0,
                identifikation: {
                    hatIdentifikation: false,
                    identifikationAm: null
                },
                identifikationPartner: {
                    hatIdentifikation: false,
                    identifikationAm: null
                },
                hatDatenschutzerklaerung: false,
                hatDatenschutzerklaerungPartner: false,
                bankverbindung: {
                    iban: "",
                    inhaber: "",
                    bankName: "",
                    bankBIC: "",
                    hatSepaLastschriftmandat: false,
                    blz: "",
                    ktoNr: "",
                    glaeubigerID: ""
                },
                steuer: {
                    hatVollmachtFA: false,
                    hatFSC: false,
                    fscBeantragtAm: null,
                    steuerID: "",
                    steuernr: "",
                    finanzamt: "",
                    regelbesteuerung: false
                },
                steuerPartner: {
                    hatVollmachtFA: false,
                    hatFSC: false,
                    fscBeantragtAm: null,
                    steuerID: "",
                    steuernr: "",
                    finanzamt: "",
                    regelbesteuerung: false
                },
                hatPostbox: false,
                beitrittAm: new Date().toISOString(),
                beitrittRueckwirkendJahr: 0,
                beitrittRueckwirkendDatum: null,
                hatHVBE: false,
                hatUnterschrMitglied: false,
                hatUnterschrPartner: false,
                kontoAktJahr: {
                    aufnahmeGebuehr: 15,
                    beitragsjahr: new Date().getFullYear(),
                    bemessungsgrundlageSave: false,
                    hatVermietVerpacht: false,
                    beitragOffen: 0
                },
                herkunft: "",
                istBlankoMG: false,
                kuendWirksamDatum: null,
                portalStSoftReferenzNr: "",
                adresseHandleWiedereintritt: 0,
                neuanlageAufnahmegebuer: 0,
                bpAngelegt: 0,
                bpFinal: 0,
                zusammenveranlagung: false,
                zusatzInfos: ""
            },
            iban_invalid: false,
            wrongPLZ: false,

            mgRueckReload: false,

            numNotizen: 0,
            numAufgaben: 0,

            //Tab handling for performance
            activeTabs: {
                bp: false,
                upload: false,
                rueckwirkend: false,
                booking: false,
            },

            mitglieder: [],
            leitzahlen: [],
            filteredLeitzahlen: [],
            freieEingabeBic: false
        }

        this.updateNotizenCount = this.updateNotizenCount.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.keepOnPage = this.keepOnPage.bind(this);
    }

    async getNewMitgliedNr() {
        try {
            let result = await MitgliedService.getMgNew();
            return result.data.mitgliedsnummer;

        } catch (e) {
            console.log("Error in loading homemessage: " + e);
        }
    }

    async loadLeitzahlen() {
        let result = await LeitzahlenService.get(this.state.mGNr);
        this.setState({
            leitzahlen: result.data.leitzahlen
        })
    }

    async componentDidMount() {

        window.addEventListener('beforeunload', this.keepOnPage);

        //get meine bst for prefilling Buchungs preset from userstore
        const context = this.context
        let resultBST = context.settings
        if (resultBST.zahlungsweiseSollRechnungen !== undefined) {
            this.setState({
                zahlungsweiseSollRechnungen1: resultBST?.zahlungsweiseSollRechnungen1 ?? ZahlungsweiseSollRechnungen.Bankeinzug,
                zahlungsweiseSollRechnungen2: resultBST?.zahlungsweiseSollRechnungen2 ?? "",
                zahlungsweiseNewDefault: resultBST?.zahlungsweiseHaben
            })
        }

        //load gruppen
        let resGr = await fetch(UserStore.url + '/mggruppen', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        let resultGr = await resGr.json();

        //load leitzahlen
        await this.loadLeitzahlen()

        //Load Mitarbeiter
        let resultMa = await MitarbeiterService.getMitarbeiter();

        //Load Mitglieder
        let resMg = await fetch(UserStore.url + '/mitglieder', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        let resultMitglieder = await resMg.json();

        if (resultGr && resultGr.success && resultMa && resultMitglieder && resultMitglieder.success) {
            this.setState({
                gruppenHerkunft: resultGr.data.herkunft,
                gruppenTitle: resultGr.data.titel,
                gruppenNationalitaet: resultGr.data.nationalitaet,
                mitarbeiter: resultMa,
                mitglieder: resultMitglieder.data.mitglieder
            });
        }

        if (typeof this.state.mGNr == "undefined") {
            //NEW MG

            let newMgNr = await this.getNewMitgliedNr();
            let emptyData = this.state.dataset;
            if (emptyData) {
                emptyData.mitgliedsnummer = newMgNr;
                emptyData.matchcode = "Neues Mitglied";

                this.setState({
                    dataset: emptyData,
                    loaded: true,
                    mGNr: newMgNr,
                    isNew: true,
                    mtglAvailable: true,
                });

                document.title = 'Neues Mitglied anlegen | ' + UserStore.title;
            }
        } else {
            //existing MG
            try {
                Promise.all([
                    //Load Mitglied
                    this.loadMG(),

                    //Load OPs
                    this.loadOPs(),
                    this.loadOPs('oLs')
                ])
            } catch (e) {
                console.log("Error in loading MG: " + e);
            }
        }
    }

    bmgOnFocus() {
        this.setState({
            saveDisabled: true,
        })
    }

    async calcValueFromBMG(bmg: any, vv: any) {

        let res = await fetch(UserStore.url + '/beitragsordnungBeitrag', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    bmg: bmg,
                    vv: vv,
                }
            })
        });

        let result = await res.json();

        if (result && result.data.betrag) {
            return result.data.betrag as number;
        }
    }

    async loadMG() {
        //load MG
        let res = await fetch(UserStore.url + '/mitglied', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    MGNr: this.state.mGNr
                }
            })
        });

        let result = await res.json();

        if (result && result.success) {
            //cehck Anrede Partner
            let thisdataset = result.data;
            let data = result.data;
            if (data.partner.anrede === "" || data.partner.anrede === "-1") {
                data.partner.anrede = "kein Partner vorhanden";
            }

            if (data.istBlankoMG && data.kontoAktJahr.aufnahmeGebuehr === 0) {
                data.kontoAktJahr.aufnahmeGebuehr = 15;
            }

            //check for not available Options (titel / herkunft / Anrede)
            //Anrede
            if (!this.state.gruppenAnrede.some(item => data.mitglied.anrede === item.id)) {
                this.setState({
                    gruppenAnrede: [...this.state.gruppenAnrede, { id: data.mitglied.anrede, value: data.mitglied.anrede }],
                });
            }
            if (!this.state.gruppenAnredePartner.some(item => data.partner.anrede === item.id)) {
                this.setState({
                    gruppenAnredePartner: [...this.state.gruppenAnredePartner, { id: data.partner.anrede, value: data.partner.anrede }]
                });
            }
            //herkunft
            if (!this.state.gruppenHerkunft.some(item => data.herkunft === item.wert)) {
                this.setState({
                    gruppenHerkunft: [...this.state.gruppenHerkunft, { wert: data.herkunft }]
                });
            }
            //titel
            if (data.mitglied.titel !== "") {
                if (!this.state.gruppenTitle.some(item => data.mitglied.titel === item.wert)) {
                    this.setState({
                        gruppenTitle: [...this.state.gruppenTitle, { wert: data.mitglied.titel }]
                    });
                }
            }
            if (data.partner.titel !== "") {
                if (!this.state.gruppenTitle.some(item => data.partner.titel === item.wert)) {
                    this.setState({
                        gruppenTitle: [...this.state.gruppenTitle, { wert: data.partner.titel }]
                    });
                }
            }

            if (data.istBlankoMG) {
                data.kontoAktJahr.beitragsjahr = (new Date()).getFullYear();
            }

            data.zusammenveranlagung = data.steuer.steuernr === data.steuerPartner.steuernr

            const freieEingabe = data.bankverbindung.bankBIC
            let filteredLeitzahlen: KeyValue[] = []

            if (data.bankverbindung.iban) {
                filteredLeitzahlen = this.filterLeitzahlen(data.bankverbindung.iban)
            }

            this.setState({
                dataset: { ...data },
                beitrittRueckwirkendJahrFix: data.beitrittRueckwirkendJahr,
                loaded: true,
                mtglAvailable: true,
                freieEingabeBic: freieEingabe,
                filteredLeitzahlen: filteredLeitzahlen
            });

            document.title = thisdataset.matchcode + ' | ' + UserStore.title;
        } else {
            this.setState({
                loaded: true,
                mtglAvailable: false,
            })
        }
    }

    async componentDidUpdate(prevProps: RouteComponentProps<MitgliedParams>) {
        if (this.props.match && prevProps.match && this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({ ...this.state, mGNr: this.props.match.params.id })

            window.location.reload();
        }
    }

    async loadOPs(mode = 'oPs') {
        let endpoint = 'mgop';
        if (mode === 'oLs') {
            endpoint = 'mgoffeneLeistungen';
        }

        try {
            let res = await fetch(UserStore.url + '/' + endpoint, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        MGNr: this.state.mGNr,
                    }
                })
            });

            let result = await res.json();

            if (mode === 'oLs') {
                if (result && result.success) {
                    this.setState({
                        oLs: result.data.leistungen,
                    });
                }
            } else if (mode === 'oPs') {
                if (result && result.success) {
                    this.setState({
                        oPs: result.data.oPs,
                        gesamtoffen: result.data.gesamtbeitragOffen
                    });
                }
            }
        } catch (e) {
            console.log("Error in loading Offene Posten: " + e);
        }

    }

    keepOnPage(e: BeforeUnloadEvent) {
        if (this.state.dataChanged) {
            var message = 'Achtung!\n\nSie haben Daten geändert, welche noch nicht gespeichert sind. Wirklich die Seite verlassen?';
            e.returnValue = message;
            return message;
        }
    }

    hasPartner() {
        return (this.state.dataset?.partner.anrede !== "-1" && this.state.dataset?.partner.anrede !== "kein Partner vorhanden")
    }

    getTitleArray() {
        //empty Value
        let titleArray = [
            { id: '', value: '' }
        ];

        for (const aValue of this.state.gruppenTitle) {
            titleArray.push({ id: aValue.wert, value: aValue.wert });
        }
        return titleArray;
    }

    getZahlungsweiseString() {
        let result = this.state.zahlungsweiseSollRechnungen1;
        if (this.state.zahlungsweiseSollRechnungen1 === "Überweisung") {
            if (typeof (this.state.zahlungsweiseSollRechnungen2) === "undefined") {
                result += ",sofort";
            } else {
                result += "," + this.state.zahlungsweiseSollRechnungen2;
            }
        }
        return result;
    }

    getNationalitaetArray() {
        let narray = [
            { id: 'DE', value: 'DE - Deutschland' },
            { id: 'AT', value: 'AT - Österreich' },
            { id: 'CH', value: 'CH - Schweiz' },
            { id: '-1', value: '---------' },
        ];

        for (const aValue of this.state.gruppenNationalitaet) {
            if (aValue.gruppe !== "DE" && aValue.gruppe !== "AT" && aValue.gruppe !== "CH") {
                narray.push({ id: aValue.gruppe, value: aValue.gruppe + " - " + aValue.bezeichnung });
            }
        }

        return narray;
    }

    getMitarbeiterArray() {
        let narray = [];

        narray.push({ id: Number(0), value: '' });
        for (const aValue of this.state.mitarbeiter) {
            narray.push({ id: aValue.bstmaId, value: aValue.nachname + ', ' + aValue.vorname });
        }

        return narray;
    }

    getHerkunftArray() {
        //empty Value
        let herkunftArray = [
            { 'id': '', 'value': '' }
        ];

        for (const aValue of this.state.gruppenHerkunft) {
            herkunftArray.push({ 'id': aValue.wert, 'value': aValue.wert });
        }
        return herkunftArray;
    }

    updateNotizenCount = (num: number) => {
        this.setState({
            numNotizen: num
        });
    }

    updateAufgabenCount = (num: number) => {
        this.setState({
            numAufgaben: num
        })
    }

    async saveASollstellung(jahr: number, bmg: any, vv: any) {

        //Send data
        let res = await fetch(UserStore.url + '/mgRwSollbuchungenSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.bstNr),
                    dataset: {
                        BSTNr: UserStore.username,
                        MGNr: this.state.mGNr,
                        beitragsjahr: jahr,
                        bmg: bmg,
                        vv: vv
                    }
                }
            })
        });

        let response = await res.json();

        if (response.success) {
            return true;
        }
        return false;
    }

    checkValidDatatypes = (dataset: MitgliedDataset) => {
        const fieldsToCheck = ["steuer.fscBeantragtAm", "steuerPartner.fscBeantragtAm", "identifikation.identifikationAm", "identifikationPartner.identifikationAm", "partner.geburtstag"]

        for (const field of fieldsToCheck) {
            const props = field.split(".")

            if (dataset[props[0]][props[1]] === "")
                dataset[props[0]][props[1]] = null
        }
    }

    isEmailTheSame() {
        const currentDataset = this.state.dataset
        if (currentDataset) {
            return currentDataset.partner &&
                (currentDataset.partner.anrede !== "-1" && currentDataset.partner.anrede !== "kein Partner vorhanden") &&
                ((currentDataset.mitglied.eMail && currentDataset.partner.eMail) && currentDataset.mitglied.eMail === currentDataset.partner.eMail)
        }
    }

    async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ saveDisabled: true })

        let forceReload = false;

        let currentDataset = this.state.dataset;

        if (currentDataset) {

            //check partner email = mg email
            if (this.isEmailTheSame()) {
                toast.warning("E-Mail des Partners = E-Mail des Mitglieds. Bitte unterschiedliche E-Mails eintragen.", {
                    autoClose: 10000
                })
                return false;
            }

            //check iban
            let iban_valid = (currentDataset.bankverbindung.iban === "") ? true : IBAN.isValid(currentDataset.bankverbindung.iban);
            if (!iban_valid) {
                toast.warning("Die eingetragene IBAN ist nicht korrekt. Bitte korrigieren Sie diese.", {
                    autoClose: 10000
                });
                return false;
            }

            if (currentDataset.kontoAktJahr.bemessungsgrundlage === 0) {
                toast.warning("Eine BMG von 0 ist nicht zulässig.", {
                    autoClose: 10000
                });
                return false;
            }

            const form = event.currentTarget;

            if (!form.reportValidity()) {
                toast.warning("Leider sind nicht alle Pflichtdaten korrekt eingetragen. Bitte kontrollieren Sie diese noch einmal.", {
                    autoClose: 10000
                });

                this.setState({
                    validated: true
                });
                return false;
            }

            //check for new BMG (ALH20-95)
            if (currentDataset.kontoAktJahr.bemessungsgrundlageSave && !this.state.isNew && !this.state.isWiedereintritt) {
                await this.saveASollstellung(currentDataset.kontoAktJahr.beitragsjahr, currentDataset.kontoAktJahr.bemessungsgrundlage, currentDataset.kontoAktJahr.hatVermietVerpacht);
            }

            //check has Partner
            if (!currentDataset.partner.anrede || currentDataset.partner.anrede === "-1" || currentDataset.partner.anrede === "kein Partner vorhanden") {
                currentDataset.partner.anrede = "";
            }

            //check Mitarbeiter
            if (!currentDataset.bstMitarbeiter) {
                currentDataset.bstMitarbeiter = '';
            }

            //chack blanko => not blanko
            if (currentDataset.istBlankoMG) {
                currentDataset.istBlankoMG = false;
                forceReload = true;
            }

            this.checkValidDatatypes(currentDataset)

            currentDataset.bstNr = UserStore.username;

            //Send data
            let res = await fetch(UserStore.url + '/mitgliedSet', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        MGNr: this.state.mGNr,
                        dataset: currentDataset
                    }
                })
            });

            let response = await res.json();

            if (response.success) {

                toast.success("Mitgliedsdaten erfolgreich gespeichert.", {
                    autoClose: 15000
                });

                //check Partner
                if (response.data.partner.anrede === "" || response.data.partner.anrede === "-1") {
                    response.data.partner.anrede = "kein Partner vorhanden";
                }

                this.setState({
                    validated: true,
                    dataChanged: false,
                    dataset: response.data,
                    mgRueckReload: true,
                });

                //reload if this is a new Mtgl
                if (this.state.isNew || this.state.isWiedereintritt || forceReload) {
                    this.props.history?.push("/mitglied/" + this.state.mGNr);
                }

                //Reload Buchungen
                this.reloadOpsBJournal();

            } else {

                this.setState({
                    validated: true
                });

                toast.error("Es gab ein Problem beim Speichern. Folgendes wurde mitgeteilt: " + (response.error || response.data.message), {
                    autoClose: false
                });

            }
        }

        this.setState({ saveDisabled: false })
    }

    filterLeitzahlen(ibanPara: string) {
        if (!ibanPara) {
            return []
        }

        const iban = ibanPara.replaceAll(" ", "")

        const blz = iban.substring(4, iban.length - 10) ?? ""

        //filter leitzahlen for dropdown 
        const temp = this.state.leitzahlen.filter(l => l.tag.includes(blz)).map(l => ({
            id: l.leitzahl,
            value: `${l.leitzahl} - ${l.bezeichnung} - ${l.tag}`
        }))

        return temp
    }

    async setInputValue(field: string, val: any, dataset = false, changedata = true, datatype = 'string') {
        let datachangedreally = true;
        let fieldArray = field.split(".");

        //check for special BMG made lower ALH20-95
        if (field === "kontoAktJahr.bemessungsgrundlage" && this.state.dataset && this.state.dataset.kontoAktJahr.bemessungsgrundlage && this.state.dataset.kontoAktJahr.bemessungsgrundlage > val) {

            //get before Stufe
            let beforeStufe = 0;
            //get new Stufe
            let newStufe = 0;

            //check staffel
            let thisBeitragsordnung = this.state.beitragsordnung;
            if (thisBeitragsordnung.length === 0) {

                let thisBeitragsordnungData = await CommonService.getBeitragsordnung(this.state.mGNr ?? "")
                thisBeitragsordnung = thisBeitragsordnungData.data.items;
                this.setState({
                    beitragsordnung: thisBeitragsordnung,
                });

            }

            //loop through BO and get Stufen
            thisBeitragsordnung.slice().reverse().forEach((item => {

                if (val <= item.bmgBis) {
                    newStufe = item.staffelNr;
                }
                if (this.state.dataset!.kontoAktJahr.bemessungsgrundlage && this.state.dataset!.kontoAktJahr.bemessungsgrundlage <= item.bmgBis) {
                    beforeStufe = item.staffelNr;
                }
            }));

            if ((beforeStufe - newStufe) > 1) {
                if (!window.confirm("Achtung: Reduzierung um mehr als eine Beitragsstufe! Die Beitragsordnung sieht keinen Verhandlungsspielraum vor. Soll der Beitrag tatsächlich reduziert werden?")) {
                    if (document.getElementById('kontoAktJahr.bemessungsgrundlage') !== null) {
                        document.getElementById('kontoAktJahr.bemessungsgrundlage')!.nodeValue = this.state.dataset.kontoAktJahr.bemessungsgrundlage.toString();
                        return false;
                    }
                }
            }
        }

        //convert
        switch (datatype) {
            case 'decimal':
                if (isNaN(val)) {
                    val = 0;
                }
                val = parseFloat(val);
                break;
            case 'int':
                val = parseInt(val);
                break;
            default:
        }

        if (fieldArray.length === 2) {
            let object = null;
            if (dataset && this.state.dataset) {
                let thisdataset = this.state.dataset;
                object = thisdataset[fieldArray[0]];
                (object[fieldArray[1]] !== val) ? datachangedreally = true : datachangedreally = false;
                object[fieldArray[1]] = val;
                this.setState({
                    dataset: thisdataset
                });
            } else {
                object = this.state[fieldArray[0]];
                (object[fieldArray[1]] !== val) ? datachangedreally = true : datachangedreally = false;
                object[fieldArray[1]] = val;
                this.setState({
                    [fieldArray[0]]: object
                });
            }
        } else if (fieldArray.length === 3) {
            let object = null;
            if (dataset && this.state.dataset) {
                let thisdataset = this.state.dataset;
                object = thisdataset[fieldArray[0]][fieldArray[1]];
                (object[fieldArray[2]] !== val) ? datachangedreally = true : datachangedreally = false;
                object[fieldArray[2]] = val;
                this.setState({
                    dataset: thisdataset
                });
            } else if (this.state.dataset) {
                object = this.state[fieldArray[0]];
                (object[fieldArray[1]][fieldArray[2]] !== val) ? datachangedreally = true : datachangedreally = false;
                object[fieldArray[1]][fieldArray[2]] = val;
                this.setState({
                    [fieldArray[0]]: object
                });
            }
        } else if (fieldArray.length === 1) {
            if (dataset && this.state.dataset) {
                let thisdataset = this.state.dataset;
                (thisdataset[fieldArray[0]] !== val) ? datachangedreally = true : datachangedreally = false;
                thisdataset[fieldArray[0]] = val;
                this.setState({
                    dataset: thisdataset
                });
            } else if (this.state.dataset) {
                (this.state[field] !== val) ? datachangedreally = true : datachangedreally = false;
                this.setState({
                    [field]: val
                });
            }
        }

        //check for Max values
        if (field === 'print.VEVon' || field === 'print.VEBis') {
            //max only last year
            let maxThisValue = new Date().getFullYear();
            if (val >= maxThisValue) {
                val = (maxThisValue - 1);
                toast.error("Der Wert darf höchsten '" + val + "' sein. Bitte korrigieren.", {
                    autoClose: false
                });
            }
        }

        if (changedata && datachangedreally) {
            this.setState({
                dataChanged: true,
            });
        }

        //check for special after change effects
        if (this.state.dataset && (field === "kontoAktJahr.bemessungsgrundlage" || field === "kontoAktJahr.hatVermietVerpacht") && (this.state.dataset.kontoAktJahr.bemessungsgrundlage ?? 0) > 0) {
            //get new value
            let newValue = await this.calcValueFromBMG(this.state.dataset.kontoAktJahr.bemessungsgrundlage, this.state.dataset.kontoAktJahr.hatVermietVerpacht);
            let thisdataset = this.state.dataset;
            thisdataset.kontoAktJahr.beitrag = newValue;
            thisdataset.kontoAktJahr.bemessungsgrundlageSave = true;
            this.setState({
                dataset: thisdataset
            })
        } else if (this.state.dataset && (this.state.dataset.kontoAktJahr.bemessungsgrundlage ?? 0) === 0) {
            this.setState({
                dataset: { ...this.state.dataset!, kontoAktJahr: { ...this.state.dataset!.kontoAktJahr, beitrag: undefined } },
                saveDisabled: true
            })
        }

        //check german plz with warning
        if (this.state.dataset && field === "mitglied.adresse.plz") {

            if (this.state.dataset.mitglied.adresse?.land === "DE" &&
                (val.length !== 5 || isNaN(val))) {

                this.setState({
                    wrongPLZ: true,
                })

                toast.warning("Achtung: Die PLZ entspricht nicht dem landesüblichen Format. Bitte kontrollieren.", {
                    autoClose: 10000
                });
            } else {

                this.setState({
                    wrongPLZ: false,
                })

            }
        }

        //set Date if not already set
        if (this.state.dataset && field === "identifikation.hatIdentifikation") {
            if (val && !this.state.dataset.identifikation.identifikationAm) {
                let thisdataset = this.state.dataset;
                thisdataset.identifikation.identifikationAm = moment().format("YYYY-MM-DD");
                this.setState({
                    dataset: thisdataset,
                })
            }
        }
        if (this.state.dataset && field === "identifikationPartner.hatIdentifikation") {
            if (val && !this.state.dataset.identifikationPartner.identifikationAm) {
                let thisdataset = this.state.dataset;
                thisdataset.identifikationPartner.identifikationAm = moment().format("YYYY-MM-DD");
                this.setState({
                    dataset: thisdataset,
                })
            }
        }
        if (this.state.dataset && field === "steuer.hatFSC") {
            if (val && !this.state.dataset.steuer.fscBeantragtAm) {
                let thisdataset = this.state.dataset;
                thisdataset.steuer.fscBeantragtAm = moment().format("YYYY-MM-DD");
                this.setState({
                    dataset: thisdataset,
                })
            }
        }
        if (this.state.dataset && field === "steuerPartner.hatFSC") {
            if (val && !this.state.dataset.steuerPartner.fscBeantragtAm) {
                let thisdataset = this.state.dataset;
                thisdataset.steuerPartner.fscBeantragtAm = moment().format("YYYY-MM-DD");
                this.setState({
                    dataset: thisdataset,
                })
            }
        }

        //track change
        if (field === 'zahlungsweiseSollRechnungen1') {
            trackEvent('Mitglied', 'Change', 'ZahlungsweiseAbrechnung2');
        }

        //check
        if (field === "beitrittRueckwirkendJahr") {
            let newDateDatum = new Date();
            newDateDatum.setMonth(0);
            newDateDatum.setFullYear(val);
            newDateDatum.setDate(1);
            this.setInputValue("beitrittRueckwirkendDatum", newDateDatum.toISOString(), true);
        }

        //clear all fields in case Partner deleted
        if (this.state.dataset && field === "partner.anrede" && (val === "-1" || val === "kein Partner vorhanden")) {

            let thisdataset = this.state.dataset;
            thisdataset.partner.nachname = '';
            thisdataset.partner.titel = '';
            thisdataset.partner.vorname = '';
            thisdataset.partner.geburtstag = null;
            thisdataset.partner.nationalitaet = '';
            thisdataset.partner.telefon = '';
            thisdataset.partner.handy = '';
            thisdataset.partner.telefonGeschaeftlich = '';
            thisdataset.partner.eMail = '';
            thisdataset.identifikationPartner.hatIdentifikation = false;
            thisdataset.identifikationPartner.identifikationAm = null;
            thisdataset.steuerPartner.hatFSC = false;
            thisdataset.steuerPartner.fscBeantragtAm = null;
            thisdataset.steuerPartner.hatVollmachtFA = false;
            thisdataset.steuerPartner.steuerID = '';
            thisdataset.steuerPartner.steuernr = '';
            thisdataset.hatDatenschutzerklaerungPartner = false;
            thisdataset.hatUnterschrPartner = false

            this.setState({
                dataset: thisdataset,
            })
        }

        //wenn zusammenveranlagung angehakt wird oder steuernr von hauptmitglied geaendert wird
        if (this.state.dataset && ((this.state.dataset.zusammenveranlagung && field === "steuer.steuernr") || (field === "zusammenveranlagung" && val))) {
            let thisdataset = this.state.dataset;
            thisdataset.steuerPartner.steuernr = thisdataset.steuer.steuernr;
            this.setState({
                dataset: thisdataset,
            })
        }

        //check
        if (field === "bankverbindung.iban") {
            const ibanValid = IBAN.isValid(val)
            let filteredLeitzahlen: KeyValue[] = []

            if (ibanValid) {
                filteredLeitzahlen = this.filterLeitzahlen(val)
            }

            this.setState({
                iban_invalid: !ibanValid,
                filteredLeitzahlen: filteredLeitzahlen
            });
        }

        this.setState({ saveDisabled: false })
    }

    setChangedData() {
        this.setState({
            dataChanged: true,
        });
    }

    showBuchung(val: string, data: any) {

        trackModal('/mitglied/NotizAbrechnung');

        this.setState({
            showBuchung: val,
            showBuchungData: data,
        });
    }

    reloadOps(clear = false) {

        if (clear) {
            this.setState({
                showBuchung: "",
                showBuchungData: [],
            });
        }

        this.loadOPs();
        this.loadOPs('oLs');
    }

    reloadOpsBJournal() {
        this.reloadOps();
        let temp = this.state.reloadBJournal + 1;
        this.setState({
            reloadBJournal: temp,
        })
    }

    handleSelectTab(key: string) {

        trackEvent('Mitglied', 'Tab', key);

        //check if Data changed
        if (key !== "mgdata" && this.state.dataChanged) {
            toast.error("Achtung: Sie haben die Grunddaten geändert aber noch nicht gespeichert! Sollten Sie nun z.B: Daten drucken wollen, dann werden hier noch die alten Daten verwendet. Bitte erst speichern.", {
                autoClose: 15000
            });
        }

        //Trigger Load in Tab when Tab active (performance)
        let thisActiveTabs = this.state.activeTabs;
        if (key in thisActiveTabs) {
            thisActiveTabs[key] = true;
            this.setState({
                activeTabs: thisActiveTabs,
            })
        }
    }

    getMgRueckwirkend(currentYear: number) {
        let values = [];
        let currentYearAvailable = false;

        if (currentYear === 0) {
            values.push({ id: '', value: '' });
        }

        let thisYear = (new Date(this.state.dataset?.beitrittAm ?? new Date())).getFullYear();

        for (var i = 1; i <= 11; i++) {
            if (currentYear === 0 || (thisYear - i) <= currentYear) {
                values.push({ id: thisYear - i, value: ((thisYear - i) + " ab VZ " + ((thisYear - i) - 1)) });
                if (currentYear === (thisYear - i)) {
                    currentYearAvailable = true;
                }
            }
        }

        //check if currentYear available
        if (!currentYearAvailable && currentYear !== 0) {
            values.push({ 'id': currentYear, 'value': ((currentYear) + " ab VZ " + (currentYear - 1)) });
        }

        return values;
    }

    async wiedereintritt() {

        if (window.confirm("Wollen Sie dieses Mitglied jetzt als neues Mitglied anlegen lassen?")) {
            //Send data
            let res = await fetch(UserStore.url + '/mgwiedereintritt', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        BSTNr: UserStore.username,
                        Mitgliedsnummer: this.state.mGNr,
                    }
                })
            });

            let response = await res.json();

            if (response.success) {
                //check Partner
                if (response.data.partner.anrede === "" || response.data.partner.anrede === "-1") {
                    response.data.partner.anrede = "kein Partner vorhanden";
                }

                let currentDataset = response.data;
                currentDataset.beitrittAm = new Date().toISOString();
                currentDataset.kontoAktJahr.beitragsjahr = new Date().getFullYear();
                currentDataset.kontoAktJahr.beitrag = await this.calcValueFromBMG(currentDataset.kontoAktJahr.bemessungsgrundlage, currentDataset.kontoAktJahr.hatVermietVerpacht)

                this.setState({
                    validated: true,
                    isWiedereintritt: true,
                    dataChanged: true,
                    mGNr: response.data.mitgliedsnummer,
                    dataset: currentDataset,
                    mgRueckReload: true,
                    beitrittRueckwirkendJahrFix: response.data.beitrittRueckwirkendJahr,
                });
            }
            return false;
        }
    }

    getLinkVdb() {
        if (UserStore.mandantId === 1) {
            return (
                <a href="https://intern.aktuell-verein.de/steuersoftware/datenabruf-vdb/" target="_blank" rel="noopener noreferrer">https://intern.aktuell-verein.de/steuersoftware/datenabruf-vdb/</a>
            )
        } else {
            return (
                <a href="https://intern.altbayerischer.de/steuersoftware/datenabruf-vdb/" target="_blank" rel="noopener noreferrer">https://intern.altbayerischer.de/steuersoftware/datenabruf-vdb/</a>
            )
        }
    }

    getNextMitglied(next: boolean, mitglieder: MitgliedItem[]) {
        let currentIndex = mitglieder.findIndex(m => m.mgNr === this.state.dataset!.mitgliedsnummer);
        let maxIndex = mitglieder.length - 1

        if (next) {
            currentIndex++
            if (currentIndex > maxIndex)
                currentIndex = 0
        } else {
            currentIndex--
            if (currentIndex < 0)
                currentIndex = maxIndex
        }

        const foundMitglied = mitglieder[currentIndex]

        if (foundMitglied) {
            return foundMitglied.mgNr
        } else {
            return this.state.dataset?.mitgliedsnummer
        }
    }

    render() {

        let nowMinus6Weeks = moment().subtract(6, 'weeks')

        let mitgliederList = this.state.mitglieder.filter(mitglied => mitglied.status === 0 || mitglied.status === 1)

        mitgliederList = sortList("mgNr", mitgliederList, "asc")

        if (this.state.loaded && this.state.mtglAvailable) {
            return (
                <div>
                    <div className='alh_alignArrowButtons' >
                        {this.state.mitglieder.length > 0 &&
                            <Link
                                to={`/mitglied/${this.getNextMitglied(false, mitgliederList)}`}
                            >
                                <Button>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Button>
                            </Link>}
                        {this.state.mitglieder.length > 0 &&
                            <Link
                                to={`/mitglied/${this.getNextMitglied(true, mitgliederList)}`}
                            >
                                <Button >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </Button>
                            </Link>}
                        <h3 className="dps_onlyMarginLeft">Mitglied: {this.state.dataset?.matchcode}</h3>
                    </div>
                    {(this.state.isNew) ?
                        <Alert key="newAlert" variant="warning">Sie legen gerade ein neues Mitglied an. Folgende Mitgliedsnummer würde beim Speichern
                            vergeben werden. Bitte füllen Sie alle relevanten Felder aus und klicken Sie auf "Daten überprüfen und Speichern".</Alert>
                        : null}
                    <div className="alh_subheader">MG-Nr.: {this.state.mGNr}</div>

                    {(this.state.dataset?.kuendWirksamDatum !== null) &&
                        <Alert key="newAlertOutdated" variant="danger">
                            <div><b>Mitglied ist bereits gekündigt:</b> <Moment format="DD.MM.YYYY">{this.state.dataset?.kuendWirksamDatum}</Moment></div><br />
                            <Button variant="primary" onClick={() => this.wiedereintritt()}>
                                Wiedereintritt als neues Mitglied
                            </Button>
                        </Alert>
                    }

                    {(this.state.isWiedereintritt) &&
                        <Alert
                            key="newAlertWiedereintritt" variant="warning">
                            Dies ist ein neues <b>Mitglied als Wiedereintritt</b>. Bitte kontrollieren Sie die Daten und speichern Sie mit "Daten überprüfen und speichern", bevor Sie weitere Aktionen ausführen!<br />
                            Die neue Mitgliedsnummer lautet dann: {this.state.mGNr}
                        </Alert>
                    }

                    {this.isEmailTheSame() && <Alert key="sameEmailAlert" variant="danger">
                        <b>Mitglied und Partner dürfen nicht die gleiche E-Mail Angabe haben.</b> Bitte die E-Mail des Partners abändern.
                    </Alert>}

                    {(this.state.dataset?.kuendWirksamDatum === null) &&
                        <>
                            <Alert
                                className={((this.state.dataset?.identifikation.hatIdentifikation || this.state.isNew) ? "d-none" : "")}
                                key="newAlertIdenti" variant="danger"><span className="alh_bold">Identifikation des Mitglieds fehlt</span>,
                                bitte unten nachtragen!</Alert>
                            {/* Der Beitritt muss länger als 6 Wochen her sein, dann wird der Alert angezeigt */}
                            <Alert
                                className={((this.state.dataset?.hatHVBE || this.state.isNew || !moment(this.state.dataset?.beitrittAm).isBefore(nowMinus6Weeks)) ? "d-none" : "")}
                                key="newAlertHVBE" variant="danger"><span className="alh_bold">BE fehlt.</span> Bitte BE unter “Dokumente drucken” zur Unterschrift ausdrucken und uploaden.</Alert>
                            <Alert
                                className={((this.state.dataset?.eMail === "" && !this.state.isNew) ? "" : "d-none")}
                                key="newAlertEmail"
                                variant="warning">Sicher keine <span className="alh_bold">E-Mail</span>? Erleichtert Ihre Kommunikation (Porto / Umzug / Neuer Handyanbieter usw.)</Alert>
                        </>
                    }

                    <Tabs defaultActiveKey="mgdata" id="alh_mg-tab" onSelect={(e) => this.handleSelectTab(e ?? '')}>

                        <Tab eventKey="mgdata" title="Grunddaten">
                            <Container>
                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                    <Row className="alh_rowTopList">
                                        <Col lg={8}>
                                            <div className="alh_frontBoxes">
                                                <Container>
                                                    <Row className="alh_rowTopList alh_clearMarginBottom">
                                                        <Col lg={12}>
                                                            <InputField
                                                                as='textarea'
                                                                type='textclean'
                                                                placeholder='Zusätzliche Informationen'
                                                                initialSize={3}
                                                                value={this.state.dataset?.zusatzInfos ?? ''}
                                                                onChange={(val) => this.setInputValue('zusatzInfos', val, true)}
                                                            />
                                                            <Table striped bordered hover size="sm" className="alhTable alhTable_clearMarginBottom">
                                                                <thead>
                                                                    <tr className="highlight">
                                                                        <th className="alh_mgEditLables">&nbsp;</th>
                                                                        <th className="text-center">Mitglied</th>
                                                                        <th className="text-center">Partner</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="text-left">Anrede*</th>
                                                                        <td className="text-left">
                                                                            <SelectField
                                                                                id='mitglied.anrede'
                                                                                name='mitglied.anrede'
                                                                                withPleaseSelect={true}
                                                                                formGroupClassName='small-form-group'
                                                                                tabIndex={1}
                                                                                size='sm'
                                                                                options={this.state.gruppenAnrede}
                                                                                value={this.state.dataset?.mitglied.anrede}
                                                                                onChange={(val) => this.setInputValue('mitglied.anrede', val, true)}
                                                                            />
                                                                        </td>
                                                                        <td className="text-left">
                                                                            <SelectField
                                                                                id='partner.anrede'
                                                                                name='partner.anrede'
                                                                                withPleaseSelect={false}
                                                                                formGroupClassName='small-form-group'
                                                                                tabIndex={6}
                                                                                size='sm'
                                                                                options={this.state.gruppenAnredePartner}
                                                                                value={this.state.dataset?.partner.anrede}
                                                                                onChange={(val) => this.setInputValue('partner.anrede', val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Titel</th>
                                                                        <td className="text-left">
                                                                            <SelectField
                                                                                id='mitglied.titel'
                                                                                name='mitglied.titel'
                                                                                withPleaseSelect={false}
                                                                                formGroupClassName='small-form-group'
                                                                                tabIndex={2}
                                                                                size='sm'
                                                                                options={this.getTitleArray()}
                                                                                value={this.state.dataset?.mitglied.titel}
                                                                                onChange={(val) => this.setInputValue('mitglied.titel', val, true)}
                                                                            />
                                                                        </td>
                                                                        <td className="text-left">
                                                                            <SelectField
                                                                                id='partner.titel'
                                                                                name='partner.titel'
                                                                                withPleaseSelect={false}
                                                                                formGroupClassName='small-form-group'
                                                                                tabIndex={7}
                                                                                size='sm'
                                                                                disabled={!this.hasPartner()}
                                                                                options={
                                                                                    [
                                                                                        { id: '', value: '' },
                                                                                        { id: 'Dipl.-Ing.', value: 'Dipl.-Ing.' },
                                                                                        { id: 'Dipl.-Ing. (FH)', value: 'Dipl.-Ing. (FH)' },
                                                                                        { id: 'Dr.', value: 'Dr.' },
                                                                                        { id: 'Prof.', value: 'Prof.' },
                                                                                        { id: 'Prof. Dr.', value: 'Prof. Dr.' },
                                                                                    ]
                                                                                }
                                                                                value={this.state.dataset?.partner.titel}
                                                                                onChange={(val) => this.setInputValue('partner.titel', val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Nachname*</th>
                                                                        <td className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                placeholder='Nachname'
                                                                                tabindex={3}
                                                                                required={true}
                                                                                disabled={this.state.dataset?.hatHVBE}
                                                                                value={this.state.dataset?.mitglied.nachname ?? ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.nachname', val, true)}
                                                                                onClick={() => {
                                                                                    if (this.state.dataset?.hatHVBE) {
                                                                                        // hv aufgabe erstellen popup
                                                                                        this.showBuchung("f", { grunddatenTodoHV: true })
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                placeholder='Nachname'
                                                                                tabindex={8}
                                                                                required={this.hasPartner()}
                                                                                disabled={!this.hasPartner()}
                                                                                value={this.state.dataset?.partner.nachname ?? ''}
                                                                                onChange={(val) => this.setInputValue('partner.nachname', val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Vorname*</th>
                                                                        <td className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                placeholder='Vorname'
                                                                                tabindex={4}
                                                                                required={true}
                                                                                disabled={this.state.dataset?.hatHVBE}
                                                                                value={this.state.dataset?.mitglied.vorname ?? ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.vorname', val, true)}
                                                                                onClick={() => {
                                                                                    if (this.state.dataset?.hatHVBE) {
                                                                                        // hv aufgabe erstellen popup
                                                                                        this.showBuchung("f", { grunddatenTodoHV: true })
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                placeholder='Vorname'
                                                                                tabindex={9}
                                                                                required={this.hasPartner()}
                                                                                disabled={!this.hasPartner()}
                                                                                value={this.state.dataset?.partner.vorname ?? ''}
                                                                                onChange={(val) => this.setInputValue('partner.vorname', val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Geburtstag*</th>
                                                                        <td className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='date'
                                                                                inputGroupClass=''
                                                                                tabindex={5}
                                                                                placeholder=''
                                                                                required={true}
                                                                                value={this.state.dataset?.mitglied.geburtstag ? (this.state.dataset.mitglied.geburtstag).substr(0, 10) : ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.geburtstag', val, true)}
                                                                            />
                                                                        </td>
                                                                        <td className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='date'
                                                                                inputGroupClass=''
                                                                                tabindex={10}
                                                                                placeholder=''
                                                                                disabled={!this.hasPartner()}
                                                                                value={this.state.dataset?.partner.geburtstag ? (this.state.dataset?.partner.geburtstag).substr(0, 10) : ''}
                                                                                onChange={(val) => this.setInputValue('partner.geburtstag', val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>




                                                                    <tr className="highlight">
                                                                        <th>Adresse</th>
                                                                        <th colSpan={2}></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Straße*</th>
                                                                        <td colSpan={2} className="text-right">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                required={true}
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Strasse'
                                                                                value={this.state.dataset?.mitglied.adresse?.strasse ?? ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.adresse.strasse', val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">PLZ* / Ort*</th>
                                                                        <td colSpan={2} className="text-right">
                                                                            <InputField
                                                                                containerClassName='onethirdSize_float'
                                                                                size='sm'
                                                                                type='clean'
                                                                                required={true}
                                                                                id='zip'
                                                                                inputGroupClass=''
                                                                                inputClassNames={this.state.wrongPLZ ? 'alertPLZ' : ''}
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='PLZ'
                                                                                value={this.state.dataset?.mitglied.adresse?.plz ?? ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.adresse.plz', val, true,)}
                                                                            />
                                                                            <InputField
                                                                                containerClassName='twothirdSize_float'
                                                                                size='sm'
                                                                                type='clean'
                                                                                required={true}
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Ort'
                                                                                value={this.state.dataset?.mitglied.adresse?.ort ?? ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.adresse.ort', val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Land</th>
                                                                        <td colSpan={2} className="text-right">
                                                                            <SelectField
                                                                                id='land'
                                                                                name='land'
                                                                                withPleaseSelect={true}
                                                                                formGroupClassName='small-form-group'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                size='sm'
                                                                                options={this.getNationalitaetArray()}
                                                                                value={this.state.dataset?.mitglied.adresse?.land}
                                                                                onChange={(val) => this.setInputValue('mitglied.adresse.land', val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>


                                                                    <tr className="highlight">
                                                                        <th></th>
                                                                        <th className="text-center">Mitglied</th>
                                                                        <th className="text-center">Partner</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Nationalität*</th>
                                                                        <th className="text-left">
                                                                            <SelectField
                                                                                id='mitglied.nationalitaet'
                                                                                name='mitglied.nationalitaet'
                                                                                withPleaseSelect={true}
                                                                                formGroupClassName='small-form-group'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                size='sm'
                                                                                required={true}
                                                                                options={this.getNationalitaetArray()}
                                                                                value={this.state.dataset?.mitglied.nationalitaet}
                                                                                onChange={(val) => this.setInputValue('mitglied.nationalitaet', val, true)}
                                                                            />
                                                                        </th>
                                                                        <th className="text-left">
                                                                            <SelectField
                                                                                id='partner.nationalitaet'
                                                                                name='partner.nationalitaet'
                                                                                withPleaseSelect={true}
                                                                                formGroupClassName='small-form-group'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                size='sm'
                                                                                required={true}
                                                                                options={this.getNationalitaetArray()}
                                                                                value={this.state.dataset?.partner.nationalitaet}
                                                                                onChange={(val) => this.setInputValue('partner.nationalitaet', val, true)}
                                                                                disabled={!this.hasPartner()}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Herkunft*</th>
                                                                        <th className="text-right">
                                                                            <SelectField
                                                                                id='herkunft'
                                                                                name='herkunft'
                                                                                withPleaseSelect={true}
                                                                                formGroupClassName='small-form-group'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                size='sm'
                                                                                required={true}
                                                                                options={this.getHerkunftArray()}
                                                                                value={this.state.dataset?.herkunft}
                                                                                onChange={(val) => this.setInputValue('herkunft', val, true)}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Identifikation
                                                                            <InfoIcon space="left" mode="modal" placement="left" title="Geldwäschegesetz (GWG) - Neue Pflichten für Lohnsteuerhilfevereine">
                                                                                <div className="alh_popover">
                                                                                    Das Gesetz mit dem Untertitel „Masterplan gegen Geldwäsche – Finanzkriminalität bekämpfen“
                                                                                    enthält Regelungen für Lohnsteuerhilfevereine.<br /><br />

                                                                                    <span className="alh_bold">Erweiterung des Kreises der Verpflichteten</span> nach § 2 Abs. 1 Nr. 12 GWG Wirtschaftsprüfer, vereidigte Buchprüfer,
                                                                                    Steuerberater, Steuerbevollmächtigte und die in § 4 Nummer 11 des Steuerberatungsgesetzes genannten Vereine.<br /><br />

                                                                                    <span className="alh_bold">Seit dem 01.01.2020 sind folgende Maßnahmen durchzuführen:</span><br /><br />

                                                                                    Identifizierung auch von Bestandsmitgliedern durch eine Kopie / Scan
                                                                                    des gültigen Passes- oder Ausweisersatzes nach § 12 Abs. 1 GWG<br /><br />

                                                                                    Die Ausweiskopie wird somit Bestandteil der Handakte.<br /><br />

                                                                                    Die <span className="alh_bold">Identifikationspflicht</span> per Ausweiskopie <b>gilt auch für Altmitglieder bei einem Beratungsstellenwechsel.</b><br /><br />

                                                                                    Ohne Identifizierung ist eine Beratung nicht möglich.
                                                                                </div>
                                                                            </InfoIcon></th>
                                                                        <td className="text-left">
                                                                            <InputCheckbox
                                                                                name='identifikation.hatIdentifikation'
                                                                                checked={this.state.dataset?.identifikation.hatIdentifikation}
                                                                                onChange={(val) => this.setInputValue('identifikation.hatIdentifikation', val, true)}
                                                                                subtext=' mittels Kopie / Scan amtlicher Ausweis'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                subtextClass='alh_checksubtextalign'
                                                                            />
                                                                            <InputField
                                                                                size='sm'
                                                                                type='date'
                                                                                inputGroupClass=''
                                                                                placeholder=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                maxDate={moment().format("YYYY-MM-DD")}
                                                                                name='identifikation.identifikationAm'
                                                                                value={this.state.dataset?.identifikation.identifikationAm ? (this.state.dataset.identifikation.identifikationAm).toString().substr(0, 10) : ''}
                                                                                onChange={(val) => this.setInputValue('identifikation.identifikationAm', val, true)}
                                                                            />
                                                                        </td>
                                                                        <td className="text-left">
                                                                            <InputCheckbox
                                                                                name='identifikationPartner.hatIdentifikation'
                                                                                checked={this.state.dataset?.identifikationPartner.hatIdentifikation}
                                                                                onChange={(val) => this.setInputValue('identifikationPartner.hatIdentifikation', val, true)}
                                                                                subtext=' mittels Kopie / Scan amtlicher Ausweis'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                disabled={!this.hasPartner()}
                                                                                subtextClass='alh_checksubtextalign'
                                                                            />
                                                                            <InputField
                                                                                size='sm'
                                                                                type='date'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder=''
                                                                                maxDate={moment().format("YYYY-MM-DD")}
                                                                                disabled={!this.hasPartner()}
                                                                                name='identifikationPartner.identifikationAm'
                                                                                value={this.state.dataset?.identifikationPartner.identifikationAm ? (this.state.dataset?.identifikationPartner.identifikationAm).toString().substr(0, 10) : ''}
                                                                                onChange={(val) => this.setInputValue('identifikationPartner.identifikationAm', val, true)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Vollmacht Finanzamt (VDB)</th>
                                                                        <th className="text-left">
                                                                            <InputCheckbox
                                                                                name='hatVollmachtFA'
                                                                                checked={this.state.dataset?.steuer.hatVollmachtFA}
                                                                                onChange={(val) => this.setInputValue('steuer.hatVollmachtFA', val, true)}
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                subtext='liegt vor'
                                                                                subtextClass='alh_checksubtextalign'
                                                                            />
                                                                        </th>
                                                                        <th className="text-left">
                                                                            <InputCheckbox
                                                                                name='steuerPartnerhatVollmachtFA'
                                                                                checked={this.state.dataset?.steuerPartner.hatVollmachtFA}
                                                                                onChange={(val) => this.setInputValue('steuerPartner.hatVollmachtFA', val, true)}
                                                                                subtext='liegt vor'
                                                                                subtextClass='alh_checksubtextalign'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                disabled={!this.hasPartner()}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">
                                                                            VDB/FSC beantragt am
                                                                            <InfoIcon space="left" mode="modal" placement="left" title="Vollmachtsdatenbank (VDB)">
                                                                                <div className="alh_popover">
                                                                                    Weitere Informationen zur Vollmachtsdatenbank (VDB) finden Sie im internen Bereich unter {this.getLinkVdb()}.
                                                                                </div>
                                                                            </InfoIcon>
                                                                        </th>
                                                                        <th className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='date'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder=''
                                                                                value={this.state.dataset?.steuer.fscBeantragtAm ? (this.state.dataset?.steuer.fscBeantragtAm).toString().substr(0, 10) : ''}
                                                                                onChange={(val) => this.setInputValue('steuer.fscBeantragtAm', val, true)}
                                                                            />
                                                                            <InputCheckbox
                                                                                name='steuer.hatFSC'
                                                                                checked={this.state.dataset?.steuer.hatFSC}
                                                                                onChange={(val) => this.setInputValue('steuer.hatFSC', val, true)}
                                                                                subtext='Berechtigung freigeschaltet'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                subtextClass='alh_checksubtextalign'
                                                                            />
                                                                        </th>
                                                                        <th className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='date'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder=''
                                                                                disabled={!this.hasPartner()}
                                                                                value={this.state.dataset?.steuerPartner.fscBeantragtAm ? this.state.dataset?.steuerPartner.fscBeantragtAm.toString().substr(0, 10) : ''}
                                                                                onChange={(val) => this.setInputValue('steuerPartner.fscBeantragtAm', val, true)}
                                                                            />
                                                                            <InputCheckbox
                                                                                name='steuerPartner.hatFSC'
                                                                                checked={this.state.dataset?.steuerPartner.hatFSC}
                                                                                onChange={(val) => this.setInputValue('steuerPartner.hatFSC', val, true)}
                                                                                subtext='Berechtigung freigeschaltet'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                subtextClass='alh_checksubtextalign'
                                                                                disabled={!this.hasPartner()}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr className="d-none">
                                                                        <th className="text-left">Datenschutzerklärung</th>
                                                                        <th className="text-left">
                                                                            <InputCheckbox
                                                                                name='hatDatenschutzerklaerung'
                                                                                checked={this.state.dataset?.hatDatenschutzerklaerung}
                                                                                onChange={(val) => this.setInputValue('hatDatenschutzerklaerung', val, true)}
                                                                                subtext=''
                                                                            />
                                                                        </th>
                                                                        <th className="text-left">
                                                                            <InputCheckbox
                                                                                name='hatDatenschutzerklaerungPartner'
                                                                                checked={this.state.dataset?.hatDatenschutzerklaerungPartner}
                                                                                onChange={(val) => this.setInputValue('hatDatenschutzerklaerungPartner', val, true)}
                                                                                subtext=''
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                disabled={!this.hasPartner()}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">SteuerID</th>
                                                                        <th className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='SteuerID'
                                                                                value={this.state.dataset?.steuer.steuerID ?? ''}
                                                                                onChange={(val) => this.setInputValue('steuer.steuerID', val, true)}
                                                                            />
                                                                        </th>
                                                                        <th className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='SteuerID'
                                                                                disabled={!this.hasPartner()}
                                                                                value={this.state.dataset?.steuerPartner.steuerID ?? ''}
                                                                                onChange={(val) => this.setInputValue('steuerPartner.steuerID', val, true)}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">
                                                                            Steuernr.

                                                                            <InputCheckbox
                                                                                subtext='Zusammenveranlagung'
                                                                                checked={this.state.dataset?.zusammenveranlagung}
                                                                                onChange={(val => this.setInputValue("zusammenveranlagung", val, true))}
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                subtextClass='alh_checksubtextalign'
                                                                            />
                                                                        </th>
                                                                        <th className="text-left" colSpan={this.state.dataset?.zusammenveranlagung ? 2 : 1}>
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Steuernr'
                                                                                value={this.state.dataset?.steuer.steuernr ?? ''}
                                                                                onChange={(val) => this.setInputValue('steuer.steuernr', val, true)}
                                                                            />
                                                                        </th>
                                                                        {!this.state.dataset?.zusammenveranlagung && <th className="text-left">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Steuernr'
                                                                                disabled={!this.hasPartner()}
                                                                                value={this.state.dataset?.steuerPartner.steuernr ?? ''}
                                                                                onChange={(val) => this.setInputValue('steuerPartner.steuernr', val, true)}
                                                                            />
                                                                        </th>}
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Finanzamt</th>
                                                                        <th className="text-left" colSpan={2}>
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Finanzamt'
                                                                                value={this.state.dataset?.steuer.finanzamt ?? ''}
                                                                                onChange={(val) => this.setInputValue('steuer.finanzamt', val, true)}
                                                                            />
                                                                        </th>
                                                                    </tr>

                                                                    <tr className="highlight">
                                                                        <th colSpan={3}>Kommunikation:</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Telefon privat**</th>
                                                                        <th className="text-right" colSpan={this.hasPartner() ? 1 : 2}>
                                                                            <InputField
                                                                                size='sm'
                                                                                required={this.state.dataset?.mitglied.handy === ""}
                                                                                type='clean'
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                inputGroupClass=''
                                                                                placeholder='Telefon privat'
                                                                                value={this.state.dataset?.mitglied.telefon ?? ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.telefon', val, true)}
                                                                            />
                                                                        </th>
                                                                        {this.hasPartner() && <th className="text-right">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                inputGroupClass=''
                                                                                placeholder='Telefon privat'
                                                                                value={this.state.dataset?.partner.telefon ?? ''}
                                                                                onChange={(val) => this.setInputValue('partner.telefon', val, true)}
                                                                            />
                                                                        </th>}
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Handy**</th>
                                                                        <th className="text-right" colSpan={this.hasPartner() ? 1 : 2}>
                                                                            <InputField
                                                                                size='sm'
                                                                                required={this.state.dataset?.mitglied.telefon === ""}
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Handy'
                                                                                value={this.state.dataset?.mitglied.handy ?? ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.handy', val, true)}
                                                                            />
                                                                        </th>
                                                                        {this.hasPartner() && <th className="text-right">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Handy'
                                                                                value={this.state.dataset?.partner.handy ?? ''}
                                                                                onChange={(val) => this.setInputValue('partner.handy', val, true)}
                                                                            />
                                                                        </th>}
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Geschäftlich</th>
                                                                        <th className="text-right" colSpan={this.hasPartner() ? 1 : 2}>
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Geschäftlich'
                                                                                value={this.state.dataset?.mitglied.telefonGeschaeftlich ?? ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.telefonGeschaeftlich', val, true)}
                                                                            />
                                                                        </th>
                                                                        {this.hasPartner() && <th className="text-right">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Geschäftlich'
                                                                                value={this.state.dataset?.partner.telefonGeschaeftlich ?? ''}
                                                                                onChange={(val) => this.setInputValue('partner.telefonGeschaeftlich', val, true)}
                                                                            />
                                                                        </th>}
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Fax</th>
                                                                        <th className="text-right" colSpan={2}>
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Fax'
                                                                                value={this.state.dataset?.telefax ?? ''}
                                                                                onChange={(val) => this.setInputValue('telefax', val, true)}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">E-Mail
                                                                            <InfoIcon space="left" placement="right">Adressdaten und Telefonnummern ändern sich, E-Mailadressen bleiben (meist) bestehen.</InfoIcon>
                                                                        </th>
                                                                        <th className="text-right" colSpan={this.hasPartner() ? 1 : 2}>
                                                                            <InputField
                                                                                size='sm'
                                                                                type='email'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='E-Mail'
                                                                                data={this.state.mGNr + " " + this.state.dataset?.matchcode}
                                                                                value={this.state.dataset?.mitglied.eMail ?? ''}
                                                                                onChange={(val) => this.setInputValue('mitglied.eMail', val, true)}
                                                                            />
                                                                        </th>
                                                                        {this.hasPartner() && <th className="text-right">
                                                                            <InputField
                                                                                size='sm'
                                                                                type='email'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='E-Mail'
                                                                                data={this.state.mGNr + " " + this.state.dataset?.matchcode}
                                                                                value={this.state.dataset?.partner.eMail ?? ''}
                                                                                onChange={(val) => this.setInputValue('partner.eMail', val, true)}
                                                                            />
                                                                        </th>}
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Einwilligung E-Mail Kommunikation</th>
                                                                        <th className="text-right" colSpan={2}>
                                                                            <SelectField
                                                                                id='einwillEMailKommErteilt'
                                                                                name='einwillEMailKommErteilt'
                                                                                withPleaseSelect={false}
                                                                                formGroupClassName='small-form-group'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                size='sm'
                                                                                options={
                                                                                    [
                                                                                        { id: 0, value: '' },
                                                                                        { id: 1, value: 'Ja' },
                                                                                        { id: 2, value: 'Nein' }
                                                                                    ]
                                                                                }
                                                                                value={this.state.dataset?.einwillEMailKommErteilt}
                                                                                onChange={(val) => this.setInputValue('einwillEMailKommErteilt', Number(val), true)}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Postbox</th>
                                                                        <th className="text-left" colSpan={2}>
                                                                            <InputCheckbox
                                                                                name='hatPostbox'
                                                                                checked={this.state.dataset?.hatPostbox}
                                                                                onChange={(val) => this.setInputValue('hatPostbox', val, true)}
                                                                                disabled={true}
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                subtext=''
                                                                            />
                                                                        </th>
                                                                    </tr>



                                                                    <tr className="highlight">
                                                                        <th colSpan={3}>Bankverbindung:</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Inhaber</th>
                                                                        <th className="text-right" colSpan={2}>
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Inhaber'
                                                                                value={this.state.dataset?.bankverbindung.inhaber ?? ''}
                                                                                onChange={(val) => this.setInputValue('bankverbindung.inhaber', val, true)}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">Bank</th>
                                                                        <th className="text-right" colSpan={2}>
                                                                            <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='Bank'
                                                                                value={this.state.dataset?.bankverbindung.bankName ?? ''}
                                                                                onChange={(val) => this.setInputValue('bankverbindung.bankName', val, true)}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">IBAN</th>
                                                                        <th className="text-right" colSpan={2}>
                                                                            <InputField
                                                                                id='alh_iban'
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='IBAN'
                                                                                isInvalid={this.state.iban_invalid}
                                                                                value={this.state.dataset?.bankverbindung.iban ?? ''}
                                                                                onChange={(val) => this.setInputValue('bankverbindung.iban', val, true)}
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">BIC</th>
                                                                        <th className="text-left" colSpan={2}>
                                                                            {this.state.freieEingabeBic ? <InputField
                                                                                size='sm'
                                                                                type='clean'
                                                                                inputGroupClass=''
                                                                                tabindex={this.state.defaultTabIndex}
                                                                                placeholder='BIC'
                                                                                value={this.state.dataset?.bankverbindung.bankBIC ?? ''}
                                                                                onChange={(val) => this.setInputValue('bankverbindung.bankBIC', val, true)}
                                                                            /> : <SelectField
                                                                                id='leitzahlen'
                                                                                name='leitzahlen'
                                                                                withPleaseSelect={true}
                                                                                formGroupClassName='small-form-group'
                                                                                size='sm'
                                                                                disabled={!this.state.filteredLeitzahlen.length}
                                                                                options={this.state.filteredLeitzahlen}
                                                                                value={this.state.dataset?.bankverbindung.bankBIC}
                                                                                onChange={(val) => {
                                                                                    this.setInputValue('bankverbindung.bankBIC', val, true)
                                                                                }}
                                                                            />}
                                                                            <InputCheckbox
                                                                                name='freieEingabe'
                                                                                checked={this.state.freieEingabeBic}
                                                                                onChange={(val) => this.setInputValue('freieEingabeBic', val, false)}
                                                                                subtext='Freie Eingabe'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                subtextClass='alh_checksubtextalign'
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="text-left">SEPA Lastschriftmandat</th>
                                                                        <th className="text-left" colSpan={2}>
                                                                            <InputCheckbox
                                                                                name='hatPostbox'
                                                                                tabIndex={this.state.defaultTabIndex}
                                                                                checked={this.state.dataset?.bankverbindung.hatSepaLastschriftmandat}
                                                                                onChange={(val) => this.setInputValue('bankverbindung.hatSepaLastschriftmandat', val, true)}
                                                                                subtext=''
                                                                            />
                                                                        </th>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>


                                                            <br />
                                                            {UserStore.hasBerechtigung('bst_mg', 'write') &&
                                                                <SubmitButton
                                                                    type='submit'
                                                                    disabled={this.state.saveDisabled}
                                                                    text="Daten überprüfen und speichern"
                                                                    tabIndex={this.state.defaultTabIndex}
                                                                    mode="success"
                                                                    className="alhButton_mgsave"
                                                                />
                                                            }

                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </Col>



                                        <Col lg={4}>
                                            <div className="alh_frontBoxes">

                                                {UserStore.hasBerechtigung('bst_mg', 'write') &&
                                                    <SubmitButton
                                                        type='submit'
                                                        disabled={this.state.saveDisabled}
                                                        text="Daten überprüfen und speichern"
                                                        mode="success"
                                                        className="alhButton_mgsave"
                                                    />
                                                }

                                                <Table striped bordered hover size="sm" className="alhTable">
                                                    <tbody>
                                                        <tr className="highlight">
                                                            <th colSpan={2}>Beitragsdaten:</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">Beitritt am*</th>
                                                            <th className="text-right halfSize">
                                                                {(this.state.isNew || this.state.isWiedereintritt || this.state.dataset?.istBlankoMG) ?
                                                                    <InputField
                                                                        size='sm'
                                                                        type='date'
                                                                        inputGroupClass=''
                                                                        placeholder=''
                                                                        minDate={moment().subtract(1, 'years').format("YYYY-MM-DD")}
                                                                        required={true}
                                                                        value={this.state.dataset?.beitrittAm ? (this.state.dataset.beitrittAm).substr(0, 10) : ''}
                                                                        onChange={(val) => this.setInputValue('beitrittAm', val, true)}
                                                                    />
                                                                    :
                                                                    <Moment format="DD.MM.YYYY">{this.state.dataset?.beitrittAm}</Moment>
                                                                }

                                                            </th>
                                                        </tr>
                                                        <tr className={(this.state.dataset?.kuendWirksamDatum !== null) ? "" : "d-none"}>
                                                            <th className="text-left">Kündigung zum</th>
                                                            <th className="text-right warning-text halfSize">
                                                                <Moment format="DD.MM.YYYY">{this.state.dataset?.kuendWirksamDatum!}</Moment>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">Rw. MG ab
                                                                <small> (01.01.)</small>
                                                                <InfoIcon space="left" placement="right">Wählen Sie hier bei einer rückwirkenden Mitgliedschaft (Rw. MG) das entsprechende Beitragsjahr aus.
                                                                    Wenn Sie die Erklärungen für dieses Mitglied z. B. ab dem VZ 2018 erstellen, wählen Sie das Beitragsjahr 2019 aus</InfoIcon>
                                                            </th>
                                                            <th className="text-right halfSize">
                                                                <SelectField
                                                                    id='beitrittRueckwirkendJahr'
                                                                    name='beitrittRueckwirkendJahr'
                                                                    withPleaseSelect={false}
                                                                    formGroupClassName='small-form-group'
                                                                    size='sm'
                                                                    options={this.getMgRueckwirkend(this.state.beitrittRueckwirkendJahrFix)}
                                                                    value={this.state.dataset?.beitrittRueckwirkendJahr ?? ''}
                                                                    onChange={(val) => this.setInputValue('beitrittRueckwirkendJahr', Number(val), true)}
                                                                />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">BE liegt HV vor</th>
                                                            <th className={((!this.state.dataset?.hatHVBE) ? 'warning-text' : '') + ' text-right halfSize'}>
                                                                {(this.state.dataset?.hatHVBE) ? "Ja" : "Nein"}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">Unterschr. MG</th>
                                                            <th className={((!this.state.dataset?.hatUnterschrMitglied) ? 'warning-text' : '') + ' text-right halfSize'}>
                                                                {(this.state.dataset?.hatUnterschrMitglied) ? "Ja" : "Nein"}
                                                            </th>
                                                        </tr>
                                                        <tr className={(this.hasPartner()) ? "" : "d-none"}>
                                                            <th className="text-left">Unterschr. Partner</th>
                                                            <th className={((!this.state.dataset?.hatUnterschrPartner) ? 'warning-text' : '') + ' text-right halfSize'}>
                                                                {(this.state.dataset?.hatUnterschrPartner) ? "Ja" : "Nein"}
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </Table>



                                                <br />
                                                <Table striped bordered hover size="sm" className="alhTable">
                                                    <tbody>
                                                        <tr className="highlight">
                                                            <th colSpan={2}>Gebühren / Beiträge (in €):</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">Aufnahmegebühr</th>
                                                            <th className="text-right halfSize">
                                                                <InputField
                                                                    size='sm'
                                                                    type='clean'
                                                                    inputGroupClass=''
                                                                    inputClassNames='text-right'
                                                                    disabled={!this.state.isNew && !this.state.isWiedereintritt && !this.state.dataset?.istBlankoMG}
                                                                    placeholder='Aufnahmegebühr'
                                                                    value={this.state.dataset?.kontoAktJahr.aufnahmeGebuehr.toString() ?? ''}
                                                                    onChange={(val) => this.setInputValue('kontoAktJahr.aufnahmeGebuehr', parseFloat(val), true, true, 'decimal')}
                                                                />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">BMG VZ {this.state.dataset?.kontoAktJahr.beitragsjahr ? this.state.dataset?.kontoAktJahr.beitragsjahr - 1 : ''}<br />(Beitrag {this.state.dataset?.kontoAktJahr.beitragsjahr})*</th>
                                                            <th className="text-right halfSize">
                                                                <InputField
                                                                    size='sm'
                                                                    type='clean'
                                                                    inputGroupClass=''
                                                                    inputClassNames='text-right'
                                                                    placeholder='BMG'
                                                                    required={true}
                                                                    onFocus={() => this.bmgOnFocus()}
                                                                    isInvalid={(this.state.dataset?.kontoAktJahr.bemessungsgrundlage ?? 0) === 0}
                                                                    id='kontoAktJahr.bemessungsgrundlage'
                                                                    value={this.state.dataset?.kontoAktJahr.bemessungsgrundlage?.toString() ?? ''}
                                                                    onChange={(val) => {
                                                                        if (val)
                                                                            this.setInputValue('kontoAktJahr.bemessungsgrundlage', parseFloat(val), true, true, 'decimal')
                                                                    }}
                                                                />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">Vermietung / Verpachtung</th>
                                                            <th className="text-right halfSize">
                                                                <InputCheckbox
                                                                    name='bankverbindung.hatVermietVerpacht'
                                                                    checked={this.state.dataset?.kontoAktJahr.hatVermietVerpacht}
                                                                    onChange={(val) => this.setInputValue('kontoAktJahr.hatVermietVerpacht', val, true)}
                                                                    subtext=''
                                                                />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">Beitrag {this.state.dataset?.kontoAktJahr.beitragsjahr}</th>
                                                            <th className="text-right halfSize">
                                                                <InputField
                                                                    size='sm'
                                                                    type='clean'
                                                                    inputGroupClass=''
                                                                    inputClassNames='text-right'
                                                                    placeholder='Beitrag'
                                                                    disabled={true}
                                                                    value={this.state.dataset?.kontoAktJahr.beitrag?.toString() ?? ''}
                                                                    onChange={(val) => this.setInputValue('kontoAktJahr.beitrag', parseFloat(val), true)}
                                                                />
                                                            </th>
                                                        </tr>
                                                        <tr className={(this.state.isNew) ? "d-none" : ""}>
                                                            <th className="text-left">Beitrag offen</th>
                                                            <th className={((this.state.dataset?.kontoAktJahr.beitragOffen! > 0) ? 'warning-text' : '') + ' text-right'}>
                                                                {this.state.dataset?.kontoAktJahr.beitragOffen}
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </Table>

                                                <br />
                                                <Table striped bordered hover size="sm" className="alhTable">
                                                    <tbody>
                                                        <tr className="highlight">
                                                            <th colSpan={2}>BST Kennzeichnungen:</th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">Zuständige Beratungsstelle</th>
                                                            <th className="text-right halfSize">
                                                                <SelectField
                                                                    id='bstStandort'
                                                                    name='bstStandort'
                                                                    withPleaseSelect={false}
                                                                    formGroupClassName='small-form-group'
                                                                    size='sm'
                                                                    options={
                                                                        [
                                                                            { id: 0, value: '' },
                                                                            { id: 1, value: 'Beratungsstelle 1' },
                                                                            { id: 2, value: 'Beratungsstelle 2' }
                                                                        ]
                                                                    }
                                                                    value={this.state.dataset?.bstStandort}
                                                                    onChange={(val) => {
                                                                        console.log("valueBst", val)
                                                                        this.setInputValue('bstStandort', Number(val), true)
                                                                    }}
                                                                />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-left">Zuständiger BST Mitarbeiter</th>
                                                            <th className="text-right halfSize">
                                                                <SelectField
                                                                    id='bstMitarbeiter'
                                                                    name='bstMitarbeiter'
                                                                    withPleaseSelect={false}
                                                                    formGroupClassName='small-form-group'
                                                                    size='sm'
                                                                    options={this.getMitarbeiterArray()}
                                                                    value={Number(this.state.dataset?.bstMitarbeiter)}
                                                                    onChange={(val) => this.setInputValue('bstMitarbeiter', val, true)}
                                                                />
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>

                                <div className="alh_small">* Pflichtfelder / ** 'Telefon privat' oder 'Handy' Pflichtfeld</div>
                            </Container>
                        </Tab>

                        <Tab eventKey="print" title="Dokumente drucken">
                            <MitgliedDokumente
                                isNew={this.state.isNew}
                                isWiedereintritt={this.state.isWiedereintritt}
                                mgNr={this.state.mGNr ?? ""}
                                partner={this.state.dataset?.partner}
                                mitgliedNachname={this.state.dataset?.mitglied.nachname}
                                zahlungsweiseSoll={this.getZahlungsweiseString()}
                            />
                        </Tab>

                        <Tab eventKey="booking" ref={React.createRef()} title="Buchungen">
                            <Container>
                                <Row className="alh_rowTopList">
                                    <Col lg={6}>
                                        <div className="alh_frontBoxes clearMinheight">
                                            Hier können Sie die Standardzahlungsweise (Meine BST - Grunddaten) für den Druck der Rechnung abändern.<br /><br />
                                            Zahlungsweise (Soll) für Rechnungen
                                            <Row>
                                                <Col lg={6}>
                                                    <SelectField
                                                        id='zahlungsweiseSollRechnungen1'
                                                        name='zahlungsweiseSollRechnungen1'
                                                        withPleaseSelect={false}
                                                        formGroupClassName="small-form-group"
                                                        value={this.state.zahlungsweiseSollRechnungen1}
                                                        options={[{ id: 'Überweisung', value: 'Überweisung' }, { id: 'Bankeinzug', value: 'Bankeinzug' }]}
                                                        onChange={(val) => this.setInputValue('zahlungsweiseSollRechnungen1', val, false, false)}
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <SelectField
                                                        id='zahlungsweiseSollRechnungen2'
                                                        name='zahlungsweiseSollRechnungen2'
                                                        withPleaseSelect={false}
                                                        formGroupClassName="small-form-group"
                                                        containerClassName={this.state.zahlungsweiseSollRechnungen1 === 'Bankeinzug' ? 'd-none' : ''}
                                                        value={this.state.zahlungsweiseSollRechnungen2}
                                                        options={[{ id: 'Sofort', value: 'Sofort' }, { id: '10 Tage', value: '10 Tage' }, { id: '14 Tage', value: '14 Tage' }]}
                                                        onChange={(val) => this.setInputValue('zahlungsweiseSollRechnungen2', val, false, false)}
                                                    />
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="alh_frontBoxes clearMinheight">
                                            {UserStore.hasBerechtigung('bst_mg', 'write') &&
                                                <>
                                                    Hier können Sie uns Änderungswünsche (z. B. Änderung Soll-Stellung, Heirat von Mitgliedern, …) mitteilen.<br /><br />
                                                    <SubmitButton
                                                        type='button'
                                                        onClick={() => this.showBuchung("Betrag", { onlyTodoHV: true })}
                                                        text="Aufgabe für Hauptverwaltung erstellen"
                                                        size="sm"
                                                        infotext="Hier können Sie eine Aufgabe an die Hauptverwaltung senden, wenn z.B. eine Sollbuchung angepasst werden soll."
                                                    />
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="alh_rowTopList">
                                    <Col lg={6}>
                                        <div className="alh_frontBoxes">
                                            {(!this.state.isNew && !this.state.isWiedereintritt) ?
                                                <MitgliederOP
                                                    mGNr={this.state.mGNr}
                                                    zahlungsweise={this.getZahlungsweiseString()}
                                                    oPs={this.state.oPs}
                                                    oLs={this.state.oLs}
                                                    gesamtoffen={this.state.gesamtoffen}
                                                    showBuchung={(val: string, data = {}) => this.showBuchung(val, data)}
                                                    active={this.state.activeTabs['booking']}
                                                /> :
                                                <ShowNotReadyNew name="keine OP" />}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="alh_frontBoxes">
                                            {(!this.state.isNew && !this.state.isWiedereintritt) ?
                                                <MitgliederBJournal
                                                    mGNr={this.state.mGNr ?? ''}
                                                    zahlungsweisehaben={this.state.zahlungsweiseNewDefault}
                                                    zahlungsweise={this.getZahlungsweiseString()}
                                                    nachname={this.state.dataset?.mitglied.nachname}
                                                    showBuchung={this.state.showBuchung}
                                                    showBuchungData={this.state.showBuchungData}
                                                    reloadBJournal={this.state.reloadBJournal}
                                                    oPs={this.state.oPs} oLs={this.state.oLs}
                                                    onReload={() => this.reloadOps(true)}
                                                    active={this.state.activeTabs.booking}
                                                /> :
                                                <ShowNotReadyNew name="kein Journal" />}
                                        </div>
                                    </Col>
                                </Row>

                            </Container>
                        </Tab>

                        <Tab eventKey="rueckwirkend" title="Rückwirkende Buchungen">
                            <Container>
                                <Row className="alh_rowTopList">
                                    <Col lg={12}>
                                        <div className="alh_frontBoxes">
                                            {(!this.state.isNew && !this.state.isWiedereintritt) ?
                                                <div>
                                                    <MitgliederRueckBuchung
                                                        mGNr={this.state.mGNr ?? ''}
                                                        onReloadOthers={() => this.reloadOpsBJournal()}
                                                        reload={this.state.mgRueckReload} onChangedDataSet={() => this.setChangedData()}
                                                        active={this.state.activeTabs['rueckwirkend']} />
                                                </div>
                                                :
                                                <ShowNotReadyNew />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>

                        <Tab eventKey="tasks" title={`Meine Notizen ${!!this.state.numNotizen ? `(${this.state.numNotizen})` : ""}`}>
                            <Container>
                                <Row className="alh_rowTopList">
                                    <Col lg={12}>
                                        <div className="alh_frontBoxes">
                                            {(!this.state.isNew && !this.state.isWiedereintritt) ?
                                                <div>
                                                    <Aufgaben mode="mgnotiz" mGNr={this.state.mGNr} updateNum={this.updateNotizenCount} />
                                                </div> : <ShowNotReadyNew name="Notizen" />}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>


                        <Tab eventKey="tasksdown" title={`Meine Aufgaben ${!!this.state.numAufgaben ? `(${this.state.numAufgaben})` : ""}`}>
                            <Container>
                                <Row className="alh_rowTopList">
                                    <Col lg={12}>
                                        <div className="alh_frontBoxes">
                                            {(!this.state.isNew && !this.state.isWiedereintritt) ?
                                                <div>
                                                    <Aufgaben mode="aufgaben" mGNr={this.state.mGNr} updateNum={this.updateAufgabenCount} />
                                                </div> : <ShowNotReadyNew name="Meine Aufgaben" />}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>

                        <Tab eventKey="bp" title={this.state.dataset ? `BP (${this.state.dataset?.bpAngelegt}/${this.state.dataset?.bpFinal})` : "BP"}>
                            <Container>
                                <Row className="alh_rowTopList">
                                    <Col lg={12}>
                                        <Beratungsprotokoll
                                            mGNr={this.state.mGNr}
                                            mGName={this.state.dataset?.matchcode}
                                            active={this.state.activeTabs.bp}
                                            emailMissing={this.state.dataset?.eMail === ""}
                                            beUnterschriftVorhanden={!!((this.state.dataset?.partner.anrede === "-1" || this.state.dataset?.partner.anrede === "kein Partner vorhanden")
                                                ? (this.state.dataset?.hatHVBE && this.state.dataset?.hatUnterschrMitglied)
                                                : (this.state.dataset?.hatHVBE && this.state.dataset?.hatUnterschrMitglied && this.state.dataset?.hatUnterschrPartner))}
                                            vmUnterschriftVorhanden={!!((this.state.dataset?.partner.anrede === "-1" || this.state.dataset?.partner.anrede === "kein Partner vorhanden")
                                                ? (this.state.dataset?.steuer.hatVollmachtFA)
                                                : (this.state.dataset?.steuer.hatVollmachtFA && this.state.dataset?.steuerPartner.hatVollmachtFA))}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>

                        {UserStore.hasBerechtigung('bst_uploads') &&
                            <Tab eventKey="upload" title="Uploads">
                                <Container>
                                    <Row className="alh_rowTopList">
                                        <Col lg={12}>
                                            <div className="alh_frontBoxes">
                                                {(!this.state.isNew && !this.state.isWiedereintritt) ?
                                                    <div>
                                                        <Uploads
                                                            mGNr={this.state.mGNr} withHistory={true}
                                                            active={this.state.activeTabs['upload']} />
                                                    </div>
                                                    :
                                                    <ShowNotReadyNew />
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                        }

                    </Tabs>
                </div>
            );
        }
        else if (this.state.loaded && !this.state.mtglAvailable) {
            return (
                <div className="alh_stat_container">
                    <Alert key="newAlert" variant="warning">Mitglied nicht vorhanden. Bitte kontrollieren Sie die Mitgliedsnummer: {this.state.mGNr}</Alert>
                </div>
            );
        } else {
            return (
                <div className="alh_stat_container">
                    <PointSpreadLoading color="#ffc800" />
                </div>
            );
        }
    }
}

interface ShowNotReadyNewProps {
    name?: string
}

export const ShowNotReadyNew = class ShowNotReadyNew extends Component<ShowNotReadyNewProps> {

    render() {
        const { name = 'keine Anzeige' } = this.props

        return (
            <div>
                Wegen Neumitglied {name} vorhanden. Bitte erst das Neumitglied einmal speichern, dann stehen diese Daten zur Verfügung.
            </div>
        )
    }

}

export default withRouter(Mitglied);
