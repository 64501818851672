import React, { Component } from 'react';
import Table from "react-bootstrap/Table";
import TableOperatorOptions from "../Blocks/TableOperatorOptions";
import Moment from "react-moment";
import { WaveTopBottomLoading } from "react-loadingg";
import UserStore from "../../stores/UserStore";
import { sortList } from "../ALHUtility";
import MitgliedItem from '../../models/Mitglied';

export interface OffeneBeIdentItem {
    ident?: string
    rw?: string
    be?: string;
    upload?: Date
}

interface OffeneBEIdentProps {
    mode?: string
}

interface OffeneBEIdentState {
    loading: boolean
    mitglieder: MitgliedItem[]
    search: string
    sort: string
    sort_mode: string
}

class OffeneBEIdent extends Component<OffeneBEIdentProps, OffeneBEIdentState> {

    constructor(props: OffeneBEIdentProps) {
        super(props);

        this.state = {
            loading: true,
            mitglieder: [],
            search: '',
            sort: 'beitritt',
            sort_mode: 'asc',
        }

    }

    async componentDidMount() {
        try {

            let endpoint = '';
            if (this.props.mode === "BE") {
                endpoint = '/offeneBE';
            } else if (this.props.mode === "ID") {
                endpoint = '/offeneIdent';
            }

            let res = await fetch(UserStore.url + endpoint, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let result = await res.json();

            if (result && result.success) {
                this.setState({
                    mitglieder: result.data.mitglieder,
                    loading: false
                });
            }

            document.title = 'Offene Beitrittserklärungen/Indentifikationen (' + result.data.mitglieder.length + ') | ' + UserStore.title;

        } catch (e) {
            console.log("Error in loading offeneBEIdent: " + e);
        }
    }

    changeSearch(val: string) {
        this.setState({
            search: val
        })
    }

    sortClick(spalte: string) {
        let newSort_mode = this.state.sort_mode;
        let newSort = this.state.sort;

        if (spalte === this.state.sort) {
            switch (this.state.sort_mode) {
                case 'asc':
                    newSort_mode = 'desc';
                    break;
                default:
                    newSort_mode = 'asc';
                    break;
            }
        } else {
            newSort = spalte;
        }

        this.setState({
            sort: newSort,
            sort_mode: newSort_mode,
        })
    }

    getHeaderText() {
        if (this.props.mode === "BE") {
            return (
                <div>
                    <h3 className="alh_pageheader">Offene Beitrittserklärungen</h3>
                    <div className="alh_subheader">Hier sehen Sie alle aktuell offenen Beitrittserklärungen. Mit Klick auf die Mitgliedsnummer gelangen Sie in die Detailansicht des Mitglieds.</div>
                </div>
            )
        } else if (this.props.mode === "ID") {
            return (
                <div>
                    <h3 className="alh_pageheader">Offene Identifikationen</h3>
                    <div className="alh_subheader">Hier sehen Sie alle aktuell offenen Identifikationen. Mit Klick auf die Mitgliedsnummer gelangen Sie in die Detailansicht des Mitglieds.</div>
                </div>
            )
        }
    }

    render() {

        const { mode = "BE" } = this.props
        let list = sortList(this.state.sort, this.state.mitglieder, this.state.sort_mode);
        let search = this.state.search;
        list = list.filter(function (beitrag) {
            return (
                beitrag.name?.toLowerCase().includes(search.toLowerCase()) ||
                beitrag.vorname.toLowerCase().includes(search.toLowerCase()) ||
                beitrag.ident?.toLowerCase().includes(search.toLowerCase()) ||
                beitrag.be?.toLowerCase().includes(search.toLowerCase()) ||
                beitrag.beitritt?.toString().toLowerCase().includes(search.toLowerCase()) ||
                beitrag.mgNr?.toLowerCase().includes(search.toLowerCase())
            );
        });

        return (
            <div>

                {this.getHeaderText()}

                <TableOperatorOptions
                    name="OffeneBEIdent"
                    placeholder={("Suche nach Nummer, Name, Beitritt...")}
                    onChange={(val) => this.changeSearch(val)}
                    print={true}
                />

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th onClick={() => this.sortClick('mgNr')} className={((this.state.sort === 'mgNr') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alhtable_nr"}>Nr.</th>
                            <th onClick={() => this.sortClick('name')} className={(this.state.sort === 'name') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Name</th>
                            <th onClick={() => this.sortClick('beitritt')} className={(this.state.sort === 'beitritt') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Beitritt</th>
                            <th onClick={() => this.sortClick('upload')} className={(this.state.sort === 'upload') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Upload am</th>
                            <th onClick={() => this.sortClick('rw')} className={((mode === "ID") ? "d-none " : "") + ((this.state.sort === 'rw') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Rw. MG ab</th>
                            <th onClick={() => this.sortClick('be')} className={((mode === "ID") ? "d-none " : "") + ((this.state.sort === 'be') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Beitrittserklärung</th>
                            <th onClick={() => this.sortClick('ident')} className={((mode === "BE") ? "d-none " : "") + ((this.state.sort === 'ident') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Identifikation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((mg, index) => (
                            <tr key={index}>
                                <td><a href={"/mitglied/" + mg.mgNr}>{mg.mgNr}</a></td>
                                <td>{mg.name}, {mg.vorname}</td>
                                <td><Moment format="DD.MM.YYYY">{mg.beitritt}</Moment></td>
                                <td>{mg.upload && <Moment format="DD.MM.YYYY">{mg.upload}</Moment>}</td>
                                <td className={((mode === "ID") ? "d-none " : "")}>{mg.rw}</td>
                                <td className={((mode === "ID") ? "d-none " : "")}>{mg.be}</td>
                                <td className={((mode === "BE") ? "d-none " : "")}>{mg.ident}</td>
                            </tr>
                        ))}


                    </tbody>
                </Table>


                {list && list.length === 0 && !this.state.loading &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}


            </div>
        );
    }
}

export default OffeneBEIdent;