import React, { Component, RefObject } from 'react';
import { InputGroup, Form, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey, faEdit, faEnvelope } from "@fortawesome/free-solid-svg-icons";

interface InputFieldProps {
    id?: string
    name?: string
    as?: 'input' | 'textarea' | any
    inputGroupClass?: string
    inputClassNames?: string
    containerClassName?: string
    required?: boolean
    maxDate?: Date | string | number
    minDate?: Date | string | number
    forceAutofill?: boolean
    isValid?: boolean
    isInvalid?: boolean
    disabled?: boolean
    placeholder: string
    value: string
    setFocus?: boolean
    data?: string
    tabindex?: number
    type?: string
    initialSize?: number
    size?: "sm" | "lg"
    onFocus?: () => void
    onChange: (value: string) => void
    onEnter?: (currentValue: string) => void
    onClick?: () => void
}

interface InputFieldState {
    mandatory: boolean
    value: string
    required?: boolean
    disabled?: boolean
    inputClassNames?: string
}

class InputField extends Component<InputFieldProps, InputFieldState> {

    thisRef = React.createRef<FormControlElement>();

    constructor(props: InputFieldProps) {
        super(props);

        this.state = {
            mandatory: false,
            value: this.props.value,
            required: this.props.required,
            disabled: this.props.disabled,
            inputClassNames: this.props.inputClassNames,
        }
    }

    componentDidMount() {
        if (this.props.setFocus) {
            setTimeout(() => {
                this.thisRef.current?.focus();
            }, 500);
        }
    }

    setInputValue(val: string) {
        this.setState({ ...this.state, value: val })

        //not on blur, not good for autofill
        if (this.props.forceAutofill) {
            this.props.onChange(val);
        }
    }

    componentDidUpdate(prevProps: InputFieldProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            })
        }
        if (prevProps.disabled !== this.props.disabled) {
            this.setState({
                disabled: this.props.disabled
            })
        }
        if (prevProps.required !== this.props.required) {
            this.setState({
                required: this.props.required
            })
        }
        if (prevProps.inputClassNames !== this.props.inputClassNames) {
            this.setState({
                inputClassNames: this.props.inputClassNames
            })
        }
    }

    checkType(param: string) {
        switch (param) {
            case 'password':
                return <FontAwesomeIcon icon={faKey} />;
            case 'text':
            case 'textclean':
                return <FontAwesomeIcon icon={faEdit} />;
            case 'email':
                return <a href={"mailto:" + this.state.value + "?subject=" + this.props.data} className="alh_faicon"><FontAwesomeIcon icon={faEnvelope} /></a>;
            case 'user':
                return <FontAwesomeIcon icon={faUser} />;
            case 'clean':
            case 'date':
            case 'checkbox':
            case 'number':
                return "";
            default:
                return <FontAwesomeIcon icon={faEdit} />;
        }
    }

    getInputType() {
        let retType: string | undefined;
        switch (this.props.type) {
            case 'user':
                retType = 'text';
                break;
            default:
                retType = this.props.type;
        }
        return retType;
    }

    onKeyPress(e: React.KeyboardEvent<typeof FormControl>) {
        if (e.key === 'Enter') {
            if (typeof this.props.onEnter === "function") {
                //hier müssten wir einen workout machen für das bluren, das is im KeyboardEvent nicht vorhanden ist
                //e.target.blur();
                this.thisRef.current?.blur();
                setTimeout(() => {
                    if (this.props.onEnter) {
                        this.props.onEnter(this.state.value);
                    }
                }, 500);
            }
        }
    }

    render() {

        const { inputGroupClass = "mb-2", isValid = false, isInvalid = false, tabindex = 0 } = this.props
        const { required = false } = this.state

        return (
            <div className={'inputField ' + this.props.containerClassName} onMouseDown={this.props.onClick}>
                <InputGroup className={inputGroupClass} >
                    <InputGroup.Prepend className={(this.checkType(this.props.type ?? "") === "") ? "d-none" : ""}>
                        <InputGroup.Text>
                            {this.checkType(this.props.type ?? "")}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        as={this.props.as}
                        rows={this.props.initialSize ?? 5}
                        ref={(input: RefObject<FormControlElement>) => { this.thisRef = input; }}
                        required={required}
                        name={this.props.name}
                        id={this.props.id}
                        disabled={this.state.disabled}
                        placeholder={this.props.placeholder}
                        type={this.getInputType()}
                        className={this.state.inputClassNames}
                        value={this.state.value}
                        isValid={isValid}
                        isInvalid={isInvalid}
                        tabIndex={tabindex}
                        size={this.props.size}
                        onFocus={() => this.props.onFocus && this.props.onFocus()}
                        onBlur={() => this.props.onChange(this.state.value)}
                        onChange={(e) => this.setInputValue(e.target.value)}
                        onKeyPress={(e: React.KeyboardEvent<typeof FormControl>) => this.onKeyPress(e)}

                        max={this.props.maxDate}
                        min={this.props.minDate}
                    />
                </InputGroup>
            </div>
        );
    }
}

export default InputField;
