import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'
import { PointSpreadLoading } from "react-loadingg";
import SelectField from "../Forms/SelectField";
import SubmitButton from "../Forms/SubmitButton";
import { toast } from "react-toastify";
import UserStore from "../../stores/UserStore";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Beratungsstellen, Decision, Ueberweisungsoptionen, ZahlungsweiseHaben, ZahlungsweiseSollRechnungen } from '../../stores/Constants';
import MyBstService from '../../services/MyBstService';
import MitarbeiterService from '../../services/MitarbeiterService';
import KeyValue from '../../models/KeyValue';
import { BstContext } from '../../stores/BstContextProvider';

interface VoreinstellungenItem {
    checkBekanntgabevollmacht: Decision
    zahlungsweiseHaben: ZahlungsweiseHaben
    adresseVASTVollmacht?: Beratungsstellen
    andruckBST2MGAusweis: Decision
    bstDefaultCheckSeparateFiles: Decision
    zahlungsweiseSollRechnungen?: string

    //emails
    emailGutschriften?: string
    emailRechnungen?: string
    emailBestellbestaetigungen?: string
    emailToDo?: string
    emailBst: string

    mitarbeiterEmails: KeyValue[]

    //wird nur geladen damit im alten soap service der wert nicht mit null überschrieben wird
    glaeubigerId: string
}

interface VoreinstellungenProps extends RouteComponentProps { }

export interface VoreinstellungenState extends VoreinstellungenItem {
    [key: string]: any
    zahlungsweiseSollRechnungen1?: ZahlungsweiseSollRechnungen
    zahlungsweiseSollRechnungen2?: Ueberweisungsoptionen
}

class Voreinstellungen extends Component<VoreinstellungenProps, VoreinstellungenState> {

    // resembles the useContext hook in functional components
    static contextType = BstContext
    context!: React.ContextType<typeof BstContext>

    constructor(props: VoreinstellungenProps) {
        super(props);

        if (!UserStore.hasBerechtigung('bst_grunddaten') && typeof this.props.history !== "undefined") {
            this.props.history.push("/notallowed/");
        }
    }

    setInputValue(field: keyof VoreinstellungenState, val: any) {
        this.setState({
            [field]: val
        });
    }

    processZahlungsweiseSollRechnungen() {

        if (this.state && typeof this.state.zahlungsweiseSollRechnungen !== "undefined") {
            let terms = this.state.zahlungsweiseSollRechnungen.split(";");

            this.setState({
                zahlungsweiseSollRechnungen1: terms[0] as ZahlungsweiseSollRechnungen,
                zahlungsweiseSollRechnungen2: terms[1] as Ueberweisungsoptionen
            })
        }

    }

    getZahlungsweiseSollRechnungen() {
        if (this.state.zahlungsweiseSollRechnungen1 === "Überweisung") {
            return this.state.zahlungsweiseSollRechnungen1 + ";" + this.state.zahlungsweiseSollRechnungen2;
        } else {
            return this.state.zahlungsweiseSollRechnungen1;
        }
    }

    async componentDidMount() {
        try {
            const context = this.context
            const mitarbeiter = await MitarbeiterService.getMitarbeiter()
            const bstSettings = context.settings
            const bstEmail = [{ id: bstSettings.emailBst, value: bstSettings.emailBst }]

            this.setState({
                ...bstSettings,
                checkBekanntgabevollmacht: bstSettings.checkBekanntgabevollmacht ? Decision.yes : Decision.no,
                bstDefaultCheckSeparateFiles: bstSettings.bstDefaultCheckSeparateFiles ? Decision.yes : Decision.no,
                adresseVASTVollmacht: bstSettings.adresseVASTVollmacht,
                andruckBST2MGAusweis: bstSettings.andruckBST2MGAusweis === "Ja" ? Decision.yes : Decision.no,
                mitarbeiterEmails: [...bstEmail, ...mitarbeiter.filter(m => m.eMail).map(m => ({ id: m.eMail, value: m.eMail }))]
            });

            this.processZahlungsweiseSollRechnungen();

        } catch (e) {
            console.log("Error in loading homemessage: " + e);
        }

        document.title = 'BST Voreinstellungen | ' + UserStore.title;
    }

    async saveData() {

        const zahlungsweise = this.getZahlungsweiseSollRechnungen();

        if (this.state) {
            //Send data
            const response = await MyBstService.update({ ...this.state, zahlungsweiseSollRechnungen: zahlungsweise ?? "" })

            if (response.data.succeeded) {
                toast.success("Daten erfolgreich gespeichert!", {
                    autoClose: 5000
                });
            } else {
                toast.error(response.data.message, {
                    autoClose: false
                });
            }
        } else {
            toast.error("Daten sind leer. Es kann nichts gespeichert werden", {
                autoClose: 5000
            })
        }
    }

    render() {
        if (this.state) {
            return (
                <div>
                    <h3 className="alh_pageheader">Voreinstellungen</h3>
                    <div className="alh_subheader">Hier können Sie Einstellungen zu Zahlungsweise etc. anpassen.<br />Vergessen Sie dann nicht auf "Speichern" zu klicken.</div><br /><br />

                    <Container>
                        <Row className="alh_rowSpacer">
                            <Col className="alh_colData">
                                <h3>Voreinstellungen</h3>
                                <Table striped bordered hover>
                                    <tbody>
                                        <tr>
                                            <td className="large alh_mobileFull">Vollmacht Finanzamt (VM): Bekanntgabevollmacht Zeile 17 für den Empfang von Steuerbescheiden ankreuzen</td>
                                            <td className="alh_mobileFull">
                                                <SelectField
                                                    id='checkBekanntgabeVollmacht'
                                                    name='checkBekanntgabeVollmacht'
                                                    withPleaseSelect={true}
                                                    value={this.state.checkBekanntgabevollmacht ?? Decision.no}
                                                    options={Object.values(Decision).map(d => ({ id: d, value: d }))}
                                                    onChange={(val) => this.setInputValue('checkBekanntgabevollmacht', val)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="large alh_mobileFull">Zahlungsweise (Soll)<br />für MG-Rechnungen</td>
                                            <td className="alh_mobileFull" >
                                                <SelectField
                                                    id='zahlungsweiseSollRechnungen1'
                                                    name='zahlungsweiseSollRechnungen1'
                                                    withPleaseSelect={true}
                                                    value={this.state.zahlungsweiseSollRechnungen1}
                                                    options={Object.values(ZahlungsweiseSollRechnungen).map(d => ({ id: d, value: d }))}
                                                    onChange={(val) => this.setInputValue('zahlungsweiseSollRechnungen1', val)}
                                                />
                                                <SelectField
                                                    id='zahlungsweiseSollRechnungen2'
                                                    name='zahlungsweiseSollRechnungen2'
                                                    withPleaseSelect={true}
                                                    containerClassName={this.state.zahlungsweiseSollRechnungen1 === ZahlungsweiseSollRechnungen.Bankeinzug ? 'd-none' : ''}
                                                    value={this.state.zahlungsweiseSollRechnungen2}
                                                    options={Object.values(Ueberweisungsoptionen).map(d => ({ id: d, value: d }))}
                                                    onChange={(val) => this.setInputValue('zahlungsweiseSollRechnungen2', val)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="large">Zahlungsweise (Haben)<br />für MG-Buchungen</td>
                                            <td>
                                                <SelectField
                                                    id='zahlungsweiseHaben'
                                                    name='zahlungsweiseHaben'
                                                    withPleaseSelect={true}
                                                    value={this.state.zahlungsweiseHaben}
                                                    options={Object.values(ZahlungsweiseHaben).map(d => ({ id: d, value: d }))}
                                                    onChange={(val) => this.setInputValue('zahlungsweiseHaben', val)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="large">Dokumentendruck immer in einzelne Dokumente (z.B. Unterschriftenpad)</td>
                                            <td>
                                                <SelectField
                                                    id='bstDefaultCheckSeparateFiles'
                                                    name='bstDefaultCheckSeparateFiles'
                                                    withPleaseSelect={true}
                                                    value={this.state.bstDefaultCheckSeparateFiles}
                                                    options={Object.values(Decision).map(d => ({ id: d, value: d }))}
                                                    onChange={(val) => this.setInputValue('bstDefaultCheckSeparateFiles', val)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="large" colSpan={2}>Weitere Voreinstellungen (nur wenn 2. Beratungsstelle vorhanden):</td>
                                        </tr>
                                        <tr>
                                            <td className="large">Adresse für Vollmacht Finanzamt (VM),<br />Hauptadresse auf MG-Rechnungen</td>
                                            <td>
                                                <SelectField
                                                    id='adresseVASTVollmacht'
                                                    name='adresseVASTVollmacht'
                                                    withPleaseSelect={true}
                                                    value={this.state.adresseVASTVollmacht}
                                                    options={Object.values(Beratungsstellen).map(d => ({ id: d, value: d }))}
                                                    onChange={(val) => this.setInputValue('adresseVASTVollmacht', val)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="large">Andruck Beratungsstelle 2<br />(MG-Ausweis, Vollständigkeitserklärung)</td>
                                            <td>
                                                <SelectField
                                                    id='andruckBST2MGAusweis'
                                                    name='andruckBST2MGAusweis'
                                                    withPleaseSelect={true}
                                                    value={this.state.andruckBST2MGAusweis}
                                                    options={Object.values(Decision).map(d => ({ id: d, value: d }))}
                                                    onChange={(val) => this.setInputValue('andruckBST2MGAusweis', val)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="large" colSpan={2}>E-Mail Empfänger einstellen:</td>
                                        </tr>
                                        <tr>
                                            <td className="large">Gutschriften</td>
                                            <td>
                                                <SelectField
                                                    id='emailGutschriften'
                                                    name='emailGutschriften'
                                                    withPleaseSelect={true}
                                                    value={!!this.state.emailGutschriften ? this.state.emailGutschriften : this.state.emailBst}
                                                    options={this.state.mitarbeiterEmails}
                                                    onChange={(val) => this.setInputValue('emailGutschriften', val)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="large">Rechnungen</td>
                                            <td>
                                                <SelectField
                                                    id='emailRechnungen'
                                                    name='emailRechnungen'
                                                    withPleaseSelect={true}
                                                    value={!!this.state.emailRechnungen ? this.state.emailRechnungen : this.state.emailBst}
                                                    options={this.state.mitarbeiterEmails}
                                                    onChange={(val) => this.setInputValue('emailRechnungen', val)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="large">Bestellbestätigungen</td>
                                            <td>
                                                <SelectField
                                                    id='emailBestellbestaetigungen'
                                                    name='emailBestellbestaetigungen'
                                                    withPleaseSelect={true}
                                                    value={!!this.state.emailBestellbestaetigungen ? this.state.emailBestellbestaetigungen : this.state.emailBst}
                                                    options={this.state.mitarbeiterEmails}
                                                    onChange={(val) => this.setInputValue('emailBestellbestaetigungen', val)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="large">Wawi-ToDos</td>
                                            <td>
                                                <SelectField
                                                    id='emailToDo'
                                                    name='emailToDo'
                                                    withPleaseSelect={true}
                                                    value={!!this.state.emailToDo ? this.state.emailToDo : this.state.emailBst}
                                                    options={this.state.mitarbeiterEmails}
                                                    onChange={(val) => this.setInputValue('emailToDo', val)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>

                    <SubmitButton text="Speichern" onClick={() => this.saveData()} mode="success" />

                </div>
            );

        } else {

            return (
                <div>
                    <PointSpreadLoading color="#ffc800" />
                </div>
            )

        }
    }
}

export default withRouter(Voreinstellungen);
