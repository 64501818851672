import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'react-moment';
import React, { useCallback, useMemo, useState } from "react";
import { GrunddatenDatencheckField } from "../../stores/DatencheckFields";
import InputCheckbox from "../Forms/InputCheckbox";
import { Datencheck, MyBstData } from "../Screens/MyBst";
import InfoIcon from "./InfoIcon";
import InputField from "../Forms/InputField";
import SelectField from "../Forms/SelectField";
import { toast } from "react-toastify";
import moment from "moment";

interface MyBstAccordionProps {
    field: GrunddatenDatencheckField
    datencheck?: Datencheck
    data: MyBstData
    accordionName: string
    fullFieldPropName: string
    datencheckExists: (countprop: string) => Datencheck | undefined
    updateDatenchecks: (datenchecks: Datencheck[]) => void
    deleteDatencheck: (datencheckProp: string) => void
    disableSave: (disable: boolean) => void
}

const oeffnungszeiten = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
]

const propsSmallInputs = ["handy", "telefon", "telefax"]

const MyBstAccordionRow = ({ field, datencheck, data, accordionName, fullFieldPropName,
    updateDatenchecks,
    deleteDatencheck,
    disableSave,
    datencheckExists
}: MyBstAccordionProps) => {

    const getValue = useCallback((fieldProp?: string) => {
        const accordionObject = data[accordionName]
        let splitprop: string[] = []
        let value: any

        if (accordionObject) {
            if (fieldProp) {
                splitprop = fieldProp.split(".")
                value = accordionObject[fieldProp]
            } else {
                splitprop = field.prop.split(".")
                value = accordionObject[field.prop]
            }

            if (splitprop.find(s => s === "split")) {
                const splitIndex = Number(splitprop[2])
                value = accordionObject[splitprop[0]].split(" ")[splitIndex]
            } else {
                switch (splitprop.length) {
                    case 2:
                        value = accordionObject[splitprop[0]][splitprop[1]]
                        break;
                    case 3:
                        value = accordionObject[splitprop[0]][splitprop[1]][splitprop[2]]
                        break;
                }
            }

            return value
        }

        return undefined
    }, [field, data, accordionName])

    const value = useMemo(() => getValue(), [getValue])
    const [edit, setEdit] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false)

    const splitTelefon = (telefonValue: string, valueIndex: number) => {
        if (valueIndex > 0) {
            return telefonValue.split(" ").length > 1 ?
                telefonValue.slice(telefonValue.split(" ")[0].length).trim() :
                telefonValue.split(" ")[valueIndex]
        } else {
            return telefonValue.split(" ")[valueIndex].replaceAll("(", "").replaceAll(")", "").trim()
        }
    }

    const splitDatencheckValue = (value: string, prop: string): { index: number, value: string } => {
        const splitprop = prop.split(".")

        const splitIndex = Number(splitprop[2])
        const result = splitTelefon(value, splitIndex)

        return { index: splitIndex, value: result }
    }

    const showError = (error?: string) => {
        toast.error(error, {
            autoClose: 5000
        })
        setEdit(true)
        setIsInvalid(true)
        disableSave(true)
    }

    const updateDatencheck = (newValue: string | number | boolean, datencheck?: Datencheck, valueIndex?: number, field?: GrunddatenDatencheckField, parent?: GrunddatenDatencheckField) => {
        let resultNewValue = newValue;
        let result: Datencheck[] = []

        //ist neuer wert kein leerer wert
        if (field?.type === "Textfeld" && typeof newValue === "string" && newValue.length > 0) {
            if (newValue.trim().length === 0) {
                showError("Keine leeren Werten möglich")
                return
            }
        }

        if (field?.validate && !field.validate(newValue)) {
            showError(field.errorMessage)
            return
        }

        //feld ist auf 2 controls aufgeteilt aber wert ist ein datencheck (betrifft vorallem handy + telefon)
        if (valueIndex !== undefined) {
            let telefon = ""
            if (datencheck?.newValue) {
                telefon = datencheck.newValue.toString()
                const split = telefon.split(" ")
                if (split.length > 1 && !split[1]) {
                    resultNewValue = `${telefon}${newValue}`
                } else {
                    resultNewValue = telefon.replace(splitTelefon(telefon, valueIndex), newValue?.toString() ?? "")
                }

                if (resultNewValue.trim() === "()") {
                    resultNewValue = ""
                }
            } else if (newValue) {
                if (value) {
                    resultNewValue = valueIndex === 0 ? `(${newValue.toString()}) ` + splitTelefon(value, 1) : `(${splitTelefon(value, 0)}) ` + newValue.toString()
                } else {
                    resultNewValue = valueIndex === 0 ? `(${newValue.toString()}) ` : "(0000) " + newValue.toString()
                }
            }
        }

        if (field && field.prop.includes("include")) {
            if (field?.type === "Checkbox") {
                if (typeof newValue === "boolean") {
                    const stringToUse = field.name
                    if (newValue) {
                        if (datencheck?.newValue) {
                            resultNewValue = datencheck.newValue?.toString().concat(`; ${stringToUse}`)
                        } else {
                            resultNewValue = value ? `${value}; ${stringToUse}` : stringToUse
                        }
                    } else {
                        let splitUpValues: string[] = []
                        if (datencheck) {
                            splitUpValues = datencheck.newValue?.toString().split(";").map(v => v.trim())
                        } else {
                            splitUpValues = value.toString().split(";").map((v: string) => v.trim())
                        }
                        resultNewValue = splitUpValues
                            .filter((v) => v !== stringToUse || !v)
                            .reduce((prev, current) => `${prev}${current.includes(stringToUse) ? "" : current ? `; ${current}` : ""}`, "")
                    }
                }
            }
        }

        if ((field?.type === "Textfeld" || field?.type === "TextfeldLink" || field?.type === "TextfeldMultiline") && resultNewValue === value?.toString()) {
            showError("Neuer Wert identisch mit altem Wert")
            if (datencheck && datencheck.id === -1) {
                deleteDatencheck(datencheck.prop)
            }
            return
        }

        if (!datencheck) {
            datencheck = {
                id: -1,
                newValue: resultNewValue,
                oldValue: value,
                prop: fullFieldPropName,
                toDoEmpfaenger: field?.toDoEmpfaenger ?? parent?.toDoEmpfaenger ?? "",
                genehmigungspflichtig: field?.genehmigungspflichtig ?? parent?.genehmigungspflichtig ?? false
            }
        }

        result = [{ ...datencheck, newValue: resultNewValue }];

        //betrifft nur checkboxen!!! !resultNewValue, damit man es noch auf nichts stellen kann. es soll nur geändert werden wenn es aktiviert wird
        //ALH20-576
        if (field?.counterProp) {
            const foundDatencheck = datencheckExists(fullFieldPropName.replace(field.prop, field.counterProp))
            if (foundDatencheck) {
                result = [...result, { ...foundDatencheck, newValue: !resultNewValue }]
            } else {
                const accordionValue = getValue(field.counterProp)
                result = [...result, {
                    id: -1,
                    newValue: !resultNewValue,
                    oldValue: accordionValue,
                    prop: fullFieldPropName.replace(field.prop, field.counterProp),
                    toDoEmpfaenger: field?.toDoEmpfaenger ?? parent?.toDoEmpfaenger ?? "",
                    genehmigungspflichtig: field?.genehmigungspflichtig ?? parent?.genehmigungspflichtig ?? false
                }]
            }
        }

        //console.log("resultnewValue", resultNewValue)
        setIsInvalid(false)
        updateDatenchecks(result)
    }

    const setOeffnungszeit = (splitZeiten: string[], newValue: string, index: number, field: GrunddatenDatencheckField, datencheck?: Datencheck) => {
        splitZeiten[index] = newValue

        if (index === 0 && !newValue && splitZeiten[1]) {
            splitZeiten[1] = ""
            splitZeiten[2] = ""
            splitZeiten[3] = ""
        }

        if (index === 2 && !newValue && splitZeiten[3]) {
            splitZeiten[3] = ""
        }

        let builtString = `${splitZeiten[0] ? `${splitZeiten[0] ?? ""}-${splitZeiten[1] ?? ""}` : ""}${splitZeiten[2] ? `, ${splitZeiten[2] ?? ""}-${splitZeiten[3] ?? ""}` : ""}`
        updateDatencheck(builtString, datencheck, undefined, field)
    }

    const getReadingControl = (paraValue?: any) => {
        switch (field.type) {
            case "Checkbox":
                return <InputCheckbox
                    name={field.name}
                    id={field.prop}
                    checked={typeof paraValue === "boolean" ? paraValue : paraValue === "true"}
                    onChange={(val) => { }}
                    title={field.name}
                    text={field.name}
                    disabled
                />
            case "Datum":
                return paraValue ? <Moment format="DD.MM.YYYY" >{moment.parseZone(paraValue).utc(true).format() ?? ""}</Moment> : "-"
            case "TextfeldLink":
                return paraValue ? <a href={paraValue} target="_blank" rel="noopener noreferrer">{paraValue}</a> : <>-</>
            case "Dropdown":
                switch (typeof paraValue) {
                    case "boolean":
                        return <>{paraValue ? "Ja" as string : "Nein" as string}</>
                    case "string":
                        return <>{paraValue === "true" ? "Ja" as string : "Nein" as string}</>
                }
                break;
            case "Oeffnungszeit":
                return <>{paraValue}</>
            case "Textfeld":
            case "TextfeldMultiline":
                return <>{paraValue || "-"}{field.prop === "regelbesteuerung" &&
                    <InfoIcon space="left" mode="modal" placement="right" title="Schaubild: Regelbesteuerung">
                        <div className="alh_popover">
                            <img className="alh_img100" src="https://bstportal.alhweb.de/img/RegelbesteuerungSchaubild.JPG" alt="Schaubild" />
                        </div>
                    </InfoIcon>}</>
            case "None":
                return undefined
        }
    }

    const getInputControl = (field: GrunddatenDatencheckField, parent?: GrunddatenDatencheckField) => {
        let index: number | undefined = undefined;
        let controlValue = datencheck?.newValue === undefined ? value : datencheck?.newValue
        let valueType = typeof datencheck?.newValue || typeof value

        //dann ist feld in vorwahl und rufnummer geteilt
        if (field.prop.includes("split")) {
            const result = splitDatencheckValue(controlValue?.toString() ?? "", field.prop)
            index = result.index
            controlValue = result.value
            valueType = typeof controlValue
        }

        if (field.prop.includes("include")) {
            let valueToFind = field.name
            if (field.type === "Checkbox") {
                controlValue = controlValue?.toString().includes(valueToFind)
                valueType = typeof controlValue
            }
        }

        switch (field.type) {
            case "Checkbox":
                return <InputCheckbox
                    name={field.name}
                    id={field.prop}
                    checked={valueType === "boolean" ? controlValue : controlValue === "true"}
                    onChange={(val) => updateDatencheck(val, datencheck, undefined, field, parent)}
                    title={field.name}
                    text={field.name}
                />
            case "Dropdown":
                return <SelectField
                    id={field.prop}
                    name={field.name}
                    withPleaseSelect={true}
                    value={controlValue.toString() || "false"}
                    options={field.values || []}
                    onChange={(val) => updateDatencheck(val, datencheck)}
                />
            case "TextfeldLink":
            case "Textfeld":
                return <InputField
                    key={field.prop}
                    type='textclean'
                    id={field.name}
                    name={field.name}
                    placeholder={field.name}
                    isValid={(controlValue && field.validate) ? !isInvalid : false}
                    isInvalid={isInvalid}
                    value={valueType === "string" ? controlValue as string : ''}
                    onChange={(val) => updateDatencheck(val, datencheck, index, field, parent)}
                />
            case "TextfeldMultiline":
                return <InputField
                    as="textarea"
                    key={field.prop}
                    type='textclean'
                    id={field.name}
                    name={field.name}
                    placeholder={field.name}
                    value={controlValue as string}
                    onChange={(val) => updateDatencheck(val, datencheck, index, field, parent)}
                />
            case "Oeffnungszeit":
                //nur 2 Zeiten möglich
                let splitZeiten: string[] = []
                for (const iterator of datencheck?.newValue.toString().split(",") ?? value.toString().split(",")) {
                    splitZeiten = [...splitZeiten, ...iterator.split("-").map((s: string) => s.trim())]
                }

                return <>
                    <SelectField
                        id={field.prop + "0"}
                        name={"Öffnet um"}
                        withPleaseSelect={true}
                        value={splitZeiten[0] as string ?? ""}
                        options={oeffnungszeiten.map(o => ({ key: o, value: o }))}
                        onChange={(val) => setOeffnungszeit(splitZeiten, val, 0, field, datencheck)}
                    />
                    <SelectField
                        id={field.prop + "1"}
                        name={"Schließt um"}
                        withPleaseSelect={true}
                        value={splitZeiten[1] as string ?? ""}
                        options={oeffnungszeiten.filter((_, index) => index > oeffnungszeiten.indexOf(splitZeiten[0])).map(o => ({ key: o, value: o }))}
                        onChange={(val) => setOeffnungszeit(splitZeiten, val, 1, field, datencheck)}
                        disabled={!splitZeiten[0]}
                    />
                    <SelectField
                        id={field.prop + "2"}
                        name={"Öffnet um"}
                        withPleaseSelect={true}
                        value={splitZeiten[2] as string ?? ""}
                        options={oeffnungszeiten.filter((_, index) => index > oeffnungszeiten.indexOf(splitZeiten[1])).map(o => ({ key: o, value: o }))}
                        onChange={(val) => setOeffnungszeit(splitZeiten, val, 2, field, datencheck)}
                        disabled={!splitZeiten[1]}
                    />
                    <SelectField
                        id={field.prop + "3"}
                        name={"Schließt um"}
                        withPleaseSelect={true}
                        value={splitZeiten[3] as string ?? ""}
                        options={oeffnungszeiten.filter((_, index) => index > oeffnungszeiten.indexOf(splitZeiten[2])).map(o => ({ key: o, value: o }))}
                        onChange={(val) => setOeffnungszeit(splitZeiten, val, 3, field, datencheck)}
                        disabled={!splitZeiten[2]}
                    />
                </>
            case "None":
                return undefined
        }
    }

    const clickEdit = () => {
        if (datencheck && field.validate) {
            setIsInvalid(!field.validate(datencheck.newValue))
        }
        setEdit(!edit);
    }

    const getEditControls = () => {
        if (field.children) {
            const childFields = field.children.filter(c => !c.childrenContainsOwnData).sort((a, b) => a.order - b.order)
            return childFields.map(f => getInputControl(f, field))
        } else {
            return getInputControl(field)
        }
    }

    //Spezielle Felder
    //Onlinetermine disablen solange Postbox aktiv ist
    if (field.prop === "onlinetermine" && data[accordionName]) {
        field.disabled = data[accordionName]['postboxAktiv']
    }

    //handy intern und öffentlich haken nur bearbeitbar sobald handy hinterlegt ist oder datencheck dafür erstellt ist
    if ((field.prop === "handyIntern" || field.prop === "handyOeffentlich") && data[accordionName]) {
        field.disabled = false;
        
        if(!data[accordionName]['handy']) {
            field.disabled = true

            if(datencheckExists(fullFieldPropName.replace(field.prop, "handy"))) {
                field.disabled = false
            }
        }
    }

    return <>
        <div className="myBst_Line">
            {getReadingControl(value)}
            {(!field.disabled && field.type !== "None") && <div className="alh_iconright" onClick={() => clickEdit()}><FontAwesomeIcon icon={faEdit} /></div>}
        </div>
        {(datencheck || edit) && <div className="myBst_Line">
            {edit ?
                <div className={field.children ? propsSmallInputs.find(i => field.prop.includes(i)) ? "myBst_EditControls" : "myBst_ChildrenEditControls" : ""} >{getEditControls()}</div> :
                <>
                    {getReadingControl(datencheck?.newValue)}
                    <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">{field.genehmigungspflichtig ? "Neuer Wert – Genehmigung ausstehend" : "Neuer Wert nach dem Speichern ohne Genehmigung"}</InfoIcon>
                </>
            }
        </div>}
    </>
}

export default MyBstAccordionRow
