import React, { PropsWithChildren } from "react";
import { Jumbotron as Jumbo, Container } from "react-bootstrap";
import { WaveTopBottomLoading } from "react-loadingg";

interface HeaderJumboProps extends PropsWithChildren {
    title: string;
    description: string;
}

class HeaderJumbo extends React.Component<HeaderJumboProps> {
    render() {
        return (
            <Jumbo fluid className="alh_jumbo">
                <div className="overlay">
                    <Container>
                        <h1>{this.props.title}</h1>

                        {this.props.description === "" &&
                            <div className="alhLoadingRel">
                                <WaveTopBottomLoading color="#ffc800" />
                            </div>}

                        <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
                        {this.props.children}
                    </Container>
                </div>
            </Jumbo>
        )
    }
}

export default HeaderJumbo;
