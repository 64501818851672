import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
    faEdit,
    faCheckCircle,
    faTrashAlt,
    faLevelUpAlt,
    faLevelDownAlt, faEquals, faFileDownload, faPrint
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import moment from "moment";
import 'moment/locale/de';
import InputField from "../Forms/InputField";
import SelectField from "../Forms/SelectField";
import { toast } from "react-toastify";
import { WaveTopBottomLoading } from "react-loadingg";
import UserStore from "../../stores/UserStore";
import { b64toBlob, sortList, trackEvent, trackModal } from "../ALHUtility";
import SubmitButton from "../Forms/SubmitButton";
import InfoIcon from "../Blocks/InfoIcon";
import AufgabenService from '../../services/AufgabenService';
import NotizenService from '../../services/NotizenService';

const defaultAufgabenItem = (mGNr: string | undefined) => {
    return {
        index: -1,
        bstNr: UserStore.username,
        mitgliedsnummer: mGNr ?? '',
        notizId: 0,
        titel: '',
        notiz: '',
        prio: 'Normal',
        terminDatum: "",
        terminZeit: "",
    }
}

interface Aufgabe {
    [key: string]: any
    index: number
    bstNr: string
    sachbearbeiter?: string
    mitgliedsnummer: string
    notizId: number
    refernzID?: number
    titel: string
    notiz: string
    prio: string
    terminDatum: string
    terminZeit: string
    datum?: Date | string
    dateien?: {
        fileID: string
        filename: string
    }[]
}

interface AufgabenProps {
    mGNr?: string
    mode: string
    position?: string
    name?: string
    loadOnFrontpage?: boolean
    updateNum?: (length: number) => void
    triggerReload?: () => void
}

interface AufgabenState {
    loading: boolean
    showEdit: boolean
    showEditMode: string
    mGNr: string
    items: Aufgabe[]

    sort: string
    sort_mode: string

    currentItem: Aufgabe
}

class Aufgaben extends Component<AufgabenProps, AufgabenState> {

    constructor(props: AufgabenProps) {
        super(props);

        this.state = {
            loading: true,
            items: [],
            showEdit: false,
            showEditMode: 'edit',
            mGNr: this.props.mGNr ?? "",

            sort: '',
            sort_mode: '',

            currentItem: defaultAufgabenItem(this.props.mGNr)
        }

        this.taskClose = this.taskClose.bind(this);
        this.taskShow = this.taskShow.bind(this);
        this.taskSave = this.taskSave.bind(this);
        this.checkTask = this.checkTask.bind(this);
    }

    componentDidMount() {
        this.loadAufgabenNotiz();
    }

    async loadAufgabenNotiz() {
        try {
            let result: any

            switch (this.props.mode) {
                case "aufgabenBST2HV":
                    result = await AufgabenService.loadAufgabenToHv(this.state.mGNr, this.props.loadOnFrontpage ? 0 : 1)
                    break;
                case "aufgaben":
                    result = await AufgabenService.loadAufgaben(this.state.mGNr, this.props.loadOnFrontpage ? 0 : 1)
                    break;
                case "mgnotiz":
                    result = await NotizenService.loadNotizen(this.state.mGNr, this.props.loadOnFrontpage ? 0 : 1)
                    break;
            }
            
            let currentList: Aufgabe[] = [];

            if (result && result.success) {
                if (result.data.message && result.data.message === "Unauthorized") {
                    this.setState({
                        items: []
                    });
                } else {

                    let sort = 'termin';
                    let sortMode = 'asc';

                    if (this.props.mode === "aufgaben") {
                        currentList = result.data.aufgaben;
                        sort = 'termin';
                        sortMode = 'desc';
                    } else if (this.props.mode === "aufgabenBST2HV") {
                        currentList = result.data.resources;
                        sort = 'datum';
                        sortMode = 'desc';
                    } else {
                        currentList = result.data.notizen
                        sort = 'datum';
                        sortMode = 'desc';
                    }

                    //filter list if mgn present
                    if (this.state.mGNr !== "") {

                        if (this.props.mode === "aufgaben" && !this.props.loadOnFrontpage) {
                            currentList = currentList.filter(item => item.referenzBez1 === this.state.mGNr);
                        }

                        if (this.props.updateNum) {
                            this.props.updateNum(currentList.length);
                        }

                    } else if (this.props.loadOnFrontpage) {
                        //on Front only overdue tasks
                        currentList = currentList.filter(item => moment(item.termin) < moment().add(3, 'days'));
                    }

                    this.setState({
                        items: currentList,
                        loading: false,
                        sort: sort,
                        sort_mode: sortMode,
                    });
                }
            }


        } catch (e) {
            console.log("Error in loading homemessage: " + e);
        }
    }

    setInputValue(property: string, val: string, date = false) {
        var item = this.state.currentItem;
        if (date) {
            if (moment(val).isValid()) {
                item[property] = val
            }
        } else {
            item[property] = val;
        }

        this.setState({
            currentItem: item
        })
    }

    taskShow(taskID: number, mode: string) {

        trackModal('/notiz/taskShow');

        let editItem: Aufgabe;

        let thisMGNr = this.state.mGNr;

        if (taskID === -1) {
            editItem = defaultAufgabenItem(this.state.mGNr)
        } else {
            if (mode === "aufgaben") {
                if (thisMGNr === "") {
                    thisMGNr = this.state.items[taskID].referenzBez1;
                }
                editItem = {
                    index: taskID,
                    bstNr: UserStore.username,
                    mitgliedsnummer: thisMGNr,
                    notizId: this.state.items[taskID].refernzID!,
                    titel: this.state.items[taskID].betreff,
                    notiz: this.state.items[taskID].notiz,
                    prio: this.state.items[taskID].prio,
                    terminDatum: this.state.items[taskID].termin ?? "",
                    terminZeit: "08:00",
                }
            } else {
                if (thisMGNr === "") {
                    thisMGNr = this.state.items[taskID].mitgliedsnummer;
                }
                const terDatum = `${this.state.items[taskID].terminDatum.split('.')[2] ?? ""}${this.state.items[taskID].terminDatum.split('.')[1] ?? ""}${this.state.items[taskID].terminDatum.split('.')[0] ?? ""}`
                editItem = {
                    index: taskID,
                    bstNr: UserStore.username,
                    mitgliedsnummer: thisMGNr,
                    notizId: this.state.items[taskID].notizID,
                    titel: this.state.items[taskID].titel,
                    notiz: this.state.items[taskID].notiz,
                    prio: this.state.items[taskID].prio,
                    terminDatum: terDatum,
                    terminZeit: this.state.items[taskID].terminZeit,
                }
            }
        }

        this.setState({
            showEdit: true,
            showEditMode: (taskID === -1) ? "new" : "edit",
            currentItem: editItem
        })
    }

    taskClose() {
        this.setState({
            showEdit: false
        })
    }

    async taskSave() {

        let thisItemTransmit = this.state.currentItem;

        // if(!thisItemTransmit.terminDatum)
        //     thisItemTransmit.terminDatum = moment(new Date()).format("YYYY-MM-DD")

        //Send data
        let res = await fetch(UserStore.url + '/mgnotizSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.bstNr),
                    dataset: thisItemTransmit,
                }
            })
        });

        let response = await res.json();

        if (response.success) {

            this.setState({
                showEdit: false,
            });

            await this.loadAufgabenNotiz();

        } else {

            toast.error("Es ist etwas schief gelaufen. Bitte später nochmals versuchen.", {
                autoClose: 5000
            });

        }

        if (this.props.triggerReload) {
            this.props.triggerReload();
        }
    }

    async checkTask(taskId: number, mode = 'aufgaben') {

        if (mode === 'aufgaben') {

            if (window.confirm('Diese Aufgabe wirklich als erledigt markieren?')) {
                //Send data
                let response = await AufgabenService.markAufgabeDone(this.state.items[taskId].aufgabeHandle)

                if (response.success) {
                    toast.success("Aufgabe erfolgreich erledigt! Sie können die Aufgabe trotzdem noch im Mitglied nachschlagen", {
                        autoClose: 5000
                    });
                    await this.loadAufgabenNotiz();
                } else {
                    toast.error("Aufgabe konnte nicht umgestellt werden.", {
                        autoClose: false
                    });
                }
            }

        } else if (mode === 'bst2hv') {

            if (window.confirm('Diese Aufgabe wirklich entfernen?')) {

                //Send data
                let response = await AufgabenService.deleteAufgabeToHv(this.state.items[taskId].mgNr, this.state.items[taskId].id)

                if (response.success) {

                    toast.success("Aufgabe erfolgreich entfernt.", {
                        autoClose: 5000
                    });
                    await this.loadAufgabenNotiz();

                } else {

                    toast.warning("Aufgabe konnte nicht entfernt werden. Bitte später erneut versuchen.", {
                        autoClose: 5000
                    });
                    await this.loadAufgabenNotiz();

                }

            }
        }


    }

    async deleteAufgabe(taskId: number) {
        if (window.confirm('Diese Aufgbe wirklich löschen?')) {
            //Send data
            let response = await AufgabenService.deleteAufgabe(this.state.items[taskId].aufgabeHandle);

            if (response.success) {
                toast.success("Aufgabe erfolgreich gelöscht!", {
                    autoClose: 5000
                });
                this.loadAufgabenNotiz();
            } else {
                toast.error("Aufgabe konnte nicht gelöscht werden.", {
                    autoClose: false
                });
            }
        }

    }

    async deleteNotiz(taskId: number) {
        if (window.confirm('Diese Notiz wirklich löschen?')) {

            //Send data
            let response = await NotizenService.deleteNotiz(this.state.items[taskId].bstNr, this.state.items[taskId].mitgliedsnummer, this.state.items[taskId].notizID)

            if (response.success) {
                toast.success("Notiz erfolgreich gelöscht!", {
                    autoClose: 5000
                });
                this.loadAufgabenNotiz();
            } else {
                toast.error("Notiz konnte nicht gelöscht werden.", {
                    autoClose: false
                });
            }
        }

    }

    async downloadFile(fileId: string, filename: string) {

        //show working message and inactivate Button
        const toastId = toast.dark(<div><FontAwesomeIcon icon={faPrint} /> Dokument wird erzeugt,<br />einen Moment bitte...</div>, {
            autoClose: false
        });

        let response = await AufgabenService.downloadFile(fileId);
        let base64 = response.data.dokument;
        const blob = b64toBlob(base64, "application/octet-stream");

        toast.dismiss(toastId);

        const link = document.createElement("a");
        link.setAttribute('target', '_blank')
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }

    sortClick(spalte: string) {
        let newSort_mode = this.state.sort_mode;
        let newSort = this.state.sort;

        if (spalte === this.state.sort) {
            switch (this.state.sort_mode) {
                case 'asc':
                    newSort_mode = 'desc';
                    break;
                default:
                    newSort_mode = 'asc';
                    break;
            }
        } else {
            newSort = spalte;
        }

        this.setState({
            sort: newSort,
            sort_mode: newSort_mode,
        })
    }

    render() {

        const { mGNr = "" } = this.state

        let list = sortList(this.state.sort, this.state.items, this.state.sort_mode, false, '', 'titel');

        return (
            <div>
                {(this.props.mode === "mgnotiz" && this.props.position !== "home") &&
                    <div>
                        <h3 className="alh_pageheader">Notizen bei diesem Mitglied <FontAwesomeIcon onClick={() => this.thisPrint()} icon={faPrint} title="Drucken" className="faButton faButtonSpaceright alh_noprint" /></h3>
                        <div className="alh_subheader">Hier können Sie Notizen für Ihre Beratungsstelle erstellen. <span className="alh_bold">Diese werden nicht an
                            die Hauptverwaltung übermittelt.</span> Nutzen Sie hierzu bitte die Funktion „Notiz zur Abrechnung“
                            unter den Buchungen.</div>

                        {UserStore.hasBerechtigung('mg_notiz') &&
                            <SubmitButton
                                type='button'
                                onClick={() => this.taskShow(-1, this.props.mode)}
                                text="Notiz erstellen"
                                size="sm"
                            />
                        }
                        <br />
                    </div>
                }

                {(mGNr === "" && this.props.mode === "aufgaben") &&
                    <h3 className="alh_pageheader alh_pageheader_topspace">Meine Aufgaben</h3>
                }
                {(mGNr === "" && this.props.mode === "aufgabenBST2HV") &&
                    <h3 className="alh_pageheader alh_pageheader_topspace">Aktuelle Aufgaben an Hauptverwaltung</h3>
                }

                {(this.props.mode === "aufgaben") &&
                    <Table striped bordered hover size="sm" className="alhTable">
                        <thead>
                            <tr className="alh_notizaufgaben">
                                <th onClick={() => this.sortClick('termin')} className={((this.state.sort === 'termin') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alh_notizaufgabenDatum"}>Termin</th>
                                <th onClick={() => this.sortClick('betreff')} className={((this.state.sort === 'betreff') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alh_notizaufgabenTitle"}>Betreff</th>
                                <th onClick={() => this.sortClick('bearbeiter')} className={((this.state.sort === 'bearbeiter') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alh_mobileHide"}>Bearbeiter</th>
                                <th className={(mGNr !== "") ? "d-none" : ""}>Referenz</th>
                                <th onClick={() => this.sortClick('prio')} className={(this.state.sort === 'prio') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Prio</th>
                                <th className="alh_notizaufgabenAction alh_noprint">&nbsp;</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.items.map((aufgabe, index) => (
                                <tr key={aufgabe.aufgabeHandle}>
                                    <td><Moment format="DD.MM.YYYY">{aufgabe.termin}</Moment></td>
                                    <td>{aufgabe.betreff} <InfoIcon space="left" placement="right">{aufgabe.notiz}</InfoIcon>
                                        {aufgabe.dateien?.map((datei, index) => (
                                            <InfoIcon key={index} icon={faFileDownload} space="left" placement="right" onClick={() => this.downloadFile(datei.fileID, datei.filename)}>Download {datei.filename}</InfoIcon>
                                        ))}
                                    </td>
                                    <td className="alh_mobileHide">{aufgabe.bearbeiter}</td>
                                    <td className={(mGNr !== "") ? "d-none" : ""}><a href={`/mitglied/${aufgabe.referenzBez1}`}>{aufgabe.referenzBez1}</a> <span className="alh_bold">{aufgabe.referenzBez2}</span></td>
                                    <td className="alh_table_prio"><div title={aufgabe.prio}><FontAwesomeIcon icon={(aufgabe.prio === "Hoch") ? faLevelUpAlt : ((aufgabe.prio === "Normal") ? faEquals : faLevelDownAlt)} /></div></td>
                                    <td className="tableActions alh_noprint">
                                        {UserStore.hasBerechtigung('bst_mg', 'write') &&
                                            <>
                                                {this.props.loadOnFrontpage && <FontAwesomeIcon onClick={() => this.checkTask(index)} icon={faCheckCircle} title="Erledigt" className="faButton faButtonSpaceright" />}
                                                {aufgabe.referenzArt === "MGNotiz" &&
                                                    <FontAwesomeIcon onClick={() => this.taskShow(index, this.props.mode)} icon={faEdit} title="Anzeigen" className="faButton faButtonSpaceright" />
                                                }
                                                <FontAwesomeIcon onClick={() => this.deleteAufgabe(index)} icon={faTrashAlt} title="Löschen" className="faButton" />
                                            </>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }

                {(this.props.mode === "aufgabenBST2HV") &&
                    <Table striped bordered hover size="sm" className="alhTable">
                        <thead>
                            <tr className="alh_notizaufgaben">
                                <th onClick={() => this.sortClick('datum')} className={((this.state.sort === 'datum') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alh_notizaufgabenDatum"}>Datum</th>
                                <th onClick={() => this.sortClick('notiz')} className={((this.state.sort === 'notiz') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alh_notizaufgabenTitle"}>Aufgabe</th>
                                <th >Sachbearbeiter</th>
                                <th >Status</th>
                                <th className={(mGNr !== "") ? "d-none" : ""}>Referenz</th>
                                <th className="alh_notizaufgabenAction alh_noprint">&nbsp;</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.items.map((aufgabe, index) => (
                                <tr key={aufgabe.id}>
                                    <td><Moment format="DD.MM.YYYY">{aufgabe.datum}</Moment></td>
                                    <td>{aufgabe.notiz}</td>
                                    <td>{aufgabe.sachbearbeiter}</td>
                                    <td>{aufgabe.istErledigtHV === -1 ? "erledigt" : "offen"}</td>
                                    <td className={(mGNr !== "") ? "d-none" : ""}><a href={`/mitglied/${aufgabe.mgNr}`}>{aufgabe.mgNr}</a> <span className="alh_bold">{aufgabe.mgName}, {aufgabe.mgVorname}</span></td>
                                    <td className="tableActions alh_noprint">
                                        {(UserStore.hasBerechtigung('mg_notiz') && aufgabe.istErledigtHV === -1) &&
                                            <FontAwesomeIcon onClick={() => this.checkTask(index, 'bst2hv')} icon={faCheckCircle} title="Aufgabe entfernen" className="faButton" />
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }

                {(this.props.mode === "mgnotiz") &&
                    <Table striped bordered hover size="sm" className="alhTable">
                        <thead>
                            <tr className="alh_notizaufgaben">
                                <th onClick={() => this.sortClick('datum')} className={((this.state.sort === 'datum') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alh_notizaufgabenDatum"}>Datum</th>
                                <th onClick={() => this.sortClick('terminDatum')} className={((this.state.sort === 'terminDatum') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alh_notizaufgabenDatum"}>Termin</th>
                                <th className="alh_notizaufgabenTitle">Betreff</th>
                                <th>Notiz</th>
                                <th onClick={() => this.sortClick('prio')} className={(this.state.sort === 'prio') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Prio</th>
                                <th className="alh_notizaufgabenAction alh_noprint">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((notiz, index) => (
                                <tr key={notiz.notizID}>
                                    <td><Moment format="DD.MM.yyyy">{notiz.datum}</Moment></td>
                                    <td>{notiz.terminDatum}</td>
                                    <td>{notiz.titel}</td>
                                    <td>{notiz.notiz}</td>
                                    <td className="alh_table_prio"><div title={notiz.prio}><FontAwesomeIcon icon={(notiz.prio === "Hoch") ? faLevelUpAlt : ((notiz.prio === "Normal") ? faEquals : faLevelDownAlt)} /></div></td>
                                    <td className="tableActions alh_noprint">
                                        {UserStore.hasBerechtigung('mg_notiz') &&
                                            <>
                                                <FontAwesomeIcon onClick={() => this.taskShow(index, this.props.mode)} icon={faEdit} title="Bearbeiten" className="faButton" />
                                                <FontAwesomeIcon onClick={() => this.deleteNotiz(index)} icon={faTrashAlt} title="Löschen" className="faButton" />
                                            </>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }


                {this.state.items && this.state.items.length === 0 && !this.state.loading &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>
                }

                <Modal
                    show={this.state.showEdit}
                    onHide={this.taskClose}
                    backdrop="static"
                    keyboard={true}
                    ref={React.createRef()}
                >
                    <Modal.Header closeButton>
                        {this.state.showEditMode === 'edit' &&
                            <Modal.Title>Notiz bearbeiten/anzeigen</Modal.Title>
                        }
                        {this.state.showEditMode === 'new' &&
                            <Modal.Title>Notiz erstellen</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showEditMode === 'edit' &&
                            <div>Hier können Sie die Notiz bearbeiten/anzeigen:</div>
                        }
                        {this.state.showEditMode === 'new' &&
                            <div>Hier können Sie eine neue Notiz für dieses Mitglied erstellen. <br /><span className="alh_bold">Tipp:</span> Erfassen Sie ein Datum, wird die Notiz zum Termin auf der Home-Seite angezeigt.</div>
                        }
                        <br />
                        <br />

                        <div>Betreff:</div>
                        <InputField
                            type='textclean'
                            id='aBetreff'
                            name='aBetreff'
                            placeholder='Betreff'
                            value={this.state.currentItem['titel']}
                            onChange={(val) => this.setInputValue('titel', val)}
                        />

                        <div>Notiz:</div>
                        <InputField
                            as='textarea'
                            type='textclean'
                            id='aNotiz'
                            name='aNotiz'
                            placeholder='Notiz'
                            value={this.state.currentItem['notiz']}
                            onChange={(val) => this.setInputValue('notiz', val)}
                        />

                        <div>Prio:</div>
                        <SelectField
                            id='aPrio'
                            name='aPrio'
                            withPleaseSelect={false}
                            value={this.state.currentItem['prio']}
                            options={
                                [
                                    { id: 'Normal', value: 'Normal' },
                                    { id: 'Hoch', value: 'Hoch' }
                                ]
                            }
                            onChange={(val) => this.setInputValue('prio', val)}
                        />

                        <div>Termindatum:</div>
                        <InputField
                            type='date'
                            id='aTermin'
                            name='aTermin'
                            placeholder='Termin'
                            value={(this.state.currentItem.terminDatum && new Date(this.state.currentItem.terminDatum).getFullYear() !== 1899) ?
                                (moment(this.state.currentItem.terminDatum).isValid() ? moment(this.state.currentItem.terminDatum).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")) : ""
                            }
                            onChange={(val) => this.setInputValue('terminDatum', val, true)}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.taskClose}>
                            Schliessen
                        </Button>
                        {(this.props.mode !== "aufgaben") &&
                            <Button variant="primary" onClick={this.taskSave}>
                                Speichern
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }


    thisPrint() {

        trackEvent('Aufgaben', 'Print', this.props.name);
        window.print();

    }
}

export default Aufgaben;
