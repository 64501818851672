import React from "react";
import HeaderJumbo from "../HeaderJumbo";
import UserStore from "../../stores/UserStore";
import Aufgaben from "../ListComponents/Aufgaben";
import { Col, Container, Row } from "react-bootstrap";
import Uploads from "./Uploads";
import VerwaltungstippsBox from "../VerwaltungstippsBox";
import DownloadSlider from "../DownloadSlider";
import Mitgliederliste from "../ListComponents/Mitgliederliste";
import { HomedataService } from "../../services/HomedataService";

interface HomeProps { }

interface HomeState {
    loading: boolean
    nameshow: string
    username: string
    welcometext: string
}

class Home extends React.Component<HomeProps, HomeState> {

    constructor(props: HomeProps) {
        super(props);

        this.state = {
            loading: true,
            nameshow: '',
            username: UserStore.username,
            welcometext: '',
        }
    }

    async componentDidMount() {
        try {
            let result = await HomedataService.getHomedata()

            if (result && result.success) {
                this.setState({
                    welcometext: result.welcometext,
                    nameshow: (result.anrede !== null ? result.anrede + " " : "") + result.name,
                    loading: false,
                });

                UserStore.name = result.name;
                UserStore.anrede = result.anrede;
                UserStore.mandantId = result.mandantId;
                UserStore.bstNr = result.bstNr + "";
            }

        } catch (e) {
            console.log("Error in loading homemessage: " + e);
        }

        document.title = 'Home | ' + UserStore.title;
    }

    render() {
        return (
            <React.Fragment>
                <HeaderJumbo title={"Willkommen " + this.state.nameshow} description={this.state.welcometext} />

                <Container className="alh_container_home">
                    <Row className="alh_rowTopList">
                        {UserStore.hasBerechtigung('bst_uploads') &&
                            <Col lg={4}>
                                <div className="alh_frontBoxes">
                                    <Uploads mode="small" />
                                </div>
                            </Col>
                        }

                        <Col lg={UserStore.hasBerechtigung('bst_uploads') ? 4 : 6}>
                            <div className="alh_frontBoxes">
                                <VerwaltungstippsBox />
                            </div>
                        </Col>
                        <Col lg={UserStore.hasBerechtigung('bst_uploads') ? 4 : 6}>
                            <div className="alh_frontBoxes">
                                <DownloadSlider />
                            </div>
                        </Col>
                    </Row>
                </Container>

                {UserStore.hasBerechtigung('bst_mg') &&
                    <Container className="alh_container_home">
                        <Row className="alh_rowTopList">
                            <Col lg={12}>
                                <div className="alh_frontBoxes maxHeight">
                                    <Aufgaben mode="aufgaben" position="home" loadOnFrontpage={true} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }

                {UserStore.hasBerechtigung('bst_mg') &&
                    <Container className="alh_container_home">
                        <Row className="alh_rowTopList">
                            <Col lg={12}>
                                <div className="alh_frontBoxes maxHeight">
                                    <Aufgaben mode="aufgabenBST2HV" position="home" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }

                {UserStore.hasBerechtigung('extras_blanko') &&
                    <Container className="alh_container_home">
                        <Row className="alh_rowTopList">
                            <Col lg={12}>
                                <div className="alh_frontBoxes maxHeight">
                                    <Mitgliederliste mode="blanko" withInfo={false} withAdd={false} />
                                </div>
                            </Col>
                        </Row>
                    </Container>

                }
            </React.Fragment>
        );
    }
}

export default Home;
