import React, { Component } from 'react';
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import UserStore from "../../stores/UserStore";
import { WaveTopBottomLoading } from "react-loadingg";
import TableOperatorOptions from "../Blocks/TableOperatorOptions";
import { sortList } from "../ALHUtility";
import moment from "moment";
import Buchung from '../../models/Buchung';
import KeyValue from '../../models/KeyValue';

interface BuchungsjournalProps { }

interface BuchungsjournalState {
    loading: boolean
    buchungen: Buchung[]
    currentYear: number
    currentMonth: number
    valuesYear: string
    gesamtoffen?: number
    valuesMonth: KeyValue[]
    search: string
    sort: string
    sort_mode: string
}

class Buchungsjournal extends Component<BuchungsjournalProps, BuchungsjournalState> {

    constructor(props: BuchungsjournalProps) {
        super(props);

        this.state = {
            loading: true,
            buchungen: [],
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth() + 1,
            valuesYear: "2000-" + new Date().getFullYear(),
            valuesMonth: [
                { id: 1, value: 'Januar' },
                { id: 2, value: 'Februar' },
                { id: 3, value: 'März' },
                { id: 4, value: 'April' },
                { id: 5, value: 'Mai' },
                { id: 6, value: 'Juni' },
                { id: 7, value: 'Juli' },
                { id: 8, value: 'August' },
                { id: 9, value: 'September' },
                { id: 10, value: 'Oktober' },
                { id: 11, value: 'November' },
                { id: 12, value: 'Dezember' },
            ],
            search: '',
            sort: 'leistungsdatum',
            sort_mode: 'desc',
        }

        document.title = 'Buchungsjournal (...) | ' + UserStore.title;
    }

    async loadList(loadAllYears?: boolean, loadAllMonths?: boolean) {

        this.setState({
            loading: true,
            buchungen: []
        });

        try {
            let res = await fetch(UserStore.url + '/bstbujournal', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        Monat: loadAllMonths ? 0 : this.state.currentMonth,
                        Jahr: loadAllYears ? 0 : this.state.currentYear,
                        BuchungHandle: 0
                    }
                })
            });

            let result = await res.json();

            if (result && result.success) {
                this.setState({
                    buchungen: result.data.buchungen,
                    gesamtoffen: result.data.gesamtbeitragOffen,
                    loading: false
                });
            }

            document.title = 'Buchungsjournal (' + result.data.buchungen.length + ') | ' + UserStore.title;

        } catch (e) {
            console.log("Error in loading Buchungsjournal: " + e);
        }
    }

    async componentDidMount() {
        this.loadList();
    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    changeSearch(val: string) {
        this.setState({
            search: val
        })
    }

    changeMonth(val: number) {
        this.setState({
            currentMonth: val
        })

        setTimeout(() => {
            this.loadList(this.state.currentYear === -1, val === -1);
        }, 500);
    }

    changeYear(val: number) {
        this.setState({
            currentYear: val
        })

        setTimeout(() => {
            this.loadList(val === -1, this.state.currentMonth === -1);
        }, 500);
    }

    sortClick(spalte: string) {
        let newSort_mode = this.state.sort_mode;
        let newSort = this.state.sort;

        if (spalte === this.state.sort) {
            switch (this.state.sort_mode) {
                case 'asc':
                    newSort_mode = 'desc';
                    break;
                default:
                    newSort_mode = 'asc';
                    break;
            }
        } else {
            newSort = spalte;
        }

        this.setState({
            sort: newSort,
            sort_mode: newSort_mode,
        })
    }

    render() {
        let list = sortList(this.state.sort, this.state.buchungen, this.state.sort_mode);
        let search = this.state.search;
        list = list.filter((buchung) => {
            return (
                buchung.buchungstext?.toLowerCase().includes(search.toLowerCase()) ||
                buchung.mgName?.toLowerCase().includes(search.toLowerCase()) ||
                buchung.vorgangsart?.toLowerCase().includes(search.toLowerCase()) ||
                buchung.zahlungsweise?.toLowerCase().includes(search.toLowerCase()) ||
                moment(buchung.leistungsdatum).format('DD.MM.YYYY').includes(search.toLowerCase()) ||
                buchung.mgNr?.toLowerCase().includes(search.toLowerCase())
            );
        });

        return (
            <div>
                <h3 className="alh_pageheader">Buchungsjournal</h3>
                <div className="alh_subheader">Sehen Sie hier das komplette Buchungsjournal.</div>

                <TableOperatorOptions
                    name="Buchungsjournal"
                    placeholder="Suche nach Nummer, Name, Datum..."
                    onChange={(val) => this.changeSearch(val)}
                    onChangeMonth={(val) => this.changeMonth(Number(val))}
                    onChangeYear={(val) => this.changeYear(Number(val))}
                    valueMonth={this.state.currentMonth}
                    valueYear={this.state.currentYear}
                    valuesMonth={this.state.valuesMonth}
                    valuesYear={this.state.valuesYear}
                    print={true}
                />

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th onClick={() => this.sortClick('mgNr')} className={(this.state.sort === 'mgNr') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Nr.</th>
                            <th onClick={() => this.sortClick('mgName')} className={(this.state.sort === 'mgName') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Name</th>
                            <th onClick={() => this.sortClick('buchungsdatum')} className={(this.state.sort === 'buchungsdatum') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>B-Datum</th>
                            <th onClick={() => this.sortClick('leistungsdatum')} className={(this.state.sort === 'leistungsdatum') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>L-Datum</th>
                            <th onClick={() => this.sortClick('buchungstext')} className={(this.state.sort === 'buchungstext') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Buchungstext</th>
                            <th onClick={() => this.sortClick('soll')} className={((this.state.sort === 'soll') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + "text-right"}>Soll</th>
                            <th onClick={() => this.sortClick('haben')} className={((this.state.sort === 'haben') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + "text-right"}>Haben</th>
                            <th onClick={() => this.sortClick('zahlungsweise')} className={(this.state.sort === 'zahlungsweise') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Zahlungsweise</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((mg, index) => (
                            <tr key={index}>
                                <td><a href={`/mitglied/${mg.mgNr}`}>{mg.mgNr}</a></td>
                                <td>{mg.mgName}</td>
                                <td><Moment format="DD.MM.YYYY">{mg.buchungsdatum}</Moment></td>
                                <td><Moment format="DD.MM.YYYY">{mg.leistungsdatum}</Moment></td>
                                <td>{mg.buchungstext}</td>
                                <td className="text-right">{(mg.soll !== null) ? this.numberFormat(mg.soll) : ""}</td>
                                <td className="text-right">{(mg.haben !== null) ? this.numberFormat(mg.haben) : ""}</td>
                                <td>{mg.zahlungsweise}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}

                {this.state.buchungen && this.state.buchungen.length === 0 && !this.state.loading &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }

            </div>
        );
    }
}

export default Buchungsjournal;