import React, { Component } from 'react';
import { Row, Col, Container, Form, ProgressBar, Alert } from "react-bootstrap";
import UserStore from "../../stores/UserStore";
import AccordeonBP from "../Blocks/AccordeonBP";
import SubmitButton from "../Forms/SubmitButton";
import { faCheckCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WaveTopBottomLoading } from "react-loadingg";
import SelectField from "../Forms/SelectField";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import Frage, { AenderungsNotizen, Antwort, Fragengruppe, FrageDto } from '../../models/Frage';
import InputField from '../Forms/InputField';
import ConfirmModal from '../Blocks/ConfirmModal';
import ChecklistItem, { UnterschriftCheckliste } from '../../models/ChecklistItem';
import InputCheckbox from '../Forms/InputCheckbox';
import Moment from 'react-moment';
import BeratungsprotokollItem from '../../models/Beratungsprotokoll';
import MitgliedService from '../../services/MitgliedService';
import BeratungsprotkollFehlendeBelegeModal from './BeratungsprotokollFehlendeBelegeModal';

const defaultUnterschriftenDocs = [
    { docName: "BE", bezeichnung: "Beitrittserklärung Unterschrift (bei Ehepartnern beide)" },
    { docName: "VE", bezeichnung: "Vollständigkeitserklärung Unterschrift (bei Ehepartnern beide)" },
    { docName: "VM", bezeichnung: "Vollmacht Unterschriften" }
]

interface BeratungsprotokollProps {
    mGNr?: string
    mGName?: string
    active?: boolean
    emailMissing?: boolean
    beUnterschriftVorhanden: boolean
    vmUnterschriftVorhanden: boolean
}

interface BeratungsprotokollState {
    mGNr?: string
    mGName?: string

    showSaveModal: boolean
    showFehlendeBelege: boolean
    tempShowFehlendeBelege: boolean

    previewEmailBody: string

    active?: boolean
    bpFragen: Fragengruppe[],
    historyBP: BeratungsprotokollItem[],
    allDone: boolean,

    currentBPSaveStatus: number,
    currentBPID: number,

    loading: boolean

    validated?: boolean

    bpVZ: number,
    bpVZs: BeratungsprotokollItem[],

    checklistItems: ChecklistItem[],
    prevChecklistItems: ChecklistItem[],
    bpChangedAfterSave: boolean

    defaultTabIndex?: number

    //Für NotizModal
    showNotizModal: boolean
    currentFrage: Frage
    saveNotizLoading: boolean
    fragengruppeId: number
    saveStatus: number
}

class Beratungsprotokoll extends Component<BeratungsprotokollProps, BeratungsprotokollState> {

    constructor(props: BeratungsprotokollProps) {
        super(props);

        this.state = {
            mGNr: this.props.mGNr,
            mGName: this.props.mGName,

            showSaveModal: false,
            showFehlendeBelege: false,
            tempShowFehlendeBelege: false,

            active: false,
            bpFragen: [],
            historyBP: [],
            allDone: false,

            currentBPSaveStatus: -1,
            currentBPID: 0,

            loading: true,

            previewEmailBody: "",

            bpVZ: -1,
            bpVZs: [],

            checklistItems: [],
            prevChecklistItems: [],
            bpChangedAfterSave: false,

            showNotizModal: false,
            currentFrage: {
                id: -1,
                notiz: '',
                tempAenderNotiz: '',
                fragenAenderungNotizen: [],
            },
            saveNotizLoading: false,
            fragengruppeId: -1,
            saveStatus: -1
        }

        this.showSaveModal = this.showSaveModal.bind(this);
        this.closeSaveModal = this.closeSaveModal.bind(this);
        this.saveBP = this.saveBP.bind(this);
        this.updateDropdownTexte = this.updateDropdownTexte.bind(this);

    }

    componentDidMount() {
        window.addEventListener('scroll', this.isSticky);
    }

    isSticky() {

        const refEl = document.querySelector('.fade.tab-pane.active.show');
        const stickyEl = document.querySelector('.alhStickyDiv');
        const scrollTop = window.scrollY;

        if (stickyEl && refEl) {
            if (stickyEl !== null && scrollTop >= (refEl.getBoundingClientRect().top + 140)) {
                stickyEl.classList.add('is-sticky');
            } else {
                stickyEl.classList.remove('is-sticky');
            }
        }

    }

    setBpVzOutdated(value: boolean) {
        this.setState({
            bpVZs: this.state.bpVZs.map(bp => {
                if (bp.id === this.state.bpVZ)
                    return { ...bp, outdated: value }
                else
                    return bp
            })
        })
    }

    async setInputValue(property: string, val: string) {

        switch (property) {
            case 'bpVZ':
                //If Year changed => load data and prepare
                this.setState({
                    loading: true,
                })

                //check if outdated => reload history
                let outdated = this.state.bpVZs.find(obj => obj.id === Number(val))?.outdated

                let thisHistoryBP = this.state.historyBP;
                if (outdated) {
                    thisHistoryBP = await this.loadHistoricBPs();
                }

                //check for historic BP
                let currentBPLoad = thisHistoryBP.find(obj => {
                    return obj.jahr === Number(val);
                });

                let currentSaveStatus = -1;
                let currentBPID = 0;
                if (currentBPLoad !== undefined) {
                    //fill fields
                    await this.loadKatalog(currentBPLoad.antworten, currentBPLoad.antwortenNotizen, currentBPLoad.dokumenteFehlendeUnterschriften, currentBPLoad.bpVersion);
                    currentSaveStatus = currentBPLoad.saveStatus ?? -1;
                    currentBPID = currentBPLoad.protID ?? 0;

                } else {
                    //fill default fields
                    await this.loadKatalog();
                }

                this.setState({
                    bpVZ: Number(val),
                    loading: false,
                    currentBPSaveStatus: currentSaveStatus,
                    currentBPID: currentBPID,
                    bpChangedAfterSave: false
                })
                break;
            case 'notiz':
                this.setState({
                    historyBP: [
                        ...this.state.historyBP.filter(b => b.jahr !== Number(this.state.bpVZ)),
                        { ...this.state.historyBP.find(b => b.jahr === Number(this.state.bpVZ))!, notiz: val }
                    ],
                    bpChangedAfterSave: this.state.currentBPSaveStatus === 2 //wenn BP abgeschlossen ist und nochmals was an der Notiz geändert wird
                })
                break;
        }
    }

    async componentDidUpdate(prevProps: BeratungsprotokollProps, prevState: BeratungsprotokollState) {
        //Init Component if active clicked
        if (this.props.active !== prevProps.active && this.props.active) {
            await this.loadHistoricBPs();

            this.setState({
                active: true,
                loading: false,
            });
        }
    }

    generateChecklistItems(fragengruppe: Fragengruppe): ChecklistItem[] {
        return fragengruppe.fragen.filter(f => f.belegFehlt).map(f => {
            return {
                frage: f,
                taskFulfilled: f.belegNachgereicht ?? false,
                taskText: 'Dokument erhalten'
            }
        }) ?? []
    }

    generateDefaultUnterschriftenCl(dokumenteFehlendeUnterschriften: UnterschriftCheckliste[]) {
        let defaultItems = defaultUnterschriftenDocs.map(ud => {
            let fulfilled = dokumenteFehlendeUnterschriften?.find(dfu => dfu.docName === ud.docName)?.docNachgereicht ?? false

            switch (ud.docName) {
                case "BE":
                    fulfilled = this.props.beUnterschriftVorhanden
                    break;
                case "VM":
                    fulfilled = this.props.vmUnterschriftVorhanden
                    break;
            }

            return {
                taskFulfilled: fulfilled,
                taskDoc: ud,
                taskText: 'Unterschrift(en) erhalten'
            }
        })

        return defaultItems
    }

    async loadKatalog(historyBPAntworten: Antwort[] = [], historyBPAenderungsNotizen: AenderungsNotizen[] = [], dokumenteFehlendeUnterschriften: UnterschriftCheckliste[] = [], bpVersion: string = "") {

        //Load questions
        let resMa = await fetch(UserStore.url + '/beratungsprotokoll', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    bpVersion: bpVersion
                }
            })
        });

        let resultBP = await resMa.json();
        let thisFragen: Fragengruppe[] = [];
        let checklist: ChecklistItem[] = this.generateDefaultUnterschriftenCl(dokumenteFehlendeUnterschriften);

        if (resultBP && resultBP.success) {
            const resources: FrageDto[] = resultBP.data.resources
            let changeSortierung = false;
            resources.forEach((item, index) => {
                let sortierung = item.gruppeSortierung

                if (index === 0)
                    changeSortierung = item.gruppeSortierung !== 0

                if (changeSortierung)
                    sortierung--;

                //create Fragengruppe
                if (!(thisFragen[sortierung])) {
                    thisFragen[sortierung] = {
                        countFragen: 0,
                        id: sortierung,
                        name: item.gruppe,
                        fragen: [],
                        allNo: false,
                        openBody: false
                    };
                }

                //find current history Answer
                let currentAnswer = historyBPAntworten.find(obj => obj.frageId === item.frageID);

                let aenderungNotizen = historyBPAenderungsNotizen.filter(obj => (obj.frageId === item.frageID));

                //process this Frage
                thisFragen[sortierung].countFragen += 1;
                thisFragen[sortierung].fragen.push({
                    frageName: item.fragetext,
                    frageSortierung: item.frageSortierung,
                    id: item.frageID,
                    fragenAnswer: ((currentAnswer !== undefined && currentAnswer.antwortOption !== null) ? 1 : ((currentAnswer !== undefined && currentAnswer.antwort !== null) ? (currentAnswer.antwort ? 1 : 2) : -1)), //not yet answered
                    notiz: currentAnswer?.notiz ? currentAnswer.notiz : '',
                    fragenAenderungNotizen: aenderungNotizen,
                    optionen: item.beratungsprotokollFragenOptionen,
                    fragenOptionenAnswer: ((currentAnswer !== undefined && currentAnswer.antwortOption !== null) ? currentAnswer.antwortOption : ''),
                    belegFehlt: currentAnswer?.dokumentVorhanden === undefined ? undefined : !currentAnswer.dokumentVorhanden,
                    belegFehltNotiz: currentAnswer?.dokumentFehltNotiz,
                    belegNachgereicht: currentAnswer?.dokumentNachgereicht,
                    bpVersion: item.bpVersion
                });

            });
            for (const fragengruppe of thisFragen) {
                checklist = [...checklist, ...this.generateChecklistItems(fragengruppe)]
            }

            this.setState({
                bpFragen: thisFragen,
                allDone: false,
                checklistItems: checklist,
            });

        }

    }

    async loadHistoricBPs() {

        //Load questions
        let resMa = await fetch(UserStore.url + '/beratungsprotokollMG', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    MGNr: this.state.mGNr,
                }
            })
        });

        let resultBP = await resMa.json();

        if (resultBP && resultBP.success) {

            let currentYears: BeratungsprotokollItem[] = [];
            resultBP.data.resources.map((item: { jahr: number, saveStatus: number, bpVersion: string }) => {
                currentYears.push({
                    id: item.jahr,
                    value: 'VZ ' + item.jahr + ' (' + (item.saveStatus === 2 ? 'Final' : 'in Bearbeitung') + ')',
                    outdated: false,
                    bpVersion: item.bpVersion
                })
                return true;
            });

            //check Dropdpwn last 5 Years
            for (let i = (new Date().getFullYear() - 4); i <= (new Date().getFullYear() - 1); i++) {
                if (!currentYears.some(item => item.id === i)) {
                    currentYears.push({ id: i, value: 'VZ ' + i, outdated: false });
                }
            }
            currentYears.sort((a, b) => a.id < b.id ? 1 : -1);

            //prepare data
            const historyBp = resultBP.data.resources.map((r: any) => {
                if (r.dokumenteFehlendeUnterschriften)
                    r = { ...r, dokumenteFehlendeUnterschriften: JSON.parse(r.dokumenteFehlendeUnterschriften) }
                return r
            })

            this.setState({
                historyBP: historyBp,
                bpVZs: currentYears
            });

            return historyBp;
        }

        return false;
    }

    calcReady() {

        let thisfragenGruppen: { cntFragen: number, cntReady: number }[] = [];
        let cntCompleteReady = 0;
        let cntCompleteFragen = 0;

        this.state.bpFragen.map((fragenGruppe) => {

            return fragenGruppe.fragen.map((aFrage) => {

                //check complete
                cntCompleteFragen++;
                if (aFrage.fragenAnswer! > 0) {
                    cntCompleteReady++;
                }

                //check Gruppe
                if (!thisfragenGruppen[fragenGruppe.id]) {
                    thisfragenGruppen[fragenGruppe.id] = {
                        cntFragen: 0,
                        cntReady: 0,
                    }
                }
                let tmpCnt = {
                    cntReady: ((aFrage.fragenAnswer! > 0) ? (thisfragenGruppen[fragenGruppe.id].cntReady + 1) : thisfragenGruppen[fragenGruppe.id].cntReady),
                    cntFragen: thisfragenGruppen[fragenGruppe.id].cntFragen + 1,
                }
                thisfragenGruppen[fragenGruppe.id] = tmpCnt;

                return true;
            });
        });

        let result = {
            percComplete: Number(((cntCompleteReady / cntCompleteFragen) * 100).toFixed(0)),
            fragen: thisfragenGruppen,
        }

        if (result.percComplete === 100 && !this.state.allDone) {
            this.setState({
                allDone: true,
            });
        } else if (result.percComplete !== 100 && this.state.allDone) {
            this.setState({
                allDone: false,
            });
        }

        return result;
    }

    toggleAccordeon(gruppenId: number, saveBP: boolean) {
        const clickedGruppe = this.state.bpFragen.find(f => f.id === gruppenId)
        if (clickedGruppe) {
            let fragengruppen = this.state.bpFragen

            fragengruppen = fragengruppen.map(f => {
                if (f.id === clickedGruppe.id) {
                    f.openBody = !f.openBody
                }
                return f
            })

            this.setState({
                fragengruppeId: gruppenId,
                bpFragen: fragengruppen
            })
        }

        if (saveBP)
            this.saveBP()
    }

    showSaveModal() {
        this.setState({
            showSaveModal: true,
        })

    }

    closeSaveModal() {
        this.setState({
            showSaveModal: false,
        })

    }

    getAntwortenForRequest(resultChecklistItems: ChecklistItem[] = []) {
        let thisResult: Antwort[] = [];
        this.state.bpFragen.forEach((bpItem) => {
            bpItem.fragen.forEach((frage) => {
                const checklistItem = resultChecklistItems.find(ci => ci.frage?.id === frage.id)
                let dokumentVorhanden: boolean | undefined = undefined

                if (resultChecklistItems.length && checklistItem) {
                    dokumentVorhanden = checklistItem.taskFulfilled;
                }

                if ((resultChecklistItems.length && checklistItem) || resultChecklistItems.length === 0) {
                    thisResult.push({
                        frageId: frage.id,
                        antwort: ((frage.fragenOptionenAnswer !== '') ? null : (frage.fragenAnswer === 1) ? true : ((frage.fragenAnswer === 2) ? false : null)),
                        antwortOption: ((frage.fragenOptionenAnswer !== '') ? frage.fragenOptionenAnswer : null),
                        notiz: frage.notiz,
                        dokumentVorhanden: frage.belegFehlt ? !frage.belegFehlt : undefined,
                        dokumentFehltNotiz: frage.belegFehltNotiz,
                        dokumentNachgereicht: dokumentVorhanden === undefined ? frage.belegNachgereicht : dokumentVorhanden
                    });
                }
            });
        });

        return thisResult
    }

    async saveChecklist(resultChecklistItems: ChecklistItem[], hideToasts?: boolean, bpId?: number) {
        //prepare data
        let thisResult = this.getAntwortenForRequest(resultChecklistItems)

        const unterschriften = [...resultChecklistItems.filter(c => c.taskDoc).map(u => {
            return {
                docName: u.taskDoc?.docName,
                docNachgereicht: u.taskFulfilled,
                docNachgereichtDatum: new Date()
            }
        })];

        let res = await fetch(UserStore.url + '/checklisteSave', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    dataset: {
                        bstnr: UserStore.username,
                        protid: bpId ?? this.state.currentBPID,
                        antworten: thisResult,
                        dokumenteFehlendeUnterschriften: unterschriften.length ? JSON.stringify(unterschriften) : null
                    }
                }
            })
        });

        let resJson = await res.json()

        if (resJson.success) {
            if(!hideToasts) {
                toast.success("Fehlende Belege gespeichert.", {
                    autoClose: 5000
                });
            }
        } else {
            toast.error(resJson.data.message ?? "Fehlende Belege konnten nicht gespeichert werden. Bitte später nochmals probieren.", {
                autoClose: false
            });
        }
    }

    async saveBP(finalsave = false) {
        //loop through all Fragen and build save request
        let thisResult = this.getAntwortenForRequest()

        //restliche Daten vorbereiten für save
        const bp = this.state.historyBP.find(b => b.jahr === Number(this.state.bpVZ));

        let res = await fetch(UserStore.url + '/beratungsprotokollSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.username),
                    dataset: {
                        bstnr: UserStore.username,
                        mgnr: this.state.mGNr,
                        protid: this.state.currentBPID,
                        jahr: Number(this.state.bpVZ),
                        saveStatus: finalsave ? 2 : 1,  //Permanent = 2 / zwischenspeichern = 1
                        antworten: thisResult,
                        notiz: bp ? bp.notiz : null,
                        bpVersion: this.state.bpFragen[0].fragen[0].bpVersion
                    }
                }
            })
        });

        let response = await res.json();

        if (response.success) {

            if (finalsave) {

                toast.success("Beratungsprotokoll abgeschlossen.", {
                    autoClose: 3000
                });
                //update dropdown
                this.updateDropdownTexte(true);

            } else {

                toast.success("Beratungsprotokoll zwischengespeichert.", {
                    autoClose: 5000
                });
                //update dropdown
                this.updateDropdownTexte(false);
                
                //save fehlende belege as well as column DokumenteFehlendeUnterschriften shouldnt be null
                this.saveFehlendeBelege(true, response.data.protokollId)
            }
        } else {
            toast.error("Protokoll konnte nicht gespeichert werden. Bitte später nochmals probieren.", {
                autoClose: false
            });
        }

        this.setState({
            showSaveModal: false,
            currentBPSaveStatus: finalsave ? 2 : 1,
            currentBPID: response.data ? response.data.protokollId : this.state.currentBPID,
            bpChangedAfterSave: false
        })

    }

    updateDropdownTexte(finalSave = false) {

        let thisbpVZs = this.state.bpVZs.map(obj => {
            if (obj.id === this.state.bpVZ) {
                return {
                    ...obj,
                    value: 'VZ ' + this.state.bpVZ + ' (' + (finalSave ? 'Final' : 'in Bearbeitung') + ')',
                    outdated: true
                };
            }

            return obj;
        });

        this.setState({
            'bpVZs': thisbpVZs,
        });

    }

    async printBP() {
        await MitgliedService.mitgliedPrintFile(this.state.mGNr ?? "", "rptBeratungsprotokoll", "D", "ProtID:=" + this.state.currentBPID, this.state.mGNr + "_BP_VZ" + this.state.bpVZ + ".pdf")
        return true;
    }

    getFragenOfBpFragen(frageId: number, bpFragen: Fragengruppe[] = []) {
        if (bpFragen.length) {
            return bpFragen.find(bp => bp.fragen.find(f => f.id === frageId))?.fragen ?? []
        } else {
            return this.state.bpFragen.find(bp => bp.fragen.find(f => f.id === frageId))?.fragen ?? []
        }
    }

    saveNotizInput(val: string, aenderNotiz = false) {

        if (!aenderNotiz) {

            //Normale Notiz
            this.setState({
                currentFrage: {
                    ...this.state.currentFrage,
                    notiz: val,
                }
            })

        } else {

            //Änderungsnotiz
            this.setState({
                currentFrage: {
                    ...this.state.currentFrage,
                    tempAenderNotiz: val,
                }
            })

        }

    }

    saveNotizInputBelegFehlt(val: boolean | string) {
        if (typeof val === "boolean") {
            this.setState({
                currentFrage: {
                    ...this.state.currentFrage,
                    belegFehlt: val
                }
            })
        } else {
            this.setState({
                currentFrage: {
                    ...this.state.currentFrage,
                    belegFehltNotiz: val
                }
            })
        }

        if (this.state.currentBPSaveStatus === 2) {
            this.setState({
                bpChangedAfterSave: true
            })
        }
    }

    async saveAenderNotiz() {

        this.setState({
            saveNotizLoading: true,
        })

        //push to endpoint
        let thisNotizen = [];
        thisNotizen.push({
            frageId: this.state.currentFrage.id,
            notiz: this.state.currentFrage.tempAenderNotiz,
        });

        let res = await fetch(UserStore.url + '/beratungsprotokollMGNotizen', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.username),
                    dataset: {
                        bstnr: UserStore.username,
                        mgnr: this.props.mGNr,
                        protid: this.state.currentBPID,
                        notizen: thisNotizen
                    }
                }
            })
        });

        let response = await res.json();

        if (response.success) {
            //temp add to notiz array
            //bpFragen updaten
            let thisBpFragen = this.state.bpFragen.map(bp => {
                return {
                    ...bp, fragen: bp.fragen.map(f => {
                        return {
                            ...f, fragenAenderungNotizen: f.id === this.state.currentFrage.id ? [...f.fragenAenderungNotizen, {
                                notizId: -1,
                                notiz: this.state.currentFrage.tempAenderNotiz!,
                                datum: new Date(),
                                frageId: f.id
                            }] : f.fragenAenderungNotizen
                        }
                    })
                }
            })

            //clear temp
            this.setState({
                bpFragen: thisBpFragen,
                saveNotizLoading: false,
                currentFrage: {
                    ...this.state.currentFrage,
                    tempAenderNotiz: '',
                    fragenAenderungNotizen: this.getFragenOfBpFragen(this.state.currentFrage.id, thisBpFragen).find(f => f.id === this.state.currentFrage.id)?.fragenAenderungNotizen!
                }
            })

            toast.success("Änderungsnotiz gespeichert.", {
                autoClose: 3000
            });

            this.setBpVzOutdated(true);
        } else {
            toast.error("Änderungsnotiz konnte nicht gespeichert werden. Bitte später nochmals probieren.", {
                autoClose: false
            });
        }

    }

    closeModalSave() {
        let thisFragen = this.getFragenOfBpFragen(this.state.currentFrage.id)
        let checklistItems = this.state.checklistItems;
        thisFragen = thisFragen.map((item) => {
            if (item.id === this.state.currentFrage.id) {
                item.belegFehlt = this.state.currentFrage.belegFehlt
                item.belegFehltNotiz = this.state.currentFrage.belegFehltNotiz
                item.notiz = this.state.currentFrage.notiz

                if (!checklistItems.find(r => r.frage?.id === item.id) && item.belegFehlt) {
                    checklistItems = [...checklistItems, {
                        frage: item,
                        taskFulfilled: false,
                        taskText: 'Dokument erhalten'
                    }]
                } else if (checklistItems.find(r => r.frage?.id === item.id) && !this.state.currentFrage.belegFehlt) {
                    checklistItems = [...checklistItems.filter(ci => ci.frage?.id !== item.id)]
                }
            }

            return item;
        });

        this.setState({
            showNotizModal: false,
            showFehlendeBelege: this.state.tempShowFehlendeBelege,
            checklistItems: checklistItems,
            bpFragen: this.state.bpFragen.map(bp => {
                return {
                    ...bp,
                    fragen: bp.fragen.map(f => {
                        const foundFrage = thisFragen.find(tf => tf.id === f.id)
                        if (foundFrage)
                            f = foundFrage
                        return f;
                    })
                }
            })
        })
    }

    handleChangeFrage(fragenId: number, val: number, option = '') {
        let thisFrage = this.getFragenOfBpFragen(fragenId)
        let fragengruppe = this.state.bpFragen.find(fg => fg.fragen.find(f => f.id === fragenId));

        if (fragengruppe) {
            fragengruppe.allNo = false
            fragengruppe.fragen = thisFrage.map((item) => {
                if (item.id === fragenId) {
                    item.fragenAnswer = val;
                    item.fragenOptionenAnswer = option;
                }
                return item;
            });
        }

        this.setState({
            bpFragen: this.state.bpFragen.map(fg => {
                if (fragengruppe && fg.id === fragengruppe.id) {
                    fg = fragengruppe
                }
                return fg;
            }),
        });
    }

    handleSwitchAllNo(allNo: boolean, fragengruppeId: number) {
        let thisAllNo = !allNo;
        let fragengruppe = this.state.bpFragen.find(fg => fg.id === fragengruppeId)!
        let thisFragen = fragengruppe.fragen;

        fragengruppe.allNo = thisAllNo
        fragengruppe.fragen = thisFragen.map((item) => {
            item.fragenAnswer = (thisAllNo) ? 2 : -1;
            return item;
        });

        this.setState({
            bpFragen: this.state.bpFragen.map(bp => {
                if (bp.id === fragengruppe.id)
                    bp = fragengruppe
                return bp
            }),
        })

        this.toggleAccordeon(fragengruppeId, false)
    }

    closeModal() {

        this.setState({
            showNotizModal: false,
            showFehlendeBelege: this.state.tempShowFehlendeBelege,
            bpChangedAfterSave: false
        })

    }

    calcReadyAccordeonBp(fragengruppe: number) {
        let result = 0;

        this.state.bpFragen.find(fg => fg.id === fragengruppe)!.fragen.map((item) => {
            if (item.fragenAnswer && item.fragenAnswer > 0) {
                result++;
            }

            return true;
        });

        return result;
    }

    showNotizModal(frageId: number) {
        const frage = this.getFragenOfBpFragen(frageId).find(f => f.id === frageId)!

        this.setState({
            showNotizModal: true,
            tempShowFehlendeBelege: this.state.showFehlendeBelege,
            showFehlendeBelege: false,
            currentFrage: {
                id: frage.id,
                notiz: frage.notiz,
                belegFehlt: frage.belegFehlt,
                belegFehltNotiz: frage.belegFehltNotiz,
                fragenAenderungNotizen: frage.fragenAenderungNotizen,
            }
        })
    }

    closeFehlendeBelegeModal() {
        this.setState({
            showFehlendeBelege: false,
            checklistItems: this.state.prevChecklistItems
        }
        )
    }

    async saveFehlendeBelege(hideToasts?: boolean, bpId?: number) {
        await this.saveChecklist(this.state.checklistItems, hideToasts, bpId)
    }

    showFehlendeBelege() {
        this.setState(prevState => {
            return {
                prevChecklistItems: prevState.checklistItems.map(c => { return { ...c } }),
                showFehlendeBelege: true
            }
        })
    }

    handleCheckboxChange(item: ChecklistItem, val: boolean) {
        let checklist = this.state.checklistItems;

        checklist = checklist.map(c => {
            if ((item.frage && c.frage?.id === item.frage?.id) || (item.taskDoc && c.taskDoc === item.taskDoc))
                c.taskFulfilled = val
            return c;
        })

        this.setState({
            checklistItems: checklist
        })
    }

    render() {
        let thisFragenStat = this.calcReady();

        return (
            <div>
                <Container>
                    <Form noValidate validated={this.state.validated}>
                        <Row className="alh_rowTopList">
                            <Col lg={8}>
                                <div className="alh_frontBoxes">

                                    <h3>Beratungsprotokoll</h3>
                                    <div>Hier können Sie ein neues Beratungsprotokoll anlegen, oder die historischen Protokolle laden und einsehen. Bitte wählen Sie ein Jahr aus, dann können Sie
                                        die entsprechenden Protokolle einsehen oder bearbeiten. Protokolle mit dem Zusatz "Final" können nur eingesehen werden. Der Zusatz "in Bearbeitung" zeigt,
                                        dass Sie hier noch Änderungen vornehmen können. Jahre ohne einen Zusatz sind noch komplett ohne Protokoll.</div>

                                    <div className="alhBP_tripple">
                                        <div>Name: <b>{this.state.mGName}</b></div>
                                        <div>Mitgliedsnummer: <b>{this.state.mGNr}</b></div>
                                        <SelectField
                                            id='bpVZ'
                                            name='bpVZ'
                                            withPleaseSelect={true}
                                            value={this.state.bpVZ}
                                            options={this.state.bpVZs}
                                            disabled={this.state.bpVZs.length === 0}
                                            onChange={(val) => this.setInputValue('bpVZ', val)}
                                        />
                                    </div>

                                    {!this.state.loading &&
                                        <div>
                                            <div className={"alhAccordion " + ((this.state.bpVZ > 0) ? '' : 'd-none')}>
                                                {this.state.bpFragen.map((item, key) =>
                                                    <div key={key}>
                                                        {item !== null &&
                                                            <AccordeonBP item={item}
                                                                key={item.id}
                                                                openBody={item.openBody}
                                                                allNoVisible={key > 1}
                                                                saveStatus={this.state.currentBPSaveStatus}
                                                                toggleAccordeon={(gruppenId, save) => this.toggleAccordeon(gruppenId, save)}
                                                                calcReadyAccordeonBp={(fragengruppe) => this.calcReadyAccordeonBp(fragengruppe)}
                                                                fragen={item.fragen}
                                                                handleChangeFrage={(fragenId, val, option) => this.handleChangeFrage(fragenId, val, option)}
                                                                handleSwitchAllNo={(val, fragengruppe) => this.handleSwitchAllNo(val, fragengruppe)}
                                                                showNotizModal={(frageId) => this.showNotizModal(frageId)}
                                                                allNo={item.allNo}
                                                            />
                                                        }
                                                    </div>
                                                )}
                                                <InputField
                                                    as='textarea'
                                                    type='textclean'
                                                    id='gNotiz'
                                                    name='gNotiz'
                                                    placeholder='Notiz'
                                                    forceAutofill
                                                    value={this.state.historyBP.find(b => b.jahr === Number(this.state.bpVZ))?.notiz ?? ''}
                                                    onChange={(val) => this.setInputValue('notiz', val)}
                                                />
                                            </div>
                                        </div>}


                                    {this.state.loading &&
                                        <div className="alhLoadingRel">
                                            <WaveTopBottomLoading color="#ffc800" />
                                        </div>}

                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="alh_frontBoxes">

                                    {!this.state.loading &&
                                        <div className={"alhStickyDiv " + ((this.state.bpVZ > 0) ? '' : 'd-none')}>

                                            <div className="alhBP_tripple">
                                                <div>Name: <b>{this.state.mGName}</b></div>
                                                <div>MgNr: <b>{this.state.mGNr}</b></div>
                                            </div>

                                            <ProgressBar variant="success" now={thisFragenStat.percComplete}
                                                label={thisFragenStat.percComplete + "%"}
                                                className="alhProgressbar" />

                                            <div className="alhBP_Leg">
                                                {this.state.bpFragen.map((item, key) => {
                                                    return (<div
                                                        className={"alhBP_LegItem " + (((thisFragenStat['fragen'][item.id] && thisFragenStat['fragen'][item.id]['cntReady'] === thisFragenStat['fragen'][item.id]['cntFragen'])) ? "alhBP_LegGreen" : "")}
                                                        key={key}>
                                                        {(thisFragenStat['fragen'][item.id] && thisFragenStat['fragen'][item.id]['cntReady'] === thisFragenStat['fragen'][item.id]['cntFragen']) &&
                                                            <FontAwesomeIcon icon={faCheckCircle}
                                                                className="alhBP_LegCheck" />}
                                                        {(thisFragenStat['fragen'][item.id] && thisFragenStat['fragen'][item.id]['cntReady'] !== thisFragenStat['fragen'][item.id]['cntFragen']) &&
                                                            <FontAwesomeIcon icon={faMinusCircle}
                                                                className="alhBP_LegXMark" />}
                                                        <span>{item.name}</span>
                                                    </div>
                                                    )
                                                })}
                                            </div>

                                            {(this.state.allDone && this.state.currentBPSaveStatus !== 2) &&
                                                <SubmitButton
                                                    text="Protokoll Abschliessen"
                                                    tabIndex={this.state.defaultTabIndex}
                                                    mode="success"
                                                    className="alhButton_mgsave"
                                                    onClick={() => this.showSaveModal()}
                                                />
                                            }

                                            {(!this.state.allDone && this.state.currentBPSaveStatus !== 2) &&
                                                <SubmitButton
                                                    text="Protokoll Zwischenspeichern"
                                                    tabIndex={this.state.defaultTabIndex}
                                                    mode="secondary"
                                                    className="alhButton_mgsave"
                                                    onClick={() => this.saveBP(false)}
                                                />
                                            }

                                            {this.state.bpChangedAfterSave &&
                                                <SubmitButton
                                                    text="Protokoll abspeichern"
                                                    tabIndex={this.state.defaultTabIndex}
                                                    mode="secondary"
                                                    className="alhButton_mgsave"
                                                    onClick={() => this.saveBP(true)}
                                                />
                                            }

                                            <SubmitButton
                                                disabled={this.state.currentBPSaveStatus !== 2}
                                                text="Beratungsprotokoll herunterladen (PDF)"
                                                tabIndex={this.state.defaultTabIndex}
                                                mode="light"
                                                className="alhButton_mgsave"
                                                onClick={() => this.printBP()}
                                            />

                                            {(this.state.currentBPSaveStatus === 1 || this.state.currentBPSaveStatus === 2) && <>
                                                <SubmitButton
                                                    text="Fehlende Belege anzeigen"
                                                    tabIndex={this.state.defaultTabIndex}
                                                    mode="light"
                                                    className="alhButton_mgsave"
                                                    onClick={() => this.showFehlendeBelege()}
                                                />
                                            </>}

                                        </div>
                                    }

                                    {this.state.loading &&
                                        <div className="alhLoadingRel">
                                            <WaveTopBottomLoading color="#ffc800" />
                                        </div>}

                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>

                <ConfirmModal
                    body='Sind Sie sich sicher, dass alle Angaben korrekt sind? Sobald Sie das Protokoll schliessen, kann es nicht mehr verändert werden. Lediglich können dann Änderungsnotizen hinzugefügt werden.'
                    closeFct={() => this.closeSaveModal()}
                    confirmFct={() => this.saveBP(true)}
                    heading='Fragebogen abschliessen'
                    okButtonStr='Speichern'
                    show={this.state.showSaveModal}
                    key='alh_confirmBPSave'
                    showConfirm={this.state.bpVZ !== -1}
                    warning={
                        this.state.bpVZ === -1 ?
                            <Alert
                                key="newAlertNoYear" variant="danger">
                                Achtung Sie müssen erst ein Jahr für das Beratungsprotokoll auswählen!
                            </Alert>
                            : undefined
                    }
                />

                <BeratungsprotkollFehlendeBelegeModal
                    showFehlendeBelege={this.state.showFehlendeBelege}
                    checklistItems={this.state.checklistItems}
                    bpVz={this.state.bpVZ}
                    currentBpId={this.state.currentBPID}
                    mgNr={this.state.mGNr ?? ""}
                    saveFehlendeBelege={async () => await this.saveFehlendeBelege()}
                    closeFehlendeBelegeModal={() => this.closeFehlendeBelegeModal()}
                    showNotizModal={(frageId) => this.showNotizModal(frageId)}
                    handleCheckboxChange={(item, val) => this.handleCheckboxChange(item, val)}
                />

                <Modal
                    show={this.state.showNotizModal}
                    onHide={() => this.closeModal()}
                    backdrop="static"
                    keyboard={true}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Notiz zur Frage</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <small>Hier können Sie Notizen zur Frage hinterlegen. Dies geht nur solange das Protokoll noch nicht abgeschlossen wurde.</small>
                        <br /><br />

                        <InputField
                            as='textarea'
                            type='textclean'
                            id='notizFrage'
                            name='notizFrage'
                            forceAutofill={true}
                            setFocus={true}
                            placeholder='Notiz zur Frage'
                            value={this.state.currentFrage.notiz ?? ''}
                            disabled={this.state.currentBPSaveStatus === 2}
                            onChange={(val) => this.saveNotizInput(val)}
                        />

                        <InputCheckbox
                            onChange={(val) => this.saveNotizInputBelegFehlt(val)}
                            checked={this.state.currentFrage.belegFehlt ?? false}
                            text="Beleg fehlt"
                        />

                        {this.state.currentFrage.belegFehlt &&
                            <InputField
                                as='textarea'
                                type='textclean'
                                id='notizBelegFehlt'
                                name='notizBelegFehlt'
                                placeholder='Beleg und Informationen für den Checklistendruck "Fehlende Belege" hier eintragen'
                                value={this.state.currentFrage.belegFehltNotiz ?? ''}
                                onChange={(val) => this.saveNotizInputBelegFehlt(val)}
                            />}

                        {this.state.currentBPSaveStatus === 2 &&
                            <div>
                                <br />
                                <h3>Änderungsnotizen</h3>
                                <small>Sobald das Protokoll abgeschlossen wurde, können Sie hier die Änderungsnotizen hinterlegen.</small>
                                <br /><br />
                                <div>
                                    {this.state.currentFrage.fragenAenderungNotizen.map((aAenderNotiz, index) => (
                                        <div key={index} className="alh_bpaendern">
                                            <div><Moment format="DD.MM.YYYY">{aAenderNotiz.datum}</Moment></div>
                                            <div>
                                                {aAenderNotiz.notiz.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br /></span>
                                                })}
                                            </div>
                                        </div>
                                    ))}

                                    {this.state.currentFrage.fragenAenderungNotizen.length === 0 &&
                                        <>
                                            <Alert variant="warning">Keine Änderungsnotizen vorhanden!</Alert>
                                        </>
                                    }


                                    <br />
                                    <InputField
                                        as='textarea'
                                        type='textclean'
                                        id='aenderNotizFrage'
                                        name='aenderNotizFrage'
                                        forceAutofill={true}
                                        setFocus={true}
                                        placeholder='Neue Änderungsnotiz zur Frage hier hinzufügen'
                                        value={this.state.currentFrage.tempAenderNotiz ?? ''}
                                        onChange={(val) => this.saveNotizInput(val, true)}
                                    />
                                    <Button
                                        variant="primary"
                                        disabled={this.state.saveNotizLoading || !this.state.currentFrage.tempAenderNotiz}
                                        onClick={() => this.saveAenderNotiz()}>
                                        Änderungsnotiz hinzufügen
                                    </Button>
                                </div>
                            </div>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        {(this.state.currentBPSaveStatus !== 2 || this.state.bpChangedAfterSave) &&
                            <Button variant="primary" onClick={() => this.closeModalSave()}>
                                Speichern
                            </Button>
                        }
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Schliessen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Beratungsprotokoll;

