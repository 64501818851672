import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { WaveTopBottomLoading } from "react-loadingg";
import UserStore from "../../stores/UserStore";
import { getCurrentDate } from "../ALHUtility";
import Abrechnung from '../../models/Abrechnung';
import { AbrechnungService } from '../../services/AbrechnungService';

interface AbrechnungenhistoryState {
    loading: boolean
    abrechnungenListe: Abrechnung[]
}
class Abrechnungenhistory extends Component<{}, AbrechnungenhistoryState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            loading: true,
            abrechnungenListe: []
        }

    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    async componentDidMount() {
        try {
            const result = await AbrechnungService.getAbrechnungHistory()

            if (result && result.success) {
                this.setState({
                    abrechnungenListe: result.data.abrechnungenListe as Abrechnung[],
                    loading: false
                });
            }

        } catch (e) {
            console.log("Error in loading homemessage: ", e);
        }
    }

    async printHandle(endpoint: string, handle: string, filenameSuffix: string, beleg: Abrechnung) {

        const filenameConst = this.getMonthName(beleg.monat) + "_" + beleg.jahr + filenameSuffix

        const filenameComplete = getCurrentDate() + "_" + UserStore.bstNr + "_" + filenameConst + ".pdf";

        await AbrechnungService.printAbrechnung(endpoint, handle, filenameComplete)
    }

    getMonthName(monatNum: number) {
        let monatName = "";

        switch (monatNum) {
            case 1:
                monatName = "Januar";
                break;
            case 2:
                monatName = "Februar";
                break;
            case 3:
                monatName = "März";
                break;
            case 4:
                monatName = "April";
                break;
            case 5:
                monatName = "Mai";
                break;
            case 6:
                monatName = "Juni";
                break;
            case 7:
                monatName = "Juli";
                break;
            case 8:
                monatName = "August";
                break;
            case 9:
                monatName = "September";
                break;
            case 10:
                monatName = "Oktober";
                break;
            case 11:
                monatName = "November";
                break;
            case 12:
                monatName = "Dezember";
                break;

            default:
                monatName = "";
        }

        return monatName;
    }

    render() {
        return (
            <div className="alh_tablemaxheight">
                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Monat</th>
                            <th>Jahr</th>
                            <th className="text-right">Belastung</th>
                            <th className="text-right">Aktion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.abrechnungenListe.map((beleg) => (
                            <tr key={beleg.abrechnungHandle}>
                                <td><Moment format="DD.MM.YYYY">{beleg.datum}</Moment></td>
                                <td>{this.getMonthName(beleg.monat)}</td>
                                <td>{beleg.jahr}</td>
                                <td className="text-right">{this.numberFormat(beleg.belastung)}</td>
                                <td className="tableActions">
                                    <FontAwesomeIcon onClick={() => this.printHandle('abrechnungPrintAbr', beleg.abrechnungHandle, "_Abrechnung", beleg)} icon={faPrint} title="Abrechnung drucken" className="faButton" />
                                    <FontAwesomeIcon onClick={() => this.printHandle('abrechnungPrintBL', beleg.abrechnungHandle, "_Beitragsliste", beleg)} icon={faPrint} title="Beitragsliste drucken" className="faButton" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>


                {this.state.abrechnungenListe && this.state.abrechnungenListe.length === 0 && !this.state.loading &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}

            </div>
        );
    }
}

export default Abrechnungenhistory;
