import UserStore from "../stores/UserStore"

const ServiceHelper = {
    buildRequestBody: (object: any) => {
        return JSON.stringify({
            attr: {
                dataset: {
                    BSTNr: UserStore.username,
                    ...object
                }
            }
        })
    }
}

export default ServiceHelper