import UserStore from "../stores/UserStore";

export const AuthorizationService = {
    authAndLogin: async (username: string, password: string) => {
        let res = await fetch(UserStore.url + '/authAndLogin', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        });

        return await res.json();
    }
}