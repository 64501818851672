import { Datencheck } from "../Components/Screens/MyBst"
import UserStore from "../stores/UserStore"
import ServiceHelper from "./ServiceHelper"

const DatencheckService = {
    get: async (mitarbeiterId?: number): Promise<Datencheck[]> => {
        const response = await fetch(UserStore.url + "/getDatencheck", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: ServiceHelper.buildRequestBody({
                mitarbeiterId: mitarbeiterId
            })
        })

        if (response.ok) {
            const result = await response.json()
            if (result.success)
                return result.data.datenchecks
        }

        return []
    },

    set: async (datenchecks: Datencheck[]) => {
        const response = await fetch(UserStore.url + "/setDatencheck", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, 
            body: ServiceHelper.buildRequestBody({datenchecks: datenchecks})
        })

        if(response.ok) {
            const res = await response.json()
            return res
        } 
        return response.ok
    }
}

export default DatencheckService