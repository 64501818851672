import React, { Component } from 'react';
import Table from "react-bootstrap/Table";
import InputCheckbox from "../Forms/InputCheckbox";
import SubmitButton from "../Forms/SubmitButton";
import UserStore from "../../stores/UserStore";
import InputField from "../Forms/InputField";
import { WaveTopBottomLoading } from "react-loadingg";
import { toast } from "react-toastify";
import { Rueckbuchung } from '../../models/Buchung';

interface MitgliederRueckBuchungBase {
    mGNr: string
    active: boolean
}

interface MitgliederRueckBuchungProps extends MitgliederRueckBuchungBase {
    reload: boolean
    onChangedDataSet?: () => void
    onReloadOthers?: () => void
}

interface MitgliederRueckBuchungState extends MitgliederRueckBuchungBase {
    mGNr: string
    loading: boolean
    list: Rueckbuchung[]
    letSave: boolean
}

class MitgliederRueckBuchung extends Component<MitgliederRueckBuchungProps, MitgliederRueckBuchungState> {

    constructor(props: MitgliederRueckBuchungProps) {
        super(props);

        this.state = {
            mGNr: this.props.mGNr,
            loading: false,
            active: false,

            list: [],
            letSave: false,
        }

    }

    async componentDidUpdate(prevProps: MitgliederRueckBuchungProps) {

        //Init Component if active clicked
        if (this.props.active !== prevProps.active && this.props.active) {
            await this.loadmgRwSollbuchungen();

            this.setState({
                active: true,
            });
        }

        if (prevProps.reload !== this.props.reload) {
            await this.loadmgRwSollbuchungen();
        }

    }

    async loadmgRwSollbuchungen() {

        this.setState({
            loading: true,
        })

        try {
            let res = await fetch(UserStore.url + '/mgRwSollbuchungen', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        MGNr: this.state.mGNr,
                    }
                })
            });

            let result = await res.json();

            if (result && result.success) {

                if (result.data.message && result.data.message === "Unauthorized") {
                    this.setState({
                        list: [],
                        loading: false,
                    });
                } else {
                    this.setState({
                        list: result.data.buchungen,
                        loading: false,
                    });
                }

            }


        } catch (e) {
            console.log("Error in loading homemessage: " + e);
        }
    }

    async setInputValueBMG(index: number, value: number) {
        let currentList = this.state.list;

        let newValue = await this.calcNewValue(value, this.state.list[index].vv);

        //only save and mark changed if value different
        if (currentList[index].sollBetrag !== newValue) {
            currentList[index].bmg = value;
            currentList[index].sollBetrag = newValue;
            currentList[index].changed = true;

            this.setState({
                list: currentList,
                letSave: true,
            })

            if (this.props.onChangedDataSet) {
                this.props.onChangedDataSet();
            }
        }
    }
    async setInputValueVV(index: number, value: boolean) {
        let currentList = this.state.list;

        let newValue = await this.calcNewValue(this.state.list[index].bmg, value);

        currentList[index].vv = value;
        currentList[index].sollBetrag = newValue;
        currentList[index].changed = true;

        this.setState({
            list: currentList,
            letSave: true,
        })

        if (typeof this.props.onChangedDataSet === "function") {
            this.props.onChangedDataSet();
        }
    }

    loseFocus(index: number) {
        this.setInputValueVV(index, this.state.list[index].vv);
    }

    async calcNewValue(bmg: number, vv: boolean) {

        let res = await fetch(UserStore.url + '/beitragsordnungBeitrag', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    bmg: bmg,
                    vv: vv,
                }
            })
        });

        let result = await res.json();

        if (result && result.data.betrag) {
            return result.data.betrag;
        }

        return 0;
    }

    saveAllSollstellungen() {

        this.state.list.forEach(item => {
            this.saveASollstellung(item.beitragsjahr ?? 0, item.bmg, item.vv);
        });

        //wait a littel bit so that server has saved
        setTimeout(() => {
            this.loadmgRwSollbuchungen();

            this.setState({
                letSave: false,
            });

            toast.success("Daten wurden gespeichert.", {
                autoClose: 5000
            });

            if (this.props.onReloadOthers) {
                this.props.onReloadOthers();
            }
        }, 1000);

    }

    async saveASollstellung(jahr: number, bmg: number, vv: boolean) {

        //Send data
        let res = await fetch(UserStore.url + '/mgRwSollbuchungenSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.username),
                    dataset: {
                        BSTNr: UserStore.username,
                        MGNr: this.state.mGNr,
                        beitragsjahr: jahr,
                        bmg: bmg,
                        vv: vv
                    }
                }
            })
        });

        let response = await res.json();

        if (response.success) {
            return true;
        }
        return false;
    }

    render() {

        let list = this.state.list;

        return (
            <div>
                <h3 className="alh_pageheader">Sollstellungen</h3>
                <div className="alh_subheader">Hier werden die Sollstellungen der rückwirkenden Beiträge dargestellt. Sie können (wenn freigegeben) die BMG und V+V ändern, dann simuliert das System automatisch den Betrag.
                    Der Betrag blinkt dann grün auf. Mittels "Daten speichern" hinterlegen Sie diese Werte fest im System.
                    <br /><span className="alh_bold">Tipp</span>: Ändern Sie den Wert bei BMG und klicken Sie dann mit der Maus ausserhalb des Feldes, um die Simulation zu starten.
                </div>

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th>Beitragsjahr</th>
                            <th>Steuerjahr</th>
                            <th>BMG</th>
                            <th>V+V</th>
                            <th className="text-right">Sollbetrag</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item, index) => (
                            <tr key={index}>
                                <td className="alh_centerWithInput">{item.beitragsjahr}</td>
                                <td className="alh_centerWithInput">{(item.beitragsjahr ?? 1) - 1}</td>
                                <td>
                                    <InputField
                                        size='sm'
                                        type='number'
                                        inputGroupClass=''
                                        placeholder='BMG'
                                        disabled={!item.istAenderbar}
                                        value={this.state.list[index].bmg.toString()}
                                        onChange={(val) => this.setInputValueBMG(index, Number(val))}
                                        onEnter={() => this.loseFocus(index)}
                                    />
                                </td>
                                <td className="alh_centerWithInput">
                                    <InputCheckbox
                                        name={index + '_vv'}
                                        checked={this.state.list[index].vv}
                                        disabled={!item.istAenderbar}
                                        onChange={(val) => this.setInputValueVV(index, val)}
                                    />
                                </td>
                                <td className={"text-right alh_centerWithInput value_" + index + (item.changed ? " highlightValue blink_me" : "")}>{this.numberFormat(item.sollBetrag)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {list && list.length === 0 && !this.state.loading &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}

                {UserStore.hasBerechtigung('bst_mg', 'write') && list.length > 0 && !this.state.loading &&
                    <SubmitButton
                        type='button'
                        disabled={!this.state.letSave}
                        onClick={() => this.saveAllSollstellungen()}
                        text="Daten speichern"
                        size="sm"
                    />
                }

                <div className={(this.state.letSave ? "" : "d-none") + " alh_mitglied_noterwb"}>Daten noch nicht dauerhaft gespeichert, dies passiert erst nach Klick auf den “Daten speichern” Button.</div>

            </div>
        );
    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }
}

export default MitgliederRueckBuchung;
