import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BstContextProvider } from './stores/BstContextProvider';

const ContextProvider = ({ children }: PropsWithChildren) => {
  return <BstContextProvider>
    {children}
  </BstContextProvider>
}

const container = document.getElementById('root');
ReactDOM.render(
  //<React.StrictMode>
  <ContextProvider>
    <App />
  </ContextProvider>
  //</React.StrictMode>,
  ,
  container
);

serviceWorker.unregister();
