import UserStore from "../stores/UserStore"
import { FileService } from "./FileService"

export const AbrechnungService = {
    printAbrechnung: async (endpoint: string, abrechnungHandle: string, filename: string) => {

        const body = JSON.stringify({
            attr: {
                BSTNr: UserStore.username,
                AbrechnungHandle: abrechnungHandle,
            }
        })

        await FileService.printFile(endpoint, body, filename)
    },
    getAbrechnungHistory: async () => {
        let res = await fetch(UserStore.url + '/abrechnungenhistory', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        let result = await res.json();

        return result
    }
}