import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";
import { BstSettings } from "../models/BstSettings";
import { Decision, ZahlungsweiseHaben } from "./Constants";
import React from 'react';

interface BstContextProps {
    settings: BstSettings
    setSettings: (settings: BstSettings) => void
}

const getDefaultSettings = () => ({
    andruckBST2MGAusweis: Decision.no,
    bstDefaultCheckSeparateFiles: false,
    checkBekanntgabevollmacht: false,
    emailBst: "",
    glaeubigerId: "",
    zahlungsweiseHaben: ZahlungsweiseHaben.Bankeinzug
})

const defaultContextValue: BstContextProps = {
    settings: getDefaultSettings(),
    setSettings: () => { }
}

export const BstContext = createContext(defaultContextValue)

export const useBstContext = () => useContext(BstContext)

export const BstContextProvider = ({ children }: PropsWithChildren) => {

    const [bstSettings, __setBstSettings] = useState<BstSettings>(getDefaultSettings())

    const setBstSettings = useCallback((bstSettings: BstSettings) => {
        __setBstSettings(bstSettings)
    }, [])

    return <BstContext.Provider value={{
        settings: bstSettings,
        setSettings: setBstSettings
    }}>
        {children}
    </BstContext.Provider>
}