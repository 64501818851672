import UserStore from "../stores/UserStore";

const NotizenService = {
    loadNotizen: async (mgnr: string, status: number) => {
        let res = await fetch(UserStore.url + '/mgnotiz', {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    Mitgliedsnummer: mgnr,
                    MGNr: mgnr,
                    Status: status
                }
            })
        });

        return await res.json();
    },
    deleteNotiz: async (bstNr?: string, mitgliedsnummer?: string, notizId?: string) => {
        let res = await fetch(UserStore.url + '/mgnotizDel', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    bstNr: bstNr,
                    mitgliedsnummer: mitgliedsnummer,
                    notizId: notizId
                }
            })
        });

        return await res.json();
    },
    setBuchungNotiz: async (mgNr: string, notiz?: string) => {
        //Send data
        let res = await fetch(UserStore.url + '/buchungNotizSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    MGNr: mgNr,
                    notiz: notiz,
                }
            })
        });

        return await res.json();
    },
    loadMgAbrechNotiz: async (mgNr: string) => {
        let res = await fetch(UserStore.url + '/mgabrechnotiz', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    MGNr: mgNr
                }
            })
        });
        return await res.json();
    }
}

export default NotizenService