import React, { Component } from 'react';
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import TableOperatorOptions from "../Blocks/TableOperatorOptions";
import { WaveTopBottomLoading } from "react-loadingg";
import UserStore from "../../stores/UserStore";
import { sortList } from "../ALHUtility";
import Buchung from '../../models/Buchung';
import { toast } from 'react-toastify';

interface OffenebeitraegeProps {
    mode: string
}

interface OffenebeitraegeState {
    loading: boolean
    buchungen: Buchung[]
    summeOffen: number
    search: string
    sort: string
    sort_mode: string
    page: number
}

class Offenebeitraege extends Component<OffenebeitraegeProps, OffenebeitraegeState> {

    constructor(props: OffenebeitraegeProps) {
        super(props);

        this.state = {
            loading: true,
            buchungen: [],
            summeOffen: 0,
            search: '',
            sort: ((this.props.mode === 'OB') ? 'buchungstext' : 'mgNr'),
            sort_mode: ((this.props.mode === 'OB') ? 'desc' : 'asc'),
            page: 1
        }

    }

    async componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps: Readonly<OffenebeitraegeProps>, prevState: Readonly<OffenebeitraegeState>, snapshot?: any): void {
        if (prevProps.mode !== this.props.mode) {
            this.loadData()
        }
    }

    async loadData() {
        this.setState({
            loading: true
        })

        //get mode
        let apiendpoint = '';
        switch (this.props.mode) {
            case 'ML':
                apiendpoint = 'mahnliste';
                break;
            case 'MLRA':
                apiendpoint = 'mahnlistera';
                break;
            case 'ZEL':
                apiendpoint = 'zahlungserinnerung';
                break;
            default:
                apiendpoint = 'offenebeitraege';
        }

        try {
            let res = await fetch(UserStore.url + '/' + apiendpoint, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let result = await res.json();

            if (result && result.success) {
                this.setState({
                    buchungen: result.data.mitglieder,
                    loading: false,
                    summeOffen: result.data.gesamtbeitragOffen,
                });
            } else {
                toast.error("Fehler beim Laden " + result.data.message, {
                    autoClose: false
                })

                this.setState({
                    loading: false
                })
            }

            document.title = 'Offene Beiträge (' + result.data.mitglieder.length + ') | ' + UserStore.title;

        } catch (e) {
            console.log("Error in loading OffeneBeiträge: " + e);
        }
    }

    changeSearch(val: string) {
        this.setState({
            search: val
        })
    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    sortClick(spalte: string) {
        let newSort_mode = this.state.sort_mode;
        let newSort = this.state.sort;

        if (spalte === this.state.sort) {
            switch (this.state.sort_mode) {
                case 'asc':
                    newSort_mode = 'desc';
                    break;
                default:
                    newSort_mode = 'asc';
                    break;
            }
        } else {
            newSort = spalte;
        }

        this.setState({
            sort: newSort,
            sort_mode: newSort_mode,
        })
    }

    getTitle(number: number) {
        switch (this.props.mode) {
            case 'OB':
                return (
                    <div>
                        <h3 className="alh_pageheader">Offene Beiträge</h3>
                        <div className="alh_subheader">Sehen Sie hier alle aktuell offenen Beiträge.<br />
                            Gesamtbetrag offen: <span className="success">{this.numberFormat(this.state.summeOffen)}</span><br />
                            Summe aller angezeigten Beträge: <span className="success">{this.numberFormat(number)}</span>
                        </div>
                    </div>
                )
            case 'ML':
                return (
                    <div>
                        <h3 className="alh_pageheader">Mahnliste</h3>
                        <div className="alh_subheader">Hier können Sie alle Mitglieder des nächsten <span className="alh_bold">Vereinsmahnlaufes</span> einsehen.<br />
                            Bitte überprüfen Sie die Liste genau, um ungerechtfertigte Mahnungen zu vermeiden.<br />
                            Ein Mitglied wird ausschließlich durch Abrechnung oder Freistellung aufgrund fehlender Beratungsbefugnis oder sozialen Gründen von der Liste gestrichen. Zur Freistellung des Mitgliedsbeitrages lassen Sie uns bitte entsprechende Nachweise über die Upload-Funktion zukommen. Weitere Informationen hierzu können Sie unserem Newsletter entnehmen.</div>
                    </div>
                )
            case 'ZEL':
                return (
                    <div>
                        <h3 className="alh_pageheader">Zahlungserinnerungsliste</h3>
                        <div className="alh_subheader">
                            Hier können Sie alle Mitglieder einsehen, die eine <span className="alh_bold">Zahlungserinnerung</span> erhalten werden.<br />
                            Bitte überprüfen Sie die Liste genau, um ungerechtfertigte Schreiben zu vermeiden.<br />
                            Ein Mitglied wird ausschließlich durch Abrechnung oder Freistellung aufgrund fehlender Beratungsbefugnis oder sozialen Gründen von der Liste gestrichen. Zur Freistellung des Mitgliedsbeitrages lassen Sie uns bitte entsprechende Nachweise über die Upload-Funktion zukommen. Weitere Informationen hierzu können Sie unserem Newsletter entnehmen.
                        </div>
                    </div>
                )
            case 'MLRA':
                return (
                    <div>
                        <h3 className="alh_pageheader">Mahnliste RA</h3>
                        <div className="alh_subheader">Hier können Sie alle Mitglieder des nächsten <span className="alh_bold">Rechtsanwaltsmahnlaufes</span> einsehen.<br />
                            Bitte überprüfen Sie die Liste genau, um ungerechtfertigte Mahnungen zu vermeiden.<br />
                            Ein Mitglied wird ausschließlich durch Abrechnung oder Freistellung aufgrund fehlender Beratungsbefugnis oder sozialen Gründen von der Liste gestrichen. Zur Freistellung des Mitgliedsbeitrages lassen Sie uns bitte entsprechende Nachweise über die Upload-Funktion zukommen. Weitere Informationen hierzu können Sie unserem Newsletter entnehmen.</div>
                    </div>
                )

            default:
                return (
                    <div>&nbsp;</div>
                )
        }
    }

    getSum(total: number, elem: Buchung) {
        return total + (elem.betrag ?? 0);
    }

    render() {

        let list = sortList(this.state.sort, this.state.buchungen, this.state.sort_mode)

        /* Testdaten 
        if (list.length < 175) {
            for (let index = 0; index < 25; index++) {
                list.push({
                    mgNr: "2132",
                    name: "Test test",
                    buchungstext: "zzzzzzzzzzzzzzzzzzzz",
                    betrag: 12345.12
                })
            }

            for (let index = 0; index < 50; index++) {
                list.push({
                    mgNr: "2132",
                    name: "Test test",
                    buchungstext: "text blah blah blah",
                    betrag: 12345.12
                })
            }

            for (let index = 0; index < 50; index++) {
                list.push({
                    mgNr: "2133",
                    name: "Test test",
                    buchungstext: "oh no",
                    betrag: 12345.12
                })
            }

            for (let index = 0; index < 50; index++) {
                list.push({
                    mgNr: "2134",
                    name: "Test test",
                    buchungstext: "oh yes",
                    betrag: 12345.12
                })
            }
        }
 */
        let search = this.state.search;
        list = list.filter(function (beitrag) {
            return (
                beitrag.name.toLowerCase().includes(search.toLowerCase()) ||
                beitrag.buchungstext?.toLowerCase().includes(search.toLowerCase()) ||
                beitrag.betrag?.toString().toLowerCase().includes(search.toLowerCase()) ||
                beitrag.mgNr?.toLowerCase().includes(search.toLowerCase())
            );
        });

        var sumKomplett = list.reduce(this.getSum, 0);

        return (
            <div>
                {this.getTitle(sumKomplett)}

                <TableOperatorOptions
                    name="OffeneBeitraege"
                    placeholder={(this.props.mode === 'OB') ? "Suche nach Nummer, Name, Beitragsjahr, Aufn.geb., Betrag" : "Nummer, Name, Vorgang..."}
                    onChange={(val) => this.changeSearch(val)}
                    print={true}
                />
                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th onClick={() => this.sortClick('mgNr')} className={((this.state.sort === 'mgNr') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alhtable_nr"}>Nr.</th>
                            <th onClick={() => this.sortClick('name')} className={(this.state.sort === 'name') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>MG-Name</th>
                            <th onClick={() => this.sortClick('buchungstext')} className={(this.state.sort === 'buchungstext') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Vorgang</th>
                            <th onClick={() => this.sortClick('betrag')} className={(this.state.sort === 'betrag') ? "text-right alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort text-right"}>Betrag</th>
                            <th className="d-none"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((buchung, index) => (
                            <tr key={index}>
                                <td><a href={"/mitglied/" + buchung.mgNr}>{buchung.mgNr}</a></td>
                                <td>{buchung.name}</td>
                                <td>{buchung.buchungstext}</td>
                                <td className="text-right">{this.numberFormat(buchung.betrag ?? 0)}</td>
                                <td className="tableActions d-none">
                                    <a href={`/mitglied/${buchung.mgNr}`}><FontAwesomeIcon icon={faEdit} title="Bearbeiten" className="faButton faButtonSpaceright" /></a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {
                    this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>
                }

                {
                    list && list.length === 0 && !this.state.loading &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }

            </div >
        );
    }
}

export default Offenebeitraege;