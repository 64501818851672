import React from "react";
import SubmitButton from "./Forms/SubmitButton";
import InputField from "./Forms/InputField";
import UserStore from "../stores/UserStore";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../assets/images/login_center.png";
import { BstContext } from "../stores/BstContextProvider";
import { runInAction } from "mobx";
import { AuthorizationService } from "../services/AuthorizationService";

interface LoginFormState {
    username: string
    password: string
    buttonDisabled: boolean
    hist1?: string
    hist2?: string
}

interface LoginFormProps {
    setBstSettings: () => Promise<void>
    match?: {
        params: {
            hist1?: string;
            hist2?: string;
        }
    }
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {

    static contextType = BstContext;
    context!: React.ContextType<typeof BstContext>;

    componentDidMount() {
        console.log("this.props", this.props)
        this.setState({
            username: "",
            password: "",

            hist1: this.props.match?.params.hist1 ?? '',
            hist2: this.props.match?.params.hist2 ?? '',
        });

        document.body.classList.add('login');
    }

    componentWillUnmount() {

        document.body.classList.remove('login');

    }

    constructor(props: LoginFormProps) {
        super(props);

        this.state = {
            username: '',
            password: '',
            buttonDisabled: false
        }

        this.resetForm = this.resetForm.bind(this);
    }

    setInputValue(property: string, val: any) {
        val = val.trim();
        if (val.length > 12) {
            return;
        }
        this.setState({ ...this.state, [property]: val })
    }

    resetForm() {
        this.setState({
            username: '',
            password: '',
            buttonDisabled: false
        })
    }

    async doLogin(currentPassword: string, currentUsername: string) {
        let userPassword = this.state.password
        let userName = this.state.username
        let loginIncorrect = false;

        if (!this.state.username) {
            if (!currentUsername) {
                loginIncorrect = true;
            } else {
                userName = currentUsername
            }
        }

        if (!userPassword) {
            if (!currentPassword) {
                loginIncorrect = true;
            } else {
                userPassword = currentPassword
            }
        }

        if (loginIncorrect) {
            toast.error("Bitte tragen Sie Ihre Zugangsdaten ein.", {
                autoClose: false
            });
            return false;
        }
        this.setState({
            buttonDisabled: true
        })

        try {
            let result = await AuthorizationService.authAndLogin(userName, userPassword)
            
            if (result && result.success) {
                //https://mobx.js.org/actions.html#asynchronous-actions
                runInAction(() => {
                    UserStore.username = userName;
                    UserStore.rights = result.rights;
                    UserStore.mandantId = result.mandantId;
                })

                console.log("hist1", this.state.hist1)

                if (this.state.hist1 && this.state.hist1 !== "") {
                    window.location.href = "/" + this.state.hist1 + "/" + ((this.state.hist2 !== 'undefined') ? this.state.hist2 : '');
                } else {
                    runInAction(() => {
                        UserStore.isLoggedIn = true;
                    })
                }

                await this.props.setBstSettings()

            } else if (result && result.success === false) {

                toast.error(result.msg, {
                    autoClose: false
                });

                this.resetForm();
                runInAction(() => {
                    UserStore.isLoggedIn = false;
                })
            }

        } catch (e) {
            console.log(e)
            toast.error("System nicht bereit. Bitte wenden Sie sich an Ihren Administrator. " + e, {
                autoClose: false
            });
            this.resetForm();
            runInAction(() => {
                UserStore.isLoggedIn = false;
            })
        }

    }

    render() {
        return (
            <div className="loginForm">
                <div className='center alh_logo'><img src={logo} alt="Alh Logo" /></div>
                <h1 className='loginHeader'>BENUTZER-ANMELDUNG<br />Webportal</h1>
                <InputField
                    type='user'
                    name='username'
                    id='username'
                    placeholder='BST-Nr.'
                    forceAutofill
                    value={this.state.username ? this.state.username : ''}
                    onChange={(val: any) => this.setInputValue('username', val)}
                    onEnter={(value) => this.doLogin(this.state.password, value)}
                />

                <InputField
                    type='password'
                    name='password'
                    id='password'
                    placeholder='Passwort'
                    forceAutofill
                    value={this.state.password ? this.state.password : ''}
                    onChange={(val: any) => this.setInputValue('password', val)}
                    onEnter={(value) => this.doLogin(value, this.state.username)}
                />

                <SubmitButton
                    text='Einloggen'
                    disabled={this.state.buttonDisabled}
                    onClick={() => this.doLogin(this.state.password, this.state.username)}
                />
            </div>
        );
    }
}

export default LoginForm;
