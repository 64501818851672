import React, { Component } from 'react';
import UnderConstruction from "../UnderConstruction";
import HeaderJumbo from "../HeaderJumbo";

class Postbox extends Component {
    render() {
        return (
            <div>
                <HeaderJumbo title="Postbox Inbox" description="Hier sehen Sie alle Nachrichten aus Ihrer Postbox App.">
                </HeaderJumbo>
                <UnderConstruction />
            </div>
        );
    }
}

export default Postbox;