import React, { Component, PropsWithChildren } from "react";

interface IAppSettings {
    apiUrl: string;
    defLogin: string;
    defPswd: string;
}

const ALHContext = React.createContext<IAppSettings>({
    apiUrl: "",
    defLogin: "",
    defPswd: ""
});

//export function useAppContext() { return useContext(ALHContext) }

export class ALHProvider extends Component<PropsWithChildren<any>>{
    state = {
        apiUrl: "",
        defLogin: "",
        defPswd: ""
    }

    constructor(props: { config: { [x: string]: any; }; }) {
        super(props);

        this.state = {
            apiUrl: props.config['url'],
            defLogin: props.config['defLogin'],
            defPswd: props.config['defPswd']
        }
    }

    render() {
        const { apiUrl, defLogin, defPswd } = this.state;
        return (
            <ALHContext.Provider value={{
                apiUrl,
                defLogin,
                defPswd
            }}>
                {this.props.children}
            </ALHContext.Provider>
        )
    }
}

export default ALHContext;