import React from 'react';
import { Form } from "react-bootstrap"
import { InputCheckboxProps } from "./InputCheckbox"

const InputCheckboxForm = ({name, id, text, tabIndex, checked, required, disabled, title, onChange}: InputCheckboxProps) => {
    return <Form.Check
    name={name}
    id={id}
    label={text}
    checked={checked}
    title={title}
    tabIndex={tabIndex}
    required={required}
    onChange={() => onChange(!checked)}
    disabled={disabled}
    />
}

export default InputCheckboxForm