import { VoreinstellungenState } from "../Components/Screens/Voreinstellungen";
import { BstSettings } from "../models/BstSettings";
import { DefaultResponse } from "../models/DefaultResponse";
import { Decision, Ueberweisungsoptionen, ZahlungsweiseSollRechnungen } from "../stores/Constants";
import UserStore from "../stores/UserStore";
import ServiceHelper from "./ServiceHelper";

const MyBstService = {
    get: async (): Promise<BstSettings | undefined> => {
        let res = await fetch(UserStore.url + '/bst', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        const response: DefaultResponse<BstSettings> = await res.json();

        let result: BstSettings | undefined = undefined;

        if (response.success) {
            result = { ...response.data }

            let terms = response.data.zahlungsweiseSollRechnungen?.split(";") ?? []


            result.zahlungsweiseSollRechnungen1 = terms[0] as ZahlungsweiseSollRechnungen
            result.zahlungsweiseSollRechnungen2 = terms[1] as Ueberweisungsoptionen
        }

        return result
    },
    getFull: async () => {
        let res = await fetch(UserStore.url + '/bst/full', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        return await res.json();
    },
    hasSecondBeratungsstelle: async () => {
        let res = await fetch(UserStore.url + '/bst/hasSecondBeratungsstelle', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const response = await res.json();
        return response.data.hasSecondBeratungsstelle
    },
    update: async (item: VoreinstellungenState) => {
        let res = await fetch(UserStore.url + '/bstSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: ServiceHelper.buildRequestBody({
                ...item,
                BSTNr: UserStore.username,
                checkBekanntgabevollmacht: item.checkBekanntgabevollmacht === Decision.yes,
                bstDefaultCheckSeparateFiles: item.bstDefaultCheckSeparateFiles === Decision.yes
            })
        });

        return await res.json();
    }
}

export default MyBstService