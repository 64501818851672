import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import InputCheckbox from "../Forms/InputCheckbox";
import UserStore from "../../stores/UserStore";
import { toast } from "react-toastify";
import { WaveTopBottomLoading } from "react-loadingg";
import TableOperatorOptions from "../Blocks/TableOperatorOptions";
import ReactGA from "react-ga";
import { trackModal } from "../ALHUtility";
import MitarbeiterItem from '../../models/Mitarbeiter';
import Berechtigung from '../../models/Berechtigung';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MitarbeiterEditForm from '../Forms/MitarbeiterEditForm';
import MitarbeiterService from '../../services/MitarbeiterService';

interface MitarbeiterProps extends RouteComponentProps {
    mode?: string
}

interface MitarbeiterState {
    loading: boolean
    mitarbeiter: MitarbeiterItem[]
    readOnlyKeys: { key: string }[]
    showEdit: boolean,
    currentBSTMAId: number,
    currentBSTMName: string,
    currentBerechtigungen: Berechtigung[],
    showEditLoading: boolean,
    search: string,
    selectedMitarbeiter?: MitarbeiterItem
}

class Mitarbeiter extends Component<MitarbeiterProps, MitarbeiterState> {

    constructor(props: MitarbeiterProps) {
        super(props);

        if (!UserStore.hasBerechtigung('bst_ma') && typeof this.props.history !== "undefined") {
            this.props.history.push("/notallowed/");
        }

        this.state = {
            loading: true,
            mitarbeiter: [],
            readOnlyKeys: [
                { key: 'bst_mg' },
                { key: 'bst_grunddaten' },
                { key: 'buha_abrechnung' },
                { key: 'extras_datenexport' },
            ],
            showEdit: false,
            currentBSTMAId: -1,
            currentBSTMName: "",
            currentBerechtigungen: [],
            showEditLoading: true,
            search: ''
        }

        this.permissionClose = this.permissionClose.bind(this);
        this.setBerechtigungCheck = this.setBerechtigungCheck.bind(this);
        this.permissionsSave = this.permissionsSave.bind(this);
    }

    async componentDidMount() {
        try {
            let result = await MitarbeiterService.getMitarbeiter()

            if (result) {
                this.setState({
                    mitarbeiter: result,
                    loading: false
                });
            }

            document.title = 'Mitarbeiter (' + result.length + ') | ' + UserStore.title;

        } catch (e) {
            console.log("Error in loading Mitarbeiter: " + e);
        }
    }

    async permissionsShow(mId: number) {

        trackModal('/mitarbeiter/permissionshow');

        this.setState({
            currentBerechtigungen: [],
            showEditLoading: true,
            showEdit: true
        });

        let result = await MitarbeiterService.getMitarbeiterBerechtigungen(this.state.mitarbeiter[mId].bstmaId);

        this.setState({
            showEditLoading: false,
            currentBSTMName: this.state.mitarbeiter[mId].nachname + ", " + this.state.mitarbeiter[mId].vorname,
            currentBSTMAId: this.state.mitarbeiter[mId].bstmaId,
            currentBerechtigungen: result
        })
    }

    async permissionsSave() {

        //prepare data
        let thisBerechtigungen = this.state.currentBerechtigungen;
        let tempArray: Berechtigung[] = [];

        for (var i = 0; i < thisBerechtigungen.length; i++) {
            tempArray[i] = {
                "schluessel": thisBerechtigungen[i].schluessel,
                "checked": thisBerechtigungen[i].checked,
                "art": ((thisBerechtigungen[i].art === 2) ? 2 : 1),
            };
        }

        //Send data
        let response = await MitarbeiterService.saveMitarbeiterBerechtigungen(Number(this.state.currentBSTMAId), tempArray);

        if (response.success) {

            this.permissionClose();

            toast.success("Berechtigungen wurden gespeichert.", {
                autoClose: 5000
            });

            if (window.location.href.indexOf("localhost") === -1) {
                ReactGA.event({
                    category: 'User',
                    action: 'Save Permission'
                });
            }

        }
    }

    permissionClose() {
        this.setState({
            showEdit: false,
            showEditLoading: false,
            currentBSTMAId: -1,
            currentBSTMName: "",
            //currentBerechtigungen: []
        })
    }

    setBerechtigungCheck(key: string, val: boolean, mode = 'normal') {
        let thisBerechtigungen = this.state.currentBerechtigungen;

        thisBerechtigungen = thisBerechtigungen.map((b) => {
            if (b.schluessel === key) {
                if (mode === 'normal') {
                    b.checked = val;
                } else {
                    b.art = (val ? 2 : 1);
                }
                return b;
            }
            return b;
        })

        this.setState({
            currentBerechtigungen: thisBerechtigungen
        });
    }

    changeSearch(val: string) {
        this.setState({
            search: val
        })
    }

    isReadOnlyPresent(key: string) {
        return this.state.readOnlyKeys.some(item => key === item.key);
    }

    setSelectedMitarbeiter(mitarbeiter?: MitarbeiterItem) {
        this.setState({
            selectedMitarbeiter: mitarbeiter
        })
    }

    render() {

        let search = this.state.search;
        const selectedMitarbeiter = this.state.selectedMitarbeiter
        let list = this.state.mitarbeiter.filter(m => !m.neuAngelegt).filter((item) => {
            return (
                item.vorname.toLowerCase().includes(search.toLowerCase()) ||
                item.nachname.toLowerCase().includes(search.toLowerCase())
            );
        });

        const neuAngelegteMitarbeiter = this.state.mitarbeiter.filter(m => m.neuAngelegt)

        return (
            <div>
                <h3 className="alh_pageheader">Mitarbeiter</h3>
                <div className={(this.props.mode === "small") ? "d-none" : "alh_subheader"}>
                    Hier sehen Sie die angemeldeten <span className="alh_bold">Mitarbeiter</span> Ihrer Beratungsstelle. Mit Klick auf das Symbol bei <span className="alh_bold">Stammdaten</span> können Sie diese öffnen und uns Änderungswünsche mitteilen.
                    Über das Symbol bei <span className="alh_bold">Berechtigungen</span> können Sie diese für den jeweiligen Mitarbeiter bearbeiten. Dies ist nur möglich, wenn für Ihren Mitarbeiter ein WebPortal-Zugang angelegt wurde.
                    Wollen Sie einen neuen Mitarbeiter registrieren lassen, klicken Sie hierzu bitte auf folgenden Button.
                </div>

                <Button style={{ marginBottom: "0.5rem" }} href='/mitarbeiter/neu'>Neuen Mitarbeiter anlegen</Button>

                {neuAngelegteMitarbeiter.length ? <h5>Neu angelegte Mitarbeiter - in Bearbeitung</h5> : undefined}

                {neuAngelegteMitarbeiter.length ? <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th>Mitarbeiter-ID</th>
                            <th>Vorname</th>
                            <th>Nachname</th>
                        </tr>
                    </thead>
                    <tbody style={!neuAngelegteMitarbeiter.length ? undefined : { opacity: 0.5 }}>
                        {neuAngelegteMitarbeiter && neuAngelegteMitarbeiter.map((aMitarbeiter, _) => (
                            <tr key={aMitarbeiter.bstmaId}>
                                <td>{aMitarbeiter.bstmaId}</td>
                                <td>{aMitarbeiter.vorname}</td>
                                <td>{aMitarbeiter.nachname}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table> : undefined}

                <TableOperatorOptions
                    name="Mitarbeiter"
                    placeholder="Suche nach Name"
                    onChange={(val) => this.changeSearch(val)}
                    value={this.state.search}
                    print={true}
                    helpfile="mitarbeiterliste.json"
                />

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th>Mitarbeiter-ID</th>
                            <th>Vorname</th>
                            <th>Nachname</th>
                            <th className='alh_noprint'>Stammdaten</th>
                            <th className='alh_noprint'>Berechtigungen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && list.map((aMitarbeiter, index) => (
                            <tr key={aMitarbeiter.bstmaId}>
                                <td>{aMitarbeiter.bstmaId}</td>
                                <td>{aMitarbeiter.vorname}</td>
                                <td>{aMitarbeiter.nachname}</td>
                                <td className="tableActions alh_noprint">
                                    <FontAwesomeIcon href='#mitarbeiterForm' onClick={() => this.setSelectedMitarbeiter(aMitarbeiter)} icon={faUserEdit} title="Stammdaten"
                                        className={"faButton faButtonSpaceright"}
                                    />
                                </td>
                                <td className="tableActions alh_noprint">
                                    {aMitarbeiter.bstmaPortalAktiv && <FontAwesomeIcon onClick={() => this.permissionsShow(index)} icon={faEdit} title="Berechtigungen"
                                        className={"faButton faButtonSpaceright"}
                                    />}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>


                <div id="mitarbeiterForm">
                    {selectedMitarbeiter && <MitarbeiterEditForm mitarbeiter={selectedMitarbeiter} />}
                </div>

                {this.state.mitarbeiter && this.state.mitarbeiter.length === 0 && !this.state.loading &&
                    <div className="info">Keine Mitarbeiter angelegt!</div>
                }

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}


                <Modal
                    show={this.state.showEdit}
                    onHide={this.permissionClose}
                    backdrop="static"
                    keyboard={true}
                    ref={React.createRef()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Berechtigungen bearbeiten</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <small>Hier können Sie die Berechtigungen von <span className="alh_bold">{this.state.currentBSTMName}</span> anpassen:</small>
                        <br />
                        <br />

                        {this.state.showEditLoading &&
                            <div className="alhLoadingRel"><WaveTopBottomLoading color="#ffc800" /></div>
                        }

                        {!this.state.showEditLoading &&
                            <form id="mitarbeiterPerm">

                                <Table striped bordered hover size="sm" className="alhTable">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Berechtigung</th>
                                            <th>Nur Lesen</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.currentBerechtigungen && this.state.currentBerechtigungen.map((aBerechtigung, index) => (

                                            <tr key={aBerechtigung.schluessel}>
                                                <td>
                                                    <InputCheckbox
                                                        name={aBerechtigung.schluessel}
                                                        checked={aBerechtigung.checked}
                                                        onChange={(val) => this.setBerechtigungCheck(aBerechtigung.schluessel, val)}
                                                        text=''
                                                        subtext=''
                                                        id={index.toString()}
                                                        containerClassName='formInline formInlineSpacing'
                                                        title='Zugriff gewähren'
                                                    />
                                                </td>

                                                <td>{aBerechtigung.bezeichnung}</td>

                                                <td>
                                                    {this.isReadOnlyPresent(aBerechtigung.schluessel) &&
                                                        <InputCheckbox
                                                            name={aBerechtigung.schluessel + "_read"}
                                                            checked={aBerechtigung.art === 2}
                                                            onChange={(val) => this.setBerechtigungCheck(aBerechtigung.schluessel, val, 'read')}
                                                            text=''
                                                            subtext=''
                                                            id={index + "_read"}
                                                            containerClassName='formInline formInlineSpacing'
                                                            title='Nur Lesen'
                                                            disabled={!aBerechtigung.checked}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>

                            </form>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.permissionClose}>
                            Schliessen
                        </Button>
                        <Button variant="success" onClick={this.permissionsSave}>
                            Speichern
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

export default withRouter(Mitarbeiter);