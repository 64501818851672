import ReactGA from "react-ga";
import MatomoTracker from '@datapunt/matomo-tracker-js'
import StringIndex from "../models/StringIndex";

export function getCurrentDate(separator = '') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

export const downloadFile = (base64String: string, filename: string) => {
    const blob = b64toBlob(base64String, "application/octet-stream");

    const link = document.createElement("a");
    link.setAttribute('target', '_blank')
    link.href = window.URL.createObjectURL(blob);
    link.download = filename
    link.click();
}

export function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

/** GA Wrapper */
export function trackEvent(cat: string, act: string, label = "") {

    if (window.location.href.indexOf("localhost") === -1) {
        ReactGA.event({
            category: cat,
            action: act,
            label: label,
        });

        const MaTracker = new MatomoTracker({
            urlBase: 'https://matomo.alh-webservice.de',
            siteId: 5,
        });
        MaTracker.trackEvent({
            category: cat,
            action: act,
            name: label, // optional
            //value: 123, // optional, numerical value
        });

    } else {
        console.log("Track Event: " + cat + " | " + act + " | " + label);
    }

}

export function trackModal(name: string) {

    if (window.location.href.indexOf("localhost") === -1) {
        ReactGA.modalview(name);
    }

    const MaTracker = new MatomoTracker({
        urlBase: 'https://matomo.alh-webservice.de',
        siteId: 5,
    });
    MaTracker.trackEvent({
        category: 'modalview',
        action: 'open',
        name: name, // optional
        //value: 123, // optional, numerical value
    });

}

export function trackException(descr: string, fatal = false) {

    if (window.location.href.indexOf("localhost") === -1) {
        ReactGA.exception({
            description: descr,
            fatal: fatal
        });
    };

    const MaTracker = new MatomoTracker({
        urlBase: 'https://matomo.alh-webservice.de',
        siteId: 5,
    });
    MaTracker.trackEvent({
        category: 'exception',
        action: 'error',
        name: descr, // optional
        //value: 123, // optional, numerical value
    });

}


export function sortList<Type extends StringIndex>(sort: string, list: Type[], sort_mode: string, date = false, date_field = 'year', fallback = 'mgNr'): Type[] {

    if (date) {

        list.sort((a, b) => {

            let sortA = new Date(a[sort]);
            let sortB = new Date(b[sort]);
            let valuesToCompareA: number;
            let valuesToCompareB: number;
            switch (date_field) {
                case 'month':
                    valuesToCompareA = sortA.getMonth();
                    valuesToCompareB = sortB.getMonth();
                    break;
                case 'ts':
                    valuesToCompareA = sortA.getTime();
                    valuesToCompareB = sortB.getTime();
                    break;

                default:
                    valuesToCompareA = sortA.getFullYear();
                    valuesToCompareB = sortB.getFullYear();
            }

            let fallbackA = new Date(a[sort]).getDate();
            let fallbackB = new Date(b[sort]).getDate();

            if (sort_mode === 'desc') {
                return (valuesToCompareA > valuesToCompareB) ? -1 : ((sortA === sortB) ? ((fallbackA < fallbackB) ? -1 : 1) : 1);
            } else {
                return (valuesToCompareA < valuesToCompareB) ? -1 : ((sortA === sortB) ? ((fallbackA < fallbackB) ? -1 : 1) : 1);
            }

        });

    } else {

        if (sort_mode === 'desc') {
            list.sort((a, b) => (a[sort] > b[sort]) ? -1 : (a[sort] === b[sort]) ? ((a[fallback] < b[fallback]) ? -1 : 1) : 1)
        } else {
            list.sort((a, b) => (a[sort] < b[sort]) ? -1 : (a[sort] === b[sort]) ? ((a[fallback] < b[fallback]) ? -1 : 1) : 1)
        }

    }

    return list;
}
