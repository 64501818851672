import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle, faComment, faCommentSlash,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import InputCheckbox from "../Forms/InputCheckbox";
import Frage, { Fragengruppe } from '../../models/Frage';

interface AccordeonBpProps {
    item: Fragengruppe
    allNo?: boolean
    openBody: boolean
    saveStatus: number
    fragen: Frage[]
    allNoVisible: boolean
    toggleAccordeon: (id: number, saveBp: boolean) => void
    showNotizModal: (frageId: number) => void
    calcReadyAccordeonBp: (fragengruppe: number) => number
    handleSwitchAllNo: (val: boolean, fragengruppe: number) => void
    handleChangeFrage: (fragenId: number, val: number, option?: string) => void
}

interface AccordeonBpState {
    name: string
    id: number
}

class AccordeonBP extends Component<AccordeonBpProps, AccordeonBpState>{

    constructor(props: AccordeonBpProps) {
        super(props);

        let thisItem = this.props.item;

        this.state = {
            name: thisItem.name ?? '',
            id: thisItem.id
        };
    }

    toggleAccordion() {
        this.props.toggleAccordeon(this.state.id, false)
    }

    nextFragenGruppe() {
        this.toggleAccordion();
        this.props.toggleAccordeon(this.state.id + 1, true);
    }

    hasNotiz(item: Frage) {
        let result = false;
        if (item.notiz || item.belegFehlt || item.belegFehltNotiz) result = true;
        if (item.fragenAenderungNotizen.length > 0) result = true;

        return result;
    }

    render() {

        let thisFragenStat = this.props.calcReadyAccordeonBp(this.state.id);

        return (
            <div className={((this.props.openBody ? 'alhAcc_Open' : 'alhAcc_Close')) + " alhAcc_Item"} key={this.state.id}>
                <div className="alhAcc_Header clearfix" onClick={(e) => { this.toggleAccordion() }}>
                    <div className="alhAll_HeaderTitle">{this.state.name}</div>
                    {(this.props.allNoVisible && this.props.saveStatus !== 2) &&
                        <div className="alhAll_HeaderCheck">
                            <InputCheckbox
                                name={'allNo_' + this.state.id}
                                id={'allNo_' + this.state.id}
                                checked={this.props.allNo}
                                onChange={() => this.props.handleSwitchAllNo(!!this.props.allNo, this.state.id)}
                                title='Alle auf "Nein" stellen'
                                text='Nein'
                                disabled={this.props.saveStatus === 2}
                                containerClassName="alhAcc_checkboxinline"
                            />
                        </div>
                    }
                    <div className="alhAll_HeaderInfo">
                        <span>
                            {thisFragenStat === this.props.fragen.length &&
                                <FontAwesomeIcon icon={faCheckCircle} className="alhBP_LegCheck" />}
                        </span>
                        <span>({thisFragenStat}/{this.props.fragen.length})</span>
                    </div>
                </div>
                <div className="alhAcc_Body">

                    {this.props.fragen.sort((a, b) => (a.frageSortierung && b.frageSortierung && a.frageSortierung > b.frageSortierung) ? 1 : -1)
                        .map((item, index) => (
                            <div className="alhAcc_Frage clearfix" key={index}>
                                <div className="alhAcc_FrageCheck">
                                    <div className={((item.optionen?.length ?? -1 > 0) ? "alhAcc_noshow" : "")}>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Ja"
                                                name={item.id + "_f"}
                                                checked={item.fragenAnswer === 1}
                                                disabled={this.props.saveStatus === 2}
                                                onChange={(e) => this.props.handleChangeFrage(item.id, 1)} /> Ja</label>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Nein"
                                                name={item.id + "_f"}
                                                checked={item.fragenAnswer === 2}
                                                disabled={this.props.saveStatus === 2}
                                                onChange={(e) => this.props.handleChangeFrage(item.id, 2)} /> Nein</label>
                                    </div>
                                </div>

                                <div>
                                    <div className="alhAcc_FrageName">
                                        <div>{item.frageName}</div>
                                        {item.optionen?.map((aOption, aOptIndex) => (
                                            <label className="alhAcc_FrageOptCheck" key={aOptIndex}>
                                                <input
                                                    type="radio"
                                                    value={aOption.id}
                                                    name={aOption.frageID + "_fo"}
                                                    disabled={this.props.saveStatus === 2}
                                                    checked={(item.fragenOptionenAnswer === aOption.optiontext)}
                                                    onChange={() => this.props.handleChangeFrage(item.id, 1, aOption.optiontext)}
                                                >
                                                </input> {aOption.optiontext}
                                            </label>
                                        ))}


                                    </div>
                                    <div className={"alh_iconright alh_clickicon " + (this.hasNotiz(item) ? 'alhBP_LegCheck' : 'alhBP_LegGrey')} title={item.notiz ? item.notiz : 'Kein Kommentar vorhanden'}>
                                        <FontAwesomeIcon onClick={() => this.props.showNotizModal(item.id)}
                                            icon={this.hasNotiz(item) ? faComment : faCommentSlash} />
                                    </div>
                                </div>

                            </div>
                        ))
                    }


                    {this.props.saveStatus !== 2 &&
                        <div className="alhAcc_next">
                            <Button variant="secondary" size="sm" onClick={() => {
                                this.nextFragenGruppe()
                            }}>Speichern und weiter</Button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default AccordeonBP;
