import React, { Component } from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import UserStore from "../stores/UserStore";
import Parser from 'rss-parser';

import bgImage from '../assets/images/bg_widget_news.png';

//https://www.npmjs.com/package/react-animated-slider
//https://codesandbox.io/s/p582xl40j?file=/styles.css:602-4268
//https://reactjsexample.com/animated-slider-carousel-component-for-react/

interface SlideProps {
    title: string;
    description: string;
    button: string;
    image: string;
    link: string;
}

interface DownloadSliderState {
    loading: boolean;
    slides: SlideProps[];
}

class DownloadSlider extends Component<{}, DownloadSliderState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            loading: true,
            slides: [],
        }
    }

    async componentDidMount() {

        try {

            let slides: SlideProps[] = [];

            let parser = new Parser();

            await (async () => {
                let url = 'https://intern.aktuell-verein.de/feed/';
                if (UserStore.mandantId === 2) { //1=akt     2=alt
                    url = 'https://intern.altbayerischer.de/feed/';
                }
                let feed = await parser.parseURL(url);

                feed.items.forEach(item => {
                    slides.push({
                        title: !!item.title ? item.title : '',
                        description: !!item.content ? item.content : '',
                        button: "Mehr erfahren",
                        image: "",
                        //image: "https://www.aktuell-verein.de/wp-content/uploads/2016/09/iStock_70091675_1086x724-768x512.jpg",
                        link: !!item.link ? item.link : '',
                    });
                });
            })();

            this.setState({
                loading: false,
                slides: slides,
            });

        } catch (e) {
            console.log("Error while loading feed --- Fallback");

            if (UserStore.mandantId === 1) {
                this.setState({
                    loading: false,
                    slides: [
                        {
                            title: 'Neues WebPortal ab 15.11. – Vorstellungswebinar',
                            description:
                                '',
                            button: 'Mehr erfahren',
                            image: 'https://www.aktuell-verein.de/wp-content/uploads/2016/09/iStock_70091675_1086x724-768x512.jpg',
                            link: 'https://intern.aktuell-verein.de/neues-webportal-ab-15-11-vorstellungswebinar/',
                        }
                    ]
                })
            } else if (UserStore.mandantId === 2) {
                this.setState({
                    loading: false,
                    slides: [
                        {
                            title: 'Neues WebPortal ab 15.11. – Vorstellungswebinar',
                            description:
                                '',
                            button: 'Mehr erfahren',
                            image: 'https://www.aktuell-verein.de/wp-content/uploads/2016/09/iStock_70091675_1086x724-768x512.jpg',
                            link: 'https://intern.altbayerischer.de/neues-webportal-ab-15-11-vorstellungswebinar/',
                        }
                    ]
                })
            }
        }

    }

    render() {

        return (
            <Slider
                clssName="slider-wrapper"
                autoplay={4000}>
                {this.state.slides.map((slide, index) => (
                    <div
                        key={index}
                        className="slider-content slider-small"
                        style={(slide.image) ? { background: `url('${slide.image}') no-repeat center center` } : { background: `url('${bgImage}') no-repeat center center` }}
                    >
                        <div className="inner">
                            <h1 className="slider_title">{slide.title}</h1>
                            <p className="slider_body">{(slide.description).replace("[&#8230;]", "...")}</p>
                            <a href={slide.link} target="_blank" rel="noopener noreferrer">
                                <button>{slide.button}</button>
                            </a>
                        </div>
                    </div>
                )
                )}
            </Slider>
        );
    }
}

export default DownloadSlider;
