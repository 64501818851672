import ChecklistItem from "../models/ChecklistItem"
import UserStore from "../stores/UserStore";

const fetchEmail = async (url: string, protId: number, checklistItems: ChecklistItem[], mgNr: string) => {
    let res = await fetch(UserStore.url + url, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            attr: {
                dataset: {
                    bstnr: UserStore.username,
                    protid: protId,
                    mgNr: mgNr,
                    checklistItems: checklistItems.map(c => {
                        if (c.taskDoc) {
                            return { beleg: c.taskDoc.bezeichnung }
                        }
                        else {
                            return { beleg: c.frage!.belegFehltNotiz || c.frage!.frageName }
                        }
                    })
                }
            }
        })
    });

    return await res.json()
}

const BeratungsprotokollService = {
    sendEmail: async (protId: number, checklistItems: ChecklistItem[], mgNr: string) => {
        return await fetchEmail('/checklisteEmail', protId, checklistItems, mgNr)
    },
    getEmailBody: async (protId: number, checklistItems: ChecklistItem[], mgNr: string) => {
        return await fetchEmail('/getChecklisteEmail', protId, checklistItems, mgNr)
    },
    downloadPdf: async (protId: number, mgNr: string) => {
        let res = await fetch(UserStore.url + "/getFehlendeBelegPdf", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    dataset: {
                        bstnr: UserStore.username,
                        protid: protId,
                        mgNr: mgNr
                    }
                }
            })
        });

        return await res.json()
    }
}

export default BeratungsprotokollService