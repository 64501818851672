import React, { Component } from 'react';
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import InputCheckbox from "../Forms/InputCheckbox";
import SubmitButton from "../Forms/SubmitButton";
import Moment from "react-moment";
import InfoIcon from "../Blocks/InfoIcon";
import Buchung from '../../models/Buchung';
import Leistung from '../../models/Leistung';
import MitgliedService from '../../services/MitgliedService';

interface MitgliederOPBase {
    oPs: Buchung[]
    oLs: Leistung[]
    mGNr?: string
    gesamtoffen: number
    active: boolean
}

interface MitgliederOPProps extends MitgliederOPBase {
    zahlungsweise: string
    showBuchung: (text: string, modeHandle?: { mode?: number, handle?: number, modalOpenedForEdit?: boolean }) => void
}

interface MitgliederOPState extends MitgliederOPBase {
    oneSammelrechnungChecked: boolean
    oneSammelrechnungCheckedoLs: boolean
    checkAll: boolean
    zahlungsweiseRechnungen: number
    listZKD: number
}


class MitgliederOP extends Component<MitgliederOPProps, MitgliederOPState> {

    constructor(props: MitgliederOPProps) {
        super(props);

        this.state = {
            oPs: this.props.oPs,
            oLs: this.props.oLs,
            oneSammelrechnungChecked: false,
            oneSammelrechnungCheckedoLs: false,

            mGNr: this.props.mGNr,
            gesamtoffen: this.props.gesamtoffen,

            checkAll: false,
            active: false,

            zahlungsweiseRechnungen: -1,
            listZKD: -1
        }
    }

    static getDerivedStateFromProps(nextProps: MitgliederOPProps, state: MitgliederOPState) {
        if (nextProps.oPs !== state.oPs) {
            return ({ oPs: nextProps.oPs });
        }
        if (nextProps.oLs !== state.oLs) {
            return ({ oLs: nextProps.oLs });
        }
        if (nextProps.gesamtoffen !== state.gesamtoffen) {
            return ({ gesamtoffen: nextProps.gesamtoffen });
        }
        return null;
    }

    async printSammelrechnung(mode: string) {
        //get all checkd items
        let RechOffenePosten = "";
        if (mode === "oPs") {
            this.state.oPs.forEach((item) => {
                if (typeof item['checked'] !== "undefined" && item['checked']) {
                    if (RechOffenePosten !== "") RechOffenePosten += ",";
                    RechOffenePosten += item['vorgangsart'] + "|" + item['konto'];
                }
            });
        }
        let RechLeistungHandles = "";
        if (mode === "oLs") {
            this.state.oLs.forEach((item) => {
                if (item.checked) {
                    if (RechLeistungHandles !== "") RechLeistungHandles += ",";
                    RechLeistungHandles += item.buchungHandle;
                }
            });
        }

        await MitgliedService.mitgliedPrintFile(
            this.state.mGNr ?? "",
            "rptMGRechnung",
            "D",
            "RechZahlungsweise:=" + this.props.zahlungsweise + ";RechBuchungHandles:=;RechOffenePosten:=" + RechOffenePosten + ";RechLeistungHandles:=" + RechLeistungHandles + ";",
            this.state.mGNr + "_Buchung.pdf")
    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    setCheckLine(index: number, value: boolean, mode = 'oPs') {
        let thisList: Buchung[] | Leistung[] = this.state.oPs;
        if (mode === 'oLs') {
            thisList = this.state.oLs;
        }
        thisList[index].checked = value;

        //calc if at least one is checked
        let sumChecked = 0;
        thisList.forEach((item: Buchung | Leistung) => {
            if (item.checked) {
                sumChecked += 1;
            }
        });

        if (mode === 'oPs') {
            this.setState({
                oPs: thisList as Buchung[],
                oneSammelrechnungChecked: (sumChecked > 0),
            })
        } else if (mode === 'oLs') {
            this.setState({
                oLs: thisList as Leistung[],
                oneSammelrechnungCheckedoLs: (sumChecked > 0),
            })
        }
    }

    getoLsPresent() {
        let result = [];

        for (let i = 0; i < this.state.oLs.length; i++) {
            result.push(this.state.oLs[i].vorgangsart + ' ' + this.state.oLs[i].beitragsjahr);
        }

        return result;
    }

    setAllChecked(val: boolean) {
        let list = this.state.oPs;
        list.map(item => item.checked = val);

        this.setState({
            checkAll: val,
            oneSammelrechnungChecked: val,
            oPs: list,
        });
    }

    render() {

        let listoPs = this.state.oPs;
        let listoLs = this.state.oLs;

        return (
            <div>
                <h3>Offene Vorgänge<InfoIcon space="left" placement="right"><small>Hier sehen Sie alle offenen Vorgänge des Mitglieds.<br />Um eine Rechnung für das Mitglied zu drucken, setzen Sie bei den gewünschten Vorgängen den Haken, die auf der Rechnung erscheinen sollen.Möchten Sie alle offenen Vorgänge als Rechnung drucken, setzen Sie den Haken vor “Vorgang”. Die Rechnung können Sie anschließend aufrufen, indem Sie auf den Button “Sammelrechnung drucken (Vorgänge)” klicken.</small></InfoIcon></h3>
                <div className="alh_subheader">Gesamtbetrag offen: <span className="warning-text">{this.numberFormat(this.state.gesamtoffen)}</span></div>

                <SubmitButton
                    type='button'
                    disabled={!this.state.oneSammelrechnungChecked}
                    onClick={() => this.printSammelrechnung('oPs')}
                    text="Sammelrechnung drucken (Vorgänge)"
                    size="sm"
                /><br />

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th className="alh_tablecheckbox">
                                <InputCheckbox
                                    name={"samml_all"}
                                    title="Alle auswählen"
                                    checked={this.state.checkAll}
                                    onChange={(val) => this.setAllChecked(val)}
                                />
                            </th>
                            <th>Vorgang</th>
                            <th className="text-right">Betrag</th>
                            <th>Buchen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listoPs.map((mg, index) => {
                            let reducedKonto = mg.konto;
                            if (mg.vorgangsart) {
                                reducedKonto = mg.konto?.replace(mg.vorgangsart, "");
                            }

                            return (
                                <tr key={index}>
                                    <td className="alh_tablecheckbox">
                                        <InputCheckbox
                                            name={"samml_" + index}
                                            title="Sammelrechnung"
                                            checked={this.state.oPs[index]['checked']}
                                            onChange={(val) => this.setCheckLine(index, val)}
                                        />
                                    </td>
                                    <td onClick={() => this.props.showBuchung(mg.vorgangsart + ((mg.vorgangsart !== reducedKonto && (reducedKonto?.length ?? -1) > 0) ? " " + reducedKonto : ""), { modalOpenedForEdit: true })} className="alh_onclicklink">{mg.vorgang}</td>
                                    <td className="text-right">{this.numberFormat(mg.betrag ?? 0)}</td>
                                    <td className="tableActions">
                                        <FontAwesomeIcon icon={faPlus} onClick={() => this.props.showBuchung(mg.vorgangsart + ((mg.vorgangsart !== reducedKonto) ? " " + reducedKonto : ""), { modalOpenedForEdit: true })} title="Buchung erzeugen" className="faButton alh_onclicklink" />
                                    </td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </Table>

                {listoPs.length === 0 &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }

                <br /><br />




                <h3>Offene Leistungen</h3>

                <SubmitButton
                    type='button'
                    disabled={!this.state.oneSammelrechnungCheckedoLs}
                    onClick={() => this.printSammelrechnung('oLs')}
                    text="Sammelrechnung drucken (Leistungen)"
                    size="sm"
                /><br />

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th className="alh_tablecheckbox">&nbsp;</th>
                            <th>L-Datum</th>
                            <th>Buchungstext</th>
                            <th className="text-right">Haben</th>
                            <th className="text-right">Nachweis</th>
                            <th className="text-right">Bezahlt</th>
                            <th>Zahlungsw.</th>
                            <th className="alh_tableactions">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listoLs.map((mg, index) => (
                            <tr key={index}>
                                <td className="alh_tablecheckbox">
                                    <InputCheckbox
                                        name={"samml_" + index}
                                        title="Sammelrechnung"
                                        checked={this.state.oLs[index]['checked']}
                                        onChange={(val) => this.setCheckLine(index, val, 'oLs')}
                                    />
                                </td>
                                <td><Moment format="DD.MM.YYYY">{mg.leistungsdatum}</Moment></td>
                                <td onClick={() => this.props.showBuchung(mg.buchungstext, { mode: 2, handle: mg.buchungHandle })} className="alh_onclicklink">{mg.buchungstext}</td>
                                <td className="text-right">{this.numberFormat(mg.betrag)}</td>
                                <td className="text-right">{mg.istNachweisErhalten ? "Ja" : "Nein"}</td>
                                <td className="text-right">{mg.istBezahlt ? "Ja" : "Nein"}</td>
                                <td className="text-right">{mg.zahlungsweise}</td>
                                <td className="tableActions">
                                    <FontAwesomeIcon icon={faEdit} onClick={() => this.props.showBuchung(mg.buchungstext, { 'mode': 2, 'handle': mg.buchungHandle })} title="Buchung ändern" className="faButton alh_onclicklink" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {listoLs.length === 0 &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }


            </div>
        );
    }
}

export default MitgliederOP;
