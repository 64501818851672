import React, { Component } from "react";

class Datasecurity extends Component {
    render() {
        return (
            <div>
                <h2>Datenschutz&shy;erklärung</h2>
                <div className="wpb_wrapper">
                    <p>Wir freuen uns über Ihren Besuch auf unserer Webseite und Ihr Interesse an unserem Unternehmen.
                        Der Schutz Ihrer personenbezogenen Daten ist für uns ein wichtiges Anliegen und wir möchten,
                        dass Sie sich beim Besuch unserer Internetseiten sicher fühlen.</p>
                    <p>Hier erläutern wir, welche Informationen wir bei Ihrem Besuch auf unserer Webseite erfassen und
                        wie diese genutzt werden.</p>
                    <h3>Unsere Kontaktdaten als verantwortliche Stelle</h3>
                    <p>Unsere Kontaktdaten finden Sie im <a
                        href="/impressum">Impressum</a>.</p>
                    <h3>Datenschutzbeauftragter</h3>
                    <p>Unseren Datenschutzbeauftragten können Sie direkt erreichen über <a
                        href="mailto:datenschutz@aktuell-verein.de">datenschutz@aktuell-verein.de</a>.</p>
                    <h3>Bereitstellung unserer Webseite</h3>
                    <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf
                        einer Webseite speichert der Webserver lediglich automatisch IP-Adresse, Datum und Uhrzeit der
                        Anfrage, Zeitzonendifferenz zur Greenwich Mean Time (GMT), Inhalt der Anforderung (konkrete
                        Seite), Zugriffsstatus/HTTP-Statuscode, jeweils übertragene Datenmenge, Webseite, von der die
                        Anforderung kommt, Browser, Betriebssystem und dessen Oberfläche sowie Sprache und Version der
                        Browsersoftware. Diese Daten werden niemals konkret zu Ihrer Person gespeichert. Diese
                        Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs
                        der Seite sowie der Verbesserung unseres Angebots ausgewertet. Soweit die IP-Adresse als
                        personenbezogen anzusehen ist, wird sie spätestens nach Ablauf von 90 Tagen gelöscht, wenn keine
                        anderen zwingenden Gründe entgegenstehen.</p>
                    <h3>Verwendung von Links</h3>
                    <p>Auf der Webseite des Vereins können Links (Verknüpfungen/Verweise auf andere Webseiten) enthalten
                        sein. Folgen Sie einem dieser Links, bitten wir Sie zu beachten, dass diese Webseiten eigenen
                        Datenschutzerklärungen unterliegen und der Verein keine Verantwortung oder Garantie für die
                        Datenschutzbedingungen dieser Webseiten übernehmen kann.</p>
                    <h3>Umgang mit personenbezogenen Daten</h3>
                    <p>Personenbezogene Daten sind Informationen, mit deren Hilfe eine Person bestimmbar ist, also
                        Angaben, die zurück zu einer Person verfolgt werden können. Dazu gehören der Name, die
                        Emailadresse oder die Telefonnummer. Aber auch Daten über Vorlieben, Hobbies, Mitgliedschaften
                        oder welche Webseiten von jemandem angesehen wurden zählen zu personenbezogenen Daten.</p>
                    <p>Personenbezogene Daten werden von dem Anbieter nur dann erhoben, genutzt und weitergegeben, wenn
                        dies gesetzlich erlaubt ist oder die Nutzer in die Datenerhebung einwilligen.</p>
                    <h3>Ihre Rechte</h3>
                    <p>Sie haben das Recht hinsichtlich Ihrer personenbezogenen Daten von uns Auskunft, Berichtigung,
                        Löschung oder Einschränkung nach den gesetzlichen Regelungen zu verlangen. Wir stellen Ihnen
                        Ihre Daten außerdem in einem strukturierten, gängigen und maschinenlesbaren Format zur
                        Verfügung. Wenn wir Ihre Daten aufgrund einer Einwilligung von Ihnen verwenden, können Sie Ihre
                        Einwilligung jederzeit widerrufen und die Löschung dieser Daten verlangen. Ab dem Widerruf
                        verwenden wir Ihre Daten nicht mehr für diesen Zweck. Sollten wir ein berechtigtes Interesse zur
                        Verarbeitung Ihrer Daten verfolgen, können Sie sich mit einem Widerspruch an <a
                            href="https://www.aktuell-verein.de/der-verein/impressum/">diese Kontaktdaten</a> wenden.
                        Davon unabhängig können Sie stets auch die zuständigen Aufsichtsbehörden kontaktieren.</p>
                    <h3>Kontaktaufnahme</h3>
                    <p>Bei der Kontaktaufnahme mit dem Verein (zum Beispiel per Kontaktformular oder E-Mail) werden Ihre
                        Angaben zwecks Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen entstehen,
                        gespeichert.</p>
                    <h3>Widerspruchsrecht</h3>
                    <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1
                        Satz 1 lit f. DS-GVO verarbeitet werden, haben Sie das Recht, Widerspruch gegen die Verarbeitung
                        Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer
                        besonderen Situation ergeben. Wir verarbeiten die personenbezogenen Daten im Falle eines
                        berechtigten Widerspruchs dann nicht mehr.</p>
                    <h3>Löschen von Daten</h3>
                    <p>Soweit Ihre Daten nicht mehr für die genannten Zwecke erforderlich sind oder wenn ihre
                        Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist, werden wir diese löschen. Hiervon
                        ausgenommen sind ausschließlich rechtmäßig erhobene Daten, zu deren Aufbewahrung wir aus
                        gesetzlichen Gründen verpflichtet sind. Bitte beachten Sie, dass bei jeder Löschung die Daten
                        zunächst nur gesperrt und dann erst mit zeitlicher Verzögerung endgültig gelöscht werden, um
                        versehentlichen Löschungen oder evtl. vorsätzlichen Schädigungen vorzubeugen. Aus technischen
                        Gründen werden Daten ggf. in Datensicherungsdateien und Spiegelungen von Diensten dupliziert.
                        Solche Kopien werden ebenfalls ggf. erst mit einer technisch bedingten zeitlichen Verzögerung
                        gelöscht.</p>
                    <h3>Einbindung von Diensten und Inhalten Dritter</h3>
                    <p>Es kann vorkommen, dass innerhalb dieses Onlineangebotes Inhalte Dritter, wie zum Beispiel Videos
                        von YouTube, Kartenmaterial von Google-Maps, RSS-Feeds oder Grafiken von anderen Webseiten
                        eingebunden werden. Dies setzt immer voraus, dass die Anbieter dieser Inhalte (nachfolgend
                        bezeichnet als „Dritt-Anbieter“) die IP-Adresse der Nutzer wahrnehmen. Denn ohne die IP-Adresse,
                        könnten sie die Inhalte nicht an den Browser des jeweiligen Nutzers senden. Die IP-Adresse ist
                        damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu
                        verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
                        verwenden. Jedoch haben wir keinen Einfluss darauf, falls die Dritt-Anbieter die IP-Adresse z.B.
                        für statistische Zwecke speichern. Soweit dies uns bekannt ist, klären wir die Nutzer darüber
                        auf.</p>
                    <h3>Google Maps</h3>
                    <p>Auf unserer Webseite verwenden wir Google Maps (ein Dienst der Google Ireland Limited, Gordon
                        House, Barrow Street, Dublin 4, Irland) zur bildlichen Darstellung unserer Standorte im
                        Google-Maps Plug-In. Bei der Nutzung von Google Maps werden Daten die zur Nutzung notwendig sind
                        und weitere Daten weitergegeben.</p>
                    <p>Weitere Informationen finden Sie in den Nutzungsbedingungen für Google Maps (<a
                        href="https://www.google.com/intl/de_de/help/terms_maps.html" target="_blank"
                        rel="noopener noreferrer">https://www.google.com/intl/de_de/help/terms_maps.html</a>).</p>
                    <h3>Youtube Video Plugins</h3>
                    <p>Auf dieser Webseite werden Inhalte dritter Anbieter eingebunden. Diese Inhalte werden von Google
                        Ireland Limited zur Verfügung gestellt („Anbieter“). Youtube wird betrieben von der Google
                        Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland („Google“). Bei Videos von
                        Youtube, die auf unserer Seite eingebunden sind, ist die erweiterte Datenschutzeinstellung
                        aktiviert. Das bedeutet, dass keine Informationen von Webseitenbesuchern bei Youtube erhoben und
                        gespeichert werden, es sei denn, diese spielen das Video ab. Die Einbindung der Videos dient der
                        Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an
                        einer optimalen Vermarktung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Zweck und
                        Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch die Anbieter
                        sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre
                        entnehmen Sie bitte den Datenschutzhinweisen von Google <a
                            href="https://policies.google.com/privacy" target="_blank"
                            rel="noopener noreferrer">https://policies.google.com/privacy</a>.</p>
                    <h3>Newsletter – Newsletter2Go</h3>
                    <p>Diese Webseite nutzt Newsletter2Go für den Versand von Newslettern. Anbieter ist die
                        Newsletter2Go GmbH, Nürnberger Straße 8, 10787 Berlin, Deutschland. Ihre Daten werden dabei an
                        die Sendinblue GmbH übermittelt. Der Sendinblue GmbH ist dabei untersagt Ihre Daten zu verkaufen
                        und für andere Zwecke als für den Versand von Newslettern zu nutzen. Die Sendinblue GmbH ist ein
                        deutscher, zertifizierter Anbieter, welcher den Anforderungen der Europäischen
                        Datenschutzgrundverordnung und dem Bundesdatenschutzgesetz entspricht.</p>
                    <p>Die von Ihnen angegeben Daten (E-Mail Adresse) werden zum Zwecke des Newsletterbezugs
                        verarbeitet.</p>
                    <p>Sollten Sie im Rahmen der Mitgliedschaft in Ihrem Lohnsteuerhilfeverein keine Newsletter des
                        Vereins empfangen wollen, können Sie den Newsletter abbestellen und somit Ihren Widerruf
                        erklären. Hierfür stellen wir in jedem Newsletter einen entsprechenden Link zur Verfügung oder
                        Sie schreiben uns eine E-Mail. Weitere Informationen finden Sie hier: <a
                            href="https://de.sendinblue.com/informationen-newsletter-empfaenger/">https://de.sendinblue.com/informationen-newsletter-empfaenger/</a>
                    </p>
                    <h3>Cookies</h3>
                    <p>Um unsere Webseite nutzerfreundlicher zu machen verwenden wir sogenannte „Cookies“. Cookies sind
                        kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet
                        gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte
                        Informationen zufließen.</p>
                    <p>Cookies werden genutzt um Nutzerfreundlichkeit unserer Webseite zu verbessern und die von Ihnen
                        genutzten Funktionen zu ermöglichen. Sofern wir Cookies auch für andere Zwecke nutzen, erläutern
                        wir Ihnen hier in unserer Datenschutzerklärung wofür wir diese einsetzen.</p>
                    <p>In Ihrem Browser können Sie diverse Einstellungen für Cookies vornehmen, diese verbieten und
                        gesetzte Cookies löschen. Wir möchten allerdings darauf hinweisen, dass Sie ohne Cookies
                        möglicherweise nicht alle Dienste unserer Webseite nutzen können.</p>
                    <p>Soweit Cookies von Drittanbietern auf dieser Seite eingesetzt werden, finden Sie weitere
                        Informationen dazu bei den hier implementierten Diensten.</p>
                    <h3>Google Analytics</h3>
                    <p>Wir setzen das Tool Google Analytics von Google ein (Google Ireland Limited, Gordon House, Barrow
                        Street, Dublin 4, Irland).</p>
                    <p>Google Analytics arbeitet mit Textdateien die auf Ihrem Gerät gespeichert werden (sog.
                        „Cookies“), um Informationen über Ihre Webseitennutzung zu gewinnen. Google nutzt diese
                        Informationen in unserem Auftrag um uns Auswertungen über die Webseitennutzung, Reports über
                        Aktivitäten auf der Webseite und weitere Services zur Verfügung zu stellen. Die anhand des
                        „Cookies“ gesammelten Daten über Ihre Webseitennutzung werden zur Analyse üblicherweise an
                        seinen Server von Google in den USA übertragen und dort gespeichert. Eine Zusammenführung mit
                        anderen bei Google über Sie eventuell hinterlegten Daten erfolgt nicht.</p>
                    <p>Wir setzen zudem die Maskierungsfunktion „anonymizeIP“ ein. Vor der Übertragung Ihrer IP-Adresse
                        an die Google Server in den USA, wird diese innerhalb der EU gekürzt. Eine Übertragung der
                        ungekürzten IP-Adresse erfolgt nur in seltenen Fällen und wird dann auf den Servern in den USA
                        gekürzt. Sie können diese Verarbeitung durch folgende Maßnahmen verhindern:</p>
                    <p>Es ist möglich Ihren Browser so einzustellen, dass keine „Cookies“ gespeichert werden. Das kann
                        allerdings dazu führen, dass unsere Webseite nicht voll funktionsfähig ist. Weiterhin besteht
                        die Möglichkeit durch ein Browser Add-On von Google die Datenerfassung zu verhindern. Das Add-On
                        finden Sie hier: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                                            rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
                    </p>
                    <p>Wahlweise zum Browser-Add-On, etwa wenn Sie ein Smartphone und Tablets nutzen, können Sie die
                        Datenerfassung durch Google Analytics verhindern, indem Sie hier klicken. Damit wird ein Cookie
                        gesetzt der die Datenerfassung verhindert. Sollten Sie unsere Webseite mit einem anderen Device
                        oder Browser erneut besuchen oder Ihre Cookies gelöscht haben, müssen Sie den Cookie erneut
                        setzen.</p>
                    <p>Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter <a
                        href="https://www.google.de/intl/de/policies/privacy/" target="_blank"
                        rel="noopener noreferrer">https://www.google.de/intl/de/policies/privacy/</a> und unter<br />
                        <a href="http://www.google.com/analytics/terms/de.html" target="_blank"
                           rel="noopener noreferrer">http://www.google.com/analytics/terms/de.html</a> &nbsp;abgerufen
                        werden. Google Analytics wird unter diesem Link <a
                        href="https://www.google.com/intl/de_de/analytics/" target="_blank"
                        rel="noopener noreferrer">https://www.google.com/intl/de_de/analytics/</a> genauer erläutert.
                    </p>
                    <p>Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem sie das
                        unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a
                            href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                            rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout?hl=de</a><br />
                            Hierdurch wird die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
                            Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch
                            Google verhindert.</p>

                </div>
            </div>
        );
    }
}

export default Datasecurity;
