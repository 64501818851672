import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPhone, faPlus, faPrint } from "@fortawesome/free-solid-svg-icons";
import TableOperatorOptions from "../Blocks/TableOperatorOptions";
import { toast } from "react-toastify";
import { WaveTopBottomLoading } from "react-loadingg";
import UserStore from "../../stores/UserStore";
import { sortList } from "../ALHUtility";
import Moment from "react-moment";
import MitgliedItem from '../../models/Mitglied';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MitgliedService from '../../services/MitgliedService';

interface MitgliederlisteParams {
    search?: string
}

interface MitgliederlisteProps extends RouteComponentProps<MitgliederlisteParams> {
    mode?: string
    addNew?: boolean
    withAdd?: boolean
    withInfo?: boolean
}

interface MitgliederlisteState {
    loading: boolean,
    mitglieder: MitgliedItem[],
    search: string,
    onlyActive: boolean,
    addingNew: number,
    addedNewNr: string,
    numShow: number,
    updateTitle: boolean,

    sort: string,
    sort_mode: string

    mtglNew: number
    mtglBonus: number
}

class Mitgliederliste extends Component<MitgliederlisteProps, MitgliederlisteState> {

    constructor(props: MitgliederlisteProps) {
        super(props);

        if (!UserStore.hasBerechtigung('bst_mg') && typeof this.props.history !== "undefined") {
            this.props.history.push("/notallowed/");
        }

        let search: string = '';
        if (typeof this.props.match !== "undefined") {
            search = this.props.match.params.search ?? '';
        }

        this.state = {
            loading: true,
            mitglieder: [],
            search: search,
            onlyActive: true,
            addingNew: 0,
            addedNewNr: '',
            numShow: -1,
            updateTitle: true,

            sort: 'mgNr',
            sort_mode: 'asc',

            mtglNew: 0,
            mtglBonus: 0,
        }

    }

    async componentDidUpdate(prevProps: MitgliederlisteProps) {
        if (this.props.match && prevProps.match && this.props.match.params.search !== prevProps.match.params.search) {
            this.changeSearch(this.props.match.params.search ?? '');
        }
    }

    async componentDidMount() {

        //check for new Blanko Mitglied to create
        if (typeof this.props.addNew !== "undefined" && this.props.addNew) {
            this.setState({
                addingNew: 1
            });

            try {
                let res = await fetch(UserStore.url + '/mgnew', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        attr: {
                            IstBlankoMG: true
                        }
                    })
                });
                let result = await res.json();

                if (result.success && result.data.errorMessage === null) {

                    //Mitgliedsnummer im Blanko Mode bekommen
                    let res = await fetch(UserStore.url + '/mgblanko', {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            attr: {
                                Mitgliedsnummer: result.data.mitgliedsnummer
                            }
                        })
                    });
                    let resultBlanko = await res.json();

                    this.setState({
                        addingNew: 2,
                        addedNewNr: resultBlanko.data.mitgliedsnummer
                    });
                } else {
                    toast.error("Anlegen der neuen Blanko-Mitgliedsnummer ist fehlgeschlagen (" + result.data.errorMessage + "). Versuchen Sie es später noch einmal oder kontaktieren Sie den Support!", {
                        autoClose: 10000
                    });
                }

            } catch (e) {
                console.log("Error in create blanko-MG: " + e);
            }
        }

        this.loadListe();
    }

    async loadListe(onlyActive = true) {

        let endpoint = "";
        switch (this.props.mode) {
            case "neu":
                endpoint = "neumitglieder";
                break;
            case "blanko":
                endpoint = "mitgliederblanko"; //@todo hier Muss noch die neue Funktion dann rein der API (noch nicht vorhanden)
                break;
            default:
                endpoint = "mitglieder";
        }

        this.setState({
            onlyActive: onlyActive,
            mitglieder: [],
            loading: true,
            updateTitle: (endpoint === "mitglieder"),
        })

        try {

            let res = await fetch(UserStore.url + '/' + endpoint, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let result = await res.json();

            //Check counts manually
            let mtglNew = result.data.mitglieder.length;
            let mtglBonus = 0;
            if (endpoint === "neumitglieder") {
                mtglNew = result.data.anzahlNeumitglieder;
                mtglBonus = result.data.anzahlNeumitgliederBonus;
                /**
                for(let i=0;i<result.data.mitglieder;i++) {
                    if( result.data.mitglieder[i].istBonusMG ) {
                        mtglBonus++;
                    }
                }*/
            }
            if (result && result.success) {
                this.setState({
                    mitglieder: result.data.mitglieder,
                    loading: false,
                    mtglNew: mtglNew,
                    mtglBonus: mtglBonus,
                });
            }

            if (endpoint === "mitglieder" && this.state.numShow !== -1) {
                document.title = 'Mitglieder (' + this.state.numShow + ') | ' + UserStore.title;
            }


        } catch (e) {
            console.log("Error in loading Mitgliederliste: " + e);
        }
    }

    getAddingNew() {
        switch (this.state.addingNew) {
            case 1:
                return (
                    <div>
                        <h3>Blanko-Mitglied erstellen</h3>
                        <div className="alert alert-warning" role="alert">Neues Mitglied wird gerade erstellt... Einen Moment bitte...</div>
                    </div>
                )
            case 2:
                return (
                    <div>
                        <h3>Blanko-Mitglied erstellt</h3>
                        <div className="alert alert-success" role="alert">
                            Neues Blanko-Mitglied erstellt: Neue Nummer: <a href={`/mitglied/${this.state.addedNewNr}`}>{this.state.addedNewNr}</a><br />
                            <small>Klicken Sie auf die neue Nummer, um weitere Aktionen mit diesem Blanko-Mitglied zu starten!</small>
                        </div>
                    </div>
                )
            default:

        }
    }

    getHeader(withAdd: boolean, withInfo: boolean) {
        if (this.props.mode === 'neu') {
            return (
                <div>
                    <h3>Neue Mitglieder</h3>
                    <div className="alh_subheader">
                        Sehen Sie hier Ihre aktuellen Neu-Mitglieder.
                        <br />
                        Anzahl Neumitglieder gesamt: <span className="alh_bold">{this.state.mtglNew}</span><br />
                        Anzahl Neumitglieder gem. Bonus: <span className="alh_bold">{this.state.mtglBonus}</span>
                    </div>
                </div>
            )
        } else
            if (this.props.mode === 'blanko') {
                return (
                    <div>
                        <h3 className={(!withAdd) ? "alh_pageheader_topspace" : "alh_pageheader"}>Blanko Mitglieder <a href="/mitgliedBlankoNew"><FontAwesomeIcon icon={faPlus} title="Neues Blankoelement hinzufügen" className={(withAdd) ? "faButton" : "d-none"} /></a></h3>
                        {withInfo &&
                            <div className="alh_subheader">Sehen Sie hier alle
                                Blankomitglieder, welche Sie bereits angelegt haben. Über das blaue +
                                können Sie weitere Blankomitglieder erstellen und anschließend die Beitrittserklärung mit der
                                vergebenen Mitgliedsnummer drucken.
                            </div>
                        }
                    </div>
                )
            } else {
                return (
                    <div>
                        <h3>Meine Mitglieder</h3>
                        <div className="alh_subheader">Sehen Sie hier alle Ihre Mitglieder. Verwenden Sie die "Suche" oder die "Sortierung", um schneller das gesuchte Mitglied zu finden.<br />
                            Mitglieder, welche mit einem roten Hintergrund versehen sind, haben gekündigt, sind aber noch bis zum 31.12. aktiv.<br />
                            Alle gekündigten Mitglieder können Sie einblenden, wenn Sie rechts neben der Suche bei “Nur Aktive” auf “Alle” umstellen. Bei den durchgestrichenen Mitgliedern ist die Mitgliedschaft bereits beendet.</div>
                    </div>
                )
            }
    }

    changeSearch(val: string) {
        this.setState({
            search: val,
        })
    }

    getMainTel(phone: string) {
        if (phone && phone.indexOf("|") > 0) {
            return (phone.split("|")[0]).trim();
        } else {
            return phone;
        }
    }

    onlyActive(val: boolean) {
        this.loadListe(val);
    }

    sortClick(spalte: string) {
        let newSort_mode = this.state.sort_mode;
        let newSort = this.state.sort;

        if (spalte === this.state.sort) {
            switch (this.state.sort_mode) {
                case 'asc':
                    newSort_mode = 'desc';
                    break;
                default:
                    newSort_mode = 'asc';
                    break;
            }
        } else {
            newSort = spalte;
        }

        this.setState({
            sort: newSort,
            sort_mode: newSort_mode,
        })
    }


    async printBE(mGNr: string) {

        await MitgliedService.mitgliedPrintFile(mGNr, "rptMGBeitrittserklaerung", "D", "", mGNr + "_Blanko_BE.pdf")

        return true;
    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }



    render() {
        let search = this.state.search;
        const { withAdd = true, withInfo = true } = this.props;
        let currentList = this.state.mitglieder;
        if (this.state.onlyActive) {
            currentList = this.state.mitglieder.filter((mitglied) =>
                mitglied.status === 0 ||
                mitglied.status === 1
            );
        }
        if (typeof search !== "undefined") {
            currentList = currentList.filter((mitglied) =>
                mitglied.name?.toLowerCase().includes(search.toLowerCase()) ||
                mitglied.namePartner?.toLowerCase().includes(search.toLowerCase()) ||
                mitglied.mgNr?.toLowerCase().includes(search.toLowerCase()) ||
                mitglied.telefon?.toLowerCase().includes(search.toLowerCase()) ||
                mitglied.plzOrt?.toLowerCase().includes(search.toLowerCase())
            );
        }

        currentList = sortList(this.state.sort, currentList, this.state.sort_mode, this.state.sort === 'kuendWirksamDatum')

        if (this.state.updateTitle) {
            document.title = 'Mitglieder (' + currentList.length + ') | ' + UserStore.title;
        }

        return (
            <div>
                {this.getAddingNew()}

                {this.getHeader(withAdd, withInfo)}

                {(this.props.mode !== 'blanko' && this.props.mode !== 'neu') &&
                    <TableOperatorOptions
                        name="Mitgliederliste"
                        placeholder="Suche nach Name, Nummer, Ort oder Telefon..."
                        onChange={(val) => this.changeSearch(val)}
                        value={this.state.search}
                        onlyActive={(val) => this.onlyActive(val)}
                        print={true}
                    />
                }

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>

                        {this.props.mode === 'blanko' &&
                            <tr>
                                <th className="alhtable_nr">Nr.</th>
                                <th>Name</th>
                                <th className=" alh_noprint">&nbsp;</th>
                            </tr>
                        }
                        {this.props.mode === 'neu' &&
                            <tr>
                                <th onClick={() => this.sortClick('mgNr')} className={((this.state.sort === 'mgNr') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alhtable_nr"}>Nr.</th>
                                <th onClick={() => this.sortClick('name')} className={((this.state.sort === 'name') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Name</th>
                                <th onClick={() => this.sortClick('beitritt')} className={((this.state.sort === 'beitritt') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alh_mobileHide"}>Beitritt</th>
                                <th onClick={() => this.sortClick('beitrag')} className={(((this.state.sort === 'beitrag') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")) + " text-right"}>Beitrag</th>
                                <th onClick={() => this.sortClick('be')} className={((this.state.sort === 'be') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>BE</th>
                                <th onClick={() => this.sortClick('info')} className={((this.state.sort === 'info') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Info</th>
                                <th onClick={() => this.sortClick('istBonusMG')} className={((this.state.sort === 'istBonusMG') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " text-right"}>Bonus</th>
                                <th className="alh_noprint tableActions alh_mobileShow" />
                            </tr>
                        }
                        {(this.props.mode !== 'blanko' && this.props.mode !== 'neu') &&
                            <tr>
                                <th onClick={() => this.sortClick('mgNr')} className={((this.state.sort === 'mgNr') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alhtable_nr"}>Nr.</th>
                                <th onClick={() => this.sortClick('name')} className={((this.state.sort === 'name') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Name</th>
                                <th onClick={() => this.sortClick('namePartner')} className={((this.state.sort === 'namePartner') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alh_mobileHide"}>Partner</th>
                                <th onClick={() => this.sortClick('plzOrt')} className={((this.state.sort === 'plzOrt') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Ort</th>
                                <th onClick={() => this.sortClick('telefon')} className={((this.state.sort === 'telefon') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " text-right alh_mobileHide"}>Telefon</th>
                                <th className="alh_mobileHide">E-Mail / E-Mail Einwilligung</th>
                                <th onClick={() => this.sortClick('postbox')} className={((this.state.sort === 'postbox') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " text-right alh_mobileHide"}>Postbox</th>
                                <th onClick={() => this.sortClick('kuendWirksamDatum')} className={((this.state.sort === 'kuendWirksamDatum') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " text-right alh_mobileHide"}>Kündigung</th>
                                <th className="alh_noprint tableActions alh_mobileShow" />
                            </tr>
                        }

                    </thead>

                    {this.props.mode === 'blanko' &&
                        <tbody>
                            {currentList.map((mitglied, index) => (
                                <tr key={mitglied.mgNr} className={((mitglied.status === 1) ? "warning" : "") + ((mitglied.status === 2) ? "deleted" : "")}>
                                    <td><a href={`/mitglied/${mitglied.mgNr}`}>{mitglied.mgNr}</a></td>
                                    <td>{mitglied.name}</td>
                                    <td className="alh_noprint tableActions">
                                        <FontAwesomeIcon onClick={() => this.printBE(mitglied.mgNr ?? '')} icon={faPrint} title="BE drucken" className="faButton" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }

                    {this.props.mode === 'neu' &&
                        <tbody>
                            {currentList.map((mitglied, _) => (
                                <tr key={mitglied.mgNr} className={((mitglied.status === 1) ? "warning" : "") + ((mitglied.status === 2) ? "deleted" : "")}>
                                    <td><a href={`/mitglied/${mitglied.mgNr}`}>{mitglied.mgNr}</a></td>
                                    <td>{mitglied.name}</td>
                                    <td><Moment format="DD.MM.YYYY">{mitglied.beitritt}</Moment></td>
                                    <td className="text-right">{this.numberFormat(mitglied.beitrag!)}</td>
                                    <td>{mitglied.be}</td>
                                    <td>{mitglied.info}</td>
                                    <td className="text-right">{mitglied.istBonusMG ? "Ja" : "Nein"}</td>
                                    <td className="alh_noprint tableActions alh_mobileShow">
                                        <a href={`/mitglied/${mitglied.mgNr}`}><FontAwesomeIcon icon={faEdit} title="Bearbeiten" className="faButton faButtonSpaceright" /></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }

                    {(this.props.mode !== 'blanko' && this.props.mode !== 'neu') &&
                        <tbody>
                            {currentList.map((mitglied, _) => (
                                <tr key={mitglied.mgNr} className={((mitglied.status === 1) ? "warning" : "") + ((mitglied.status === 2) ? "deleted" : "")}>
                                    <td><a href={`/mitglied/${mitglied.mgNr}`}>{mitglied.mgNr}</a></td>
                                    <td>{mitglied.name}</td>
                                    <td className="alh_mobileHide">{mitglied.namePartner}</td>
                                    <td>{mitglied.plzOrt}</td>
                                    <td className="text-right alh_mobileHide">{mitglied.telefon}</td>
                                    <td className="alh_mobileHide"><a href={"mailto:" + mitglied.eMail + "?subject=" + mitglied.mgNr + " " + mitglied.name?.replace(",", "")}>{mitglied.eMail + " | " + ((mitglied.eMailEinwilligung) ? "Ja" : "Nein")}</a></td>
                                    <td className="text-right alh_mobileHide">
                                        <a href='https://www.alh-postbox.de/' target='_blank' rel="noopener noreferrer" className={(mitglied.postbox ? "" : "d-none")}>Ja</a>
                                        <span className={(mitglied.postbox ? "d-none" : "")}>Nein</span>
                                    </td>
                                    <td className="text-right alh_mobileHide">
                                        {mitglied.kuendWirksamDatum &&
                                            <Moment format="DD.MM.YYYY">{mitglied.kuendWirksamDatum}</Moment>}
                                    </td>
                                    <td className="alh_noprint tableActions alh_mobileShow">
                                        <a href={`tel:${this.getMainTel(mitglied.telefon ?? '')}`} className="alh_noprint"><FontAwesomeIcon icon={faPhone} title="Anrufen" className="alh_noprint faButton faButtonSpaceright" /></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }

                </Table>


                {currentList && currentList.length === 0 && !this.state.loading &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}

            </div>
        );
    }
}

export default withRouter(Mitgliederliste);
