import React from "react"
import { useState, useCallback } from "react"
import { Modal, Alert, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import ChecklistItem from "../../models/ChecklistItem"
import BeratungsprotokollService from "../../services/BeratungsprotokollService"
import MitgliedService from "../../services/MitgliedService"
import ChecklistContainer from "../ListComponents/ChecklistContainer"

interface BeratungsprotkollFehlendeBelegeModalProps {
    showFehlendeBelege: boolean
    mgNr: string
    currentBpId: number
    bpVz: number
    checklistItems: ChecklistItem[]
    closeFehlendeBelegeModal: () => void
    saveFehlendeBelege: () => Promise<void>
    showNotizModal: (frageId: number) => void
    handleCheckboxChange: (item: ChecklistItem, val: boolean) => void
}

const BeratungsprotkollFehlendeBelegeModal = ({ showFehlendeBelege, currentBpId, mgNr, bpVz, checklistItems, closeFehlendeBelegeModal, saveFehlendeBelege, showNotizModal, handleCheckboxChange }: BeratungsprotkollFehlendeBelegeModalProps) => {

    const [previewEmailBody, setPreviewEmailBody] = useState("")
    const [loading, setLoading] = useState(false)
    const [changed, setChanged] = useState(false)

    const sendEmail = useCallback(async () => {
        if (checklistItems.length) {
            let resJson = await BeratungsprotokollService.sendEmail(currentBpId, checklistItems.filter(c => !c.taskFulfilled), mgNr)

            if (resJson.success) {
                toast.success("E-Mail wurde versendet.", {
                    autoClose: false
                })
            } else if (resJson.data && resJson.data.ErrorText) {
                toast.error("E-Mail konnte nicht versendet werden. " + resJson.data.ErrorText, {
                    autoClose: 10000
                })
            } else {
                toast.error("E-Mail konnte nicht gesendet werden, bitte später nochmal probieren", {
                    autoClose: 10000
                })
            }
        } else {
            toast.error("Keine fehlenden Belege vorhanden.", {
                autoClose: 10000
            })
        }
        closeFehlendeBelegeModal()
    }, [checklistItems, closeFehlendeBelegeModal, currentBpId, mgNr])

    const previewEmail = useCallback(async () => {
        if (checklistItems.length) {
            let resJson = await BeratungsprotokollService.getEmailBody(currentBpId, checklistItems.filter(c => !c.taskFulfilled), mgNr)

            if (resJson.success) {
                setPreviewEmailBody(resJson.data.emailBody)
            } else if (resJson.data && resJson.data.ErrorText) {
                toast.error("E-Mail Body konnte nicht geladen werden. " + resJson.data.ErrorText, {
                    autoClose: 10000
                })
            } else {
                toast.error("E-Mail Body konnte nicht geladen werden, bitte später nochmal probieren", {
                    autoClose: 10000
                })
            }
        } else {
            toast.error("Keine fehlenden Belege vorhanden.", {
                autoClose: 10000
            })
        }
    }, [checklistItems, currentBpId, mgNr])

    const downloadPdf = useCallback(async () => {
        await MitgliedService.mitgliedPrintFile(mgNr, "rptMGFehlendeBelege", "D", "ProtId:=" + currentBpId, mgNr + "_FehlendeBelege_" + bpVz + ".pdf")
    }, [bpVz, currentBpId, mgNr])

    //onClick Pipeline wo loading gesetzt wird
    const handleMethodCall = useCallback(async (action: string, data?: any) => {
        switch (action) {
            case 'handleCheckboxChange':
                setLoading(true)
                setChanged(true)
                handleCheckboxChange(data.item, data.value)
                break;
            case 'saveFehlendeBelege':
                setLoading(true)
                setChanged(false)
                await saveFehlendeBelege()
                break;
            case 'sendEmail':
                setLoading(true)
                await sendEmail()
                break;
            case 'previewEmail':
                setLoading(true)
                await previewEmail()
                break;
            case 'downloadPdf':
                setLoading(true)
                await downloadPdf()
                break;
        }

        setLoading(false)
    }, [downloadPdf, handleCheckboxChange, previewEmail, saveFehlendeBelege, sendEmail])

    return <Modal
        show={showFehlendeBelege}
        onHide={() => closeFehlendeBelegeModal()}
        backdrop="static"
        keyboard={true}
        size="xl"
    >
        <Modal.Header>
            <Modal.Title>Fehlende Belege</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {!previewEmailBody ? checklistItems.length > 0 ? checklistItems.map(c => {
                if (c.frage) {
                    return <ChecklistContainer
                        key={c.frage!.id}
                        checklistItem={c}
                        showNotizModal={(frageId) => showNotizModal(frageId)}
                        handleCheckboxChange={(item, value) => handleMethodCall('handleCheckboxChange', { item, value })} />
                } else {
                    return <ChecklistContainer
                        key={c.taskDoc?.docName}
                        checklistItem={c}
                        handleCheckboxChange={(item, value) => handleMethodCall('handleCheckboxChange', { item, value })} />
                }
            }
            ) : <Alert
                key="alertNoChecklistItems" variant="warning">
                Sie haben für dieses Beratungsprotkoll bei keiner Frage "Beleg fehlt" markiert.
            </Alert>
                :
                <iframe
                    id="previewEmailBody"
                    title="Preview Email Body"
                    width='100%'
                    height='750'
                    srcDoc={previewEmailBody}
                ></iframe>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => handleMethodCall('downloadPdf')} disabled={loading || checklistItems.length <= 0 || changed}>
                Fehlende Belege als PDF herunterladen
            </Button>
            {!previewEmailBody ? <Button variant="secondary" onClick={() => handleMethodCall('previewEmail')} disabled={loading}>
                Preview E-Mail
            </Button> : <Button variant="secondary" onClick={() => setPreviewEmailBody("")} disabled={loading}>
                Preview schließen
            </Button>}
            <Button variant="primary" onClick={() => handleMethodCall('sendEmail')} disabled={loading || checklistItems.length <= 0}>
                Fehlende Belege als E-Mail versenden
            </Button>
            <Button variant="primary" onClick={() => handleMethodCall('saveFehlendeBelege')} disabled={loading || !changed}>
                Speichern
            </Button>
            <Button variant="secondary" onClick={() => closeFehlendeBelegeModal()}>
                Abbrechen
            </Button>
        </Modal.Footer>
    </Modal>
}

export default BeratungsprotkollFehlendeBelegeModal