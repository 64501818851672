import React from 'react';
import { Form, FormControl, Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from '../assets/images/alh_logo_neutral.png';
import logoAkt from '../assets/images/alh_logo_full.png';
import logoAlt from '../assets/images/alh_logo_full_alt.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import UserStore from "../stores/UserStore";
import MitgliedItem from '../models/Mitglied';
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';

interface NavigationBarState {
    username?: string;
    mode: string;
    searchMGNummer: string;
    expanded: boolean;
}

interface NavigationBarProps extends RouteComponentProps {
    username?: string;
    mode: string;
    //history?: string[]
}

class NavigationBar extends React.Component<NavigationBarProps, NavigationBarState> {

    constructor(props: NavigationBarProps) {
        super(props);

        this.state = {
            username: props.username,
            mode: props.mode,
            searchMGNummer: "",
            expanded: false,
        }

        this.onMGNummerChange = this.onMGNummerChange.bind(this);
        this.onLoadMGDetails = this.onLoadMGDetails.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    onMGNummerChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchMGNummer: event.target.value
        })
    }

    onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.charCode === 13) {
            this.onLoadMGDetails();
        }
    }

    getStringWithoutDash(value: string) {
        const split = value.split('-')
        return `${split[0]}${split[1]}`
    }

    async onLoadMGDetails() {
        //check if there is one Mitglied with this Search
        let res = await fetch(UserStore.url + '/mitglieder', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        let result = await res.json();
        let search = "";
        let searchWithDash = this.state.searchMGNummer;

        if (this.state.searchMGNummer.includes('-')) {
            search = this.getStringWithoutDash(this.state.searchMGNummer)
        } else {
            search = this.state.searchMGNummer
        }

        let currentList = result.data.mitglieder.filter((mitglied: MitgliedItem) => {
            let newMgNr = ""
            if (mitglied.mgNr)
                newMgNr = this.getStringWithoutDash(mitglied.mgNr)

            return (mitglied.name?.toLowerCase().includes(searchWithDash.toLowerCase()) ||
                mitglied.namePartner?.toLowerCase().includes(searchWithDash.toLowerCase()) ||
                newMgNr.toLowerCase().includes(search.toLowerCase()) ||
                mitglied.telefon?.toLowerCase().includes(searchWithDash.toLowerCase()) ||
                mitglied.plzOrt?.toLowerCase().includes(searchWithDash.toLowerCase()))
        });

        if (currentList.length === 1) {
            this.props.history?.push("/mitglied/" + currentList[0].mgNr);
        } else {
            this.props.history?.push("/mitgliederliste/" + this.state.searchMGNummer);
        }

        this.setExpand();
    }

    async doLogout() {
        try {
            let res = await fetch(UserStore.url + '/logout', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let result = await res.json();

            if (result && result.success) {
                UserStore.isLoggedIn = false;
                UserStore.username = '';

                let currentURL = new URL(window.location.href);
                if (currentURL.pathname !== "" && currentURL.pathname !== "/") {
                    window.location.href = "/";
                }
            }

        } catch (e) {
            console.log(e);
        }
    }

    setExpand() {
        this.setState({
            expanded: (this.state.expanded ? false : true)
        })
    }

    render() {
        return (
            <React.Fragment>
                <Navbar fixed="top" expand="lg" expanded={this.state.expanded}>
                    <NavLink to="/" className="navbar-brand"><img
                        src={((UserStore.mandantId === 1) ? logoAkt : ((UserStore.mandantId === 2) ? logoAlt : logo))}
                        alt="Alh Logo" className="alh_logobar" /></NavLink>
                    {UserStore.isLoggedIn &&
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => this.setExpand()} />
                    }
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav id="nav_loggedin" className={this.props.mode === 'normal' ? 'ml-auto' : 'd-none'}>
                            <Nav.Item>
                                <NavLink className="alh-nav-head nav-link" to="/">
                                    Home
                                </NavLink>
                            </Nav.Item>

                            <NavDropdown
                                title="Meine Bst"
                                id="nav-dropdown"
                                className={UserStore.hasNavDropdownBerechtigung('bst') ? 'alh-nav-head' : 'd-none'}>
                                <NavLink to="/mitgliederliste"
                                    className={UserStore.hasBerechtigung('bst_mg') ? 'nav-link' : 'd-none'}>
                                    Mitglieder
                                </NavLink>
                                <NavLink to="/mitglied"
                                    className={UserStore.hasBerechtigung('bst_mgneu') ? 'nav-link' : 'd-none'}>
                                    Neues Mitglied
                                </NavLink>
                                <NavLink to="/uploads"
                                    className={UserStore.hasBerechtigung('bst_uploads') ? 'nav-link' : 'd-none'}>
                                    Uploads
                                </NavLink>
                                <NavLink to="/statistik"
                                    className={UserStore.hasBerechtigung('bst_statistik') ? 'nav-link' : 'd-none'}>
                                    Statistik
                                </NavLink>
                                <NavLink to="/mitarbeiter"
                                    className={UserStore.hasBerechtigung('bst_ma') ? 'nav-link' : 'd-none'}>
                                    Mitarbeiter
                                </NavLink>
                                <NavLink to="/meinebst"
                                    className={UserStore.hasBerechtigung('bst_grunddaten') ? 'nav-link' : 'd-none'}>
                                    Grunddaten
                                </NavLink>
                                <NavLink to="/voreinstellungen"
                                    className={UserStore.hasBerechtigung('bst_grunddaten') ? 'nav-link' : 'd-none'}>
                                    Voreinstellungen
                                </NavLink>
                            </NavDropdown>

                            <NavDropdown
                                title="Buchhaltung"
                                id="nav-dropdown"
                                className={UserStore.hasNavDropdownBerechtigung('buha') ? 'alh-nav-head' : 'd-none'}>
                                <NavLink to="/abrechnung"
                                    className={UserStore.hasBerechtigung('buha_abrechnung') ? 'nav-link' : 'd-none'}>Abrechnung</NavLink>
                                <NavLink to="/sammelbuchung"
                                    className={UserStore.hasBerechtigung('buha_sammelbuchung') ? 'nav-link' : 'd-none'}>Sammelbuchung</NavLink>
                                <NavLink to="/belegeliste"
                                    className={UserStore.hasBerechtigung('buha_gutschrrech') ? 'nav-link' : 'd-none'}>Gutschriften
                                    / Rechnungen</NavLink>
                                <NavLink to="/offeneBEIdentBE"
                                    className={UserStore.hasBerechtigung('buha_offenebeitritterkl') ? 'nav-link' : 'd-none'}>Offene
                                    Beitrittserklärungen</NavLink>
                                <NavLink to="/offeneBEIdentID"
                                    className={UserStore.hasBerechtigung('buha_offeneidentifikat') ? 'nav-link' : 'd-none'}>Offene
                                    Identifikationen</NavLink>
                                <NavLink to="/offeneBeitraege"
                                    className={UserStore.hasBerechtigung('buha_offenemgbeitraege') ? 'nav-link' : 'd-none'}>Offene
                                    MG-Beiträge</NavLink>
                                <NavLink to="/zahlungserinnerung"
                                    className={UserStore.hasBerechtigung('buha_zahlungserinnerung') ? 'nav-link' : 'd-none'}>Zahlungserinnerungsliste</NavLink>
                                <NavLink to="/mahnliste"
                                    className={UserStore.hasBerechtigung('buha_mahnliste') ? 'nav-link' : 'd-none'}>Mahnliste</NavLink>
                                <NavLink to="/mahnlistera"
                                    className={UserStore.hasBerechtigung('buha_mahnlistera') ? 'buha_mahnlistera nav-link' : 'd-none'}>Mahnliste
                                    RA</NavLink>
                            </NavDropdown>

                            <NavDropdown
                                title="Extras"
                                id="nav-dropdown"
                                className={UserStore.hasNavDropdownBerechtigung('extras') ? 'alh-nav-head' : 'd-none'}>
                                <NavLink to="/blankounterlagen"
                                    className={UserStore.hasBerechtigung('extras_blanko') ? 'nav-link' : 'd-none'}>Blankounterlagen</NavLink>
                                <NavLink to="/buchungsjournal"
                                    className={UserStore.hasBerechtigung('extras_bujournal') ? 'nav-link' : 'd-none'}>Buchungsjournal</NavLink>
                                <NavLink to="/geburtstagsliste"
                                    className={UserStore.hasBerechtigung('extras_gebliste') ? 'nav-link' : 'd-none'}>Geburtstagsliste</NavLink>
                                <NavLink to="/datenexport"
                                    className={UserStore.hasBerechtigung('extras_datenexport') ? 'nav-link' : 'd-none'}>Datenexport</NavLink>
                                <NavLink to="/bstwechsel"
                                    className={UserStore.hasBerechtigung('extras_bstwechsel') ? 'nav-link' : 'd-none'}>BST-Wechsel</NavLink>
                            </NavDropdown>

                            <NavDropdown
                                className="alh-nav-head"
                                title="Extern"
                                id="nav-dropdown">
                                <Nav.Link
                                    href={(UserStore.mandantId === 1) ? "https://intern.aktuell-verein.de/" : "https://intern.altbayerischer.de/"}
                                    target="_blank">Interner Bereich</Nav.Link>
                                <Nav.Link
                                    href={(UserStore.mandantId === 1) ? "https://webmail.aktuell-verein.de/" : "https://webmail.altbayerischer.de/"}
                                    target="_blank">Webmail</Nav.Link>
                                <Nav.Link href="https://www.alh-postbox.de/" target="_blank">Postbox</Nav.Link>
                            </NavDropdown>

                            <Nav.Item>
                                <NavLink to="/hilfe" className="nav-link">Hilfe</NavLink>
                            </Nav.Item>
                            {/* <Nav.Item><NavLink href="/postbox"><FontAwesomeIcon title="Inbox" icon={faInbox} /><div className="alh_counter">4</div></NavLink></Nav.Item> */}
                            <Nav.Item>
                                <Nav.Link onClick={() => this.doLogout()}><FontAwesomeIcon title="Ausloggen"
                                    icon={faSignOutAlt} /></Nav.Link>
                            </Nav.Item>
                        </Nav>

                        {UserStore.hasBerechtigung('bst_mg') &&
                            <Form
                                className={(this.props.mode === 'normal' ? 'd-flex' : 'd-none') + ' alh_navSearch'}
                                onSubmit={e => {
                                    e.preventDefault();
                                }}>
                                <FormControl
                                    type="search"
                                    placeholder="MG Schnellsuche"
                                    className="mr-2"
                                    size="sm"
                                    value={this.state.searchMGNummer}
                                    onChange={this.onMGNummerChange}
                                    onKeyPress={this.onKeyUp}
                                />
                            </Form>
                        }
                    </Navbar.Collapse>
                </Navbar>
            </React.Fragment>
        )
    }
}

export default withRouter(NavigationBar);
//export default NavigationBar;
