import { makeAutoObservable, toJS } from "mobx";
import Berechtigung from "../models/Berechtigung";

/**
 * UserStores
 */
class UserStore {

    url = process.env.REACT_APP_NODE_SERVER ?? "";
    mandantId = -1;
    title = 'ALH WebPortal 2.0';
    rights: Berechtigung[] = [];
    isLoggedIn = false;
    username = '';
    bstNr = '';
    loading = true;
    name = ''
    anrede = ''

    constructor() {
        makeAutoObservable(this)
    }

    hasBerechtigung(key: string, mode = 'read') {

        if (toJS(this.rights).length === 0) {
            return false;
        } else if (!toJS(this.rights).find(b => b.schluessel === key)) {
            return false;
        } else {
            let thisRight = toJS(this.rights).find(item => item.schluessel === key);

            if (typeof thisRight !== "object") {
                return false;
            } else {
                if (mode === 'read') {
                    return thisRight.checked;
                } else {
                    return ((thisRight.art === 1 || thisRight.art === 0) && thisRight.checked);
                }
            }
        }
    }

    hasNavDropdownBerechtigung(area: string) {
        if (this.rights.filter(r => r.schluessel.includes(area)).length !== 0) {
            return !!this.rights.filter(r => r.schluessel.includes(area)).find(r => r.checked);
        }

        return false;
    }
}

const userStore = new UserStore();

export default userStore 
