import React, { Component, PropsWithChildren } from 'react';
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface InfoIconProps {
    mode?: string
    containerClassName?: string
    icon?: IconDefinition
    space?: string
    title?: string
    placement?: string
    onClick?: React.MouseEventHandler<SVGSVGElement>
}

interface InfoIconState {
    showModal: boolean
}

class InfoIcon extends Component<InfoIconProps & Omit<PropsWithChildren<Popover & Tooltip>, "Content" | "Title">, InfoIconState> {

    constructor(props: InfoIconProps & PropsWithChildren<Popover & Tooltip>) {
        super(props);

        this.state = {
            showModal: false,
        }

        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    showModal() {
        this.setState({
            showModal: true,
        })
    }

    closeModal() {
        this.setState({
            showModal: false,
        })
    }

    getInfoIconCore(mode: string) {
        if (mode === 'popover') {
            return (
                <Popover id={Math.floor(Math.random() * 100).toString()}>
                    {this.props.children}
                </Popover>
            )
        } else {
            return (
                <Tooltip id={Math.floor(Math.random() * 100).toString()}>
                    {this.props.children}
                </Tooltip>
            )
        }
    }

    render() {

        const { icon = faInfoCircle, placement = "left", mode = "tooltip" } = this.props

        if (mode === 'modal') {
            return (
                <span className={this.props.containerClassName}>
                    <FontAwesomeIcon onClick={() => this.showModal()} icon={icon} className={(this.props.space === 'left' ? 'infoLeftSpace' : 'infoRightSpace') + ' lightIcon faButton'} />

                    <Modal
                        show={this.state.showModal}
                        onHide={this.closeModal}
                        backdrop="static"
                        keyboard={true}
                        size="lg"
                        ref={React.createRef()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{(this.props.title === '' ? 'ALH Info' : this.props.title)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.props.children}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Schliessen
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </span>
            )
        } else {
            return (
                <OverlayTrigger
                    placement={placement === 'right' ? 'right' : 'left'}
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.getInfoIconCore(mode)}
                >
                    <FontAwesomeIcon
                        className={(this.props.space === 'left' ? 'infoLeftSpace' : 'infoRightSpace') + ' lightIcon ' + this.props.containerClassName}
                        icon={icon}
                        onClick={this.props.onClick}
                    />
                </OverlayTrigger>
            );
        }
    }
}

export default InfoIcon;