import React, { Component } from 'react';
import InfoIcon from "../Blocks/InfoIcon";

export interface InputCheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    containerClassName?: string
    subtext?: string
    subtextClass?: string
    text?: string
    infotext?: string
    infotextMode?: string
    onChange: (value: boolean) => void
}

interface InputCheckboxState {
    mandatory: boolean
}

class InputCheckbox extends Component<InputCheckboxProps, InputCheckboxState> {

    constructor(props: InputCheckboxProps) {
        super(props);

        this.state = {
            mandatory: false,
        }
    }

    setInputValue() {
        this.props.onChange(!this.props.checked);
    }

    render() {

        const { infotextMode = 'modal', tabIndex = 0, checked = false } = this.props

        return (
            <div className={'inputField ' + this.props.containerClassName ?? ''} title={this.props.title}>
                <input
                    type='checkbox'
                    name={this.props.name}
                    id={this.props.id}
                    checked={checked}
                    tabIndex={tabIndex}
                    onChange={(e) => this.setInputValue()}
                    disabled={this.props.disabled}
                />
                <span className={(!this.props.subtext) ? "d-none" : this.props.subtextClass}><small>{" " + this.props.subtext}</small></span>
                <span className={(!this.props.text) ? "d-none" : "inCheckbox"}><label htmlFor={this.props.id}>{this.props.text}</label></span>

                {this.props.infotext &&
                    <InfoIcon space="left" mode={infotextMode} placement="right" title="Information">
                        <div className="alh_popover" dangerouslySetInnerHTML={{ __html: this.props.infotext! }}></div>
                    </InfoIcon>
                }

            </div>
        );
    }
}

export default InputCheckbox;
