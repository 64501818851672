import UserStore from "../stores/UserStore";

export const CommonService = {
    getBeitragsordnung: async (mgNr: string) => {
        //load Staffeln
        let resBO = await fetch(UserStore.url + '/beitragsordnung', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    MGNr: mgNr,
                    jahr: (new Date()).getFullYear()
                }
            })
        });
        
        return await resBO.json();
    }
}

