import { Definition, DefinitionField } from "../Components/Screens/Datenexport";
import KeyValue from "../models/KeyValue";
import UserStore from "../stores/UserStore"

const url = UserStore.url;

const DatenexportService = {
    loadDefinitions: async (): Promise<Definition[]> => {
        let resDef = await fetch(url + '/expdefinition', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {}
            })
        });

        let resultDef = await resDef.json();

        if (resultDef && resultDef.success) {
            const defs = resultDef.data.definitionen;
            return defs
        }

        return [];
    },
    loadBereiche: async () => {
        let listeBer: KeyValue[] = [];

        let resBer = await fetch(url + '/expbereiche', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {}
            })
        });

        let resultBer = await resBer.json();

        if (resultBer && resultBer.success) {
            const bereiche: { id: number, bezeichnung: string }[] = resultBer.data.bereiche

            bereiche.forEach((item) => {
                listeBer.push({
                    id: item.id,
                    value: item.bezeichnung,
                })
            })
        }

        return listeBer
    },
    loadDefItems: async (bereich: number, defID = 0) => {
        let resultArray: { items: DefinitionField[], sort?: string } = {
            items: [],
            sort: '',
        };

        try {
            let resDefItems = await fetch(url + '/expdefinitionItems', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        BSTNr: UserStore.username,
                        defID: defID,
                        bereich: bereich,
                    }
                })
            });

            let resultDefItems = await resDefItems.json();

            if (resultDefItems && resultDefItems.success) {
                resultArray.items = resultDefItems.data.fields;
                resultArray.sort = resultDefItems.data.sortField !== null ? resultDefItems.data.sortField : undefined;
            }
        } catch (e) {
            console.log("Error in loading list: " + e);
        }

        return resultArray;
    },
    print: async (defId: number, itemSelected: Definition) => {
        let res = await fetch(url + '/expexport', {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'responseType': 'arraybuffer'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    defID: defId,
                    bezeichnung: itemSelected.bezeichnung,
                    bereich: itemSelected.bereich,
                }
            })
        });

        return await res.json();
    },
    deleteDefinition: async (definition: Definition) => {
        let resDef = await fetch(url + '/expdefinitionItemsDel', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    defID: definition.defID
                }
            })
        });

        return await resDef.json();
    },
    saveDefinition: async (currentAuswahlDefEdit: number, currentAuswahlBer: number, currentBez: string, sortField: string | undefined, defItems: DefinitionField[]) => {
        let res = await fetch(url + '/expdefinitionItemsSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: Number(UserStore.username),
                    dataset: {
                        BSTNr: UserStore.username,
                        defID: currentAuswahlDefEdit,
                        bereich: currentAuswahlBer,
                        bezeichnung: currentBez,
                        sortField: sortField,
                        fields: defItems,
                    }
                }
            })
        });

        return await res.json();
    }
}

export default DatenexportService