import React, { Component } from "react";
import UserStore from "../../stores/UserStore";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import SelectField from "../Forms/SelectField";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faPrint } from "@fortawesome/free-solid-svg-icons";
import { trackEvent } from "../ALHUtility";
import InputField from "../Forms/InputField";
import InputCheckbox from "../Forms/InputCheckbox";
import KeyValue from "../../models/KeyValue";
import { PointSpreadLoading } from "react-loadingg";
import DatenexportService from "../../services/DatenexportService";

export interface Definition {
    defID: number
    bezeichnung: string
    bereich: number
}

export interface DefinitionField {
    selected: boolean
    field: string
}

interface DatenexportProps { }

interface DatenexportState {
    definitionen: Definition[],
    bereiche: KeyValue[],
    defItems: DefinitionField[],

    defItemsCheckAll: boolean,

    currentBez: string,
    currentAuswahlDef: number,
    currentAuswahlDefEdit: number,
    currentAuswahlSor?: string,
    currentAuswahlBer: number,
    printWorking: boolean,
    loading?: boolean
}

class Datenexport extends Component<DatenexportProps, DatenexportState> {

    constructor(props: DatenexportProps) {
        super(props);

        this.state = {
            definitionen: [],
            bereiche: [],
            defItems: [],

            defItemsCheckAll: false,

            currentBez: '',
            currentAuswahlDef: 0,
            currentAuswahlDefEdit: 0,
            currentAuswahlBer: 0,
            printWorking: false,
        }

        this.loadDefinitions = this.loadDefinitions.bind(this);
        this.loadBereiche = this.loadBereiche.bind(this)
        this.updateWahl = this.updateWahl.bind(this)
        this.runExport = this.runExport.bind(this)
        this.loadDef2Edit = this.loadDef2Edit.bind(this)
    }

    async componentDidMount() {
        try {
            let listeDef = await this.loadDefinitions();
            let listeBer = await this.loadBereiche();

            this.setState({
                definitionen: listeDef,
                bereiche: listeBer,
                loading: false
            });

        } catch (e) {
            console.log("Error in loading list: " + e);
        }
    }

    async loadBereiche() {
        return await DatenexportService.loadBereiche()
    }

    async loadDefinitions(): Promise<Definition[]> {

        this.setState({
            loading: true
        })

        return await DatenexportService.loadDefinitions()
    }

    async updateWahl(val: any, mode = 'def') {
        if (mode === 'def') {
            this.setState({
                currentAuswahlDef: val,
            })
        } else if (mode === 'ber') {
            let defItems = await this.loadDefItems(val, 0);
            this.setState({
                currentAuswahlBer: val,
                defItems: defItems.items,
                currentAuswahlSor: defItems.sort,
            })
        } else if (mode === 'bez') {
            this.setState({
                currentBez: val,
            })
        } else if (mode === 'sor') {
            this.setState({
                currentAuswahlSor: val,
            })
        }
    }

    async loadDefItems(bereich: number, defID = 0) {
        return await DatenexportService.loadDefItems(bereich, defID)
    }

    async runExport() {

        //show working message and inactivate Button
        const toastId = toast.dark(<div><FontAwesomeIcon icon={faPrint} /> Dokument wird erzeugt,<br />einen Moment bitte...</div>, {
            autoClose: false
        });

        await this.printRequest();

        //hide working message and activate Button
        toast.dismiss(toastId);
    }

    findArrayElementById(array: Definition[], id: number) {
        return array.find((element) =>
            element.defID === id
        )
    }

    async printRequest() {
        let itemSelected = this.findArrayElementById(this.state.definitionen, this.state.currentAuswahlDef);

        trackEvent('Export', 'Get', this.state.currentAuswahlDef.toString());

        if (itemSelected) {
            const response = await DatenexportService.print(this.state.currentAuswahlDef, itemSelected)

            if (response.success && response.data.succeeded) {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(new Blob([
                    new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
                    response.data.daten], { type: "text/plain;charset=UTF-8" }));
                link.download = response.data.dateiname;
                link.click();
            } else {
                toast.warning("Der Export konnte nicht geladen werden: " + response.error, {
                    autoClose: 10000
                });
            }
        }
    }

    getDefinitionById(id: number, mode = 'item') {
        let thisDefinition = this.state.definitionen.find(item => item.defID === id);

        switch (mode) {
            case 'ber':
                return thisDefinition?.bereich;
            case 'bez':
                return thisDefinition?.bezeichnung;
            default:
                return thisDefinition;
        }

    }

    async loadDef2Edit(clear = false) {
        trackEvent('Export', 'Edit');

        if (clear) {
            this.setState({
                currentAuswahlDef: 0,
                currentAuswahlDefEdit: 0,
                currentAuswahlBer: 0,
                currentBez: '',
                defItems: [],
                loading: false
            })
        } else {
            //load this definition
            let thisItem = this.getDefinitionById(this.state.currentAuswahlDef) as Definition | undefined;
            if (thisItem) {
                let defItems = await this.loadDefItems(thisItem.bereich, Number(thisItem.defID));

                this.setState({
                    currentAuswahlDefEdit: this.state.currentAuswahlDef,
                    currentAuswahlBer: thisItem.bereich as number,
                    currentBez: thisItem.bezeichnung,
                    defItems: defItems.items,
                    currentAuswahlSor: defItems.sort,
                    defItemsCheckAll: false,
                    loading: false
                })

                toast.success("Definition erfolgreich geladen. Sie können diese nun anpassen.", {
                    autoClose: 5000
                });
            }
        }
    }

    async deleteDef() {
        trackEvent('Export', 'Delete');

        this.setState({ loading: true })

        //load this definition
        let definition = this.getDefinitionById(this.state.currentAuswahlDef) as Definition | undefined;

        if (definition) {
            try {
                const result = await DatenexportService.deleteDefinition(definition)

                if (result && result.success) {
                    let listeDef = await this.loadDefinitions();

                    this.setState({
                        definitionen: listeDef,
                        loading: false
                    })

                    this.loadDef2Edit(true)

                    toast.success("Definition erfolgreich gelöscht.", {
                        autoClose: 5000
                    });
                }
            } catch (e) {
                console.log("Error in loading list: " + e);
            } finally {
                this.setState({ loading: false })
            }
        }

        this.setState({ loading: false })
    }

    async saveDef() {

        this.setState({
            loading: true
        })

        const response = await DatenexportService.saveDefinition(
            this.state.currentAuswahlDefEdit,
            this.state.currentAuswahlBer,
            this.state.currentBez,
            this.state.currentAuswahlSor,
            this.state.defItems,
        )

        if (response.success) {
            let listeDef = await this.loadDefinitions();
            this.setState({
                definitionen: listeDef,
            });

            this.loadDef2Edit(true);

            toast.success("Definition erfolgreich gespeichert. Sie können diese nun verwenden.", {
                autoClose: 5000
            });
        }

        this.setState({
            loading: false
        })
        return false;
    }

    getSortFields() {
        let result: KeyValue[] = [];

        this.state.defItems.forEach(item => {
            if (item.selected) {
                result.push({
                    'id': item.field,
                    'value': item.field ?? '',
                })
            }
        });

        return result;
    }

    setCheckLine(index: number, val: boolean) {
        let thisList = this.state.defItems;

        if (index === -1) {
            //switch all
            thisList.forEach(item => item.selected = val);
            this.setState({
                defItems: thisList,
                defItemsCheckAll: val,
            })

        } else {
            thisList[index].selected = val;
            this.setState({
                defItems: thisList,
            })
        }
    }

    array_move(old_index: number, new_index: number) {
        let arr = this.state.defItems;

        arr.splice(new_index, 0, ...arr.splice(old_index, 1));

        this.setState({
            defItems: arr,
        })
    };

    render() {
        console.log("currentAuswahlDef", this.state.currentAuswahlDef)

        return (
            <div>
                <h3 className="alh_pageheader">Datenexport</h3>
                <div className="alh_subheader">Hier können Sie alle Daten einfach und schnell in das CSV Format exportieren. Dieses können Sie dann z.B. mit Microsoft Excel öffnen.</div>

                {!this.state.loading ? <Container>
                    <Row className="alh_rowTopList">
                        <Col lg={6}>
                            <div className="alh_frontBoxes">
                                <h3>Auswahl Exportdefinition</h3>
                                <div>Wählen Sie sich hier eine der bereits angelegten Definitionen aus und führen Sie den Export mit "Datenexport starten" aus:</div>
                                <br />
                                <SelectField
                                    id='auswahlDef'
                                    name='auswahlDef'
                                    withPleaseSelect={true}
                                    formGroupClassName='small-form-group'
                                    size='sm'
                                    options={this.state.definitionen.map(d => ({ id: d.defID, value: d.bezeichnung }))}
                                    value={this.state.currentAuswahlDef}
                                    onChange={(val) => this.updateWahl(Number(val), 'def')}
                                /><br />

                                <Button variant="success" className='fullSize alhButton' disabled={this.state.currentAuswahlDef === 0} onClick={() => this.runExport()}>Datenexport starten</Button>
                                <br /><br />
                                {UserStore.hasBerechtigung('extras_datenexport', 'write') &&
                                    <Button variant="outline-info" size='sm' className='fullSize alhButton alhButtonSpaceBottom'
                                        disabled={this.state.currentAuswahlDef === 0}
                                        onClick={() => this.loadDef2Edit()}>Definition anpassen</Button>
                                }
                                {UserStore.hasBerechtigung('extras_datenexport', 'write') &&
                                    <Button variant="outline-danger" size='sm' className='fullSize alhButton'
                                        disabled={this.state.currentAuswahlDef === 0}
                                        onClick={() => this.deleteDef()}>Definition löschen</Button>
                                }
                            </div>
                        </Col>
                        {UserStore.hasBerechtigung('extras_datenexport', 'write') &&
                            <Col lg={6}>
                                <div className="alh_frontBoxes">
                                    <h3>Export
                                        definieren {(this.state.currentAuswahlDefEdit === 0) ? "(Neu)" : "(" + this.getDefinitionById(this.state.currentAuswahlDefEdit, 'bez') + ")"}</h3>
                                    <div>Hier können Sie einen <b>bestehenden Export anpassen</b> (hierfür bitte links die
                                        Definition auswählen und dann mit "Definition anpassen" laden) oder einen <b>neuen
                                            Export erstellen</b>.
                                    </div>
                                    <br />
                                    <div>Bereich wählen:</div>
                                    <SelectField
                                        id='auswahlBer'
                                        name='auswahlBer'
                                        withPleaseSelect={true}
                                        formGroupClassName='small-form-group'
                                        size='sm'
                                        disabled={this.state.currentAuswahlDefEdit !== 0}
                                        options={this.state.bereiche}
                                        value={this.state.currentAuswahlBer}
                                        onChange={(val) => this.updateWahl(Number(val), 'ber')}
                                    />
                                    <br />
                                    <div>Bezeichnung:</div>
                                    <InputField
                                        type='clean'
                                        id='currentBez'
                                        name='currentBez'
                                        size='sm'
                                        forceAutofill={true}
                                        disabled={this.state.currentAuswahlDefEdit !== 0}
                                        placeholder='Bezeichnung des Exports'
                                        value={this.state.currentBez}
                                        onChange={(val) => this.updateWahl(val, 'bez')}
                                    />
                                    <br />
                                    <div>Sortierung:</div>
                                    <SelectField
                                        id='auswahlSort'
                                        name='auswahlSort'
                                        formGroupClassName='small-form-group'
                                        size='sm'
                                        withPleaseSelect={true}
                                        disabled={this.state.defItems.length === 0}
                                        options={this.getSortFields()}
                                        value={this.state.currentAuswahlSor}
                                        onChange={(val) => this.updateWahl(val, 'sor')}
                                    />
                                    <br />
                                    <Button variant="outline-success" size='sm'
                                        className='fullSize alhButton alhButtonSpaceBottom'
                                        disabled={(this.state.currentAuswahlBer === 0 || this.state.currentBez === "")}
                                        onClick={() => this.saveDef()}>Definition speichern</Button>
                                    <Button variant="outline-danger" size='sm' className='fullSize alhButton'
                                        disabled={(this.state.currentAuswahlBer === 0 && this.state.currentBez === "")}
                                        onClick={() => this.loadDef2Edit(true)}>Definition verwerfen</Button>

                                    {(this.state.defItems.length > 0) &&
                                        <>
                                            <br /><br />
                                            <Table striped bordered hover size="sm" className="alhTable">
                                                <thead>
                                                    <tr>
                                                        <th className="alh_tablecheckbox">
                                                            <InputCheckbox
                                                                name={"check_all"}
                                                                title="Alle auswählen"
                                                                checked={this.state.defItemsCheckAll}
                                                                onChange={(val) => this.setCheckLine(-1, val)}
                                                            />
                                                        </th>
                                                        <th>Feldname</th>
                                                        <th className="alh_tableactions">&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.defItems.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="alh_tablecheckbox">
                                                                        <InputCheckbox
                                                                            name={"check_" + index}
                                                                            title="Auswählen"
                                                                            checked={this.state.defItems[index]['selected']}
                                                                            onChange={(val) => this.setCheckLine(index, val)}
                                                                        />
                                                                    </td>
                                                                    <td className="">{item.field}</td>
                                                                    <td className="tableActions">
                                                                        <FontAwesomeIcon icon={faArrowUp}
                                                                            onClick={() => this.array_move(index, index - 1)}
                                                                            title="Nach oben"
                                                                            className={((index === 0) ? "d-none" : "") + " faButton"} />
                                                                        <FontAwesomeIcon icon={faArrowDown}
                                                                            onClick={() => this.array_move(index, index + 1)}
                                                                            title="Nach unten"
                                                                            className={((index === this.state.defItems.length - 1) ? "d-none" : "") + " faButton"} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>

                                            <br />
                                            <Button variant="outline-success" size='sm'
                                                className='fullSize alhButton alhButtonSpaceBottom'
                                                disabled={(this.state.currentAuswahlBer === 0 || this.state.currentBez === "")}
                                                onClick={ () => this.saveDef()}>Definition speichern</Button>
                                            <Button variant="outline-danger"
                                                size='sm'
                                                className='fullSize alhButton'
                                                disabled={(this.state.currentAuswahlBer === 0 && this.state.currentBez === "")}
                                                onClick={() => this.loadDef2Edit(true)}>Definition verwerfen</Button>
                                        </>
                                    }
                                </div>
                            </Col>
                        }
                    </Row>
                </Container> : <PointSpreadLoading></PointSpreadLoading>}

            </div>
        );
    }
}

export default Datenexport;
