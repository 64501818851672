import React, { Component, ReactNode } from 'react';
import { Button, Modal } from "react-bootstrap";

interface ConfirmModalProps {
    show: boolean
    heading: string
    body: string
    warning?: ReactNode
    okButtonStr: string
    showConfirm?: boolean
    closeFct: () => void
    confirmFct: (e: React.MouseEvent<HTMLElement>) => void
}

class ConfirmModal extends Component<ConfirmModalProps> {

    constructor(props: ConfirmModalProps) {
        super(props);

        this.closeConfirm = this.closeConfirm.bind(this);
    }

    closeConfirm() {
        if (typeof this.props.closeFct === "function") {
            this.props.closeFct();
        }
    }

    render() {

        const { okButtonStr = "OK" } = this.props

        return (
            <Modal show={this.props.show} onHide={this.closeConfirm}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.warning ? this.props.body : this.props.warning}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeConfirm}>
                        Schließen
                    </Button>
                    {this.props.showConfirm && <Button variant="primary" onClick={(e) => this.props.confirmFct(e)}>
                        {okButtonStr}
                    </Button>}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ConfirmModal;