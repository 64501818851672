import UserStore from "../stores/UserStore";

const LeitzahlenService = {
    get: async (mgnr?: string) => {
        let res = await fetch(UserStore.url + '/leitzahlen', {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'responseType': 'arraybuffer'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    MGNr: mgnr
                }
            })
        });

        return await res.json();
    }
}

export default LeitzahlenService