import { JournalBuchung } from "../models/Buchung";
import UserStore from "../stores/UserStore";
import { FileService } from "./FileService";

const MitgliedService = {
    mitgliedVgdPrint: async (mgnr: string, buchung: JournalBuchung, dokumente: string[], beitragsjahr?: number, begruendung?: string) => {
        let res = await fetch(UserStore.url + '/mitgliedVgdPrint', {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'responseType': 'arraybuffer'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    MGNr: mgnr,
                    buchung: buchung,
                    beitragsjahr: beitragsjahr,
                    begruendung: begruendung,
                    dokumente: dokumente
                }
            })
        });

        return await res.json();
    },
    mitgliedPrintFile: async (mgnr: string, dokumentName: string, sprache: string, args: string, filename: string) => {

        const body =  JSON.stringify({
            attr: {
                BSTNr: UserStore.username,
                MGNr: mgnr,
                DokumentName: dokumentName,
                Sprache: sprache,
                Arguments: args
            }
        })

        await FileService.printFile("mitgliedPrint", body, filename)
    },
    getMgNew: async () => {
        let res = await fetch(UserStore.url + '/mgnew', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    IstBlankoMG: false
                }
            })
        });

        return await res.json();
    },
    loadBuJournal: async (mgNr: string, loadAll: boolean) => {
        let res = await fetch(UserStore.url + '/mgbujournal', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    MGNr: mgNr,
                    loadAll: loadAll
                }
            })
        });
        return await res.json();
    }
}

export default MitgliedService