import KeyValue from "../models/KeyValue"

export interface MyBstAccordionDatencheck {
    accordion: string,
    accordionProperty?: string,
    fields: GrunddatenDatencheckField[]
}

interface BaseDatencheckField {
    prop: string
    name: string
    type: "Textfeld" | "TextfeldLink" | "TextfeldMultiline" | "Dropdown" | "Checkbox" | "Datum" | "Radio" | "Oeffnungszeit" | "None"
    order: number
    values?: KeyValue[]
    validate?: (value: any) => boolean
}

export interface GrunddatenDatencheckField extends BaseDatencheckField {
    genehmigungspflichtig?: boolean
    toDoEmpfaenger?: "ALH\\andrea.huettner" | "ALH\\lena.wohlmannstetter"
    disabled?: boolean
    errorMessage?: string
    counterProp?: string
    childrenContainsOwnData?: boolean
    children?: GrunddatenDatencheckField[]
}

export interface MitarbeiterDatencheckField extends BaseDatencheckField {
    columnText: string
    placeholder?: string
    readonly?: boolean
    title?: string
    //speziell für E-Mail Verein
    differentDatencheckProp?: string
    differentTitle?: string

    useOldValue?: boolean
    dontShowMitarbeiterProp?: boolean

    //für bstmaportalaktiv
    condition?: (value: any) => boolean

    children?: MitarbeiterDatencheckField[]
}

const numberValidation = (value: any) => {
    //Wenn value = number => true sonst false
    return !isNaN(value)
}

const vorwahlCheck = (value: any) => {
    return value.toString().includes("+49") || value.toString().includes("0049")
}

const grunddatenDatencheckFields: MyBstAccordionDatencheck[] = [
    {
        accordion: "Grunddaten",
        fields: [
            {
                prop: "bst.nachname",
                name: "Nachname",
                type: "Textfeld",
                order: 0,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "bst.vorname",
                name: "Vorname",
                type: "Textfeld",
                order: 1,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "berufsbezeichnung",
                name: "Berufsbezeichnung",
                type: "Textfeld",
                order: 2,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "bst.adresse.strasse",
                name: "Strasse, Haus-Nr. (privat, nicht veröffentlicht)",
                type: "Textfeld",
                order: 3,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "bst.adresse.plz",
                name: "PLZ (privat, nicht veröffentlicht)",
                type: "Textfeld",
                order: 4,
                validate: (value) => (numberValidation(value) && value.toString().length === 5),
                errorMessage: "PLZ darf nur aus Zahlen bestehen + 5 Stellen haben",
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "bst.adresse.ort",
                name: "Ort (privat, nicht veröffentlicht)",
                type: "Textfeld",
                order: 5,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "bst.adresse.ortsteil",
                name: "Orts- bzw. Stadtteil (privat, nicht veröffentlicht)",
                type: "Textfeld",
                order: 6,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "bst.geburtstag",
                name: "Geburtstag",
                type: "Datum",
                order: 7,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                disabled: true
            },
            {
                prop: "telefonPrivat",
                name: "Telefon (privat, nicht veröffentlicht)",
                type: "Textfeld",
                order: 8,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                children: [
                    {
                        prop: "telefonPrivat.split.0",
                        name: "Vorwahl",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value) && !vorwahlCheck(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen und keine +49 oder 0049",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "telefonPrivat.split.1",
                        name: "Rufnummer",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 1
                    }
                ]
            },
            {
                prop: "handyPrivat",
                name: "Handy (privat, nicht veröffentlicht)",
                type: "Textfeld",
                order: 9,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                children: [
                    {
                        prop: "handy.split.0",
                        name: "Vorwahl",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value) && !vorwahlCheck(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen und keine +49 oder 0049",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "handy.split.1",
                        name: "Rufnummer",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 1
                    }
                ]
            },
            {
                prop: "eMail",
                name: "E-Mail (Beratungsstelle)",
                type: "Textfeld",
                order: 10,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                disabled: true
            },
            {
                prop: "eMailPrivat",
                name: "E-Mail (privat, nicht veröffentlicht)",
                type: "Textfeld",
                order: 11,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
            },
        ]
    },
    {
        accordion: "Beratungsstelle 1",
        accordionProperty: "bstStandort1",
        fields: [
            {
                prop: "ofd",
                name: "bei folgender OFD gemeldet",
                type: "Textfeld",
                order: 0,
                disabled: true
            },
            {
                prop: "adresse.strasse",
                name: "Staße, Haus-Nr.",
                type: "Textfeld",
                order: 0,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "adresse.plz",
                name: "PLZ",
                type: "Textfeld",
                validate: (value) => (numberValidation(value) && value.toString().length === 5),
                errorMessage: "PLZ darf nur aus Zahlen bestehen + 5 Stellen haben",
                genehmigungspflichtig: true,
                order: 1,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "adresse.ort",
                name: "Ort",
                type: "Textfeld",
                genehmigungspflichtig: true,
                order: 2,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "adresse.ortsteil",
                name: "Ortsteil",
                type: "Textfeld",
                genehmigungspflichtig: true,
                order: 3,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "telefon",
                name: "Telefon",
                type: "Textfeld",
                genehmigungspflichtig: true,
                order: 4,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                children: [
                    {
                        prop: "telefon.split.0",
                        name: "Vorwahl",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value) && !vorwahlCheck(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen und keine +49 oder 0049",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "telefon.split.1",
                        name: "Rufnummer",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 1
                    },
                ]
            },
            {
                prop: "handy",
                name: "Handy",
                type: "Textfeld",
                order: 5,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                children: [
                    {
                        prop: "handy.split.0",
                        name: "Vorwahl",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value) && !vorwahlCheck(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen und keine +49 oder 0049",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "handy.split.1",
                        name: "Rufnummer",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 1
                    }, {
                        prop: "handyIntern",
                        name: "Intern",
                        type: "Checkbox",
                        order: 2,
                        childrenContainsOwnData: true,
                        counterProp: "handyOeffentlich",
                        genehmigungspflichtig: true,
                        toDoEmpfaenger: "ALH\\andrea.huettner",
                    },
                    {
                        prop: "handyOeffentlich",
                        name: "Öffentlich",
                        type: "Checkbox",
                        order: 3,
                        childrenContainsOwnData: true,
                        counterProp: "handyIntern",
                        genehmigungspflichtig: true,
                        toDoEmpfaenger: "ALH\\andrea.huettner",
                    },
                ]
            },
            {
                prop: "telefax",
                name: "Fax",
                type: "Textfeld",
                order: 6,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                children: [
                    {
                        prop: "telefax.split.0",
                        name: "Vorwahl",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "telefax.split.1",
                        name: "Rufnummer",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 1
                    },
                ]
            },
            {
                prop: "homepage",
                name: "Webseite",
                type: "TextfeldLink",
                order: 7,
                genehmigungspflichtig: false,
                disabled: true
            },
            {
                prop: "facebook",
                name: "Facebook",
                type: "TextfeldLink",
                order: 8,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "terminlink",
                name: "Terminlink",
                type: "Textfeld",
                order: 9,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "googleMyBusiness",
                name: "Google My Business",
                type: "TextfeldLink",
                order: 10,
                disabled: true,
                children: [
                    {
                        prop: "googleMyBusinessText",
                        name: "",
                        order: 0,
                        type: "Textfeld",
                        disabled: true,
                        childrenContainsOwnData: true,
                    }
                ]
            },
            {
                prop: "anzeigeGoogleBewertungen",
                name: "Google Bewertungen auf Webseite anzeigen",
                type: "Dropdown",
                order: 11,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                values: [{ id: "true", value: "Ja" }, { id: "false", value: "Nein" }]
            },
            {
                prop: "oeffnungszeiten.montag",
                name: "Montag",
                type: "Oeffnungszeit",
                order: 12,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.dienstag",
                name: "Dienstag",
                type: "Oeffnungszeit",
                order: 13,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.mittwoch",
                name: "Mittwoch",
                type: "Oeffnungszeit",
                order: 14,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.donnerstag",
                name: "Donnerstag",
                type: "Oeffnungszeit",
                order: 15,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.freitag",
                name: "Freitag",
                type: "Oeffnungszeit",
                order: 16,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.samstag",
                name: "Samstag",
                type: "Oeffnungszeit",
                order: 17,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.sonntag",
                name: "Sonntag",
                type: "Oeffnungszeit",
                order: 18,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "weitereInfos",
                name: "Weitere Infos (z.B. Termine nach Vereinbarung)",
                type: "Textfeld",
                order: 19,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "bedeutung",
                name: "Lohnsteuerhilfe bedeutet für mich",
                type: "Textfeld",
                order: 20,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "ausbildungErfahrung",
                name: "Ausbildung und Erfahrung",
                type: "TextfeldMultiline",
                order: 21,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "spezialkenntnisse",
                name: "Spezialkenntnisse",
                type: "Textfeld",
                order: 21,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                children: [
                    {
                        prop: "spezialkenntnisse.include.Sonstiges",
                        name: "Sonstiges",
                        type: "TextfeldMultiline",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "spezialkenntnisse.include.Ausland",
                        name: "Ausland",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "spezialkenntnisse.include.Firmenwagen",
                        name: "Firmenwagen",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 1
                    },
                    {
                        prop: "spezialkenntnisse.include.Kapitaleinkünfte",
                        name: "Kapitaleinkünfte",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 2
                    },
                    {
                        prop: "spezialkenntnisse.include.Reisekosten",
                        name: "Reisekosten",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 3
                    },
                    {
                        prop: "spezialkenntnisse.include.Rentner",
                        name: "Rentner:innen",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 4
                    },
                    {
                        prop: "spezialkenntnisse.include.Soldaten",
                        name: "Soldaten:innen",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 5
                    },
                    {
                        prop: "spezialkenntnisse.include.Vermietung und Verpachtung",
                        name: "Vermietung und Verpachtung",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 6
                    },

                ]
            },
            {
                prop: "anfahrtsbeschreibung",
                name: "Anfahrtsbeschreibung",
                type: "TextfeldMultiline",
                order: 22,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "sprachen",
                name: "Beratung auch in folgenden Sprachen:",
                type: "Textfeld",
                order: 23,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                children: [
                    {
                        prop: "sprachen.include.arabisch",
                        name: "arabisch",
                        type: "Checkbox",
                        order: 1
                    },
                    {
                        prop: "sprachen.include.albanisch",
                        name: "albanisch",
                        type: "Checkbox",
                        order: 2
                    },
                    {
                        prop: "sprachen.include.aramäisch",
                        name: "aramäisch",
                        type: "Checkbox",
                        order: 3
                    },
                    {
                        prop: "sprachen.include.belgisch",
                        name: "belgisch",
                        type: "Checkbox",
                        order: 4
                    },
                    {
                        prop: "sprachen.include.bulgarisch",
                        name: "bulgarisch",
                        type: "Checkbox",
                        order:5
                    },
                    {
                        prop: "sprachen.include.bosnisch",
                        name: "bosnisch",
                        type: "Checkbox",
                        order: 6
                    },
                    {
                        prop: "sprachen.include.englisch",
                        name: "englisch",
                        type: "Checkbox",
                        order: 7
                    },
                    {
                        prop: "sprachen.include.französisch",
                        name: "französisch",
                        type: "Checkbox",
                        order: 8
                    },
                    {
                        prop: "sprachen.include.Ghana",
                        name: "Ghana",
                        type: "Checkbox",
                        order: 9
                    },
                    {
                        prop: "sprachen.include.griechisch",
                        name: "griechisch",
                        type: "Checkbox",
                        order: 10
                    },
                    {
                        prop: "sprachen.include.hindi",
                        name: "hindi",
                        type: "Checkbox",
                        order: 11
                    },
                    {
                        prop: "sprachen.include.italienisch",
                        name: "italienisch",
                        type: "Checkbox",
                        order: 12
                    },
                    {
                        prop: "sprachen.include.japanisch",
                        name: "japanisch",
                        type: "Checkbox",
                        order: 13
                    },
                    {
                        prop: "sprachen.include.Kenia",
                        name: "Kenia",
                        type: "Checkbox",
                        order: 14
                    },
                    {
                        prop: "sprachen.include.kroatisch",
                        name: "kroatisch",
                        type: "Checkbox",
                        order: 15
                    },
                    {
                        prop: "sprachen.include.kurdisch",
                        name: "kurdisch",
                        type: "Checkbox",
                        order: 16
                    },
                    {
                        prop: "sprachen.include.niederländisch",
                        name: "niederländisch",
                        type: "Checkbox",
                        order: 17
                    },
                    {
                        prop: "sprachen.include.Nigeria",
                        name: "Nigeria",
                        type: "Checkbox",
                        order: 18
                    },
                    {
                        prop: "sprachen.include.persisch",
                        name: "persisch",
                        type: "Checkbox",
                        order: 19
                    },
                    {
                        prop: "sprachen.include.polnisch",
                        name: "polnisch",
                        type: "Checkbox",
                        order: 20
                    },
                    {
                        prop: "sprachen.include.portugiesisch",
                        name: "portugiesisch",
                        type: "Checkbox",
                        order: 21
                    },
                    {
                        prop: "sprachen.include.rumänisch",
                        name: "rumänisch",
                        type: "Checkbox",
                        order: 22
                    },
                    {
                        prop: "sprachen.include.russisch",
                        name: "russisch",
                        type: "Checkbox",
                        order: 23
                    },
                    {
                        prop: "sprachen.include.serbisch",
                        name: "serbisch",
                        type: "Checkbox",
                        order: 24
                    },
                    {
                        prop: "sprachen.include.slowakisch",
                        name: "slowakisch",
                        type: "Checkbox",
                        order: 25
                    },
                    {
                        prop: "sprachen.include.spanisch",
                        name: "spanisch",
                        type: "Checkbox",
                        order: 26
                    },
                    {
                        prop: "sprachen.include.tschechisch",
                        name: "tschechisch",
                        type: "Checkbox",
                        order: 27
                    },
                    {
                        prop: "sprachen.include.tygrinya",
                        name: "tygrinya",
                        type: "Checkbox",
                        order: 28
                    },
                    {
                        prop: "sprachen.include.türkisch",
                        name: "türkisch",
                        type: "Checkbox",
                        order: 29
                    },
                    {
                        prop: "sprachen.include.ukrainisch",
                        name: "ukrainisch",
                        type: "Checkbox",
                        order: 30
                    },
                    {
                        prop: "sprachen.include.ungarisch",
                        name: "ungarisch",
                        type: "Checkbox",
                        order: 31
                    },
                ]
            },
            {
                prop: "onlinetermine",
                name: "Onlinetermine (wenn Postbox aktiv: nicht änderbar)",
                type: "Dropdown",
                order: 24,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                values: [{ id: "true", value: "Ja" }, { id: "false", value: "Nein" }]
            },
            {
                prop: "beratungVorOrt",
                name: "Beratung vor Ort (in der Beratungsstelle)",
                type: "Dropdown",
                order: 25,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                values: [{ id: "true", value: "Ja" }, { id: "false", value: "Nein" }]
            },
            {
                prop: "extraService",
                name: "Extra-Service",
                type: "Textfeld",
                order: 25,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                children: [
                    {
                        prop: "extraService.include.Sonstiges",
                        name: "Sonstiges",
                        type: "TextfeldMultiline",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "extraService.include.Hausbesuche",
                        name: "Hausbesuche",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 1
                    },
                    {
                        prop: "extraService.include.Abendtermine",
                        name: "Abendtermine",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 2
                    },
                    {
                        prop: "extraService.include.Briefkasten zum Einwerfen von Unterlagen",
                        name: "Briefkasten zum Einwerfen von Unterlagen",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 3
                    }
                ]
            },
            {
                prop: "parkplaetze",
                name: "Parkplätze",
                type: "None",
                order: 26,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                children: [
                    {
                        prop: "parkplaetzeKostenlos",
                        name: "Kostenlos",
                        type: "Checkbox",
                        childrenContainsOwnData: true,
                        counterProp: "parkplaetzeGebuehrenpflichtig",
                        toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                        order: 0,
                    },
                    {
                        prop: "parkplaetzeGebuehrenpflichtig",
                        name: "Gebührenpflichtig",
                        type: "Checkbox",
                        childrenContainsOwnData: true,
                        counterProp: "parkplaetzeKostenlos",
                        toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                        order: 1
                    }
                ]
            },
            {
                prop: "rollstuhlgerecht",
                name: "Rollstuhlgerecht",
                type: "Dropdown",
                order: 27,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                values: [{ id: "true", value: "Ja" }, { id: "false", value: "Nein" }]
            },
            {
                prop: "klimaanlage",
                name: "Klimaanlage",
                type: "Checkbox",
                order: 28,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },
            {
                prop: "toilette",
                name: "WC",
                type: "Checkbox",
                order: 29,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },
            {
                prop: "kartenzahlung",
                name: "EC-Kartenzahlung",
                type: "Checkbox",
                order: 30,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },
            {
                prop: "kinderfreundlich",
                name: "Kinderfreundlich (Spielecke usw.)",
                type: "Checkbox",
                order: 31,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },
            {
                prop: "hundeErlaubt",
                name: "Hunde erlaubt",
                type: "Checkbox",
                order: 32,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },

        ]
    },
    {
        accordion: "Beratungsstelle 2",
        accordionProperty: "bstStandort2",
        fields: [
            {
                prop: "adresse.strasse",
                name: "Staße, Haus-Nr.",
                type: "Textfeld",
                order: 0,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "adresse.plz",
                name: "PLZ",
                type: "Textfeld",
                validate: (value) => (numberValidation(value) && value.toString().length === 5),
                errorMessage: "PLZ darf nur aus Zahlen bestehen + 5 Stellen haben",
                genehmigungspflichtig: true,
                order: 1,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "adresse.ort",
                name: "Ort",
                type: "Textfeld",
                genehmigungspflichtig: true,
                order: 2,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "adresse.ortsteil",
                name: "Ortsteil",
                type: "Textfeld",
                genehmigungspflichtig: true,
                order: 3,
                toDoEmpfaenger: "ALH\\andrea.huettner"
            },
            {
                prop: "telefon",
                name: "Telefon",
                type: "Textfeld",
                genehmigungspflichtig: true,
                order: 4,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                children: [
                    {
                        prop: "telefon.split.0",
                        name: "Vorwahl",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value) && !vorwahlCheck(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen und keine +49 oder 0049",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "telefon.split.1",
                        name: "Rufnummer",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 1
                    },
                ]
            },
            {
                prop: "handy",
                name: "Handy",
                type: "Textfeld",
                order: 5,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                children: [
                    {
                        prop: "handy.split.0",
                        name: "Vorwahl",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value) && !vorwahlCheck(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen und keine +49 oder 0049",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "handy.split.1",
                        name: "Rufnummer",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 1
                    }, {
                        prop: "handyIntern",
                        name: "Intern",
                        type: "Checkbox",
                        order: 2,
                        childrenContainsOwnData: true,
                        counterProp: "handyOeffentlich",
                        genehmigungspflichtig: true,
                        toDoEmpfaenger: "ALH\\andrea.huettner",
                    },
                    {
                        prop: "handyOeffentlich",
                        name: "Öffentlich",
                        type: "Checkbox",
                        order: 3,
                        childrenContainsOwnData: true,
                        counterProp: "handyIntern",
                        genehmigungspflichtig: true,
                        toDoEmpfaenger: "ALH\\andrea.huettner",
                    },
                ]
            },
            {
                prop: "telefax",
                name: "Fax",
                type: "Textfeld",
                order: 6,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                children: [
                    {
                        prop: "telefax.split.0",
                        name: "Vorwahl",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "telefax.split.1",
                        name: "Rufnummer",
                        type: "Textfeld",
                        validate: (value) => numberValidation(value),
                        errorMessage: "Nummer bitte ohne Leerzeichen und Sonderzeichen",
                        genehmigungspflichtig: true,
                        order: 1
                    },
                ]
            },
            {
                prop: "homepage",
                name: "Webseite",
                type: "TextfeldLink",
                order: 7,
                genehmigungspflichtig: false,
                disabled: true
            },
            {
                prop: "facebook",
                name: "Facebook",
                type: "TextfeldLink",
                order: 8,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "terminlink",
                name: "Terminlink",
                type: "Textfeld",
                order: 9,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "googleMyBusiness",
                name: "Google My Business",
                type: "TextfeldLink",
                order: 10,
                disabled: true
            },
            {
                prop: "oeffnungszeiten.montag",
                name: "Montag",
                type: "Oeffnungszeit",
                order: 12,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.dienstag",
                name: "Dienstag",
                type: "Oeffnungszeit",
                order: 13,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.mittwoch",
                name: "Mittwoch",
                type: "Oeffnungszeit",
                order: 14,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.donnerstag",
                name: "Donnerstag",
                type: "Oeffnungszeit",
                order: 15,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.freitag",
                name: "Freitag",
                type: "Oeffnungszeit",
                order: 16,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.samstag",
                name: "Samstag",
                type: "Oeffnungszeit",
                order: 17,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "oeffnungszeiten.sonntag",
                name: "Sonntag",
                type: "Oeffnungszeit",
                order: 18,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "weitereInfos",
                name: "Weitere Infos (z.B. Termine nach Vereinbarung)",
                type: "Textfeld",
                order: 19,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "bedeutung",
                name: "Lohnsteuerhilfe bedeutet für mich",
                type: "Textfeld",
                order: 20,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "ausbildungErfahrung",
                name: "Ausbildung und Erfahrung",
                type: "TextfeldMultiline",
                order: 21,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "spezialkenntnisse",
                name: "Spezialkenntnisse",
                type: "Textfeld",
                order: 21,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                children: [
                    {
                        prop: "spezialkenntnisse.include.Sonstiges",
                        name: "Sonstiges",
                        type: "TextfeldMultiline",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "spezialkenntnisse.include.Ausland",
                        name: "Ausland",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "spezialkenntnisse.include.Firmenwagen",
                        name: "Firmenwagen",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 1
                    },
                    {
                        prop: "spezialkenntnisse.include.Kapitaleinkünfte",
                        name: "Kapitaleinkünfte",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 2
                    },
                    {
                        prop: "spezialkenntnisse.include.Reisekosten",
                        name: "Reisekosten",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 3
                    },
                    {
                        prop: "spezialkenntnisse.include.Rentner",
                        name: "Rentner:innen",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 4
                    },
                    {
                        prop: "spezialkenntnisse.include.Soldaten",
                        name: "Soldaten:innen",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 5
                    },
                    {
                        prop: "spezialkenntnisse.include.Vermietung und Verpachtung",
                        name: "Vermietung und Verpachtung",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 6
                    },

                ]
            },
            {
                prop: "anfahrtsbeschreibung",
                name: "Anfahrtsbeschreibung",
                type: "TextfeldMultiline",
                order: 22,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "sprachen",
                name: "Beratung auch in folgenden Sprachen:",
                type: "Textfeld",
                order: 23,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
            },
            {
                prop: "onlinetermine",
                name: "Onlinetermine",
                type: "Dropdown",
                order: 24,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                values: [{ id: "true", value: "Ja" }, { id: "false", value: "Nein" }]
            },
            {
                prop: "beratungVorOrt",
                name: "Beratung vor Ort (in der Beratungsstelle)",
                type: "Dropdown",
                order: 25,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                values: [{ id: "true", value: "Ja" }, { id: "false", value: "Nein" }]
            },
            {
                prop: "extraService",
                name: "Extra-Service",
                type: "Textfeld",
                order: 25,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                children: [
                    {
                        prop: "extraService.include.Sonstiges",
                        name: "Sonstiges",
                        type: "TextfeldMultiline",
                        genehmigungspflichtig: true,
                        order: 0
                    },
                    {
                        prop: "extraService.include.Hausbesuche",
                        name: "Hausbesuche",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 1
                    },
                    {
                        prop: "extraService.include.Abendtermine",
                        name: "Abendtermine",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 2
                    },
                    {
                        prop: "extraService.include.Briefkasten zum Einwerfen von Unterlagen",
                        name: "Briefkasten zum Einwerfen von Unterlagen",
                        type: "Checkbox",
                        genehmigungspflichtig: true,
                        order: 3
                    }
                ]
            },
            {
                prop: "parkplaetze",
                name: "Parkplätze",
                type: "None",
                order: 26,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                children: [
                    {
                        prop: "parkplaetzeKostenlos",
                        name: "Kostenlos",
                        type: "Checkbox",
                        childrenContainsOwnData: true,
                        counterProp: "parkplaetzeGebuehrenpflichtig",
                        toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                        order: 0,
                    },
                    {
                        prop: "parkplaetzeGebuehrenpflichtig",
                        name: "Gebührenpflichtig",
                        type: "Checkbox",
                        childrenContainsOwnData: true,
                        counterProp: "parkplaetzeKostenlos",
                        toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                        order: 1
                    }
                ]
            },
            {
                prop: "rollstuhlgerecht",
                name: "Rollstuhlgerecht",
                type: "Dropdown",
                order: 27,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter",
                values: [{ id: "true", value: "Ja" }, { id: "false", value: "Nein" }]
            },
            {
                prop: "klimaanlage",
                name: "Klimaanlage",
                type: "Checkbox",
                order: 28,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },
            {
                prop: "toilette",
                name: "WC",
                type: "Checkbox",
                order: 29,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },
            {
                prop: "kartenzahlung",
                name: "EC-Kartenzahlung",
                type: "Checkbox",
                order: 30,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },
            {
                prop: "kinderfreundlich",
                name: "Kinderfreundlich (Spielecke usw.)",
                type: "Checkbox",
                order: 31,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },
            {
                prop: "hundeErlaubt",
                name: "Hunde erlaubt",
                type: "Checkbox",
                order: 32,
                genehmigungspflichtig: false,
                toDoEmpfaenger: "ALH\\lena.wohlmannstetter"
            },

        ]
    },
    {
        accordion: "Finanzdaten",
        fields: [
            {
                prop: "regelbesteuerung",
                name: "Regelbesteuerung",
                type: "Dropdown",
                order: 0,
                genehmigungspflichtig: false,
                disabled: true
            },
            {
                prop: "steuerID",
                name: "UStID",
                type: "Textfeld",
                order: 1,
                genehmigungspflichtig: false,
                disabled: true
            },
            {
                prop: "steuernr",
                name: "Steuernummer",
                type: "Textfeld",
                order: 2,
                genehmigungspflichtig: false,
                disabled: true
            },
            {
                prop: "finanzamt",
                name: "Finanzamt",
                type: "Textfeld",
                order: 3,
                genehmigungspflichtig: false,
                disabled: true
            },
        ]
    },
    {
        accordion: "Bankverbindung",
        fields: [
            {
                prop: "inhaber",
                name: "Inhaber",
                type: "Textfeld",
                order: 0,
                genehmigungspflichtig: false,
                disabled: true
            },
            {
                prop: "bankName",
                name: "Bank",
                type: "Textfeld",
                order: 1,
                genehmigungspflichtig: false,
                disabled: true
            },
            {
                prop: "iban",
                name: "IBAN",
                type: "Textfeld",
                order: 2,
                genehmigungspflichtig: false,
                disabled: true
            },
            {
                prop: "bankBIC",
                name: "BIC",
                type: "Textfeld",
                order: 3,
                genehmigungspflichtig: false,
                disabled: true
            },
            {
                prop: "glaeubigerID",
                name: "Gläubiger-ID",
                type: "Textfeld",
                order: 4,
                genehmigungspflichtig: false
            },
        ]
    },
]

export const mitarbeiterDatencheckFields: MitarbeiterDatencheckField[] = [
    {
        columnText: "Anrede (Herr/Frau)",
        prop: "anrede",
        name: "Anrede",
        values: [{ id: "herr", value: "Herr" }, { id: "frau", value: "Frau" }],
        type: "Dropdown",
        order: 0
    },
    {
        columnText: "Name",
        prop: "nachname",
        name: "Nachname",
        placeholder: "Nachname",
        type: "Textfeld",
        order: 1
    },
    {
        columnText: "Vorname",
        prop: "vorname",
        name: "Vorname",
        placeholder: "Vorname",
        type: "Textfeld",
        order: 2
    },
    {
        columnText: "Berufsbezeichnung (Andruck auch auf Werbematerial)",
        prop: "beruf",
        name: "Berufsbezeichnung",
        values: [
            { id: "Steuerfachangestellte:r", value: "Steuerfachangestellte:r" }, 
            { id: "Steuerberater:in", value: "Steuerberater:in" }, 
            { id: "Steuerfachassistent:in", value: "Steuerfachassistent:in" },
            { id: "Steuerfachwirt:in", value: "Steuerfachwirt:in" }
        ],
        type: "Dropdown",
        order: 3
    },
    {
        columnText: "Straße, Haus-Nr.",
        prop: "strasse",
        name: "Strasse",
        placeholder: "Straße, Haus-Nr.",
        type: "Textfeld",
        order: 4
    },
    {
        columnText: "PLZ",
        prop: "plz",
        name: "Plz",
        placeholder: "PLZ",
        type: "Textfeld",
        validate: (value) => value ? isNaN(value) || (value.toString().length ?? 0) < 5 || (value.toString().length ?? 6) > 5 : false,
        order: 5
    },
    {
        columnText: "Ort",
        prop: "ort",
        name: "Ort",
        placeholder: "Ort",
        type: "Textfeld",
        order: 6
    },
    {
        columnText: "Geb. Datum",
        prop: "gebDatum",
        name: "gebDatum",
        type: "Datum",
        order: 7
    },
    {
        columnText: "Handy",
        prop: "handy",
        name: "handy",
        type: "Textfeld",
        children: [
            {
                columnText: "",
                name: "handy.1",
                prop: "handy.1",
                placeholder: "Vorwahl",
                type: "Textfeld",
                order: 0
            },
            {
                columnText: "",
                name: "handy.2",
                prop: "handy.2",
                placeholder: "Nummer",
                type: "Textfeld",
                order: 0
            },
        ],
        order: 8
    },
    {
        columnText: "E-Mail privat",
        prop: "eMailPrivat",
        name: "eMailPrivat",
        placeholder: "E-Mail privat",
        type: "Textfeld",
        order: 9
    },
    {
        columnText: "E-Mail Verein",
        prop: "eMail",
        name: "eMail",
        differentDatencheckProp: "emailVereinBeantragen",
        useOldValue: false,
        title: "E-Mail beantragen",
        type: "Checkbox",
        order: 10
    },
    {
        columnText: "Zugang WebPortal",
        prop: "webportalZugangBeantragen",
        name: "webportalZugang",
        differentDatencheckProp: "bstmaPortalAktiv",
        useOldValue: false,
        title: "beantragen",
        type: "Checkbox",
        condition: (value) => !!value,
        order: 11
    },
    {
        columnText: "Anmeldung für",
        prop: "AnmeldungFuer",
        name: "AnmeldungFuer",
        type: "Checkbox",
        children: [
            {
                columnText: "",
                prop: "anmeldungErsteBst",
                name: "anmeldungErsteBst",
                title: "1. BST",
                type: "Checkbox",
                order: 0
            },
            {
                columnText: "",
                prop: "anmeldungZweiteBst",
                name: "anmeldungZweiteBst",
                title: "2. BST",
                type: "Checkbox",
                order: 1
            },
        ],
        order: 12
    },
    {
        columnText: "bei OFD angemeldet",
        prop: "ofdAn",
        name: "ofdAn",
        readonly: true,
        type: "Datum",
        order: 13
    },
    {
        columnText: "Anmeldung Mitarbeiter:in bei der OFD (nur falls Mitarbeiter:in steuerlich tätig)",
        prop: "ofdBeantragen",
        name: "",
        dontShowMitarbeiterProp: true,
        title: "OFD beantragen",
        type: "Radio",
        order: 14
    },
    {
        columnText: "Mitarbeiter abmelden?",
        prop: "mitarbeiterAbmelden",
        name: "mitarbeiterAbmelden",
        useOldValue: false,
        dontShowMitarbeiterProp: true,
        title: "Mitarbeiter abmelden",
        type: "Checkbox",
        order: 15
    },
]

export default grunddatenDatencheckFields