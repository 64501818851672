import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import { WaveTopBottomLoading } from "react-loadingg";
import { sortList } from "../ALHUtility";
import Buchung from '../../models/Buchung';

interface BeitragslisteBase {
    loading: boolean
}

interface BeitragslisteProps extends BeitragslisteBase {
    liste: Buchung[]
}

interface BeitragslisteState extends BeitragslisteBase {
    sort: string
    sort_mode: string
}

class Beitragsliste extends Component<BeitragslisteProps, BeitragslisteState> {

    constructor(props: BeitragslisteProps) {
        super(props);

        this.state = {
            loading: this.props.loading,

            sort: 'mgNr',
            sort_mode: 'asc',
        }

    }

    sortClick(spalte: string) {
        let newSort_mode = this.state.sort_mode;
        let newSort = this.state.sort;

        if (spalte === this.state.sort) {
            switch (this.state.sort_mode) {
                case 'asc':
                    newSort_mode = 'desc';
                    break;
                default:
                    newSort_mode = 'asc';
                    break;
            }
        } else {
            newSort = spalte;
        }

        this.setState({
            sort: newSort,
            sort_mode: newSort_mode,
        })
    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    render() {

        let currentList = sortList(this.state.sort, this.props.liste, this.state.sort_mode, (this.state.sort_mode === 'lDatum'))

        return (
            <div>
                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th onClick={() => this.sortClick('mgNr')} className={((this.state.sort === 'mgNr') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alhtable_nr"}>MG-Nr</th>
                            <th onClick={() => this.sortClick('mgName')} className={((this.state.sort === 'mgName') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>MG-Name</th>
                            <th onClick={() => this.sortClick('buchungstext')} className={((this.state.sort === 'buchungstext') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Buchungstext</th>
                            <th onClick={() => this.sortClick('betrag')} className={(((this.state.sort === 'betrag') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")) + " text-right"}>Betrag</th>
                            <th onClick={() => this.sortClick('lDatum')} className={(((this.state.sort === 'lDatum') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")) + " text-right"}>L-Datum</th>
                            <th onClick={() => this.sortClick('zahlungsweise')} className={(((this.state.sort === 'zahlungsweise') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")) + " text-right"}>Zahlungsweise</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentList.map((elem, index) => (
                            <tr key={elem.buchungHandle}>
                                <td><a href={`/mitglied/${elem.mgNr}`}>{elem.mgNr}</a></td>
                                <td>{elem.mgName}</td>
                                <td>{elem.buchungstext}</td>
                                <td className="text-right">{this.numberFormat(elem.betrag ?? 0)}</td>
                                <td className="text-right"><Moment format="DD.MM.YYYY">{elem.lDatum}</Moment></td>
                                <td className="text-right">{elem.zahlungsweise}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {this.props.liste && this.props.liste.length === 0 && !this.state.loading &&
                    <div className="info">Keine Einträge vorhanden!</div>
                }

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}

            </div>
        );
    }
}

export default Beitragsliste;