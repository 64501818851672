import { faArrowDown, faArrowUp, faDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Container, Row, Col, Table, Alert } from "react-bootstrap"
import { GrunddatenDatencheckField, MyBstAccordionDatencheck } from "../../stores/DatencheckFields"
import UserStore from "../../stores/UserStore"
import { Datencheck, MyBstData } from "../Screens/MyBst"
import MyBstAccordionRow from "./MyBstAccordionRow";

interface MyBstAccordionProps {
    accordion: MyBstAccordionDatencheck
    data: MyBstData
    datenchecks: Datencheck[]
    updateDatenchecks: (datenchecks: Datencheck[]) => void
    deleteDatencheck: (datencheckProp: string) => void
    disableSave: (disable: boolean) => void
}

const accordionsWithWarnings = ["Finanzdaten", "Bankverbindung"]

const MyBstAccordion = ({ accordion, datenchecks, ...props }: MyBstAccordionProps) => {
    const [open, setOpen] = useState(false)
    const [linkObject, setLinkObject] = useState<{ downloadName: string, href: string }>()

    const beratungsstelleAccordion = useMemo(() => {
        return accordion.accordion.toLowerCase().includes("beratungsstelle")
    }, [accordion.accordion])

    const firstHalf = useMemo(() => {
        const filteredAccordion = accordion.fields.filter(f => beratungsstelleAccordion ? !f.prop.toLowerCase().includes("oeffnungszeiten") : true)
        return filteredAccordion.slice(0, Math.round(filteredAccordion.length / 2)).sort((a, b) => a.order - b.order)
    }, [accordion.fields, beratungsstelleAccordion])
    const secondHalf = useMemo(() => {
        const filteredAccordion = accordion.fields.filter(f => beratungsstelleAccordion ? !f.prop.toLowerCase().includes("oeffnungszeiten") : true)
        return filteredAccordion.filter(f => beratungsstelleAccordion ? !f.prop.toLowerCase().includes("oeffnungszeiten") : true).slice(Math.round(filteredAccordion.length / 2), filteredAccordion.length).sort((a, b) => a.order - b.order)
    }, [accordion.fields, beratungsstelleAccordion])

    const accordionHasAlert = useMemo(() => {
        return !!accordionsWithWarnings.find(a => a === accordion.accordion)
    }, [accordion.accordion])

    const buildLinkObject = useCallback(() => {
        let downloadName = ""
        switch (accordion.accordion) {
            case "Finanzdaten":
                downloadName = "Abrechnung_mittels_Gutschrift"
                if (UserStore.mandantId === 1) {
                    //Akt
                    setLinkObject({
                        downloadName: downloadName,
                        href: "/Formular_Abrechnung_mittels_Gutschrift_Akt.pdf"
                    })
                } else {
                    //Alt
                    setLinkObject({
                        downloadName: downloadName,
                        href: "/Formular_Abrechnung_mittels_Gutschrift_Alt.pdf"
                    })
                }
                break;
            case "Bankverbindung":
                downloadName = "Sepa_Lastschriftmandat"
                if (UserStore.mandantId === 1) {
                    //Akt
                    setLinkObject({
                        downloadName: downloadName,
                        href: "/Formular_Sepa_Lastschriftmandat_Akt.pdf"
                    })
                } else {
                    //Alt
                    setLinkObject({
                        downloadName: downloadName,
                        href: "/Formular_Sepa_Lastschriftmandat_Alt.pdf"
                    })
                }
                break;
        }
    }, [accordion.accordion])

    useEffect(() => {
        if (accordionHasAlert && !linkObject) {
            buildLinkObject()
        }
    }, [accordionHasAlert, buildLinkObject, linkObject])

    const getAccordionPropertyName = () => {
        return accordion.accordionProperty ? accordion.accordionProperty : accordion.accordion.charAt(0).toLowerCase() + accordion.accordion.slice(1)
    }

    const getFullFieldPropName = (field: GrunddatenDatencheckField) => {
        return getAccordionPropertyName() + "." + field.prop
    }

    return <div className='alhAccordion'>
        <div className={(open ? "alhAcc_Open" : "alhAcc_Close") + " alhAcc_Item"}>
            <div className="alhAcc_Header_MyBst alhAcc_Header clearfix" onClick={() => setOpen(!open)}>
                <div className="alhAll_HeaderTitle">{accordion.accordion}</div>
                <div className="alhAll_HeaderInfo">
                    {open ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                </div>
            </div>
            <div className="alhAcc_Body">
                <Container>
                    <Row className="alh_rowSpacer">
                        <Col className="alh_colData">
                            <Table striped bordered hover>
                                <tbody>
                                    {firstHalf.map((field, index) => <tr key={field.prop + index}>
                                        <td>{field.name}</td>
                                        <td>
                                            <>
                                                <MyBstAccordionRow
                                                    accordionName={getAccordionPropertyName()}
                                                    datencheck={datenchecks.find(d => d.prop === getFullFieldPropName(field))}
                                                    datencheckExists={(countprop) => datenchecks.find(d => d.prop === countprop)}
                                                    field={field}
                                                    fullFieldPropName={getFullFieldPropName(field)}
                                                    {...props}
                                                />
                                                {field.children?.filter(c => c.childrenContainsOwnData).sort((a, b) => a.order - b.order).map(c =>
                                                    <MyBstAccordionRow
                                                        accordionName={getAccordionPropertyName()}
                                                        datencheck={datenchecks.find(d => d.prop === getFullFieldPropName(c))}
                                                        datencheckExists={(countprop) => datenchecks.find(d => d.prop === countprop)}
                                                        field={c}
                                                        fullFieldPropName={getFullFieldPropName(c)}
                                                        {...props}
                                                    />
                                                )}
                                            </>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Col>
                        <Col className="alh_colData">
                            <Table striped bordered hover>
                                <tbody>
                                    {secondHalf.map((field, index) => <tr key={field.prop + index}>
                                        <td>{field.name}</td>
                                        <td>
                                            <>
                                                <MyBstAccordionRow
                                                    accordionName={getAccordionPropertyName()}
                                                    datencheck={datenchecks.find(d => d.prop === getFullFieldPropName(field))}
                                                    datencheckExists={(countprop) => datenchecks.find(d => d.prop === countprop)}
                                                    field={field}
                                                    fullFieldPropName={getFullFieldPropName(field)}
                                                    {...props}
                                                />
                                                {field.children?.filter(c => c.childrenContainsOwnData).sort((a, b) => a.order - b.order).map(c =>
                                                    <MyBstAccordionRow
                                                        accordionName={getAccordionPropertyName()}
                                                        datencheck={datenchecks.find(d => d.prop === getFullFieldPropName(c))}
                                                        datencheckExists={(countprop) => datenchecks.find(d => d.prop === countprop)}
                                                        field={c}
                                                        fullFieldPropName={getFullFieldPropName(c)}
                                                        {...props}
                                                    />
                                                )}
                                            </>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {beratungsstelleAccordion && <Row>
                        <Col className="alh_colData">
                            <Table striped bordered hover>
                                <tbody>
                                    <td>Öffnungszeiten</td>
                                    <td></td>
                                    {accordion.fields.filter(f => f.prop.toLowerCase().includes("oeffnungszeiten")).map((field, index) => <tr key={field.prop + index}>
                                        <td>{field.name}</td>
                                        <td>
                                            <>
                                                <MyBstAccordionRow
                                                    accordionName={getAccordionPropertyName()}
                                                    datencheck={datenchecks.find(d => d.prop === getFullFieldPropName(field))}
                                                    datencheckExists={(countprop) => datenchecks.find(d => d.prop === countprop)}
                                                    field={field}
                                                    fullFieldPropName={getFullFieldPropName(field)}
                                                    {...props}
                                                />
                                                {field.children?.filter(c => c.childrenContainsOwnData).map(c =>
                                                    <MyBstAccordionRow
                                                        accordionName={getAccordionPropertyName()}
                                                        datencheck={datenchecks.find(d => d.prop === getFullFieldPropName(c))}
                                                        datencheckExists={(countprop) => datenchecks.find(d => d.prop === countprop)}
                                                        field={c}
                                                        fullFieldPropName={getFullFieldPropName(c)}
                                                        {...props}
                                                    />
                                                )}
                                            </>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>}
                    {(accordionHasAlert && linkObject) &&
                        <Alert key="changeWarning" variant="warning">
                            Für Änderungen beschreibbares <a href={linkObject.href} download={linkObject.downloadName}>Formular downloaden <FontAwesomeIcon icon={faDownload} /></a>, ausfüllen und per <a className="emailLink" href="mailto:huettner@aktuell-verein.de">E-Mail</a> an uns senden.
                        </Alert>}
                </Container>
            </div>
        </div>
    </div>
}

export default MyBstAccordion