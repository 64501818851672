import React, { useCallback, useEffect, useState } from "react"
import { Form, Container, Row, Col } from "react-bootstrap"
import { toast } from "react-toastify"
import MitgliedItem from "../../../models/Mitglied"
import MitgliedService from "../../../services/MitgliedService"
import { useBstContext } from "../../../stores/BstContextProvider"
import UserStore from "../../../stores/UserStore"
import InputCheckbox from "../../Forms/InputCheckbox"
import InputField from "../../Forms/InputField"
import SelectField from "../../Forms/SelectField"
import SubmitButton from "../../Forms/SubmitButton"
import { ShowNotReadyNew } from "../Mitglied"

interface MitgliedDokumenteProps {
    isNew: boolean
    isWiedereintritt: boolean
    partner?: MitgliedItem
    mitgliedNachname?: string
    mgNr: string
    zahlungsweiseSoll?: string
}

interface PrintOptions {
    [key: string]: any
    working: boolean,
    lang: string,
    MGVollmachtfinanzamt: boolean,
    separatefiles: boolean,

    zahlungsweiseNewDefault: string,

    VEVon: number,
    VEBis: number,

    rptMGVollstaendigkeitserklaerung: boolean,
    rptMGMitgliedsausweis: boolean,
    rptMGBeitrittserklaerung: boolean,
    rptMGEinwilligungEMail: boolean,
    rptMGSEPALastschriftmandat: boolean,
    rptMGAVollmachtErb: boolean,
    rptMGAVollmachtHaus: boolean,
    rptMGAntragVGD: boolean,
    rptMGVollmachtFinanzamt: boolean,
    rptMGKuendigung: boolean,

    VGDBeitragsjahr: number
    VGDBMG: string,
    VGDVV: boolean,
    KuendFreitext: string
}

const MitgliedDokumente = ({ isNew, isWiedereintritt, partner, mgNr, mitgliedNachname, zahlungsweiseSoll }: MitgliedDokumenteProps) => {

    const { settings } = useBstContext()

    const [print, setPrint] = useState<PrintOptions>({
        working: false,
        lang: "D",
        MGVollmachtfinanzamt: false,
        separatefiles: false,

        zahlungsweiseNewDefault: 'Bankeinzug',

        rptMGVollstaendigkeitserklaerung: false,
        VEVon: (new Date().getFullYear() - 1),
        VEBis: (new Date().getFullYear() - 1),

        rptMGMitgliedsausweis: false,
        rptMGBeitrittserklaerung: false,
        rptMGEinwilligungEMail: false,
        rptMGSEPALastschriftmandat: false,
        rptMGAVollmachtErb: false,
        rptMGAVollmachtHaus: false,
        rptMGAntragVGD: false,
        rptMGVollmachtFinanzamt: false,
        rptMGKuendigung: false,
        VGDBeitragsjahr: (new Date().getFullYear() - 1),
        VGDBMG: "",
        VGDVV: false,
        KuendFreitext: '',
    })

    const loadDefaulctCheckSeparateFiles = useCallback(async () => {
        let resultBST = settings
        setPrint((prev: PrintOptions) => ({ ...prev, separatefiles: (resultBST.bstDefaultCheckSeparateFiles ?? false) }))
    }, [settings])

    useEffect(() => {
        const loader = async () => {
            await loadDefaulctCheckSeparateFiles()
        }

        loader()
    }, [loadDefaulctCheckSeparateFiles])

    const setInputValue = useCallback((prop: string, value: any) => {
        setPrint((oldValue) => ({ ...oldValue, [prop]: value }))
    }, [])

    const printRequest = useCallback(async (dokumentName: string, argumentsPrint: string, filename_suffix: string) => {
        //ALH20-568
        let sortNumber = ""
        if (dokumentName.includes("rptMGVollmachtFinanzamt") && argumentsPrint.includes("Sort")) {
            sortNumber = argumentsPrint.substring(argumentsPrint.length - 1)
        }

        await MitgliedService.mitgliedPrintFile(mgNr, dokumentName, print.lang, argumentsPrint, mgNr + "_" + mitgliedNachname + "_" + filename_suffix + (sortNumber ? `_${sortNumber}` : "") + ".pdf")

        return true;
    }, [mgNr, mitgliedNachname, print.lang])

    const handlePrintSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        let filename_suffix = "";

        event.preventDefault();
        event.stopPropagation();

        //check which Dokument checked
        let dokumentePrint: string[] = [];
        let dokumentePrintSuffix: string[] = [];
        let argumentsPrint: string[] = [];

        if (print.rptMGMitgliedsausweis) {
            dokumentePrint.push("rptMGMitgliedsausweis");
            filename_suffix += "MA";
            argumentsPrint.push("");
            dokumentePrintSuffix.push("MA");
        }
        if (print.rptMGBeitrittserklaerung) {
            dokumentePrint.push("rptMGBeitrittserklaerung");
            filename_suffix += "BE";
            argumentsPrint.push("");
            dokumentePrintSuffix.push("BE");
        }
        if (print.rptMGVollstaendigkeitserklaerung) {
            if (!print.separatefiles) {

                dokumentePrint.push("rptMGVollstaendigkeitserklaerung");
                filename_suffix += "VE" + print.VEVon;
                dokumentePrintSuffix.push("VE" + print.VEVon);
                argumentsPrint.push("VEJahrVon:=" + print.VEVon + ";VEJahrBis:=" + print.VEBis);

            } else {

                for (var i = print.VEVon; i <= print.VEBis; i++) {
                    dokumentePrint.push("rptMGVollstaendigkeitserklaerung");
                    filename_suffix += "VE" + i;
                    dokumentePrintSuffix.push("VE" + i);
                    argumentsPrint.push("VEJahrVon:=" + i + ";VEJahrBis:=" + i);
                }

            }

        }
        if (print.rptMGEinwilligungEMail) {
            dokumentePrint.push("rptMGEinwilligungEMail");
            filename_suffix += "EE";
            argumentsPrint.push("");
            dokumentePrintSuffix.push("EE");
        }
        if (print.rptMGVollmachtFinanzamt) {
            if (print.separatefiles && partner && partner.anrede !== "-1") {
                dokumentePrint.push("rptMGVollmachtFinanzamt");
                filename_suffix += "VM";
                argumentsPrint.push("Sort:=1");
                dokumentePrintSuffix.push("VM");

                dokumentePrint.push("rptMGVollmachtFinanzamt");
                filename_suffix += "VM";
                argumentsPrint.push("Sort:=2");
                dokumentePrintSuffix.push("VM");
            } else {
                dokumentePrint.push("rptMGVollmachtFinanzamt");
                filename_suffix += "VM";
                argumentsPrint.push("");
                dokumentePrintSuffix.push("VM");
            }
        }
        if (print.rptMGSEPALastschriftmandat) {
            dokumentePrint.push("rptMGSEPALastschriftmandat");
            filename_suffix += "SEPA";
            argumentsPrint.push("");
            dokumentePrintSuffix.push("SEPA");
        }
        if (print.rptMGAVollmachtErb) {
            dokumentePrint.push("VollmachtErbengemeinschaft");
            argumentsPrint.push("");
            dokumentePrintSuffix.push("VMErb")
        }
        if (print.rptMGAVollmachtHaus) {
            dokumentePrint.push("VollmachtHausgemeinschaft");
            argumentsPrint.push("");
            dokumentePrintSuffix.push("VMHaus")
        }
        if (print.rptMGKuendigung) {
            dokumentePrint.push("rptMGKuendigung");
            filename_suffix += "KUENDIGUNG";
            dokumentePrintSuffix.push("KUENDIGUNG");
            if (print.KuendFreitext !== "") {
                argumentsPrint.push("KuendigungFreitext:=" + print.KuendFreitext);
            } else {
                argumentsPrint.push("");
            }
        }

        if (print.rptMGAntragVGD) {
            dokumentePrint.push("rptMGAntragVGD");
            filename_suffix += "VGD";
            dokumentePrintSuffix.push("VGD");
            argumentsPrint.push("VGDBeitragsjahr:=" + print.VGDBeitragsjahr + ";VGDBMG:=" + print.VGDBMG + ";VGDVV:=" + ((print.VGDVV) ? "true" : "false") + "");

            if (print.VGDBeitragsjahr === 0 || print.VGDBMG === "") {
                toast.error("Bei Antrag VGD muss ein Beitragsjahr und eine BMG gesetzt werden.", {
                    autoClose: 5000
                });
                return false;
            }
        }

        if (dokumentePrint.length === 0) {
            toast.warning("Bitte wählen Sie mind. ein Dokument zum Erstellen aus.", {
                autoClose: 5000
            });
            return false;
        }

        setInputValue('working', true);

        if (!print.separatefiles) {
            if (dokumentePrint.includes("VollmachtErbengemeinschaft") || dokumentePrint.includes("VollmachtHausgemeinschaft")) {
                const dokumenteToPrint = dokumentePrint.filter(s => s !== "VollmachtErbengemeinschaft" && s !== "VollmachtHausgemeinschaft");
                if (dokumenteToPrint.length > 0)
                    await printRequest(dokumenteToPrint.join(";"), argumentsPrint.join(";"), filename_suffix);

                await Promise.all(dokumentePrint.filter(s => s === "VollmachtErbengemeinschaft" || s === "VollmachtHausgemeinschaft").map(async (item, index) => {
                    await printRequest(item, "AVEH", "AVEH");
                }));
            } else {
                await printRequest(dokumentePrint.join(";"), argumentsPrint.join(";"), filename_suffix);
            }
        } else {
            await Promise.all(dokumentePrint.map(async (item, index) => {
                await printRequest(item, argumentsPrint[index], dokumentePrintSuffix[index]);
            }));
        }

        setInputValue('working', false);
    }, [partner, print, printRequest, setInputValue])

    /**
     * Select all Checkboxes
     */
    const selectAllDruck = useCallback(() => {
        if (print.rptMGSEPALastschriftmandat
            || print.rptMGBeitrittserklaerung
            || print.rptMGMitgliedsausweis
            || print.rptMGVollstaendigkeitserklaerung
            || print.rptMGVollmachtFinanzamt
            || print.rptMGAntragVGD
        ) {
            setPrint((prevState: PrintOptions) => ({
                ...prevState,
                rptMGSEPALastschriftmandat: false,
                rptMGBeitrittserklaerung: false,
                rptMGMitgliedsausweis: false,
                rptMGVollstaendigkeitserklaerung: false,
                rptMGVollmachtFinanzamt: false,
                rptMGAntragVGD: false
            }))
        } else {
            setPrint((prevState: PrintOptions) => ({
                ...prevState,
                rptMGSEPALastschriftmandat: zahlungsweiseSoll === "Bankeinzug",
                rptMGBeitrittserklaerung: true,
                rptMGMitgliedsausweis: true,
                rptMGVollstaendigkeitserklaerung: true,
                rptMGVollmachtFinanzamt: true,
                rptMGAntragVGD: false
            }))
        }
    }, [print, zahlungsweiseSoll])

    return <Form onSubmit={handlePrintSubmit}>
        <Container>
            <Row className="alh_rowTopList">
                <Col lg={6}>
                    {(!isNew && !isWiedereintritt) ?
                        <div className="alh_frontBoxes">
                            <h3>Welche Daten sollen gedruckt werden?</h3>

                            <SubmitButton
                                type='button'
                                onClick={() => selectAllDruck()}
                                text="Dokumentauswahl für Neumitglied"
                                size="sm"
                            /><br />

                            <InputCheckbox
                                name='print.rptMGBeitrittserklaerung'
                                id='print.rptMGBeitrittserklaerung'
                                checked={print.rptMGBeitrittserklaerung}
                                onChange={(val) => setInputValue('rptMGBeitrittserklaerung', val)}
                                subtext=''
                                text='Beitrittserklärung (BE)'
                                infotext='Die unterschriebene Beitrittserklärung laden Sie beim Mitglied über “Uploads” zu uns hoch.'
                                infotextMode=''
                            />
                            <InputCheckbox
                                name='print.rptMGMitgliedsausweis'
                                id='print.rptMGMitgliedsausweis'
                                checked={print.rptMGMitgliedsausweis}
                                onChange={(val) => setInputValue('rptMGMitgliedsausweis', val)}
                                subtext=''
                                text='MG-Ausweis'
                                infotext='Den Mitgliedsausweis übergeben Sie dem Mitglied. Er enthält die wichtigsten Satzungsauszüge  zur Mitgliedschaft und Kündigungsfrist sowie Ihre Kontaktdaten.'
                                infotextMode=''
                            />
                            <InputCheckbox
                                name='print.rptMGVollstaendigkeitserklaerung'
                                id='print.rptMGVollstaendigkeitserklaerung'
                                checked={print.rptMGVollstaendigkeitserklaerung}
                                onChange={(val) => setInputValue('rptMGVollstaendigkeitserklaerung', val)}
                                subtext=''
                                text='Vollständigkeitserklärung (VE)'
                                infotext='Dieses Dokument wird für jede Steuererklärung benötigt, die Sie für das Mitglied erstellen.'
                                infotextMode=''
                            />
                            (
                            <InputField
                                size='sm'
                                type='number'
                                containerClassName='formInline formInlineSpacing'
                                inputClassNames='formInline'
                                inputGroupClass='formInline'
                                placeholder='von'
                                maxDate={(new Date().getFullYear()) - 1}
                                forceAutofill={true}
                                value={print.VEVon.toString()}
                                onChange={(val) => setInputValue('VEVon', Number(val))}
                            /> bis
                            <InputField
                                size='sm'
                                type='number'
                                containerClassName='formInline formInlineSpacing'
                                inputClassNames='formInline'
                                inputGroupClass='formInline'
                                placeholder='bis'
                                maxDate={(new Date().getFullYear()) - 1}
                                forceAutofill={true}
                                value={print.VEBis.toString()}
                                onChange={(val) => setInputValue('VEBis', Number(val))}
                            />)
                            <br /><br />

                            <InputCheckbox
                                name='print.rptMGVollmachtFinanzamt'
                                id='print.rptMGVollmachtFinanzamt'
                                checked={print.rptMGVollmachtFinanzamt}
                                onChange={(val) => setInputValue('rptMGVollmachtFinanzamt', val)}
                                subtext=''
                                text='Vollmacht Finanzamt (VM)'
                                infotext='Dieses Dokument wird für den Datenabruf über die Vollmachtsdatenbank benötigt.'
                                infotextMode=''
                            />
                            <InputCheckbox
                                name='print.rptMGSEPALastschriftmandat'
                                id='print.rptMGSEPALastschriftmandat'
                                checked={print.rptMGSEPALastschriftmandat}
                                onChange={(val) => setInputValue('rptMGSEPALastschriftmandat', val)}
                                subtext=''
                                text='SEPA-Lastschriftformular'
                                infotext='Dieses Dokument lassen Sie vom Kontoinhaber unterschreiben, um die Beiträge per SEPA-Lastschrift über Ihre Bank einziehen zu können.'
                                infotextMode=''
                            />

                            <br /><br />
                            <h3>Sonstige Dokumente:</h3>
                            <InputCheckbox
                                name='print.rptMGAntragVGD'
                                id='print.rptMGAntragVGD'
                                disabled
                                checked={print.rptMGAntragVGD}
                                onChange={(val) => setInputValue('rptMGAntragVGD', val)}
                                subtext=''
                                text='Antrag VGD'
                                /* VGD Automatisierung => alter Infotext */
                                /* infotext='Diesen Antrag reichen Sie bei uns in der Hauptverwaltung ein, um Ihren Anteil auf eine Direktzahlung des Mitglieds (z. B. Zahlung auf Kündigungsbestätigung oder Mahnung) zu erhalten.' */
                                infotext="Falls ein MG direkt an die HV bezahlt hat,
                                (z.B. Zahlung auf Kündigungsbestätigung oder Mahnung),
                                können Sie Ihren BST-Anteil anfordern,
                                vorausgesetzt Sie sind für diese Steuererklärung vollumfänglich
                                steuerlich tätig geworden.
                                Den VGD–Antrag können Sie über den Reiter „Buchungen“
                                über das „+“ im Buchungsjournal stellen."
                                infotextMode=''
                            />
                            {/* VGD Automatisierung => wird nicht mehr benötigt
                            (Beitragsjahr
                            <InputField
                                size='sm'
                                type='clean'
                                containerClassName='formInline formInlineSpacing'
                                inputClassNames='formInline'
                                inputGroupClass='formInline'
                                placeholder='BJahr'
                                value={print.VGDBeitragsjahr.toString()}
                                onChange={(val) => setInputValue('VGDBeitragsjahr', val)}
                            /> BMG
                            <InputField
                                size='sm'
                                type='clean'
                                containerClassName='formInline formInlineSpacing'
                                inputClassNames='formInline'
                                inputGroupClass='formInline'
                                placeholder='BMG'
                                value={print.VGDBMG}
                                onChange={(val) => setInputValue('VGDBMG', val)}
                            /> V+V
                            <InputCheckbox
                                name='print.VGDVV'
                                containerClassName='formInline formInlineSpacing'
                                checked={print.VGDVV}
                                onChange={(val) => setInputValue('VGDVV', val)}
                                subtext=''
                            />) <br /><br /> */}

                            <InputCheckbox
                                name='print.rptMGAVollmachtErb'
                                id='print.rptMGAVollmachtErb'
                                checked={print.rptMGAVollmachtErb}
                                onChange={(val) => setInputValue('rptMGAVollmachtErb', val)}
                                subtext=''
                                text='Allgemeine Vollmacht bei Erbengemeinschaft'
                                infotext=''
                                infotextMode=''
                            />
                            <InputCheckbox
                                name='print.rptMGAVollmachtHaus'
                                id='print.rptMGAVollmachtHaus'
                                checked={print.rptMGAVollmachtHaus}
                                onChange={(val) => setInputValue('rptMGAVollmachtHaus', val)}
                                subtext=''
                                text='Allgemeine Vollmacht bei Hausgemeinschaft'
                                infotext=''
                                infotextMode=''
                            />
                            {/* ALH20-598: wird erstmal nicht mehr gebraucht
                                ALH20-616: wird doch wieder gebraucht */
                                <InputCheckbox
                                    name='print.rptMGEinwilligungEMail'
                                    id='print.rptMGEinwilligungEMail'
                                    checked={print.rptMGEinwilligungEMail}
                                    onChange={(val) => setInputValue('rptMGEinwilligungEMail', val)}
                                    subtext=''
                                    text='Einwilligung zur E-Mail Kommunikation'
                                    infotext='Dieses Dokument benötigen Sie nur bei z. B. Wechselmitgliedern oder langjährigen Mitgliedern, bei denen die Einwilligung noch nicht vorliegt. Bei Neumitgliedern ist diese Einwilligungsmöglichkeit bereits auf der Beitrittserklärung enthalten.'
                                    infotextMode=''
                                />}
                            <InputCheckbox
                                name='print.rptMGKuendigung'
                                id='print.rptMGKuendigung'
                                checked={print.rptMGKuendigung}
                                onChange={(val) => setInputValue('rptMGKuendigung', val)}
                                subtext=''
                                text='Kündigung'
                                infotext='Hier können Sie eine vorgefertigte Kündigung für das Mitglied drucken.<br />
Bei einer Einmalberatung geben Sie bitte unbedingt im Freitext an, welche Veranlagungszeiträume von Ihnen erstellt werden, um ungerechtfertigten Mahnungen vorzubeugen. Der Freitext wird automatisch auf das Kündigungsschreiben übernommen.<br />
Bei <b>Einmalberatungen</b> lassen Sie uns bitte unbedingt die <b>Beitrittserklärung und Kündigung zusammen</b> zukommen. Am besten laden Sie beim Mitglied über “Uploads” die beiden Dokumente in einer PDF-Datei zu uns hoch.'
                            />
                            <InputField
                                size='sm'
                                type='clean'
                                placeholder='Freitext für erstellte Veranlagungszeiträume.'
                                value={print.KuendFreitext}
                                onChange={(val) => setInputValue('KuendFreitext', val)}
                            />
                        </div>
                        :
                        <div className="alh_frontBoxes">
                            <ShowNotReadyNew name="keine Druckfunktion" />
                        </div>
                    }
                </Col>
                <Col lg={6}>
                    {(!isNew && !isWiedereintritt) ?
                        <div className="alh_frontBoxes">
                            <SelectField
                                id='print.lang'
                                name='print.lang'
                                withPleaseSelect={false}
                                onChange={(val) => setInputValue('lang', val)}
                                options={
                                    [
                                        { id: 'D', value: 'Deutsch' },
                                        { id: 'E', value: 'Englisch' }
                                    ]
                                }
                                value={print.lang}
                            />
                            <InputCheckbox
                                name='print.separatefiles'
                                id='print.separatefiles'
                                checked={print.separatefiles}
                                onChange={(val) => setInputValue('separatefiles', val)}
                                text='Alle Dokumente in einzelnen Dateien (z.B. für Unterschriftenpad)'
                                infotext={"Akzeptiert werden derzeit rechtssichere Unterschriften mit dem Unterschriftenpad signotec Delta der Firma Signotec in Kombination mit der Software signosign2 bzw. Steuersoft ESt-Plus. Für weitere Informationen kontaktieren Sie uns unter <a href='mailto:" + ((UserStore.mandantId === 1) ? "info@aktuell-verein.de" : "info@altbayerischer.de") + "?subject=Unterschriftenpad'>" + ((UserStore.mandantId === 1) ? "info@aktuell-verein.de" : "info@altbayerischer.de") + "</a>"}
                            />
                            <div className="alh_mgp_infoprint">(Info: Evtl. müssen Sie "Mehrfachdownload" in Ihrem Browser zulassen. Es wird ein Popup dazu erscheinen.)</div>
                            <SubmitButton
                                type='submit'
                                text="Dokumente drucken"
                                disabled={print.working}
                            />
                        </div>
                        :
                        <div className="alh_frontBoxes">
                            <ShowNotReadyNew name="keine Druckfunktion" />
                        </div>
                    }

                </Col>
            </Row>
        </Container>
    </Form>
}

export default MitgliedDokumente