import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import { WaveTopBottomLoading } from "react-loadingg";
import UserStore from "../../stores/UserStore";
import { sortList } from "../ALHUtility";
import TableOperatorOptions from "../Blocks/TableOperatorOptions";
import moment from "moment";
import MitgliedItem from '../../models/Mitglied';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const defaultTageBis = 90;

interface GeburtstagslisteProps extends RouteComponentProps {
    mode?: string
    tageBis?: number
}

interface GeburtstagslisteState {
    loading: boolean
    mitglieder: MitgliedItem[]
    sort: string
    sort_mode: string
    search: string
}

class Geburtstagsliste extends Component<GeburtstagslisteProps, GeburtstagslisteState> {

    constructor(props: GeburtstagslisteProps) {
        super(props);

        if (!UserStore.hasBerechtigung('extras_gebliste') && this.props.history) {
            this.props.history.push("/notallowed/");
        }

        this.state = {
            loading: true,
            mitglieder: [],
            sort: 'daystill',
            sort_mode: 'desc',
            search: '',
        }

        if (this.props.mode !== 'small') {
            document.title = 'Geburtstagsliste (...) | ' + UserStore.title;
        }
    }

    async componentDidMount() {
        try {
            let res = await fetch(UserStore.url + '/gebtag', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        TageBis: this.props.tageBis ?? defaultTageBis,
                    }
                })
            });

            let result = await res.json();

            if (result && result.success) {
                let list: MitgliedItem[] = result.data.mitglieder;

                //add tilldays to each entry
                let me = this;
                list.forEach((item, index) => {
                    let gebtag = moment(me.getGebtagThisYear(item.geburtstag!));
                    list[index].daystill = moment().diff(gebtag, 'minutes');
                });

                this.setState({
                    mitglieder: list,
                    loading: false
                });
            }

            if (this.props.mode !== 'small') {
                document.title = 'Geburtstagsliste (' + result.data.mitglieder.length + ') | ' + UserStore.title;
            }

        } catch (e) {
            console.log("Error in loading list: " + e);
        }
    }

    getGebtagThisYear(date: string) {
        var thisYear = new Date();
        var gebDate = new Date(date);
        if (gebDate.getMonth() < thisYear.getMonth()) {
            gebDate.setFullYear(thisYear.getFullYear() + 1)
        } else {
            gebDate.setFullYear(thisYear.getFullYear())
        }
        return gebDate.toISOString();
    }

    getYearForBirthday(date: string) {
        var thisYear = new Date();
        var gebDate = new Date(date);

        if (gebDate.getMonth() < thisYear.getMonth()) {
            return thisYear.getFullYear() - gebDate.getFullYear() + 1;
        } else {
            return thisYear.getFullYear() - gebDate.getFullYear();
        }
    }

    sortClick(spalte: string) {
        let newSort_mode = this.state.sort_mode;
        let newSort = this.state.sort;

        if (spalte === this.state.sort) {
            switch (this.state.sort_mode) {
                case 'asc':
                    newSort_mode = 'desc';
                    break;
                default:
                    newSort_mode = 'asc';
                    break;
            }
        } else {
            newSort = spalte;
        }

        this.setState({
            sort: newSort,
            sort_mode: newSort_mode,
        })
    }

    changeSearch(val: string) {
        this.setState({
            search: val
        })
    }

    render() {
        const { mode = "normal" } = this.props
        let search = this.state.search;
        let list = this.state.mitglieder;
        if (typeof search !== "undefined") {
            list = this.state.mitglieder.filter(function (mitglied) {
                return (
                    mitglied.name?.toLowerCase().includes(search.toLowerCase()) ||
                    mitglied.mgNr?.toLowerCase().includes(search.toLowerCase())
                );
            });
        }

        list = sortList(this.state.sort, list, this.state.sort_mode, (this.state.sort === 'geburtstag'), 'month');

        return (
            <div>
                <h3 className="alh_pageheader">Aktuelle Geburtstagsliste</h3>
                <div className={(mode === "small") ? "d-none" : "alh_subheader"}>Sehen Sie hier alle Mitglieder mit deren Geburtstagen innerhalb der nächsten <span className="alh_bold">90 Tage</span>.</div>

                <TableOperatorOptions
                    name="Geburtstagsliste"
                    placeholder="Suche nach Name oder Nummer ..."
                    onChange={(val) => this.changeSearch(val)}
                    value={this.state.search}
                    print={true}
                    helpfile="gebtagsliste.json"
                />

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th onClick={() => this.sortClick('mgNr')} className={(this.state.sort === 'mgNr') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Nummer</th>
                            <th onClick={() => this.sortClick('name')} className={(this.state.sort === 'name') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Name</th>
                            <th>E-Mail</th>
                            <th>Adresse</th>
                            <th onClick={() => this.sortClick('daystill')} className={(this.state.sort === 'daystill') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Geburtstag</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((mitglied, index) => (
                            <tr key={index}>
                                <td><a href={`/mitglied/${mitglied.mgNr}`}>{mitglied.mgNr}</a></td>
                                <td>{mitglied.name}</td>
                                <td><a href={"mailto:" + mitglied.eMail + "?subject=" + mitglied.mgNr + " " + mitglied.name?.replace(",", "")}>{mitglied.eMail}</a></td>
                                <td>{mitglied.strasse}<br />{mitglied.plzOrt}</td>
                                <td>
                                    <Moment format="DD.MM.YYYY">{moment.parseZone(mitglied.geburtstag).utc(true).format() ?? ""}</Moment>
                                    <div className="infoSmall success"><Moment fromNow>{this.getGebtagThisYear(mitglied.geburtstag!)}</Moment> {this.getYearForBirthday(mitglied.geburtstag!)}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>


                {this.state.mitglieder && this.state.mitglieder.length === 0 && !this.state.loading &&
                    <div className="info">Keine bevorstehenden Geburtstage</div>
                }

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}

            </div>
        );
    }
}

export default withRouter(Geburtstagsliste);