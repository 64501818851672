import React, { Component } from 'react';
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { b64toBlob, sortList } from "../ALHUtility";
import UserStore from "../../stores/UserStore";
import TableOperatorOptions from "../Blocks/TableOperatorOptions";
import { WaveTopBottomLoading } from "react-loadingg";
import moment from "moment";
import Beleg from '../../models/Beleg';
import { toast } from 'react-toastify';

interface BelegelisteProps { }

interface BelegelisteState {
    loading: boolean
    belege: Beleg[]
    search: string
    sort: string
    sort_mode: string
}

class Belegeliste extends Component<BelegelisteProps, BelegelisteState> {

    constructor(props: BelegelisteProps) {
        super(props);

        this.state = {
            loading: true,
            belege: [],
            search: '',

            sort: 'belegdatum',
            sort_mode: 'desc',
        }

    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    async componentDidMount() {
        try {
            let res = await fetch(UserStore.url + '/rechnungen', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let result = await res.json();

            if (result && result.success) {
                this.setState({
                    belege: result.data.belege,
                    loading: false
                });
            }

            document.title = 'Gutschriften / Rechnungen (' + result.data.belege.length + ') | ' + UserStore.title;

        } catch (e) {
            console.log("Error in loading homemessage: " + e);
        }
    }

    async printBeleg(docID: string, art: string, nummer: string) {

        let res = await fetch(UserStore.url + '/rechnungenOpen', {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'responseType': 'arraybuffer'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    DocID: docID,
                }
            })
        });

        let response = await res.json();

        if (response.data.dokument) {
            let base64 = response.data.dokument;
            const blob = b64toBlob(base64, "application/octet-stream");

            const link = document.createElement("a");
            link.setAttribute('target', '_blank')
            link.href = window.URL.createObjectURL(blob);
            link.download = art + "_" + nummer + ".pdf";
            link.click();
        } else {
            toast.error("Fehler beim Laden der Datei => dokument ist null", {
                autoClose: 5000
            })
        }
    }

    changeSearch(val: string) {
        this.setState({
            search: val
        })
    }

    sortClick(spalte: string) {
        let newSort_mode = this.state.sort_mode;
        let newSort = this.state.sort;

        if (spalte === this.state.sort) {
            switch (this.state.sort_mode) {
                case 'asc':
                    newSort_mode = 'desc';
                    break;
                default:
                    newSort_mode = 'asc';
                    break;
            }
        } else {
            newSort = spalte;
        }

        this.setState({
            sort: newSort,
            sort_mode: newSort_mode,
        })
    }

    render() {
        let search = this.state.search;
        let currentList = this.state.belege.filter((beleg) =>
            beleg.belegnummer.toLowerCase().includes(search.toLowerCase()) ||
            beleg.text.toLowerCase().includes(search.toLowerCase()) ||
            moment(beleg.belegdatum).format('DD.MM.YYYY').includes(search.toLowerCase()) ||
            beleg.betrag.toString().toLowerCase().includes(search.toLowerCase())

        );

        currentList = sortList(this.state.sort, currentList, this.state.sort_mode, (this.state.sort === 'belegdatum'), 'ts')

        return (
            <div>
                <div>
                    <h3 className="alh_pageheader">Gutschriften / Rechnungen</h3>
                    <div className="alh_subheader">Hier sehen Sie alle Gutschriften / Rechnungen Ihrer Beratungsstelle. Über das Drucker-Symbol können Sie die Gutschrift / Rechnung jederzeit als PDF-Datei aufrufen.</div>
                </div>

                <TableOperatorOptions
                    name="Belegliste"
                    placeholder="Suche nach Art, Nummer, Abrechnungsmonat..."
                    onChange={(val) => this.changeSearch(val)}
                    print={true}
                />

                <Table striped bordered hover size="sm" className="alhTable">
                    <thead>
                        <tr>
                            <th onClick={() => this.sortClick('belegart')} className={(this.state.sort === 'belegart') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Art</th>
                            <th onClick={() => this.sortClick('belegnummer')} className={(this.state.sort === 'belegnummer') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Nummer</th>
                            <th onClick={() => this.sortClick('belegdatum')} className={(this.state.sort === 'belegdatum') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort"}>Datum</th>
                            <th onClick={() => this.sortClick('text')} className={(this.state.sort === 'text') ? "text-right alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort text-right"}>Abrechnungsmonat</th>
                            <th onClick={() => this.sortClick('betrag')} className={(this.state.sort === 'betrag') ? "text-right alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort text-right"}>Betrag</th>
                            <th className="alh_noprint">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentList.map((beleg, index) => (
                            <tr key={beleg.belegHandle}>
                                <td>{beleg.belegart}</td>
                                <td>{beleg.belegnummer}</td>
                                <td><Moment format="DD.MM.YYYY">{beleg.belegdatum}</Moment></td>
                                <td className="text-right">{beleg.text}</td>
                                <td className="text-right">{this.numberFormat(beleg.betrag)}</td>
                                <td className="tableActions alh_noprint">
                                    <FontAwesomeIcon onClick={() => this.printBeleg(beleg.docID, beleg.belegart, beleg.belegnummer)} icon={faPrint} title="Drucken" className="faButton faButtonSpaceright" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {this.state.loading &&
                    <div className="alhLoadingRel">
                        <WaveTopBottomLoading color="#ffc800" />
                    </div>}

            </div>
        );
    }
}

export default Belegeliste;
