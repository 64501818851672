import UserStore from "../stores/UserStore";

const AufgabenService = {
    saveAufgabeToHv: async (mgnr: string, notiz?: string) => {
        const res = await fetch(UserStore.url + '/aufgabenBST2HVSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    MGNr: mgnr,
                    sachbearbeiter: UserStore.name,
                    betreff: notiz?.slice(0, 50),
                    notiz: notiz,
                }
            })
        });

        return await res.json();
    },
    loadAufgabenToHv: async (mgnr: string, status: number) => {
        let res = await fetch(UserStore.url + '/aufgabenBST2HV', {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    Mitgliedsnummer: mgnr,
                    MGNr: mgnr,
                    Status: status
                }
            })
        });

        return await res.json();
    },
    loadAufgaben: async (mgnr: string, status: number) => {
        let res = await fetch(UserStore.url + '/aufgaben', {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    Mitgliedsnummer: mgnr,
                    MGNr: mgnr,
                    Status: status
                }
            })
        });

        return await res.json();
    },
    deleteAufgabeToHv: async (mgnr?: string, id?: string) => {
        let res = await fetch(UserStore.url + '/aufgabenBST2HVSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    MGNr: mgnr,
                    ID: id,
                    IstErledigtPortal: true,
                }
            })
        });

        return await res.json();
    },
    markAufgabeDone: async (aufgabeHandle?: number) => {
        let res = await fetch(UserStore.url + '/aufgabenSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    AufgabeID: aufgabeHandle,
                    Action: 4 //4=Status auf 1 setzen
                }
            })
        });

        return await res.json();
    },
    deleteAufgabe: async (aufgabeHandle?: number) => {
        let res = await fetch(UserStore.url + '/aufgabenSet', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                attr: {
                    AufgabeID: aufgabeHandle,
                    Action: 2 //2 = löschen
                }
            })
        });

        return await res.json();
    },
    downloadFile: async (fileId: string) => {
        let res = await fetch(UserStore.url + '/aufgabenFileDownload', {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'responseType': 'arraybuffer'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    FileID: fileId,
                }
            })
        });

        return await res.json();
    }
}

export default AufgabenService