import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Col, Container, Row, Table } from "react-bootstrap"
import { toast } from "react-toastify"
/* import { Prompt } from "react-router-dom" */
import MitarbeiterItem from "../../models/Mitarbeiter"
import DatencheckService from "../../services/DatencheckService"
import { MitarbeiterDatencheckField, mitarbeiterDatencheckFields } from "../../stores/DatencheckFields"
import { Datencheck } from "../Screens/MyBst"
import InputCheckbox from "./InputCheckbox"
import InputField from "./InputField"
import SelectField from "./SelectField"
import { WaveTopBottomLoading } from "react-loadingg";
import SubmitButton from "./SubmitButton"
import InfoIcon from "../Blocks/InfoIcon"
import MyBstService from "../../services/MyBstService"

interface MitarbeiterEditFormProps {
    mitarbeiter: MitarbeiterItem
}

const MitarbeiterEditForm = ({ mitarbeiter }: MitarbeiterEditFormProps) => {
    const [isDirty, setIsDirty] = useState(false)

    const [datenchecks, setDatenchecks] = useState<Datencheck[]>()
    const [isLoading, setIsLoading] = useState(false)
    const [hasSecondBeratungsstelle, setHasSecondBeratungsstelle] = useState(false)

    const datencheckPropPrefix = useMemo(() => `mitarbeiter.${mitarbeiter.bstmaId}.`, [mitarbeiter.bstmaId])

    const getDatencheck = useCallback((prop: string) => datenchecks?.find(d => d.prop === `${datencheckPropPrefix}${prop}`)?.newValue, [datencheckPropPrefix, datenchecks])

    const loadDatenchecks = useCallback(async () => {
        setIsLoading(true);
        const datenchecks = await DatencheckService.get(mitarbeiter.bstmaId)
        setDatenchecks(datenchecks)
        setIsLoading(false)
    }, [mitarbeiter.bstmaId])

    const loadSecondBstStelle = useCallback(async () => {
        setIsLoading(true)
        const hasSecondBeratungsstelle = await MyBstService.hasSecondBeratungsstelle()
        setIsLoading(false)
        setHasSecondBeratungsstelle(hasSecondBeratungsstelle)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                loadDatenchecks(),
                loadSecondBstStelle()
            ])
        }
        fetchData()
    }, [loadDatenchecks, loadSecondBstStelle])

    const handleChange = (prop: string, oldValue: string | number | boolean, newValue: string | number | boolean, index?: number, order?: number) => {
        prop = `${datencheckPropPrefix}${prop}`
        let newDatencheck = datenchecks?.find(d => d.prop === prop)

        //wenn index dann ist es auf jedenfall eine telefonnummer, wo der wert erst zusammengesetzt werden muss
        if (typeof index !== "undefined") {
            let splitValue: string[] = []
            //gibt es schon einen datencheck, den man abändern muss
            if (newDatencheck) {
                //kann der datencheckwert gesplittet werden
                splitValue = (newDatencheck.newValue?.toString() ?? "").split(" ")
            } else /* gibt es keinen datencheck und ich kann oldvalue benutzen */ {
                //kann oldvalue gesplittet werden
                splitValue = (oldValue?.toString() ?? "").split(" ")
            }

            if (splitValue.length === 2) {
                newValue = index === 0 ? `(${newValue}) ${splitValue[1]}` : `${splitValue[0]} ${newValue}`
            } else {
                newValue = index === 0 ? `(${newValue}) 0000` : `(0000) ${newValue}`
            }
        }

        if (typeof newValue === "boolean") {
            setIsDirty(true)
        }

        if (!!oldValue && !!newValue) {
            setIsDirty(true)
        }

        //wertvalidierungen
        if (typeof newValue === "string" && newValue.length === 0) {
            if (datenchecks && newDatencheck) {
                setDatenchecks([...datenchecks.filter(d => d.prop !== prop)])
            }
            return;
        }

        if (datenchecks && oldValue === newValue) {
            setDatenchecks([...datenchecks.filter(d => d.prop !== prop)])
            return;
        }

        if (!newDatencheck) {
            newDatencheck = {
                id: -1,
                oldValue: oldValue,
                newValue: newValue,
                prop: prop,
                genehmigungspflichtig: true,
                toDoEmpfaenger: "ALH\\andrea.huettner",
                order: order
            }
        } else {
            newDatencheck = { ...newDatencheck, newValue: newValue }
        }

        if (datenchecks) {
            setDatenchecks([...datenchecks.filter(d => d.prop !== prop), { ...newDatencheck }])
        } else {
            setDatenchecks([{ ...newDatencheck }])
        }
    }

    const getPartOfNumber = (value: string, index: number) => {
        const splitNumber = value.split(" ")
        if (splitNumber.length === 2) {
            let result = splitNumber[index]
            if (index === 0) {
                result = result.substring(1, result.length - 1)
            }
            return result
        }
    }

    const buildTableRow = (field: MitarbeiterDatencheckField, children: JSX.Element) => {
        return <tr key={field.prop}>
            <td>{field.columnText}</td>
            {children}
        </tr>
    }

    const getDatencheckField = (field: MitarbeiterDatencheckField) => {
        let control: JSX.Element = <td></td>;
        const datencheckValue = getDatencheck(field.prop)

        //datum ausschließlich readonly
        //spezialbehandlung für ofd
        let ofdAngemeldet: boolean | undefined = undefined
        const mitarbeiterOfdValue = mitarbeiter['ofdAn']
        ofdAngemeldet = mitarbeiter["ofdAngemeldet"] && new Date(mitarbeiterOfdValue).getFullYear() !== 1899

        switch (field.type) {
            case "Checkbox":
                if (field.children) {
                    control = <td>
                        {field.children.map(c => <div key={c.prop} className="myBst_Line">
                            {/* if there is no second bst, dont show the checkbox */}
                            {!mitarbeiter[c.prop] && (hasSecondBeratungsstelle ? true : c.prop !== "anmeldungZweiteBst") ? <>
                                <InputCheckbox
                                    name={c.name}
                                    id={c.prop}
                                    checked={
                                        (!getDatencheck(c.prop) && mitarbeiter[c.prop]) ||
                                        !!getDatencheck(c.prop)
                                    }
                                    title={c.title}
                                    text={c.title}
                                    onChange={(val) => handleChange(c.prop, c.useOldValue ? mitarbeiter[c.prop] : false, val, undefined, field.order)}
                                />
                                {((!getDatencheck(c.prop) && mitarbeiter[c.prop]) ||
                                    !!getDatencheck(c.prop)) && <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Neuer Wert – Genehmigung ausstehend</InfoIcon>}
                            </> : <div>{`${c.prop === "anmeldungErsteBst" ? "für 1. BST angemeldet" : (hasSecondBeratungsstelle ? true : c.prop !== "anmeldungZweiteBst") ? "für 2. BST angemeldet" : ""}`}</div>}
                        </div>)}
                    </td>
                } else {
                    control = <td>
                        {!field.dontShowMitarbeiterProp && <div className="myBst_Line">
                            {!!mitarbeiter[field.prop] || !!mitarbeiter[field.differentDatencheckProp ?? ""] ? field.prop === "webportalZugangBeantragen" ? "vorhanden" : mitarbeiter[field.prop] : ""}
                        </div>}
                        {!mitarbeiter[field.prop] && <div className="myBst_Line">
                            <div className="myBst_EditControls">
                                {(field.condition && field.condition(mitarbeiter[field.prop])) ? <InputCheckbox
                                    readOnly
                                    name={field.name}
                                    id={field.differentDatencheckProp}
                                    checked={!!mitarbeiter[field.differentDatencheckProp!]}
                                    title={field.differentTitle}
                                    text={field.differentTitle}
                                    onChange={() => { }}
                                /> : <>
                                    {!mitarbeiter[field.differentDatencheckProp ?? ""] && <InputCheckbox
                                        name={field.name}
                                        id={field.prop}
                                        checked={!!datencheckValue}
                                        title={field.title}
                                        text={field.title}
                                        onChange={(val) => handleChange(field.prop, field.useOldValue ? mitarbeiter[field.prop] : false, val, undefined, field.order)}
                                    />}
                                    {!!datencheckValue && <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Neuer Wert – Genehmigung ausstehend</InfoIcon>}
                                </>}
                            </div>
                        </div>}
                    </td>
                }
                break;
            case "Datum":
                control = <td>
                    <div className="myBst_Line">
                        {field.prop === "ofdAn" ?
                            ofdAngemeldet ? `Ja, seit ${new Date(mitarbeiterOfdValue)?.toLocaleDateString()}` : "Nein"
                            : (!!mitarbeiter[field.prop] && typeof mitarbeiter[field.prop] === "string") ? new Date(mitarbeiter[field.prop])?.toLocaleDateString() ?? "-" : "-"}
                    </div>
                </td>
                break;
            case "Textfeld":
                control = <td>
                    <div className="myBst_Line">
                        {!!mitarbeiter[field.prop] ? mitarbeiter[field.prop] : "-"}
                    </div>
                    {!field.readonly && <div className="myBst_Line">
                        <div className="myBst_EditControls">
                            {!field.children ? <InputField
                                key={field.prop}
                                type='textclean'
                                id={field.prop}
                                name={field.name}
                                isInvalid={field.validate ? field.validate(datencheckValue) : false}
                                placeholder={field.placeholder ?? ""}
                                value={datencheckValue?.toString() ?? ""}
                                onChange={(val) => handleChange(field.prop, mitarbeiter[field.prop], val, undefined, field.order)}
                            /> :
                                field.children.map((c, index) => <InputField
                                    key={c.prop}
                                    type="textclean"
                                    id={c.prop}
                                    name={c.name}
                                    placeholder={c.placeholder ?? ""}
                                    value={getPartOfNumber(datencheckValue?.toString() ?? "", index) ?? ""}
                                    onChange={(val) => handleChange(field.prop, mitarbeiter[field.prop], val, index, field.order)}
                                />)
                            }
                            {!!datencheckValue && <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Neuer Wert – Genehmigung ausstehend</InfoIcon>}
                        </div>
                    </div>}
                </td>
                break;
            case "Dropdown":
                control = <td>
                    <div className="myBst_Line">
                        {!!mitarbeiter[field.prop] ? mitarbeiter[field.prop] : "-"}
                    </div>
                    <div className="myBst_Line">
                        <div className="myBst_EditControls">
                            <SelectField
                                id={field.prop}
                                name={field.name}
                                withPleaseSelect={true}
                                value={datencheckValue?.toString() ?? ""}
                                options={field.values!}
                                onChange={(val) => handleChange(field.prop, mitarbeiter[field.prop], val, undefined, field.order)}
                            />
                            {!!datencheckValue && <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Neuer Wert – Genehmigung ausstehend</InfoIcon>}
                        </div>
                    </div>
                </td>
                break;
            case "Radio":
                if (!ofdAngemeldet) {
                    control = <td>
                        <div className="myBst_Line">
                            <div className="myBst_EditControls">
                                <label>
                                    <input
                                        type="radio"
                                        value="Ja"
                                        name={field.name}
                                        checked={typeof datencheckValue !== "undefined" ? typeof datencheckValue === "string" ? datencheckValue === "true" : !!datencheckValue : false}
                                        onChange={(e) => handleChange(field.prop, mitarbeiter[field.prop], true, undefined, field.order)}
                                    /> Ja (steuerlich tätig)
                                </label>
                                {datencheckValue !== undefined && (typeof datencheckValue === "string" ? datencheckValue === "true" : !!datencheckValue) && <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Neuer Wert – Genehmigung ausstehend</InfoIcon>}
                            </div>
                        </div>
                        <div className="myBst_Line">
                            <div className="myBst_EditControls">
                                <label>
                                    <input
                                        type="radio"
                                        value="Nein"
                                        name={field.name}
                                        checked={typeof datencheckValue !== "undefined" ? typeof datencheckValue === "string" ? datencheckValue === "false" : !datencheckValue : false}
                                        onChange={(e) => handleChange(field.prop, mitarbeiter[field.prop], false, undefined, field.order)}
                                    /> Nein (nur organisatorische Aufgaben)
                                </label>
                                {datencheckValue !== undefined && (typeof datencheckValue === "string" ? datencheckValue === "false" : !datencheckValue) && <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Neuer Wert – Genehmigung ausstehend</InfoIcon>}
                            </div>
                        </div>
                    </td>
                } else {
                    return <div></div>
                }
                break;
        }

        return buildTableRow(field, control)
    }

    const saveDatencheck = async () => {
        setIsDirty(false)

        if (datenchecks) {
            
            // anmeldung für pflichtfeld
            if ((!mitarbeiter.anmeldungErsteBst && !mitarbeiter.anmeldungZweiteBst) &&
                (!datenchecks?.find(d => d.prop.includes("anmeldungErsteBst")) && !datenchecks?.find(d => d.prop.includes("anmeldungZweiteBst")))) {
                toast.warning("Bitte eine BST auswählen, in der der Mitarbeiter angemeldet werden soll.", {
                    autoClose: 10000
                });
                return;
            }


            //Send data
            const response = await DatencheckService.set(datenchecks.map(d => ({ ...d, oldValue: d.oldValue?.toString() ?? "", newValue: d.newValue?.toString() ?? "" })))

            if (response) {
                if (response.success) {
                    toast.success("Daten erfolgreich gespeichert!", {
                        autoClose: 5000
                    });
                    setDatenchecks(response.data.datenchecks.map((d: Datencheck) => ({ ...d })).filter((d: Datencheck) => d.prop.includes(datencheckPropPrefix)))
                } else {
                    toast.error("Fehler beim Speichern. " + response.message, {
                        autoClose: 5000
                    })
                }
            } else {
                toast.error("Fehler beim Speichern", {
                    autoClose: 5000
                })
            }
        } else {
            toast.error("Daten sind leer. Es kann nichts gespeichert werden", {
                autoClose: 5000
            })
        }
    }

    return <div id="mitarbeitereditform">
        <div>Ausgewählte/r Mitarbeiter/in: <b>{`${mitarbeiter.vorname} ${mitarbeiter.nachname}`}</b></div>
        {!isLoading && <Container>
            <Row className="alh_rowSpacer">
                <Col className="alh_colData">
                    <Table striped bordered hover>
                        <tbody>
                            {mitarbeiterDatencheckFields.filter(d => d.order < 10).map(f => getDatencheckField(f))}
                        </tbody>
                    </Table>
                </Col>
                <Col className="alh_colData">
                    <Table striped bordered hover>
                        <tbody>
                            {mitarbeiterDatencheckFields.filter(d => d.order >= 10).map(f => getDatencheckField(f))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>}
        {isLoading && <div className="alhLoadingRel"><WaveTopBottomLoading color="#ffc800" /></div>}
        <SubmitButton text="Speichern" disabled={!isDirty} onClick={() => saveDatencheck()} mode="success" />
    </div>
}

export default MitarbeiterEditForm