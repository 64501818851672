import React, { Component } from 'react';
import { Col, Container, Row, Table } from "react-bootstrap";
import { WaveTopBottomLoading } from "react-loadingg";
import InfoIcon from "../Blocks/InfoIcon";
import { faArrowLeft, faArrowRight, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Mitgliederliste from "../ListComponents/Mitgliederliste";
import UserStore from "../../stores/UserStore";
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface StatistikProps extends RouteComponentProps { }

interface StatistikState {
    jahr: {
        [key: number]: number
        1: number
        2: number
    }
    stat: {
        [key: number]: any
        1: any
        2: any
    }
}

class Statistik extends Component<StatistikProps, StatistikState> {

    constructor(props: StatistikProps) {
        super(props);

        if (!UserStore.hasBerechtigung('bst_statistik') && typeof this.props.history !== "undefined") {
            this.props.history.push("/notallowed/");
        }

        this.state = {
            jahr: {
                1: (new Date().getFullYear() - 1),
                2: (new Date().getFullYear()),
            },
            stat: {
                1: {},
                2: {}
            }
        }

        this.getCurrentMitglieder = this.getCurrentMitglieder.bind(this);
    }

    async getStatistikFromServer(jahrId: number) {
        //reset stat
        let currentStat = this.state.stat;
        currentStat[jahrId] = {};
        this.setState({
            stat: currentStat
        });

        try {
            let res = await fetch(UserStore.url + '/statistik', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        Jahr: this.state.jahr[jahrId],
                    }
                })
            });

            let result = await res.json();

            if (result && result.success) {

                let currentStat = this.state.stat;
                currentStat[jahrId] = result.data;
                this.setState({
                    stat: currentStat
                });


            }

        } catch (e) {
            console.log("Error in loading homemessage: " + e);
        }
    }

    async componentDidMount() {
        this.getStatistikFromServer(1);
        this.getStatistikFromServer(2);


        document.title = 'Statistik | ' + UserStore.title;
    }

    getCurrentMitglieder(gekuendigt = false) {
        let currentStat = this.state.stat[1];
        if (currentStat.mitglieder) {
            if (gekuendigt) {
                return currentStat.mitglieder.mitgliederAnzahlGesamtGekuendigt;
            } else {
                return currentStat.mitglieder.mitgliederAnzahlGesamt;
            }
        } else {
            return "...";
        }
    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    updateStatBoxYear(jahrIndex: number, value: number) {
        let currentJahrSetup = this.state.jahr;
        currentJahrSetup[jahrIndex] += value;

        this.setState({
            jahr: currentJahrSetup,
        });

        this.getStatistikFromServer(jahrIndex);
    }

    getStatBox(jahr: number) {
        let currentStat = this.state.stat[jahr];
        if (currentStat.mitglieder) {

            return (
                <div className="alh_stat_container">
                    <Container>
                        <Row className="alh_rowTopList">
                            <Col lg={3} className="text-right alh_noprint">
                                <div><FontAwesomeIcon className="pointer" title="Vorheriges Jahr" icon={faArrowLeft} onClick={() => { this.updateStatBoxYear(jahr, -1); }} /></div>
                            </Col>
                            <Col lg={6}>
                                <h2>{this.state.jahr[jahr]}</h2>
                            </Col>
                            <Col lg={3} className={((this.state.jahr[jahr] >= new Date().getFullYear()) ? 'd-none' : '') + ' text-left alh_noprint'}>
                                <div><FontAwesomeIcon className="pointer" title="Nächstes Jahr" icon={faArrowRight} onClick={() => { this.updateStatBoxYear(jahr, +1); }} /></div>
                            </Col>
                        </Row>
                    </Container>

                    <h3 className="alh_bold">Umsatz</h3>
                    <Table striped bordered hover size="sm" className="alhTable">
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="text-right"><small>Brutto</small></td>
                                <td className="text-right"><small>DS</small></td>
                            </tr>
                            <tr>
                                <td>
                                    Gesamt
                                    <InfoIcon space="left">Umsatz brutto + Dezemberumsatz Vorjahr ./. Dezemberumsatz aktuelles Jahr</InfoIcon>
                                </td>
                                <td className="text-right">{this.numberFormat(currentStat.umsatz.umsatzGesamt)}</td>
                                <td className="text-right">
                                    <InfoIcon>[Umsatz brutto gesamt] / [Beiträge abgerechnet gesamt (inkl. Aufnahmegeb.)]</InfoIcon>
                                    {this.numberFormat(currentStat.umsatz.umsatzDurschschnitt)}
                                </td>
                            </tr>
                            <tr>
                                <td>Vergütung bis 31.12.<InfoIcon space="left">Umsatz brutto 01.01.-31.12. – bis 31.10. identisch mit Pünktlichkeitsbonus</InfoIcon></td>
                                <td className="text-right">{this.numberFormat(currentStat.umsatz.umsatzVerguetung)}</td>
                            </tr>
                            <tr>
                                <td>
                                    Pünktlichk.-Bonus bis 31.10.
                                    <InfoIcon space="left" mode="modal" placement="right" title="Abweichung Umsatz Gesamt zu Pünktlichkeitsbonus">
                                        <div className="alh_popover">
                                            Im Umsatz Gesamt ist der Dezemberumsatz des Vorjahres enthalten.
                                            Im Umsatz Pünktlichkeitsbonus fließt dieser nicht mit ein (siehe 4.3).
                                            <br /><br />

                                            <h2>Originalfassung:</h2>
                                            <div>5. Pünktlichkeitsbonus (PB)</div>
                                            Der PB ist eine freiwillige Ausschüttung des Vereins für pünktliche und
                                            leistungsgerechte Abrechnung der treuhänderisch vereinnahmten Mitgliedsbeiträge.
                                            <div>5.1 Laufzeit</div>
                                            Die Laufzeit des PB gilt vorbehaltlich der Gesamtmitgliederentwicklung
                                            bis auf Widerruf.
                                            <div>5.2 Pünktlichkeitszeitraum:</div>
                                            Die Abrechnung der Mitgliedsbeiträge muss ganzjährig durchgehend
                                            pünktlich bis zum 10. des Folgemonats erfolgen.
                                            <div>5.3 Umsatzzeitraum:</div>
                                            Der für die Höhe des PB ausschlaggebende Umsatzzeitraum gilt von 01.01.{(new Date()).getFullYear()}
                                            bis einschließlich 31.10.{(new Date()).getFullYear()}
                                            <div>5.4 Berücksichtigung</div>
                                            Abrechnungsquote:
                                            Berücksichtigt wird auch die Abrechnungsquote der Beiträge des aktuellen
                                            Jahres, also der Anteil abgerechneter Jahresbeiträge {(new Date()).getFullYear()} zur gesamten,
                                            aktiven Mitgliederzahl. Damit soll ausgeschlossen werden, dass verspätet
                                            gemeldete Vorjahresbeiträge auch noch belohnt werden.
                                            <div>5.5 Pünktlicher Zahlungseingang</div>
                                            Eine Abrechnung gilt als pünktlich, sofern diese bis zum 10. des Folgemonats
                                            in der Hauptverwaltung eingeht und bezahlt wird. Als bezahlt gilt eine
                                            Abrechnung dann, wenn der abzubuchende HV-Anteil auf dem Vereinskonto
                                            bis spätestens 10. des Folgemonats gutgeschrieben worden ist. Rücklastschriften
                                            werden als unpünktliche Abrechnung gewertet. Es ist jederzeit
                                            möglich die Abrechnung bereits zum 1. des Folgemonats zu übermitteln.
                                            Beim Pünktlichkeitsbonus werden nur Beratungsstellen berücksichtigt, die am
                                            Lastschriftverfahren teilnehmen.
                                            <div>5.6 Umsatzgrenzen und Höhe des PB:</div>
                                            Umsatz PB<br />
                                            ab 5.000,– € brutto 100,– € netto<br />
                                            ab 10.000,– € brutto 200,– € netto<br />
                                            ab 15.000,– € brutto 300,– € netto<br />
                                            ab 30.000,– € brutto 500,– € netto<br />
                                            Als Umsatz gilt die Summe der monatlichen Einzelabrechnungen in besagtem
                                            Zeitraum. Über jede Abrechnung erhält der Beratungsstellenleiter eine Gutschrift
                                            per PDF zugemailt, sofern zur Umsatzsteuer optiert wird. Die BSTool-
                                            Statistik dient nicht als Rechenbasis für die Umsatzgrenze.
                                            <div>5.7 Auszahlungszeitpunkt</div>
                                            Die Auszahlung des PB erfolgt per Überweisung in der Regel im Dezember
                                            des Jahres, für das der PB ausgeschüttet wird.
                                        </div>
                                    </InfoIcon>
                                </td>
                                <td className="text-right">{this.numberFormat(currentStat.umsatz.umsatzPuenktlBonus)}</td>
                            </tr>
                        </tbody>
                    </Table>


                    <h3 className="alh_bold">Beratungsstellenanteil</h3>
                    <Table striped bordered hover size="sm" className="alhTable">
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="text-right"><small>Brutto</small></td>
                                <td className="text-right"><small>Vergütung</small></td>
                            </tr>
                            <tr>
                                <td>BST Anteil vor Boni</td>
                                <td className="text-right">{this.numberFormat(currentStat.bstAnteil.vorBoni)}</td>
                                <td className="text-right">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    Bonus Gesamtsumme
                                    <InfoIcon space="left" placement="right">
                                        Summe aller Bonis des Jahres (incl. z. B. Flyer-Verteilkosten oder redaktionelle Beiträge).
                                    </InfoIcon>
                                </td>
                                <td className="text-right">{this.numberFormat(currentStat.bstAnteil.bonusGesamt)}</td>
                                <td className="text-right">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>BST Anteil nach Boni
                                    <InfoIcon space="left" placement="right">
                                        Hier werden alle Bonuszahlungen des laufenden Jahres berücksichtigt
                                        (wie der Vereinsanteil für Druck- und Verteilkosten bei Flyern , redaktionelle Beiträge,  Pünktlichkeits-, Mitglieder-, Sonder- und sonstige Boni).
                                    </InfoIcon>
                                </td>
                                <td className="text-right">{this.numberFormat(currentStat.bstAnteil.nachBoni)}</td>
                                <td className="text-right">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>BST % vor Boni</td>
                                <td className="text-right">&nbsp;</td>
                                <td className="text-right">{currentStat.bstAnteil.prozVorBoni}%</td>
                            </tr>
                            <tr>
                                <td>BST % nach Boni</td>
                                <td className="text-right">&nbsp;</td>
                                <td className="text-right">{currentStat.bstAnteil.prozNachBoni}%</td>
                            </tr>
                        </tbody>
                    </Table>


                    <h3 className="alh_bold">Mitglieder</h3>
                    <Table striped bordered hover size="sm" className="alhTable">
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="text-right"><small>Aktiv</small></td>
                                <td className="text-right"><small>Gekündigt</small></td>
                                <td className="text-right"><small>+/-</small></td>
                            </tr>
                            <tr>
                                <td>Mitglieder</td>
                                <td className="text-right">{currentStat.mitglieder.mitgliederAktiv}</td>
                                <td className="text-right">{currentStat.mitglieder.mitgliederGekuendigt}</td>
                                <td className="text-right">{currentStat.mitglieder.mitgliederDiff}</td>
                            </tr>
                            <tr>
                                <td>davon Neumitglieder bis 31.12.</td>
                                <td className="text-right">{currentStat.mitglieder.neumitgliederAktiv}</td>
                                <td className="text-right">{currentStat.mitglieder.neumitgliederGekuendigt}</td>
                                <td className="text-right">{currentStat.mitglieder.neumitgliederDiff}</td>
                            </tr>
                            <tr>
                                <td>
                                    davon Neumitglieder bis 31.08.
                                    <InfoIcon space="left" mode="popover" placement="right">
                                        <div className="alh_popover">
                                            <h1>Originalfassung:</h1>
                                            <div>6. Neumitgliederbonus (NB)</div>
                                            Der NB ist eine freiwillige Ausschüttung des Vereins für
                                            überdurchschnittliche Leistungen bei der Neumitgliedergewinnung.
                                            <div>6.1 Laufzeit</div>
                                            Die Laufzeit des NB gilt vorbehaltlich der Gesamtmitgliederentwicklung
                                            bis auf Widerruf.
                                            <div>6.2 Wertungszeitraum und Beitrittsdatum</div>
                                            Das Beitrittsdatum eines Neumitglieds muss zwischen dem
                                            01.01. und 31.08. liegen. Als Beitrittsdatum gilt dasjenige Datum,
                                            mit dem das Mitglied die Beitrittserklärung unterschrieben hat, nicht zu
                                            verwechseln mit dem rückwirkenden Eintrittsdatum „Mitgliedschaft ab“ für
                                            rückwirkende Mitgliedschaften.
                                            <div>6.3 Beitrittserklärung</div>
                                            Als Nachweis dient die vom Mitglied (bei Eheleuten auch vom Partner)
                                            unterschriebene Beitrittserklärung (BE) mit Datumsangabe. Die vollständige
                                            BE muss bis zum 10.09. bei der HV eingegangen sein.
                                            <div>6.4 Abrechnung</div>
                                            Für ein Neumitglied muss bis zum 10.09. ein Mitgliedsbeitrag abgerechnet
                                            und bezahlt worden sein. Als bezahlt gilt eine Abrechnung dann, wenn
                                            der abzubuchende HV-Anteil auf dem Vereinskonto bis spätestens 10. des
                                            Folgemonats gutgeschrieben worden ist. Wird für ein Neumitglied ein bereits
                                            abgerechneter Beitrag nachträglich storniert, so gilt dieses Neumitglied als
                                            nicht gewertet. Falls dadurch eine der Bonusgrenzen unterschritten wird, ist
                                            ein bereits gewährter Bonus zurückzubezahlen.
                                            <div>6.5 Grenzen und Höhe des NB</div>
                                            50 Neumitglieder 500,– € brutto<br />
                                            75 Neumitglieder 750,– € brutto<br />
                                            100 Neumitglieder 1.000,– € brutto
                                            <div>6.6 Auszahlungszeitpunkt</div>
                                            Die Auszahlung des NB erfolgt per Überweisung in der Regel nach Abschluss
                                            der Herbstseminarreihe des Jahres, für das der NB ausgeschüttet wird.
                                        </div>
                                    </InfoIcon>
                                </td>
                                <td className="text-right">{currentStat.mitglieder.neumitglieder3108Aktiv}</td>
                                <td className="text-right">{currentStat.mitglieder.neumitglieder3108Gekuendigt}</td>
                                <td className="text-right">{currentStat.mitglieder.neumitglieder3108Gekuendigt}</td>
                            </tr>
                        </tbody>
                    </Table>


                    <h3 className="alh_bold">Beiträge</h3>
                    <Table striped bordered hover size="sm" className="alhTable">
                        <tbody>
                            <tr>
                                <td></td>
                                <td className="text-right"></td>
                                <td className="text-right">
                                    <InfoIcon space="right">[Beiträge (Nur aktive)] / [Mitglieder aktiv]</InfoIcon>
                                    <small>Quote</small>
                                </td>
                            </tr>
                            <tr>
                                <td>Abger. gesamt {this.state.jahr[jahr]}</td>
                                <td className="text-right">{currentStat.beitraege.beitraegeAbrechnungGesamt}</td>
                            </tr>
                            <tr>
                                <td>davon {this.state.jahr[jahr]} (inkl. KÜ)</td>
                                <td className="text-right">{currentStat.beitraege.beitraegeAbrechnungAktBeitrag}</td>
                            </tr>
                            <tr>
                                <td>davon {this.state.jahr[jahr]} (nur aktive)</td>
                                <td className="text-right">{currentStat.beitraege.beitraegeAbrechnungAktBeitragNurAktiv}</td>
                                <td className="text-right">{currentStat.beitraege.beitraegeQuote} %</td>
                            </tr>
                            <tr>
                                <td>
                                    MG-Bonus
                                    <InfoIcon space="left" mode="popover" placement="right">
                                        <h1>6. Mitgliederbonus (MB)</h1>
                                        <div>6.1 Konditionen</div>
                                        <div>Voraussetzung für den MB ist die Gewährung des PB gemäß Ziffer 5. Gewertet
                                            werden alle Abrechnungsmitglieder. Als Abrechnungsmitglied gilt ein
                                            Mitglied, dessen Mitgliedschaft ungekündigt ist und für das ein Beitrag für das
                                            laufende Jahr über das Web-Portal gemäß Ziffer 5. abgerechnet worden ist.
                                            Bei Neumitgliedern mit Beitrittsdatum im laufenden Jahr und rückwirkender
                                            Mitgliedschaft werden auch zurückliegende Beiträge gewertet, sofern sie
                                            gemäß Ziffer 5. abgerechnet worden sind.</div>
                                        <div>6.2 Berücksichtigungszeitraum</div>
                                        <div>Der für die Höhe des MB ausschlaggebende Umsatzzeitraum gilt von 01.01.
                                            bis einschließlich 31.10.</div>
                                        <div>6.3 Höhe des MB</div>
                                        <div>Der MB beträgt 1,- € netto pro Abrechnungsmitglied.</div>
                                        <div>6.4 Auszahlungszeitpunkt</div>
                                        <div>Die Auszahlung des MB erfolgt per Überweisung im Dezember des Jahres,
                                            für das der PB ausgeschüttet wird.</div>
                                    </InfoIcon>
                                </td>
                                <td className="text-right">{currentStat.beitraege.beitraegeMGBonus}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )

        } else {
            return (
                <div className="alh_stat_container">
                    <WaveTopBottomLoading color="#ffc800" />
                </div>
            )
        }

    }

    render() {
        return (
            <div className="alh_stat">
                <h3 className="alh_pageheader">Ihre Statistik</h3>
                <div className="alh_subheader">
                    Sehen Sie hier die aktuelle Statistik. Sie können auf beiden Seiten unterschiedliche Jahre betrachten, um diese
                    hier zu vergleichen.
                    <div className="alh_print_right"><FontAwesomeIcon onClick={() => window.print()} icon={faPrint} title="Drucken" className="faButton faButtonSpaceright" /></div>
                </div>


                <Container>
                    <Row className="alh_rowTopList d-none">
                        <Col lg={6}>
                            <div className="alh_stat_keys">Mitglieder Anzahl gesamt: <span className="success">{this.getCurrentMitglieder()}</span></div>
                        </Col>
                        <Col lg={6}>
                            <div className="alh_stat_keys">Mitglieder Anzahl gekündigt: <span>{this.getCurrentMitglieder(true)}</span></div>
                        </Col>
                    </Row>

                    <Row className="alh_rowTopList">
                        <Col lg={6} className="col-print-6">
                            <div className="alh_frontBoxes">
                                {this.getStatBox(2)}
                            </div>
                        </Col>
                        <Col lg={6} className="col-print-6">
                            <div className="alh_frontBoxes">
                                {this.getStatBox(1)}
                            </div>
                        </Col>
                    </Row>
                </Container>

                <br />
                <Mitgliederliste mode="neu" />
            </div>
        );
    }
}

export default withRouter(Statistik);