import React, { Component } from "react";
import { Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import UserStore from "../../stores/UserStore";
import InfoIcon from "../Blocks/InfoIcon";
import SelectField from "../Forms/SelectField";
import InputField from "../Forms/InputField";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faPrint, faSortNumericDown, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import Table from "react-bootstrap/Table";
import { b64toBlob, sortList, trackEvent, trackException } from "../ALHUtility";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import TableOperatorOptions from "../Blocks/TableOperatorOptions";
import InputSearchField from "../Forms/InputSearchField";
import Buchung from "../../models/Buchung";
import KeyValue from "../../models/KeyValue";
import MitgliedItem from "../../models/Mitglied";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Zahlungweise from "../../models/Zahlungsweise";
import { WaveTopBottomLoading } from "react-loadingg";
import BuchungService from "../../services/BuchungService";
import { BstContext } from "../../stores/BstContextProvider";
import { CommonService } from "../../services/CommonService";

enum InputProperties {
    mgnr = "mGNr",
    mgNameSearch = "mgNameSearch",
    betrag = "betrag",
    vorgang = "vorgang",
    leistungsdatum = "leistungsdatum",
    zahlungsweise = "zahlungsweise"
}

interface SammelbuchungProps extends RouteComponentProps {
    mGNr?: string
    zahlungsweise?: string
}

interface SammelbuchungState {
    mGNr: string
    mgName: string
    mgNameSearch: string
    mgNameFilename: string
    mgSearchList: MitgliedItem[]

    zahlungsweisen: Zahlungweise[]

    buchungenHistory: Buchung[]
    oPs: Buchung[]
    boStaffeln: {
        beitrag: number
    }[]

    loadingitems: boolean

    currentBuchung: Buchung
    vorgangValues: KeyValue[]
    zahlungsweiseNewDefault: string
    selectedVorgang: string
    sort: string
    sort_mode: string
    isSaving: boolean
}

class Sammelbuchung extends Component<SammelbuchungProps, SammelbuchungState> {

    // resembles the useContext hook in functional components
    static contextType = BstContext
    context!: React.ContextType<typeof BstContext>

    constructor(props: SammelbuchungProps) {
        super(props)

        if (!UserStore.hasBerechtigung('buha_sammelbuchung') && typeof this.props.history !== "undefined") {
            this.props.history.push("/notallowed/");
        }

        this.state = {
            mGNr: !this.props.mGNr ? UserStore.bstNr + "-" : this.props.mGNr,
            mgName: '',
            mgNameSearch: '',
            mgNameFilename: '-',
            mgSearchList: [],

            zahlungsweisen: [
                { id: "Bankeinzug", value: "Bankeinzug", filter1: true, filter2: true },
                { id: "Bar", value: "Bar", filter1: true, filter2: false },
                { id: "EC Cash", value: "EC Cash", filter1: true, filter2: false },
                { id: "Überweisung", value: "Überweisung", filter1: true, filter2: true },
            ],

            buchungenHistory: [],  //List of already done buchungen
            oPs: [],    //List of possible Buchungen
            boStaffeln: [],

            loadingitems: false,

            currentBuchung: {
                index: -1,
                buchungHandle: -1,
                buchungTanEdit: 0,
                bstNr: UserStore.username,
                buchungsart: 1,

                mgNr: "",
                beitragsjahr: new Date().getFullYear(),
                betrag: 0,
                betragMax: 0,
                istTeilzahlung: false,
                leistungsdatum: moment().format("YYYY-MM-DD"),
                notiz: "",
                vorgang: "Beitrag",
                zahlungsweise: "",
                bmg: 0,
                vv: false
            },

            vorgangValues: [],
            zahlungsweiseNewDefault: "",
            selectedVorgang: "",

            sort: 'mgNr',
            sort_mode: 'asc',
            isSaving: false
        }

        document.title = 'Sammelbuchung | ' + UserStore.title;

        this.mGNrEnterPress = this.mGNrEnterPress.bind(this);
        this.getCurrentSavedBuchung = this.getCurrentSavedBuchung.bind(this);
    }

    sortClick(spalte: string) {
        let newSort_mode = this.state.sort_mode;
        let newSort = this.state.sort;

        if (spalte === this.state.sort) {
            switch (this.state.sort_mode) {
                case 'asc':
                    newSort_mode = 'desc';
                    break;
                default:
                    newSort_mode = 'asc';
                    break;
            }
        } else {
            newSort = spalte;
        }

        this.setState({
            sort: newSort,
            sort_mode: newSort_mode,
        })
    }

    async componentDidMount() {

        await this.getAllMitgliederSearch();
        
        let resultBO = await CommonService.getBeitragsordnung(this.state.mGNr)

        const context = this.context

        //Load meine bst for prefilling Buchungs preset
        let defZahlungsweise = "";
        let resultBST = context.settings
        if (resultBST.zahlungsweiseSollRechnungen) {
            defZahlungsweise = resultBST.zahlungsweiseHaben;
        }

        let editBuchung = this.state.currentBuchung;
        editBuchung.zahlungsweise = defZahlungsweise;
        this.setState({
            boStaffeln: resultBO.data.items,
            zahlungsweiseNewDefault: defZahlungsweise,
            currentBuchung: editBuchung,
        });

    }

    async loadItems2Mg(thisMgNr: string | null = null) {

        if (thisMgNr === null) {
            thisMgNr = this.state.mGNr;
        }

        trackEvent('Sammelbuchung', 'LoadItems', '');

        //reset Buchung
        this.setState({
            selectedVorgang: "",
            loadingitems: true,
            mgName: '',
            mgNameFilename: '',
        });

        let mgNamecurrent = '';
        let mgNamecurrentFilename = '';
        try {
            //load Mitglied
            let resMG = await fetch(UserStore.url + '/mitglied', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        MGNr: thisMgNr
                    }
                })
            });

            let resultMG = await resMG.json();

            if (resultMG && resultMG.success) {
                mgNamecurrent = resultMG.data.mitglied.anrede + ' ' + resultMG.data.mitglied.vorname + ' ' + resultMG.data.mitglied.nachname;
                mgNamecurrentFilename = resultMG.data.mitglied.name;
            }

            //check if mgnr inserted correctly
            if (this.state.mGNr !== resultMG.data.mitgliedsnummer) {
                this.setState({
                    mGNr: resultMG.data.mitgliedsnummer
                });
            }

            let editBuchung = this.state.currentBuchung;

            //reset Buchung
            editBuchung.betrag = 0;
            editBuchung.betragMax = 0;
            editBuchung.istTeilzahlung = false;


            let res = await fetch(UserStore.url + '/mgop', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        MGNr: thisMgNr,
                    }
                })
            });

            let result = await res.json();

            if (result && result.success) {
                this.setState({
                    oPs: result.data.oPs
                });

                let thisSelectedVorgang = this.updateValidVorgaenge(1);
                editBuchung.mgNr = thisMgNr;

                this.setState({
                    currentBuchung: editBuchung,
                    selectedVorgang: thisSelectedVorgang,
                });
                this.setInputValue(InputProperties.vorgang, thisSelectedVorgang, false);

                toast.success("Daten wurden geladen, Sie können nun mit dem Buchen für dieses Mitglied starten.", {
                    autoClose: 10000
                });

            } else {

                toast.warning("Es konnten keine Daten gefunden werden. Bitte kontrollieren Sie die Mitgliedsnummer.", {
                    autoClose: 10000
                });

            }

        } catch (e) {
            console.log("Error in loading Offene Posten: " + e);
        }


        this.setState({
            loadingitems: false,
            mgName: mgNamecurrent,
            mgNameFilename: mgNamecurrentFilename,
        });

    }

    valueInStaffel(val: number) {
        let result = false;
        this.state.boStaffeln.forEach((item) => {
            if (item.beitrag === val) {
                result = true;
            }
        });

        return result;
    }

    updateValidVorgaenge(buchungsart: number, forceValue = "") {
        //calc VorgangValues
        let currVorgangValues = [];
        let forceValuePresent = false;
        let vorgangId = '';

        let thisEntries = this.state.oPs;
        thisEntries.forEach((entry) => {
            let valid = true;
            //buchungstextExists
            if (buchungsart === 2 && this.state.currentBuchung.index === -1 /* && this.buchungstextExists(entry.vorgang)*/) {
                valid = false;
            }
            if (valid) {
                //calc correct vorgang
                vorgangId = entry.vorgangsart + ((entry.vorgangsart !== entry.konto) ? " " + entry.konto : "");

                currVorgangValues.push({
                    id: vorgangId,
                    value: entry.vorgang,
                });

                if (vorgangId === forceValue) {
                    forceValuePresent = true;
                }
            }
        });

        //check force and present
        if (forceValue && !forceValuePresent) {
            currVorgangValues.push({
                id: forceValue,
                value: forceValue
            })
        }

        this.setState({
            vorgangValues: currVorgangValues,
        });

        if (currVorgangValues.length > 0) {
            return currVorgangValues[0].id;
        } else {
            return "";
        }

    }

    getBetragFromOp(val: string, check: boolean = false) {
        //fill betrag according Ops
        let currentBetrag = -1;
        let vorgangId = '';

        let thisEntries = [];
        thisEntries = this.state.oPs;
        thisEntries.forEach((entry) => {
            vorgangId = entry.vorgangsart + ((entry.vorgangsart !== entry.konto) ? " " + entry.konto : "");
            if (vorgangId === val) {
                currentBetrag = entry.betrag ?? -1;
            }
        });

        if (check) {
            if (currentBetrag !== -1 || thisEntries.length === 0) {
                return true;
            } else {
                return this.state.oPs[0].vorgang;
            }
        } else {
            return (currentBetrag === -1 ? 0 : currentBetrag);
        }
    }

    setInputValue(property: InputProperties, val: string, date = false) {

        if (property === InputProperties.mgnr) {
            this.setState({
                mGNr: val
            })
        } else if (property === InputProperties.mgNameSearch) {
            this.setState({
                mGNr: val
            })
            this.loadItems2Mg(val);
        } else {

            var buchung = this.state.currentBuchung;

            if (date) {
                buchung[property.valueOf()] = val + "T00:00:00";
            } else if (property === InputProperties.betrag) {
                buchung[property] = parseFloat(val);
            } else {
                buchung[property] = val;
            }

            //split in case of Buchung
            if (property === InputProperties.vorgang) {
                if (val !== "" && val.indexOf(" ") > 0) {
                    const valueExplode = val.split(" ");
                    const jahr = Number(valueExplode[1])
                    buchung.vorgang = valueExplode[0];
                    buchung.beitragsjahr = isNaN(jahr) ? Number(valueExplode[1].substring(valueExplode[1].length - 4)) : jahr
                    buchung.betrag = this.getBetragFromOp(val) as number;
                    buchung.betragMax = this.getBetragFromOp(val) as number;
                } else {
                    buchung.vorgang = val;
                    buchung.beitragsjahr = 0;
                    buchung.betrag = this.getBetragFromOp(val) as number;
                    buchung.betragMax = this.getBetragFromOp(val) as number;
                }
            }

            //check Betrag not > max betrag
            if (property === InputProperties.betrag) {
                if (parseFloat(val) > buchung.betragMax! && !this.valueInStaffel(parseFloat(val))) {
                    toast.error("Der Beitrag entspricht nicht der Beitragsordnung. Kein Buchen möglich. Bitte Betrag anpassen", {
                        autoClose: 5000
                    });
                }
            }

            this.setState({
                currentBuchung: buchung,
                selectedVorgang: property === "vorgang" ? val : this.state.selectedVorgang
            })

        }
    }

    mGNrEnterPress(target: React.KeyboardEvent<HTMLInputElement>) {
        if (target.key === 'Enter') {
            this.loadItems2Mg();
        }
    }

    async getCurrentSavedBuchung(handle: number, nameMtgl = '') {

        try {

            let res = await fetch(UserStore.url + '/mgbujournal', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        MGNr: this.state.mGNr,
                        buchungHandle: handle
                    }
                })
            });
            let result = await res.json();

            if (result.success && result.data.buchungen.length > 0) {

                let currentHistory = this.state.buchungenHistory;

                result.data.buchungen.forEach((item: Buchung) => {
                    if (item.buchungHandle === handle) {

                        item.fileNameNName = nameMtgl;
                        currentHistory.push(item);

                    }
                })

                this.setState({
                    buchungenHistory: currentHistory,
                })

            } else {

                toast.error("Buchung konnte für die History nicht geladen werden. Bitte checken Sie die Buchung im Mitglied.", {
                    autoClose: 5000
                });

            }


        } catch (e) {
            console.log("Error in loading homemessage: " + e);
        }
    }

    async buchungSave(teilzahlung: boolean) {
        this.setState({ isSaving: true })
        let nameMtgl = this.state.mgNameFilename;
        let currentBuchung = this.state.currentBuchung;
        currentBuchung.istTeilzahlung = teilzahlung;
        currentBuchung.beitragsjahr = Number(currentBuchung.beitragsjahr);

        //Send data
        let response = await BuchungService.saveBuchung({ ...currentBuchung, betrag: Number(currentBuchung.betrag) })

        if (response.success) {

            //reload the inputs
            this.loadItems2Mg();

            //load the Buchung to show in history
            this.getCurrentSavedBuchung(response.data.buchungHandle, nameMtgl);

            toast.success("Buchung erfolgreich gespeichert.", {
                autoClose: 5000
            });

            trackEvent('Sammelbuchung', 'buchungSave', '');

        } else {
            if (response.data && response.data.message) {
                toast.error("Buchung konnte nicht ausgeführt werden: " + response.data.message, {
                    autoClose: 5000
                });
            } else {
                toast.error("Es gab einen Problem bei der Buchung." + response.data.message, {
                    autoClose: 5000
                });
            }

            trackException('SammelbuchungSave', false);
        }

        this.setState({ isSaving: false })

    }

    numberFormat(number: number) {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    }

    async buchungPrint(buchungID: number) {

        let mgNr = this.state.buchungenHistory[buchungID].mgNr;
        let filenameSuffix = this.state.buchungenHistory[buchungID].fileNameNName;

        const toastId = toast.dark(<div><FontAwesomeIcon icon={faPrint} /> Dokument wird erzeugt,<br />einen Moment bitte...</div>, {
            autoClose: false
        });

        let res = await fetch(UserStore.url + '/mitgliedPrint', {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'responseType': 'arraybuffer'
            },
            body: JSON.stringify({
                attr: {
                    BSTNr: UserStore.username,
                    MGNr: mgNr,
                    DokumentName: "rptMGRechnung",
                    Sprache: "D",
                    Arguments: "RechZahlungsweise:=" + (!this.state.buchungenHistory[buchungID].zahlungsweise ? this.props.zahlungsweise : this.state.buchungenHistory[buchungID].zahlungsweise) + ";RechBuchungHandles:=" + this.state.buchungenHistory[buchungID].buchungHandle + ";"
                }
            })
        });

        let response = await res.json();
        let base64 = response.data.dokument;
        const blob = b64toBlob(base64, "application/octet-stream");

        //hide working message and activate Button
        toast.dismiss(toastId);

        const link = document.createElement("a");
        link.setAttribute('target', '_blank')
        link.href = window.URL.createObjectURL(blob);
        link.download = mgNr + "_" + filenameSuffix + "_Rechnung.pdf";
        link.click();
    }

    async buchungDelete(handle: number) {
        if (window.confirm('Dies Buchung wirklich löschen?')) {

            //Send data
            let res = await fetch(UserStore.url + '/buchungDel', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attr: {
                        BSTNr: Number(UserStore.username),
                        dataset: {
                            BSTNr: UserStore.username,
                            Buchungsart: 1,
                            BuchungTan: handle,
                        }
                    }
                })
            });

            let response = await res.json();

            if (response.success) {
                toast.success("Buchung erfolgreich gelöscht!", {
                    autoClose: 5000
                });

                //delete this from List
                let currentHistory: Buchung[] = [];

                this.state.buchungenHistory.forEach((item) => {
                    if (item.buchungHandle !== handle) {
                        currentHistory.push(item);
                    }
                })

                this.setState({
                    buchungenHistory: currentHistory,
                })

            } else {
                toast.error("Buchung konnte nicht gelöscht werden.", {
                    autoClose: false
                });
            }
        }

    }

    async getAllMitgliederSearch() {

        if (this.state.mgSearchList.length === 0) {

            try {

                let res = await fetch(UserStore.url + '/mitglieder', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                let result = await res.json();

                //save in state for cache
                if (result && result.success) {
                    this.setState({
                        mgSearchList: result.data.mitglieder,
                    });
                }

            } catch (e) {
                console.log("Error in loading Mitgliederliste: " + e);
            }

        }

    }

    // ALH20-105
    isValidPrint(kennzeichen: string, vorgangsart: string, status: number, benutzer: string, bst: string) {
        if (kennzeichen === "H") {
            if ((vorgangsart === "Beitrag" ||
                vorgangsart === "Aufnahmegeb" ||
                vorgangsart === "MahngebVerein" ||
                vorgangsart === "MahngebRA" ||
                vorgangsart === "EMA") && benutzer === "BST" && bst === UserStore.bstNr) {

                if (status === 2) {
                    return true;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    getSumOfBeitraege() {
        let sum = 0

        for (const buchung of this.state.buchungenHistory) {
            sum += buchung.haben ?? 0
        }

        return sum;
    }

    render() {
        const { mGNr = "" } = this.state

        let list = this.state.buchungenHistory;
        list = sortList(this.state.sort, list, this.state.sort_mode, this.state.sort === 'leistungsdatum', 'ts')

        return (
            <div>
                <h3 className="alh_pageheader">Sammelbuchung</h3>
                <div className="alh_subheader">Sie können hier schnell und ohne die Seite zu wechseln Buchungen durchführen. Tragen Sie hierfür zuerst die Mitgliedsnummer ein, klicken Sie auf "Offene Vorgänge laden" und buchen Sie dann bequem und schnell durch.</div>

                <TableOperatorOptions
                    name="Sammelbuchung"
                    showSearch={false}
                    placeholder="Suche nach Name oder Nummer ..."
                    print={true}
                    helpfile="sammelbuchung.json"
                />

                <Container>
                    <Row className="alh_rowTopList">
                        <Col md={5} className={'alh_noprint'}>
                            {this.state.isSaving && <div className="alh_stat_container">
                                <WaveTopBottomLoading color="#ffc800" />
                            </div>}
                            {!this.state.isSaving && <div className="alh_frontBoxes">
                                <Container className="alh_containerNoPadding">
                                    <Row className="">
                                        <Col lg={12}>

                                            <div>Suchen Sie nach dem Namen des Mitgliedes:</div>
                                            <InputGroup className="mb-2 mr-sm-2">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text><FontAwesomeIcon icon={faAddressBook} /></InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <InputSearchField
                                                    placeholder="Namen eingeben"
                                                    values={this.state.mgSearchList}
                                                    onSelItem={(id) => this.setInputValue(InputProperties.mgNameSearch, id)}
                                                />
                                                <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Hier können Sie den Namen des Mitgliedes suchen.</InfoIcon>
                                            </InputGroup>

                                            <div>Oder tragen Sie die Mitgliedsnummer direkt ein:</div>
                                            <InputGroup className="mb-2 mr-sm-2">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text><FontAwesomeIcon icon={faSortNumericDown} /></InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    size="sm"
                                                    id="mgnrSammelbuchung"
                                                    placeholder="Mitgliedsnummer (z.B. 1234-5678)"
                                                    value={mGNr}
                                                    onKeyPress={this.mGNrEnterPress}
                                                    onChange={(e) => this.setInputValue(InputProperties.mgnr, e.target.value)}
                                                />
                                                <InfoIcon space="left" placement="right" containerClassName="alh_infoinputext">Hier können nur Mitgliedsnummern eingegeben werden, die aktuell Ihrer Beratungsstelle zugeordnet sind.</InfoIcon>
                                            </InputGroup>

                                            <Button variant="outline-info"
                                                size='sm'
                                                className='fullSize alhButton alhButtonSpaceBottom'
                                                disabled={mGNr.length !== 9}
                                                onClick={() => this.loadItems2Mg()}>
                                                Offene Vorgänge laden
                                                <span className={"alh_loadingSpinner " + (this.state.loadingitems ? "" : "d-none")}>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Button>
                                            <br />
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <Col lg={12}>
                                            Mitglied: {this.state.mgName}
                                            <br />
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <Col lg={6}>
                                            <div>Vorgang: <InfoIcon space="left" placement="right"><small>Wählen Sie hier
                                                aus Ihren offenen Vorgängen aus.</small></InfoIcon></div>
                                            <SelectField
                                                id='vorgang'
                                                name='vorgang'
                                                withPleaseSelect={true}
                                                options={this.state.vorgangValues}
                                                disabled={this.state.oPs.length === 0}
                                                value={this.state.selectedVorgang}
                                                onChange={(val) => this.setInputValue(InputProperties.vorgang, val, false)}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <div>L-Datum: <InfoIcon space="left" placement="right"><small>Tragen Sie hier
                                                das Zahlungsdatum ein.</small></InfoIcon></div>
                                            <InputField
                                                type='date'
                                                id='leistungsdatum'
                                                name='leistungsdatum'
                                                placeholder='Leistungsdatum'
                                                maxDate={moment().toDate()}
                                                disabled={this.state.oPs.length === 0}
                                                inputClassNames="form-control-sm"
                                                value={moment(this.state.currentBuchung.leistungsdatum).format("YYYY-MM-DD")}
                                                onChange={(val) => this.setInputValue(InputProperties.leistungsdatum, val, true)}
                                            />

                                        </Col>
                                    </Row>
                                    <Row className="alh_containerNoPadding">
                                        <Col lg={6}>
                                            <div>Betrag:</div>
                                            <InputField
                                                type='number'
                                                id='betrag'
                                                name='betrag'
                                                placeholder='Betrag'
                                                disabled={this.state.oPs.length === 0}
                                                inputClassNames="form-control-sm"
                                                value={parseFloat(this.state.currentBuchung.betrag?.toString() ?? '').toFixed(2)}
                                                onChange={(val) => this.setInputValue(InputProperties.betrag, val, false)}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <div>Zahlungsweise (Haben): <InfoIcon space="left" placement="right"><small>Die
                                                bevorzugte Zahlungsweise kann über Meine BST | Grunddaten voreingestellt
                                                werden.</small></InfoIcon></div>
                                            <SelectField
                                                id='zahlungsweise'
                                                name='zahlungsweise'
                                                withPleaseSelect={false}
                                                disabled={this.state.oPs.length === 0}
                                                options={this.state.zahlungsweisen.filter(zahlung => zahlung["filter" + this.state.currentBuchung.buchungsart] === true)}
                                                value={this.state.currentBuchung.zahlungsweise}
                                                onChange={(val) => this.setInputValue(InputProperties.zahlungsweise, val, false)}
                                            />
                                        </Col>
                                    </Row>
                                </Container>

                                {
                                    UserStore.hasBerechtigung('bst_mg', 'write') && new Date(this.state.currentBuchung.leistungsdatum ?? '') <= new Date() && ((this.state.currentBuchung.betrag ?? 0) < (this.state.currentBuchung.betragMax ?? -1) && this.state.currentBuchung.buchungsart === 1) ?
                                        <Button
                                            variant="primary"
                                            className={"alhButton alhButtonFullWidth"}
                                            onClick={() => this.buchungSave(true)}
                                            disabled={this.state.oPs.length === 0 || this.state.isSaving}>
                                            Teilzahlung
                                        </Button> : null
                                }
                                {
                                    UserStore.hasBerechtigung('bst_mg', 'write') && new Date(this.state.currentBuchung.leistungsdatum ?? '') <= new Date() && (this.valueInStaffel(this.state.currentBuchung.betrag ?? 0) && this.state.currentBuchung.buchungsart === 1 && this.state.currentBuchung.betrag !== this.state.currentBuchung.betragMax) ?
                                        <Button
                                            variant="primary"
                                            className={"alhButton alhButtonFullWidth"}
                                            onClick={() => this.buchungSave(false)}
                                            disabled={this.state.oPs.length === 0 || this.state.isSaving}>
                                            Buchen / Sollkorrektur
                                        </Button> : null
                                }
                                {
                                    UserStore.hasBerechtigung('bst_mg', 'write') && new Date(this.state.currentBuchung.leistungsdatum ?? '') <= new Date() && (this.state.currentBuchung.betrag === this.state.currentBuchung.betragMax || this.state.currentBuchung.buchungsart === 2) ?
                                        <Button
                                            variant="primary"
                                            className={"alhButton alhButtonFullWidth"}
                                            onClick={() => this.buchungSave(false)}
                                            disabled={this.state.oPs.length === 0 || this.state.isSaving}>
                                            Buchen
                                        </Button> : null
                                }

                            </div>}
                        </Col>


                        <Col md={7}>
                            {this.state.isSaving && <div className="alh_stat_container">
                                <WaveTopBottomLoading color="#ffc800" />
                            </div>}
                            {!this.state.isSaving && <div className="alh_frontBoxes">
                                Hier sehen Sie alle aktuell gebuchten Einträge:
                                <br />
                                <br />
                                <div className="alh_subheader">
                                    Summe aller aktuell gebuchten Beiträge: {this.numberFormat(this.getSumOfBeitraege())}
                                </div>
                                <Table striped bordered hover size="sm" className="alhTable">
                                    <thead>
                                        <tr>
                                            <th onClick={() => this.sortClick('leistungsdatum')} className={((this.state.sort === 'leistungsdatum') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>L-Datum</th>
                                            <th onClick={() => this.sortClick('buchungstext')} className={((this.state.sort === 'buchungstext') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Buchungstext</th>
                                            <th onClick={() => this.sortClick('mgNr')} className={((this.state.sort === 'mgNr') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort") + " alhtable_nr"}>Nr.</th>
                                            <th onClick={() => this.sortClick('mgName')} className={((this.state.sort === 'mgName') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Name</th>
                                            <th onClick={() => this.sortClick('haben')} className={(((this.state.sort === 'haben') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")) + " text-right"}>Haben</th>
                                            <th onClick={() => this.sortClick('zahlungsweise')} className={((this.state.sort === 'zahlungsweise') ? "alh_sort_active" + (this.state.sort_mode === "desc" ? " alh_sort_active_down" : " alh_sort_active_up") : "alh_sort")}>Zahlungsw.</th>
                                            <th className="alh_tableactions alh_noprint">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map((mg, index) => (
                                            <tr key={index} className={(mg.benutzer !== "BST" && mg.bstNr !== UserStore.bstNr) ? "highlight" : ""}>
                                                <td><Moment format="DD.MM.YYYY">{mg.leistungsdatum}</Moment></td>
                                                <td>{mg.buchungstext}</td>
                                                <td><a href={`/mitglied/${mg.mgNr}`}>{mg.mgNr}</a></td>
                                                <td>{mg.mgName}</td>
                                                <td className="text-right">{(mg.haben !== null) ? this.numberFormat(mg.haben) : ""}</td>
                                                <td>{mg.zahlungsweise}</td>
                                                <td className="tableActions alh_noprint">
                                                    <FontAwesomeIcon icon={faPrint} onClick={() => this.buchungPrint(index)} title="Rechnung drucken" className={(this.isValidPrint(mg.kennzeichen, mg.vorgangsart ?? '', mg.portalStatus, mg.benutzer, mg.bstNr ?? '') ? "faButton faButtonSpaceright" : "d-none")} />
                                                    <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.buchungDelete(mg.buchungHandle ?? -1)} title="Eintrag löschen" className={(mg.portalStatus === 1 ? "faButton" : "d-none")} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </div>}
                        </Col>
                    </Row>
                </Container>


            </div>
        );
    }
}

export default withRouter(Sammelbuchung);
