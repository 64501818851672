import React, { Component } from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import bgImage from '../assets/images/bg_widget_help.png';

const slides = [
    {
        title: 'Beitrittserklärung uploaden?',
        description:
            'Über den Kasten “Upload Dokumente” (alternativ “Meine BST → Uploads”) können Sie im Dropdown “Beitrittserklärung” auswählen, hochladen und direkt an die Hauptverwaltung senden.',
        button: '',
        //image: 'https://www.aktuell-verein.de/wp-content/uploads/2015/07/altbayerischer-lohnsteuerhilfeverein-parallax-index.jpg',
        link: '',
    },
    {
        title: 'Kündigungen uploaden',
        description:
            'Ab sofort ist auch der Upload von Kündigungen möglich. Über den Kasten “Upload Dokumente” (alternativ “Meine BST → Uploads”) können Sie im Dropdown “Kündigung” auswählen, hochladen und direkt an die Hauptverwaltung senden.',
        button: '',
        //image: 'https://www.aktuell-verein.de/wp-content/uploads/2015/07/altbayerischer-lohnsteuerhilfeverein-parallax-index.jpg',
    },
    /*{
        title: 'Welche Formulare im Original?',
        description:
            'Lediglich die Vollmacht (VM)  wird im Original benötigt - alles andere wie Beitrittserklärung (BE), Vollständigkeitserklärung (VE), SEPA reichen auch als Kopie oder Scan.',
        button: '',
        //image: 'https://www.aktuell-verein.de/wp-content/uploads/2015/07/altbayerischer-lohnsteuerhilfeverein-parallax-index.jpg',
    },*/
    {
        title: 'Warum Beratungs­protokoll?',
        description:
            'Zur Schadensprävention empfehlen wir Ihnen unser Bera­tungsprotokoll, welches Ihnen hohe rechtliche Sicherheit bei Beratungsgesprächen bietet. Es hat uns mittlerweile vor zahlreichen Haftungsschäden bewahrt. ' +
            'Grundsätz­lich ist es ausreichend, wenn Sie das Protokoll selbst unterzeichnen. Wir empfehlen das Beratungsprotokoll grundsätzlich ' +
            'bei allen Mitgliedern, egal ob Neu- oder Bestandsmitglied, zu verwenden. Zwingend sollten Sie das Protokoll in folgenden Situationen verwenden:<br />' +
            '<br />' +
            'bei Neumitgliedern<br />' +
            'bei schwierigen Erklärungen<br />' +
            'bei schwierigen Mitgliedern<br />' +
            'bei Online-Beratung',
        button: '',
        //image: 'https://www.aktuell-verein.de/wp-content/uploads/2015/07/altbayerischer-lohnsteuerhilfeverein-parallax-index.jpg',
    },
    {
        title: 'Warum Identifizierung?',
        description:
            'Die nach dem GwG vorgesehene Identifizierung muss seit dem 01.01.2020 zwingend anhand eines inländischen oder nach ausländerrechtlichen Bestimmungen anerkannten oder zugelassenen Passes, Personalausweises oder Pass-oder Ausweisersatzes erfolgen (§ 12 Abs. 1 GwG). Die Iden­tifizierung ist durch Anfertigung einer Kopie des vollstän­digen Dokuments zu dokumentieren. Sie kann auch digital erfasst werden (§ 8 Abs. 2 GwG). Ein vorhandener Frei­schaltcode des Mitglieds genügt nicht.',
        button: '',
        //image: 'https://www.aktuell-verein.de/wp-content/uploads/2015/07/altbayerischer-lohnsteuerhilfeverein-parallax-index.jpg',
    }
];

class VerwaltungstippsBox extends Component {


    render() {

        return (
            <Slider
                clssName="slider-wrapper"
                autoplay={4000}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className="slider-content"
                        style={/*(slide.image) ? { background: `url('${slide.image}') no-repeat center center` } : */{ background: `url('${bgImage}') no-repeat center center` }}
                    >
                        <div className="inner">
                            <h1 className="slider_title">{slide.title}</h1>
                            <p className="slider_body" dangerouslySetInnerHTML={{ __html: slide.description }}></p>
                            {slide.button !== "" ?
                                <button data-link={slide.link}>{slide.button}</button>
                                : null
                            }
                        </div>
                    </div>
                )
                )}
            </Slider>
        );
    }
}

export default VerwaltungstippsBox;
