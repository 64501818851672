import UserStore from "../stores/UserStore";

export const HeartbeatService = {
    heartbeat: async () => {
        let res = await fetch(UserStore.url + '/heartbeat', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'User-Agent': 'request'
            }
        });

        return await res.json();
    }
}