import React, {Component} from 'react';
import gotoAddMitglied from '../../assets/images/user-plus.svg';
import gotoAbrechnung from '../../assets/images/coins.svg';
import gotoMitglieder from '../../assets/images/users.svg';
import {trackEvent} from "../ALHUtility";
import UserStore from "../../stores/UserStore";

class Widgets extends Component {

    render() {
        return (
            <div className="alh_widget_cont">
                <div className="alh_widget_bst" title="BST Nummer">{ UserStore.bstNr }</div>

                {UserStore.hasBerechtigung('bst_mg') &&
                <div className="alh_widget_item" title="Mitgliederliste öffnen">
                    <a href="/mitgliederliste" onClick={() => trackEvent('Menu', 'Widget', 'Mitgliederliste')}>
                        <img src={gotoMitglieder} alt="Mitgliederliste"/>
                    </a>
                </div>
                }

                {UserStore.hasBerechtigung('bst_mgneu') &&
                <div className="alh_widget_item" title="Neues Mitglied anlegen">
                    <a href="/mitglied" onClick={() => trackEvent('Menu', 'Widget', 'Mitglied')}>
                        <img src={gotoAddMitglied} alt="Neues Mitglied"/>
                    </a>
                </div>
                }
                {UserStore.hasBerechtigung('buha_abrechnung') &&
                <div className="alh_widget_item" title="Zur Abrechnung">
                    <a href="/abrechnung" onClick={() => trackEvent('Menu', 'Widget', 'Abrechnung')}>
                        <img src={gotoAbrechnung} alt="Abrechnung"/>
                    </a>
                </div>
                }
            </div>
        );
    }

}

export default Widgets;