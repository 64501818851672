import React, { Component } from 'react';
import { Button, ButtonProps } from "react-bootstrap";
import InfoIcon from "../Blocks/InfoIcon";

interface SubmitButtonProps extends ButtonProps {
    text: string
    infotext?: string
    mode?: string
}

class SubmitButton extends Component<SubmitButtonProps> {
    render() {

        const { size = "lg", disabled = false } = this.props

        return (
            <div className="submitButton">
                <Button
                    variant={this.props.mode}
                    size={size}
                    type={this.props.type}
                    block
                    disabled={disabled}
                    className={"alhButton " + this.props.className}
                    onClick={(e) => this.props.onClick && this.props.onClick(e)}
                >
                    {this.props.text}
                    {this.props.infotext &&
                        <InfoIcon space="left" placement="left" title="Information">
                            {this.props.infotext}
                        </InfoIcon>
                    }
                </Button>
            </div>
        );
    }
}

export default SubmitButton;