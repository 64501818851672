import React, { Component } from 'react';
import { FormControlProps } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import KeyValue from '../../models/KeyValue';

interface SelectFieldProps extends Omit<FormControlProps, 'onChange' | 'values'> {
    containerClassName?: string
    formGroupClassName?: string
    name: string
    options: KeyValue[]
    withPleaseSelect: boolean;
    required?: boolean;
    tabIndex?: number;
    onChange: (value: string) => void
}

class SelectField extends Component<SelectFieldProps> {

    constructor(props: SelectFieldProps) {
        super(props);
        this.change = this.change.bind(this);
    }

    change(event: React.ChangeEvent<FormControlElement>) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    componentDidUpdate(prevProps: SelectFieldProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            })
        }
        if (prevProps.options.length !== this.props.options.length) {
            let thisValue = this.props.value;
            //check if value set still in values
            if (this.props.options && this.props.options.length > 0 && this.props.value !== -1 && this.props.value !== "" && this.props.value !== 0 && !this.props.options.some(item => this.props.value === item.value)) {
                thisValue = this.props.options[0].value;

                console.log("Value update: " + this.props.id + " -> " + thisValue);
                //console.log(this.props.values);

                if (typeof this.props.onChange === "function") {
                    this.props.onChange(thisValue.toString());
                }
            }

            this.setState({
                options: this.props.options,
                value: thisValue,
            })
        }
        if (prevProps.disabled !== this.props.disabled) {
            this.setState({
                disabled: this.props.disabled
            })
        }
        if (prevProps.required !== this.props.required) {
            this.setState({
                required: this.props.required
            })
        }
    }

    render() {
        const { className = 'custom-select', tabIndex = 0, value = -1 } = this.props

        return (
            <div className={this.props.containerClassName + ' inputField'}>

                <Form.Group className={this.props.formGroupClassName}>
                    <Form.Control
                        as="select"
                        name={this.props.name}
                        id={this.props.id}
                        className={className}
                        size={this.props.size}
                        onChange={this.change}
                        disabled={this.props.disabled}
                        required={this.props.required}
                        value={value}
                        tabIndex={tabIndex}
                    >
                        {
                            this.props.withPleaseSelect
                                ? <option key='-1' value=''>-- Bitte wählen --</option>
                                : ''
                        }
                        {this.props.options.map(item => (
                            <option key={item.id} value={item.id}>{item.value}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

            </div>
        );
    }
}

export default SelectField;
